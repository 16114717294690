import { MdDownloading as DownloadIcon } from "react-icons/md";

import Button from "features/ui/Button";

interface FormData {
  name: string;
  vins: string[];
}

interface Props {
  activeStep: number;
  stepsLength: number;
  collectionName: string;
  vins: string[];
  isLoading: boolean;
  collectionNameValid: boolean;
  onFormSubmit: (fd: FormData) => void;
  onStepChange: (step: number) => void;
}

const FormNavigation = ({
  activeStep,
  stepsLength,
  collectionName,
  vins,
  isLoading,
  collectionNameValid,
  onFormSubmit,
  onStepChange,
}: Props) => {
  const isLastStep = activeStep === stepsLength - 1;

  const goToNextStep = () => {
    if (isLastStep) return;

    onStepChange(activeStep + 1);
  };

  const goToPrevStep = () => {
    if (activeStep === 0) return;

    onStepChange(activeStep - 1);
  };

  const nextEnabled = () => {
    if (activeStep === 0) {
      return vins.length > 0;
    }

    if (activeStep === 1) {
      return collectionNameValid;
    }

    if (activeStep === 2) {
      return vins.length > 0 && collectionNameValid;
    }

    return false;
  };

  return (
    <div className="mt-4 flex justify-between">
      {activeStep !== 0 && (
        <Button
          label="Back"
          color="secondary"
          size="small"
          onClick={goToPrevStep}
        />
      )}
      {!isLastStep && (
        <div className="ml-auto">
          <Button
            label="Next"
            disabled={!nextEnabled()}
            color="primary"
            size="small"
            onClick={goToNextStep}
            testId="btn-form-next"
          />
        </div>
      )}
      {isLastStep && (
        <Button
          label={isLoading ? "Saving" : "Save collection"}
          color="primary"
          size="small"
          endIcon={(isLoading && <DownloadIcon />) || <></>}
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() =>
            onFormSubmit({
              name: collectionName,
              vins,
            })
          }
        />
      )}
    </div>
  );
};

export default FormNavigation;
