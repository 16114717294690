import { useFlags } from "launchdarkly-react-client-sdk";

import { useCustomLocalStorageState } from "shared/hooks";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import * as config from "config/config";

import {
  DEFAULT_ISSUES_SORT,
  DEFAULT_SUGGESTED_ISSUES_FILTER,
  DEFAULT_SUGGESTED_ISSUES_SORT,
  ISSUES_CHART_ACTIONS,
  ISSUES_CHART_KEY,
  ISSUES_PAGE_KEY,
  SUGGESTED_ISSUES_CHART_ACTIONS,
  SUGGESTED_ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_PAGE_KEY,
} from "./constants";
import CreateIssue from "./CreateIssue/CreateIssue";
import IssuesTable from "./IssuesTable";
import IssuesTabs from "./IssuesTabs";
import SuggestedIssuesTable from "./SuggestedIssues/SuggestedIssuesTable";
import { getDefaultIssueFilter } from "./utils";

const Issues = () => {
  const { pages } = config.get();
  const { issues, suggestedIssues } = useFlags();

  const issuesEnabled = pages.issues && issues;
  const suggestedIssuesEnabled =
    pages.issues?.suggestedIssues && suggestedIssues;

  const pageTitle =
    suggestedIssuesEnabled && !issuesEnabled ? "Suggested Issues" : "Issues";

  const suggestedIssuesFilterSortState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultSort: DEFAULT_SUGGESTED_ISSUES_SORT,
    defaultFilterValues: DEFAULT_SUGGESTED_ISSUES_FILTER,
  });

  const issuesFilterSortState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: getDefaultIssueFilter(),
    defaultSort: DEFAULT_ISSUES_SORT,
  });

  const [selectedIssueChartOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(ISSUES_CHART_KEY, {
    defaultValue: getDefaultActions(ISSUES_CHART_ACTIONS),
  });

  const [selectedSuggestedIssueChartOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(SUGGESTED_ISSUES_CHART_KEY, {
    defaultValue: getDefaultActions(SUGGESTED_ISSUES_CHART_ACTIONS),
  });

  return (
    <>
      <PageHeaderWrapper>
        <Title text={pageTitle} />
        <PageHeaderActionsWrapper>
          {issuesEnabled && <CreateIssue action="create" />}
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      {issuesEnabled && suggestedIssuesEnabled && <IssuesTabs />}
      {issuesEnabled && !suggestedIssuesEnabled && (
        <IssuesTable
          selectedChartOptions={selectedIssueChartOptions}
          topFilterSortState={issuesFilterSortState}
        />
      )}
      {!issuesEnabled && suggestedIssuesEnabled && (
        <SuggestedIssuesTable
          selectedChartOptions={selectedSuggestedIssueChartOptions}
          topFilterSortState={suggestedIssuesFilterSortState}
        />
      )}
    </>
  );
};

export default Issues;
