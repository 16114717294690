import { addYears, startOfHour, subWeeks } from "date-fns";

import {
  GetIssuesOverviewRequest,
  IssuesOverview,
} from "shared/api/issues/api";
import { useIssuesOverview } from "shared/api/issues/hooks";
import {
  BucketByIssuesEnum,
  ChartActionID,
  GroupByIssuesEnum,
  IssueMeasuresEnum,
  ValueType,
} from "shared/types";

import { ISSUES_CHART_KEY, ISSUES_DASHBOARD_KEY } from "pages/Issues/constants";

import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";

import { IssuesViewType } from "./constants";

const sumTotalCount = (data: IssuesOverview[] | undefined): number => {
  if (!data) return 0;

  return data.reduce((total, item) => total + item.count, 0);
};

export const getMetricData = (
  data: IssuesOverview[] | undefined,
  dataDiff: IssuesOverview[] | undefined
) => {
  // week 1
  const totalCurrent = sumTotalCount(data);
  // week 1 (-) week 2
  const totalDiff = sumTotalCount(dataDiff);

  let percentageDiff = 0;
  if (totalCurrent === 0 && totalDiff === 0) {
    percentageDiff = 0;
  } else if (totalCurrent > 0 && totalCurrent === totalDiff) {
    percentageDiff = 100;
  } else {
    const totalPrevious = totalCurrent - totalDiff;
    percentageDiff = totalPrevious ? (totalDiff / totalPrevious) * 100 : 0;
  }

  return {
    total: totalCurrent,
    diff: percentageDiff,
  };
};

export const getIssuesCount = (
  data: IssuesOverview[] | undefined,
  dataDiff: IssuesOverview[] | undefined
) => {
  // state this week
  const totalIssuesCountCurr = sumTotalCount(data);
  // state previous week
  const totalIssuesCountPrev = sumTotalCount(dataDiff);

  let issuesCountPercentageDiff;

  if (totalIssuesCountCurr === 0 && totalIssuesCountPrev === 0) {
    issuesCountPercentageDiff = 0;
  } else if (totalIssuesCountPrev === 0) {
    issuesCountPercentageDiff = totalIssuesCountCurr > 0 ? 100 : 0;
  } else {
    issuesCountPercentageDiff =
      ((totalIssuesCountCurr - totalIssuesCountPrev) / totalIssuesCountPrev) *
      100;
  }

  return {
    issuesCount: totalIssuesCountCurr,
    issuesCountDiff: issuesCountPercentageDiff,
  };
};

export const useIssuesOverviewData = (
  measure: IssueMeasuresEnum,
  valueType: ValueType | undefined,
  filtersQuery: string,
  viewAt?: string
) => {
  // groupBy value does not really matter since we aggregate it except for
  // issue count since we reuse that data for the graph
  const requestParams: GetIssuesOverviewRequest = {
    measure,
    groupBy: "statusObj.value" as GroupByIssuesEnum,
    bucketBy: "none" as BucketByIssuesEnum,
    lookbackWindow: 1,
    valueType,
    filter: filtersQuery,
    splitByIssueSource: false,
    ...(viewAt && { viewAt }),
  };

  return useIssuesOverview(requestParams);
};

export const getDataDashboardFilters = (
  restrictToStaleIssues: boolean,
  issuesViewType: IssuesViewType,
  userEmail: string,
  groupIds: string[]
) => {
  const statusFilter: FilterRowState = {
    type: "row",
    id: "row-0",
    attribute: "statusObj.value",
    operator: FilterOperator.NOT_IN,
    values: ["Closed"],
  };

  const staleIssuesFilter: FilterRowState = {
    type: "row",
    id: "row-1",
    attribute: "updatedAt",
    operator: FilterOperator.LESS_THAN,
    values: [startOfHour(subWeeks(new Date(), 1)).toISOString()],
  };

  const myIssuesFilter: FilterRowState = {
    type: "row",
    id: "row-2",
    attribute: "assignee",
    operator: FilterOperator.IN,
    values: [userEmail],
  };

  // if we use groupFilter and groupIds is empty, this would result in all issues
  // in that case it actually needs to result in no issues, that's why we use a filter that will never match
  const groupFilter: FilterRowState =
    groupIds.length > 0
      ? {
          type: "row",
          id: "row-3",
          attribute: "assignedGroupID",
          operator: FilterOperator.IN,
          values: groupIds,
        }
      : {
          type: "row",
          id: "row-3",
          attribute: "createdAt",
          operator: FilterOperator.GREATER_THAN,
          values: [startOfHour(addYears(new Date(), 1)).toISOString()],
        };

  const issueViewFilters: Record<IssuesViewType, FilterRowState[]> = {
    myIssues: [myIssuesFilter],
    groupIssues: [groupFilter],
    allIssues: [],
  };

  const filters: FilterGroupState = {
    type: "group",
    id: "group-0",
    anyAll: "all",
    children: [
      statusFilter,
      ...(restrictToStaleIssues ? [staleIssuesFilter] : []),
      ...issueViewFilters[issuesViewType],
    ],
  };

  const filtersQuery = getFiltersQuery(filters);

  return { filtersQuery, filters };
};

export const getChartOptionsAndOpenDashboard = (
  measureType: IssueMeasuresEnum,
  groupBy: string = "name"
) => {
  const chartOptions: Record<
    string,
    { id: ChartActionID; optionId: string }[]
  > = {
    [ISSUES_CHART_KEY]: [
      { id: "measure", optionId: measureType },
      { id: "lookbackWindow", optionId: "1" },
      { id: "valueType", optionId: "percentage" },
      { id: "groupBy", optionId: groupBy },
      { id: "splitByIssueSource", optionId: "" },
      { id: "bucketBy", optionId: "none" },
    ],
  };

  localStorage.setItem(getPageKeyWithVersion(ISSUES_DASHBOARD_KEY), "true");

  return chartOptions;
};
