import * as config from "config/config";

// https://developers.heap.io/docs/web
const runHeapInstall = () => {
  // @ts-ignore
  window.heap = window.heap || [];
  // @ts-ignore
  window.heap.load = function (e, t) {
    // @ts-ignore
    window.heap.appid = e;
    // @ts-ignore
    window.heap.config = t = t || {
      secureCookie: true,
    };
    var r = document.createElement("script");
    r.type = "text/javascript";
    r.async = !0;
    r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
    var a = document.getElementsByTagName("script")[0];
    // @ts-ignore
    a.parentNode.insertBefore(r, a);
    for (
      // @ts-ignore
      var n = function (e) {
          return function () {
            // @ts-ignore
            window.heap.push(
              [e].concat(Array.prototype.slice.call(arguments, 0))
            );
          };
        },
        p = [
          "addEventProperties",
          "addUserProperties",
          "clearEventProperties",
          "identify",
          "resetIdentity",
          "removeEventProperty",
          "setEventProperties",
          "track",
          "unsetEventProperty",
        ],
        o = 0;
      o < p.length;
      o++
    )
      // @ts-ignore
      window.heap[p[o]] = n(p[o]);
  };
};

let loaded = false;

export const init = () =>
  new Promise(async (resolve, reject) => {
    if (loaded) {
      return resolve(null);
    }

    const {
      analytics: { heap },
    } = config.get();

    if (!(heap && heap.id)) {
      // Passing null b/c https://github.com/microsoft/TypeScript/issues/41359
      return reject(null);
    }

    runHeapInstall();

    if (!(window.heap && window.heap.load)) {
      // Passing null b/c https://github.com/microsoft/TypeScript/issues/41359
      return reject(null);
    }

    window.heap.load(heap.id);

    // Passing null b/c https://github.com/microsoft/TypeScript/issues/41359
    loaded = true;

    return resolve(null);
  });
