import { useContext } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { RouteContext } from "services/routes/RouteMiddleware";
import { breadcrumbsConfig } from "services/routesConfig";

import Breadcrumbs from "./Breadcrumbs";

interface Props {
  children?: React.ReactNode;
  skipBreacrumbsAndActions?: boolean;
}

const PageHeaderWrapper = ({ children, skipBreacrumbsAndActions }: Props) => {
  const { landingPage } = useFlags();
  const routeContext = useContext(RouteContext);

  if (skipBreacrumbsAndActions) {
    return (
      <div className="flex justify-between items-center mb-5">{children}</div>
    );
  }

  return (
    <>
      {routeContext && routeContext.breadcrumb && (
        <Breadcrumbs
          routeConfig={breadcrumbsConfig}
          defaultActions={routeContext.routeActions ?? []}
        />
      )}
      {children && (
        <div className="flex justify-between items-center mb-5">
          {children}
          <div className="flex justify-between items-center ml-1">
            {/*If routeActions were already rendered in breadcrumbs above skip
            adding routeActions again*/}
            {landingPage &&
              routeContext &&
              !routeContext.breadcrumb &&
              routeContext.routeActions &&
              routeContext.routeActions.map((action, i) => (
                <div key={i}>{action}</div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeaderWrapper;
