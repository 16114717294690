import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";

import { CreateBookmarkRequest } from "shared/api/bookmarks/api";

import { isValidUrl } from "pages/utils";

import Card from "features/ui/Card";
import Input from "features/ui/Input";
import TextArea from "features/ui/TextArea";

const PATH_EDIT_DISABLED = true;
const TITLE_REQUIRED_TEXT = "Title is required";
const INVALID_URL_TEXT = "The provided URL is invalid";

interface UpdateBookmarkProps {
  bookmark: CreateBookmarkRequest;
  onConfirm: (bookmark: CreateBookmarkRequest) => void;
  onCancel: () => void;
  isEmpty?: boolean;
}

const CreateBookmark = ({
  bookmark,
  onConfirm,
  onCancel,
  isEmpty,
}: UpdateBookmarkProps) => {
  const [title, setTitle] = useState(bookmark.title);
  const [description, setDescription] = useState(bookmark.description);
  const [path, setPath] = useState(bookmark.path);
  const [titleError, setTitleError] = useState<string | null>(null);
  const [pathError, setPathError] = useState<string | null>(null);

  const elementRef = useRef<HTMLInputElement>(null);

  // focus on the title input on render and select text
  useEffect(() => {
    const { current } = elementRef;
    if (current != null) {
      current.focus();
    }
  }, []);

  const handleConfirm = () => {
    const updatedBookmark: CreateBookmarkRequest = {
      title,
      description,
      path,
    };

    if (title.length === 0) {
      setTitleError(TITLE_REQUIRED_TEXT);

      return;
    }

    if (!isValidUrl(path)) {
      setPathError(INVALID_URL_TEXT);

      return;
    }

    onConfirm(updatedBookmark);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
    if (value.length === 0) {
      setTitleError(TITLE_REQUIRED_TEXT);
    } else {
      setTitleError(null);
    }
  };

  return (
    // form mainly for handling enter key press submit, prevent default to prevent refresh
    <Card classNames="min-h-70 max-h-96 min-w-96 max-w-80">
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
        className="flex flex-col gap-3 items-start"
      >
        <span className="text-lg font-semibold">Add to quick start</span>
        <Input
          label="Title"
          value={title}
          error={Boolean(titleError)}
          helperText={titleError}
          onChange={handleTitleChange}
          testId="bookmark-description"
          inputRef={elementRef}
          tabIndex={1}
          sx={{
            ".MuiInputBase-input": {
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            },
          }}
        />

        <Input
          label="URL"
          value={path}
          onChange={({ target: { value } }) => setPath(value)}
          error={Boolean(pathError)}
          helperText={pathError}
          testId="bookmark-description"
          tabIndex={1}
          disabled={isEmpty ? false : PATH_EDIT_DISABLED}
          sx={{
            ".MuiInputBase-input": {
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            },
          }}
        />
        <TextArea
          className="text-sm"
          label="Help text"
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
          testId="bookmark-description"
          tabIndex={1}
          rows={7}
          sx={{
            ".MuiInputBase-input": {
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            },
          }}
        />
        <div className="w-full flex flex-row justify-end gap-2">
          <Button
            size="small"
            variant="contained"
            disabled={Boolean(titleError)}
            onClick={handleConfirm}
            type="submit"
          >
            Save
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={onCancel}
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CreateBookmark;
