import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import { InspectionAnalyticsProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

// TODO: we shouldn't set defaults in code as these can be removed from the API.
// If changing this, make sure to also adjust in claims, repairs & signal event analytics
const DEFAULT_GROUP_BY_ATTRIBUTE = "status";

export const GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT = "Empty";

const INSPECTION_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "inspAnTopContrChartOptions";

const INSPECTION_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "inspAnalyticsTopContGroupByOptions";

const TopContributors = ({
  inspectionsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: InspectionAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    inspectionsFiltersFilterSortState,
    INSPECTION_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.INSPECTION
  );

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.INSPECTION,
    inspectionsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    INSPECTION_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const inspectionsFilters = inspectionsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        groupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    inspectionsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        inspectionsFilters={inspectionsFilters}
        filters={generalFilters}
        onInspectionsFiltersChange={
          inspectionsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        inspectionsFilters={inspectionsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
