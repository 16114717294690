import { useCallback, useEffect, useState } from "react";
import { Location, useBlocker, type BlockerFunction } from "react-router-dom";

export const useNavigationBlocker = (shouldBlock: boolean) => {
  const [blocked, setBlocked] = useState(false);

  const handleBlockedNavigation = useCallback<BlockerFunction>(
    (ev: { currentLocation: Location; nextLocation: Location }) => {
      if (
        shouldBlock &&
        ev.currentLocation.pathname !== ev.nextLocation.pathname
      ) {
        setBlocked(true);

        return true;
      }

      setBlocked(false);

      return false;
    },
    [shouldBlock]
  );

  const blocker = useBlocker(handleBlockedNavigation);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && !shouldBlock) {
      blocker.reset();
    }
  }, [blocker, shouldBlock]);

  const confirmNavigation = () => {
    blocker.proceed?.();
  };

  const cancelNavigation = () => {
    blocker.reset?.();
    setBlocked(false);
  };

  return { blocked, confirmNavigation, cancelNavigation };
};
