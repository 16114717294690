import { SyntheticEvent, useState } from "react";
import classNames from "classnames";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";

import Button from "features/ui/Button";
import { OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY } from "features/ui/Filters/constants";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getTopLevelRowFromFilterGroupState,
  isDefaultAdvancedFilterState,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import {
  FilterChangeProps,
  FilterOperator,
  UseFilterSortState,
} from "features/ui/Filters/types";
import {
  prepareFilterValuesForAPI,
  resetFilters,
} from "features/ui/Filters/utils";
import Input from "features/ui/Input";
import { SchemaEntry } from "features/ui/Table";

import ResetToDefaultButton from "./ResetToDefaultButton";
import { matchesQuery } from "./utils";
import VirtualizedFiltersList from "./VirtualizedFiltersList";

const NO_RESULTS_TEXT = "Nothing found.";
const HEIGHT_CLASS = "h-96";

interface Props {
  schema: SchemaEntry[];
  filterSortState: UseFilterSortState;
  filters: FilterGroupState;
  pendingFilters: FilterGroupState;
  pendingFilterKeysRemoved: string[];
  pendingFilterKeysUpdatedOrAdded: string[];
  defaultFilters?: FilterGroupState;
  baseEntityText?: string;
  testId: string;
  onPendingFiltersChange: (filters: FilterGroupState) => void;
  onPendingFiltersClear?: (accessors?: string[]) => void;
  pageKey: string;
}

const BasicFilters = ({
  filters,
  pendingFilters,
  pendingFilterKeysRemoved,
  pendingFilterKeysUpdatedOrAdded,
  schema,
  filterSortState,
  defaultFilters = DEFAULT_FILTER_BUILDER_STATE,
  baseEntityText,
  testId,
  onPendingFiltersChange,
  onPendingFiltersClear,
  pageKey,
}: Props) => {
  const [query, setQuery] = useState("");
  const [expanded, setExpanded] = useState<string | false>(false);

  // Construct FilterGroupState from keysRemoved based on filters
  const removedFilters: FilterGroupState = {
    type: "group",
    id: "group",
    anyAll: "all",
    children: pendingFilterKeysRemoved.map((key) => {
      const filterRow = getTopLevelRowFromFilterGroupState(key, filters);

      return {
        id: key,
        type: "row",
        attribute: key,
        operator: filterRow?.operator || FilterOperator.NOT_FILTERED,
        values: filterRow?.values || [],
        extra: filterRow?.extra,
      };
    }),
  };

  const newChildren =
    pendingFilters && isDefaultAdvancedFilterState(pendingFilters)
      ? removedFilters.children
      : (pendingFilters?.children || []).concat(removedFilters.children);

  const overviewPendingFilters: FilterGroupState = {
    id: "group-0",
    type: "group",
    anyAll: "all",
    children: newChildren,
  };

  const filteredSchemas =
    query === ""
      ? schema
      : schema.filter(
          ({ label, accessor, filter }) =>
            matchesQuery(label, query) ||
            matchesQuery(accessor, query) ||
            matchesQuery(filter?.label, query)
        );

  const onResetFiltersToDefault = (accessors?: string[]) => {
    // if no accessors, reset all to default
    if (!accessors || accessors.length === 0) {
      onPendingFiltersChange(defaultFilters);

      return;
    }

    const accessorsRemovedFromDefaultFilters =
      getFilterGroupStateTopLevelRowAttributes(defaultFilters).filter(
        (accessor) => accessors.includes(accessor)
      );

    const newPendingFilters = resetFilters(
      pendingFilters,
      accessors,
      accessorsRemovedFromDefaultFilters,
      defaultFilters
    );

    onPendingFiltersChange(newPendingFilters);
  };

  const onClear = () => {
    onPendingFiltersChange(DEFAULT_FILTER_BUILDER_STATE);
  };

  const handleAccordionChange =
    (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const activeFilterKeys = getFilterGroupStateTopLevelRowAttributes(filters);

  const handleOnFilterValuesOrOperatorChange = ({
    key: accessor,
    op_id: operator,
    values = [],
    extra,
  }: FilterChangeProps) => {
    const newPendingFilters = updateOrAddRowFilterGroupState(pendingFilters, {
      id: accessor,
      type: "row",
      attribute: accessor,
      operator,
      values: prepareFilterValuesForAPI(values, operator),
      extra,
    });

    const wasActiveBefore = Boolean(
      activeFilterKeys.includes(accessor) ||
        (pendingFilters &&
          pendingFilters.children.findIndex(
            (child) => child.type === "row" && child.attribute === accessor
          ) > -1)
    );

    // if values empty & was previously applied, remove this filter, then apply
    // if operator = NOT_FILTERED, remove this filter, then apply
    // if operator is in OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY but wasn't applied previously, then apply
    // if operator NOT in OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY & values empty & wasn't previously applied, then don't do anything
    // if operator NOT in OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY & values empty & was previously applied, then remove this filter, then apply
    if (
      values.length === 0 &&
      !wasActiveBefore &&
      !OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY.includes(operator)
    ) {
      return;
    }

    onPendingFiltersChange(newPendingFilters);
  };

  return (
    <>
      <div className="flex items-center px-3 py-1 border-t">
        <FiltersOverview
          filters={overviewPendingFilters}
          onFiltersReset={onPendingFiltersClear}
          hideClearAll={true}
          className="flex flex-wrap gap-1"
          noModalForMultipleValues={true}
          keysUpdated={pendingFilterKeysUpdatedOrAdded}
          keysRemoved={pendingFilterKeysRemoved}
          tableSchema={filteredSchemas}
          baseEntityText={baseEntityText}
        />
        <div className="ml-auto flex flex-row items-center gap-1">
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={onClear}
            className="text-nowrap"
            data-testid={`${testId}-clearAll`}
          >
            Clear
          </Button>

          <ResetToDefaultButton
            onResetToDefault={onResetFiltersToDefault}
            testId={`${testId}-resetToDefault`}
          />
        </div>
      </div>
      <div className="relative border">
        <Input
          type="search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search"
          icon={<SearchIcon size={25} aria-hidden="true" color="gray" />}
          testId="filter-selector-search-input"
        />

        <div
          className={classNames(HEIGHT_CLASS, "flex flex-col mt-1")}
          data-testid="filter-selector-items"
        >
          {filteredSchemas.length === 0 && query !== "" ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              {NO_RESULTS_TEXT}
            </div>
          ) : (
            <VirtualizedFiltersList
              schema={filteredSchemas}
              activeFilterKeys={activeFilterKeys}
              filters={pendingFilters}
              expanded={expanded}
              handleAccordionChange={handleAccordionChange}
              onFilterChange={filterSortState.manageFilterChange}
              onResetFilters={onResetFiltersToDefault}
              filtersInitialized={filterSortState.initialized}
              defaultFilters={defaultFilters}
              onFilterValuesOrOperatorChange={
                handleOnFilterValuesOrOperatorChange
              }
              pageKey={pageKey}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicFilters;
