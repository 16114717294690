import Checkbox, { CheckboxProps } from "features/ui/Checkbox";

interface CheckboxSimple extends Omit<CheckboxProps, "onChange" | "checked"> {
  value: string;
}

interface Props {
  checkboxes: CheckboxSimple[];
  checked: string[]; // list of values (not labels)
  onChange: (checked: string[]) => void;
}

const CheckboxGroup = ({ checkboxes, onChange, checked = [] }: Props) => {
  const onChangeIndividual = (value: string, isChecked: boolean) => {
    let newChecked = [...checked];
    if (isChecked && !checked.includes(value)) {
      newChecked.push(value);
    } else {
      newChecked = newChecked.filter((v) => v !== value);
    }

    onChange(newChecked);
  };

  return (
    <div>
      {checkboxes.map((checkbox) => {
        const { value } = checkbox;
        const isChecked = checked?.includes(value);

        return (
          <div key={value} className="mb-1">
            <Checkbox
              {...checkbox}
              checked={isChecked}
              onChange={(checked) => onChangeIndividual(value, checked)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxGroup;
