import { getFetcher, patchFetcher, putFetcher } from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import { PagesConfig } from "pages/Admin/PagesConfig/types";

import { SelectOption } from "features/ui/Select";

const ADMIN_BASE_ROUTE = "admin";

/**
 * Attributes config
 */
const ATTRIBUTES_CONFIG_BASE = "attributesConfig";

export enum AttributeResource {
  Vehicle = "vehicle",
  Claim = "claim",
  SignalEventOccurrence = "signalEventOccurrence",
  Dealer = "dealer",
  VehicleECU = "vehicleECU",
  VehicleOption = "vehicleOption",
  Issue = "issue",
  SuggestedIssue = "suggestedIssue",
  ServicePlan = "servicePlan",
  ServiceRecommendation = "serviceRecommendation",
  AlertDefinition = "alertDefinition",
  Inspection = "inspection",
  InspectionItem = "inspectionItem",
  Repair = "repair",
  RepairPart = "repairPart",
  CustomSignalEventDefinition = "customSignalEventDefinition",
  FailureMode = "failureMode",
  FailureModeEvent = "failureModeEvent",
  CustomRecord = "customRecord",
  RiskModelPrediction = "riskModelPrediction",
  WarrantyPolicy = "warrantyPolicy",
  WorkOrder = "workOrder",
  Part = "part",
}

export const RESOURCE_OPTIONS: SelectOption[] = Object.entries(
  AttributeResource
).map(([key, value]) => ({
  id: value,
  value: key,
}));

export interface AdminAttributesConfigRequest {
  resource: AttributeResource;
}

export interface AdminAttributesConfigUpdateRequest
  extends AdminAttributesConfigRequest {
  config: object;
}

export interface AdminAttributesConfigResponse {}

export const getAdminAttributesConfigRequestURI = ({
  resource,
  ...params
}: AdminAttributesConfigRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
    params,
  });

export const getAdminAttributesConfig = (args: AdminAttributesConfigRequest) =>
  getFetcher<AdminAttributesConfigResponse>(
    getAdminAttributesConfigRequestURI(args)
  );

export const updateAdminAttributesConfigRequestURI = ({
  resource,
}: AdminAttributesConfigRequest): string =>
  clientV1.getUri({
    method: "put",
    url: createURL([ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
  });

export const updateAdminAttributesConfig = ({
  resource,
  ...args
}: AdminAttributesConfigUpdateRequest) =>
  putFetcher<AdminAttributesConfigResponse>(
    updateAdminAttributesConfigRequestURI({ resource }),
    { data: args.config }
  );

/**
 * Pages config
 */
const PAGES_CONFIG_BASE = "pagesConfig";

export interface PageConfig {
  ID: string;
  config: Record<string, any>;
}

export interface AdminPagesConfigRequest {}

export interface AdminPagesConfigUpdateRequest {
  config: PageConfig[];
}

export const getAdminPagesConfigRequestURI = (
  params: AdminPagesConfigRequest
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
    params,
  });

export const getAdminPagesConfig = (args: AdminPagesConfigRequest) =>
  getFetcher<PagesConfig>(getAdminPagesConfigRequestURI(args));

export const updateAdminPagesConfigRequestURI = (): string =>
  clientV1.getUri({
    method: "PUT",
    url: createURL([ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
  });

export const updateAdminPagesConfig = (args: AdminPagesConfigUpdateRequest) =>
  putFetcher<PagesConfig>(updateAdminPagesConfigRequestURI(), {
    data: args.config,
  });

/**
 * General config
 */
const GENERAL_CONFIG_BASE = "generalConfig";

export interface MetabaseDashboard {
  id: string;
  name: string;
}

export interface Bookmark {
  title: string;
  path: string;
  description?: string;
}

export interface GeneralConfigModel {
  mileageUnit: MileageUnit;
  maxDate?: string;
  globalSearch: boolean;
  flagUserAnon: boolean;
  helpContactEmail?: string;
  rootPage?: string;
  bookmarks: Bookmark[];
  metabaseDashboards: MetabaseDashboard[];
}

export interface GeneralConfigRequest {}

export interface GeneralConfigUpdateRequest {
  config: GeneralConfigModel;
}

export const getGeneralConfigRequestURI = (
  params: GeneralConfigRequest
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
    params,
  });

export const updateGeneralConfigRequestURI = (): string =>
  clientV1.getUri({
    method: "PUT",
    url: createURL([ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
  });

export const updateGeneralConfig = (args: GeneralConfigUpdateRequest) =>
  patchFetcher<GeneralConfigModel>(
    updateGeneralConfigRequestURI(),
    args.config
  );
