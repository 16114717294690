import { generatePath, Link } from "react-router-dom";

import { Issue } from "shared/api/issues/api";
import { SHORT_DATE_FORMAT } from "shared/constants";
import useIssuesSchema from "shared/schemas/issuesSchema";
import { formatDate } from "shared/utils";

import IssueGroupCell from "pages/Issues/IssueGroupCell";

import Card from "features/ui/Card";
import Label from "features/ui/Label";

import { routes } from "services/routes";

import { CreateIssueProps } from "./CardActions";
import CardHeader from "./CardHeader";

interface Props {
  issue: Issue;
  createIssueProps: CreateIssueProps;
}

const CardOverview = ({ issue, createIssueProps }: Props) => {
  const { getDisplayLabel } = useIssuesSchema();

  const {
    assignee,
    assignedGroup,
    description,
    statusObj,
    severityObj,
    notes,
    canEdit,
    externalID,
    createdAt,
    createdBy,
    promotedFromID,
  } = issue;

  const suggestedIssueLink = promotedFromID ? (
    <Link
      to={generatePath(routes.suggestedIssueLatestRun, { id: promotedFromID })}
      className="text-metabase-blue hover:underline"
    >
      suggested issue
    </Link>
  ) : (
    ""
  );

  const originPrefix = promotedFromID ? (
    <span>
      {getDisplayLabel("promotedFrom", "Promoted From")} {suggestedIssueLink}
    </span>
  ) : (
    "Manually created"
  );
  const origin = (
    <span className="text-viaduct-black">
      {originPrefix} by <u>{createdBy}</u> on{" "}
      <u>{formatDate(createdAt, SHORT_DATE_FORMAT)}</u>.
    </span>
  );

  return (
    <Card classNames="!p-5" testId="issue-overview-card">
      <CardHeader
        title="Overview"
        canEdit={canEdit}
        createIssueProps={createIssueProps}
      />
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("statusObj", "Status")} />
          <Label text={statusObj?.value} className="text-viaduct-black" />
        </div>
        <div className="relative ml-2">
          <Label text={getDisplayLabel("severityObj", "Severity")} />
          <Label text={severityObj?.value} className="text-viaduct-black" />
        </div>
        <div className="relative ml-2">
          <Label text={getDisplayLabel("assignee", "Assignee")} />
          <Label text={assignee} className="text-viaduct-black" />
        </div>
        <div className="relative">
          <Label
            text={getDisplayLabel("assignedGroupID", "Responsible Group")}
          />
          <IssueGroupCell
            assignedGroupID={issue.assignedGroupID}
            assignedGroup={assignedGroup}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text="Origin" />
          <Label text={origin} />
        </div>
        <div className="relative ml-2">
          <Label text={getDisplayLabel("externalID", "External ID")} />
          <Label text={externalID} className="text-viaduct-black" />
        </div>
      </div>
      <div className="flex mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("description", "Description")} />
          <p
            data-testid="issue_description"
            className="pl-1 text-viaduct-black"
          >
            {description}
          </p>
        </div>
      </div>
      <div className="flex flex-col mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("notes", "Notes")} />
          <pre
            data-testid="issue_notes"
            className="pl-1 text-viaduct-black whitespace-pre-wrap max-h-[200px] overflow-y-auto break-all"
            style={{ fontFamily: "inherit" }}
          >
            {notes}
          </pre>
        </div>
      </div>
    </Card>
  );
};

export default CardOverview;
