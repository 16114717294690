import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useRepair } from "shared/api/repairs/hooks";
import { EventTypeEnum } from "shared/types";
import {
  getEntityWithExternalIDTitle,
  getTenantMileageUnit,
} from "shared/utils";

import PartItems from "pages/RepairAnalytics/PartItems";
import CardResourceDetails from "pages/shared/CardResourceDetails";

import APIError from "features/ui/APIError/APIError";
import Board from "features/ui/Board/Board";
import CardWithJSONData from "features/ui/CardWithJSONData";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

type Params = {
  id: string;
};

const SingleRepair = () => {
  const { id } = useParams<Params>();

  const { data, isLoading, error } = useRepair({
    ID: id as string,
    mileageUnit: getTenantMileageUnit(),
  });

  if (isLoading) {
    return <Skeleton count={2} height={300} className="mb-3" />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const boardElements: JSX.Element[][] = [
    [
      <CardResourceDetails
        data={data}
        eventType={EventTypeEnum.REPAIR}
        key="repair-details"
        columnsToSkip={["parts"]}
      />,
    ],
    [
      <CardWithJSONData
        title="Repair Data"
        data={data}
        key="repair-other-data"
      />,
    ].filter(Boolean) as JSX.Element[],
  ];

  const { externalID } = data;

  const title = getEntityWithExternalIDTitle(externalID, EventTypeEnum.REPAIR);

  return (
    <>
      <PageHeaderWrapper>
        <Title text={title} />
      </PageHeaderWrapper>
      <div className="flex space-x-10 mb-4 items-center"></div>
      <Board elements={boardElements} />
      {data.parts.length > 0 && <PartItems items={data.parts} />}
    </>
  );
};

export default SingleRepair;
