import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { PageChartSettingsState } from "features/ui/Filters/types";
import { Option } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY } from "./TopContributors";

export const TABLE_SCHEMA: SchemaEntry[] = [
  {
    label: "DPTV",
    accessor: "IPTV",
    dataType: DataType.NUMBER,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "IPTV",
      label: "Cumulative IPTV",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
    }),
  },
  {
    label: "% DPTV change",
    accessor: "IPTV30DayChangeRatio",
    dataType: DataType.JSX,
    sortable: true,
    description: "Percentage change in DPTV in the last 30 days",
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "IPTV30DayChangeRatio",
      label: "% IPTV change",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
    }),
  },
  {
    label: "DPTV - Distinct VINs",
    accessor: "DPTV",
    dataType: DataType.NUMBER,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "DPTV",
      label: "Cumulative DPTV",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
    }),
  },
  {
    label: "% DPTV change",
    accessor: "DPTV30DayChangeRatio",
    dataType: DataType.JSX,
    sortable: true,
    description:
      "Percentage change in DPTV - Distinct VINs in the last 30 days",
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "DPTV30DayChangeRatio",
      label: "% DPTV change",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
    }),
  },
  {
    label: "# of sig. events",
    accessor: "numSignalEventOccurrences",
    dataType: DataType.NUMBER,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "numSignalEventOccurrences",
      label: "# of sig. events",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
    }),
  },
  {
    label: "Affected Vehicles",
    accessor: "numVehicles",
    dataType: DataType.NUMBER,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "numVehicles",
      label: "Affected Vehicles",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Total Vehicles",
    accessor: "totalNumVehicles",
    dataType: DataType.NUMBER,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      fieldName: "totalNumVehicles",
      label: "Total Vehicles",
      search: false,
      disableFiltering: true,
      disableSelectFilters: true,
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
];

export const getInitialSelectedOptions = (
  chartSettings: PageChartSettingsState | undefined,
  actions: ChartAction<Option>[]
): SelectedChartOptions[] => {
  if (!chartSettings) return getDefaultActions(actions);

  const topContributorsSettings = chartSettings[TOP_CONTRIBUTORS_TAB_KEY];
  if (!topContributorsSettings) return getDefaultActions(actions);

  const chartOptions =
    topContributorsSettings[SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY];

  return chartOptions ?? getDefaultActions(actions);
};
