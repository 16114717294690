import React, { ReactNode } from "react";

import { useListAlertDefinitionsAttributes } from "shared/api/alertDefinitions/hooks";
import { useClaimAttributes } from "shared/api/claims/hooks";
import { useCustomRecordAttributes } from "shared/api/customRecords/hooks";
import { useCustomSignalEventDefinitionsAttributes } from "shared/api/customSignalEvents/hooks";
import {
  useFailureModeAttributes,
  useFailureModeEventsAttributes,
  useRiskModelPredictionAttributes,
} from "shared/api/failureModes/hooks";
import {
  useInspectionAttributes,
  useInspectionItemAttributes,
} from "shared/api/inspections/hooks";
import { useIssuesAttributes } from "shared/api/issues/hooks";
import { useListPartsAttributes } from "shared/api/parts/hooks";
import { useListRepairPartsAttributes } from "shared/api/repairParts/hooks";
import { useRepairAttributes } from "shared/api/repairs/hooks";
import { useSensorReadingsAttributes } from "shared/api/sensors/hooks";
import { useServicePlanAttributes } from "shared/api/servicePlans/hooks";
import { useServiceRecommendationsAttributes } from "shared/api/serviceRecommendations/hooks";
import {
  useDealerAttributes,
  useSignalEventsOccurrencesAttributes,
} from "shared/api/signalEvents/hooks";
import { useSuggestedIssuesAttributes } from "shared/api/suggestedIssues/hooks";
import { useVehicleAttributes } from "shared/api/vehicles/hooks";
import { useListWorkOrdersAttributes } from "shared/api/workOrders/hooks";

import EventRegistryContextWrapper from "./EventRegistryContext";
import {
  createGenericContextWrapper,
  UseAttributesHook,
} from "./GenericAttributesContextWrapper";
import VehicleECUsAttributesContextWrapper from "./VehicleECUsAttributesContextWrapper";
import VehicleOptionsAttributeContextWrapper from "./VehicleOptionsAttributesContextWrapper";

export interface ContextWrapComponentProps {
  children: ReactNode;
}

type ContextWrapper = React.FC<ContextWrapComponentProps>;

export enum ContextProviderName {
  AlertDefinition = "AlertDefinition",
  Claim = "Claim",
  CustomRecord = "CustomRecord",
  CustomSignalEventDefinition = "CustomSignalEventDefinition",
  Dealer = "Dealer",
  FailureMode = "FailureMode",
  FailureModeEvents = "FailureModeEvents",
  Inspection = "Inspection",
  InspectionItem = "InspectionItem",
  Issue = "Issue",
  Repair = "Repair",
  RiskModelPrediction = "RiskModelPrediction",
  SensorReading = "SensorReading",
  ServicePlan = "ServicePlan",
  ServiceRecommendation = "ServiceRecommendation",
  SignalEventOccurrence = "SignalEventOccurrence",
  SuggestedIssue = "SuggestedIssue",
  Vehicle = "Vehicle",
  WorkOrder = "WorkOrder",
  Part = "Part",
  RepairPart = "RepairPart",
}

export type ContextName =
  (typeof ContextProviderName)[keyof typeof ContextProviderName];

type ContextProviderConfig = {
  [K in ContextName]: UseAttributesHook;
};

const CONTEXT_PROVIDERS_CONFIG: ContextProviderConfig = {
  [ContextProviderName.AlertDefinition]: useListAlertDefinitionsAttributes,
  [ContextProviderName.Claim]: useClaimAttributes,
  [ContextProviderName.CustomRecord]: useCustomRecordAttributes,
  [ContextProviderName.CustomSignalEventDefinition]:
    useCustomSignalEventDefinitionsAttributes,
  [ContextProviderName.Dealer]: useDealerAttributes,
  [ContextProviderName.FailureMode]: useFailureModeAttributes,
  [ContextProviderName.FailureModeEvents]: useFailureModeEventsAttributes,
  [ContextProviderName.Inspection]: useInspectionAttributes,
  [ContextProviderName.InspectionItem]: useInspectionItemAttributes,
  [ContextProviderName.Issue]: useIssuesAttributes,
  [ContextProviderName.Repair]: useRepairAttributes,
  [ContextProviderName.RiskModelPrediction]: useRiskModelPredictionAttributes,
  [ContextProviderName.SensorReading]: useSensorReadingsAttributes,
  [ContextProviderName.ServicePlan]: useServicePlanAttributes,
  [ContextProviderName.ServiceRecommendation]:
    useServiceRecommendationsAttributes,
  [ContextProviderName.SignalEventOccurrence]:
    useSignalEventsOccurrencesAttributes,
  [ContextProviderName.SuggestedIssue]: useSuggestedIssuesAttributes,
  [ContextProviderName.Vehicle]: useVehicleAttributes,
  [ContextProviderName.WorkOrder]: useListWorkOrdersAttributes,
  [ContextProviderName.Part]: useListPartsAttributes,
  [ContextProviderName.RepairPart]: useListRepairPartsAttributes,
};

// Other context that don't match our /attributes specific interfaces above
const ADDITIONAL_WRAPPERS: ContextWrapper[] = [
  VehicleECUsAttributesContextWrapper,
  EventRegistryContextWrapper,
  VehicleOptionsAttributeContextWrapper,
];

const contextWrapperItems = Object.entries(CONTEXT_PROVIDERS_CONFIG).map(
  ([name, useAttributesHook]) => {
    const GenericContextWrapper = createGenericContextWrapper(
      useAttributesHook,
      name as ContextName
    );

    return { name, GenericContextWrapper };
  }
);

const ContextWrappers: React.FC<ContextWrapComponentProps> = ({ children }) => {
  // First, wrap with the generic context wrappers
  let wrappedChildren = contextWrapperItems.reduce(
    (acc, { GenericContextWrapper }) => (
      <GenericContextWrapper>{acc}</GenericContextWrapper>
    ),
    children
  );

  // Wrap with the additional context wrappers
  wrappedChildren = ADDITIONAL_WRAPPERS.reduce(
    (acc, Wrapper) => <Wrapper>{acc}</Wrapper>,
    wrappedChildren
  );

  return wrappedChildren;
};

export default ContextWrappers;
