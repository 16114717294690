import { FailureModeEventsTimelineBucket } from "shared/api/failureModes/api";
import { increaseOneMonth } from "shared/utils";

import { GraphDataBucket } from "./EventsGraph";

// We convert from group label, which is in "YYYY-mm" format to timestamp, so graph can ingest it.
// As we want to show also the months without any failures/repairs, we need to manually fill the gaps in the data.
export const formatDataForRollingGraph = (
  data: FailureModeEventsTimelineBucket[]
): GraphDataBucket[] => {
  if (data.length === 0) {
    return [];
  }

  const preparedData = [];
  const firstGroup = data[0].month;
  const lastGroup = data[data.length - 1].month;
  for (let i = firstGroup; i <= lastGroup; i = increaseOneMonth(i)) {
    const entry = data.find((entry) => entry.month === i);
    // we do middle of the month to avoid timezone conversion problems
    const timestamp = new Date(`${i}-15`).getTime();
    preparedData.push({
      ts: timestamp,
      countFailures: entry?.failures || 0,
      countRepairs: entry?.proactiveRepairs || 0,
      countTotal: entry?.totalRepairs || 0,
      countFailuresPTV: entry?.failuresPTV || 0,
      countRepairsPTV: entry?.proactiveRepairsPTV || 0,
      countTotalPTV: entry?.totalRepairsPTV || 0,
    });
  }

  return preparedData;
};
