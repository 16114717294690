import {
  Autocomplete,
  AutocompleteValue,
  SelectProps,
  TextField,
} from "@mui/material";

import { TestProps } from "shared/types";

import { SelectOption } from "features/ui/Select";

interface Props<
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
> extends TestProps {
  label?: string;
  options: SelectOption<T>[];
  groupedOptions?: SelectOption<T>[][];
  selectedOption: AutocompleteValue<
    SelectOption<T>,
    Multiple,
    DisableClearable,
    false
  >;
  onSelectedOptionChange: (
    value: AutocompleteValue<SelectOption<T>, Multiple, DisableClearable, false>
  ) => void;
  className?: string;
  width?: number;
  fullWidth?: SelectProps["fullWidth"];
  disabled?: SelectProps["disabled"];
  disableClearable?: DisableClearable;
  placeholder?: string;
  multiple?: Multiple;
}

const DEFAULT_WIDTH = 250;

const HORIZONTAL_LINE = <hr className="m-2" />;

const DropdownWithSearch = <
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({
  label = "",
  options,
  groupedOptions,
  selectedOption,
  onSelectedOptionChange,
  className = "",
  width = DEFAULT_WIDTH,
  fullWidth = false,
  testId,
  placeholder,
  multiple = false as Multiple,
  ...otherProps
}: Props<T, Multiple, DisableClearable>) => {
  if (groupedOptions) {
    const optionsWithGroups: SelectOption<T>[] = [];
    groupedOptions.forEach((opList, index) =>
      opList.forEach((op) => {
        optionsWithGroups.push({ ...op, group: index.toString() });
      })
    );
    options = optionsWithGroups;
  }

  return (
    <Autocomplete
      multiple={multiple}
      disablePortal
      options={options}
      groupBy={(option) => option.group ?? ""}
      fullWidth={fullWidth}
      sx={fullWidth ? undefined : { width }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          placeholder={placeholder}
        />
      )}
      renderGroup={
        groupedOptions
          ? (params) => (
              <li key={params.key}>
                {params.group !== "0" && HORIZONTAL_LINE}
                <ul>{params.children}</ul>
              </li>
            )
          : undefined
      }
      size="small"
      getOptionLabel={(option) => option.value.toString() || ""}
      className={className}
      value={selectedOption}
      onChange={(_, value) => {
        onSelectedOptionChange(value as any);
      }}
      data-testid={testId}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...otherProps}
    />
  );
};

export default DropdownWithSearch;
