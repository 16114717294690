import { provideQueryStringNavigation } from "duck/graph/PageHandler/utils";
import { Stack } from "@mui/material";

import DuckMessages from "./DuckMessages";
import DuckResetMemory from "./DuckResetMemory";
import DuckTextInput from "./DuckTextInput";
import { useQueryStringNavigation, useThreadId } from "./hooks";

const DuckOpenContent = () => {
  const { threadId, resetThreadId } = useThreadId();
  // We make the queryStringNavigation functions available to the agent
  // and the tools and handlers used by the agent.
  const queryStringNavigation = useQueryStringNavigation();
  provideQueryStringNavigation(queryStringNavigation);

  return (
    <Stack height="100%" paddingX={0.5} display="flex" flexGrow={1}>
      <Stack
        display="flex"
        direction="column"
        flexGrow={1}
        overflow="hidden"
        height="100%"
      >
        <DuckMessages updateLocation={queryStringNavigation.updateLocation} />
      </Stack>
      <Stack className="py-1" direction="row" spacing={0.5} flexGrow={0}>
        <DuckResetMemory resetThreadId={resetThreadId} />
        <DuckTextInput
          threadId={threadId}
          acquireLocationInformation={queryStringNavigation.deliverLocationInfo}
          clearLocationInfo={queryStringNavigation.clearLocationInfo}
        />
      </Stack>
    </Stack>
  );
};

export default DuckOpenContent;
