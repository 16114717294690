import { Dispatch, SetStateAction } from "react";
import Skeleton from "react-loading-skeleton";
import { TooltipProps, XAxisProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Margin } from "recharts/types/util/types";
import { Alert } from "@mui/material";

import { APIErrorResponse } from "shared/api/api";
import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";
import { ServiceRecord } from "shared/api/serviceRecords/api";
import { getTenantServiceRecordName } from "shared/utils";

import { MAX_LIMIT_EVENTS } from "pages/VINView/constants";

import APIError from "features/ui/APIError";
import {
  MANUFACTURE_DATE_LINE_COLOR,
  REFERENCE_LINE_COLOR,
  STARTED_DRIVING_AT_COLOR,
} from "features/ui/charts/constants";
import ScatterChart, {
  DataRow,
  VehicleReferenceLineType,
} from "features/ui/charts/ScatterChart/ScatterChart";
import { LegendConfig, ZoomProps } from "features/ui/charts/types";

import EventsTimelineChartTooltip from "./EventsTimelineChartTooltip";
import { ProcessedVehicleEvent, SignalEventPoint } from "./utils";

interface Props {
  signalEventPoints: SignalEventPoint[];
  sensorChartsPresent: boolean;
  rows: DataRow[];
  setHoveredSignal: Dispatch<SetStateAction<string>>;
  vehicleHistoryEvents?: VehicleReferenceLineType[];
  serviceRecordReferenceLines?: ProcessedVehicleEvent[];
  onReferenceLineClick?: (data: ServiceRecord) => void;
  isLoading?: boolean;
  error?: APIErrorResponse;
  margin?: Margin;
  grouping: SensorReadingsTimelineGrouping;
  sharedXAxisProps: XAxisProps;
  signalEventDescriptions: Record<string, string>;
}

const X_AXIS_LABEL = "Date";
const TOP_PX_TO_SENSORS_CHART = 45;

export const LEGEND_CONFIG: LegendConfig = {
  labels: [
    {
      label: `${getTenantServiceRecordName(false)} (click lines for details)`,
      color: REFERENCE_LINE_COLOR,
    },
    { label: "Manufacture date", color: MANUFACTURE_DATE_LINE_COLOR },
    { label: "Started driving at", color: STARTED_DRIVING_AT_COLOR },
  ],
};

const chartTooltipProps = (
  grouping: SensorReadingsTimelineGrouping,
  xAxisLabel: string,
  descriptions: Record<string, string>
) => ({
  content: (props: TooltipProps<ValueType, NameType>) => (
    <EventsTimelineChartTooltip
      {...props}
      grouping={grouping}
      xAxisLabel={xAxisLabel}
      descriptions={descriptions}
    />
  ),
});

const EventsTimelineGraph = ({
  signalEventPoints,
  sensorChartsPresent,
  isLoading,
  error,
  rows,
  setHoveredSignal,
  vehicleHistoryEvents,
  serviceRecordReferenceLines,
  onZoom,
  onZoomOut,
  onMouseMove,
  currentZoom,
  sharedXAxisProps,
  cursorX,
  onZoomReferenceAreaChange,
  onSyncDateWithZoom,
  zoomReferenceAreaOverride,
  onReferenceLineClick,
  margin,
  grouping,
  signalEventDescriptions,
}: Props & ZoomProps) => {
  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading) {
    return (
      <div className="my-10">
        <Skeleton
          height={400}
          count={1}
          containerClassName="lg:flex flex-1 lg:space-x-4"
        />
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: sensorChartsPresent ? -TOP_PX_TO_SENSORS_CHART : "auto",
      }}
    >
      <ScatterChart
        margin={{
          bottom: 10,
          ...margin,
        }}
        xAxisKey="ts"
        xAxisLabel={X_AXIS_LABEL}
        xAxisProps={sharedXAxisProps}
        yAxisKey="signal"
        yAxisLabel="Signal Event"
        zAxisKey="count"
        zAxisLabel="Number of Events"
        serviceEventsReferenceLines={serviceRecordReferenceLines}
        vehicleReferenceLines={vehicleHistoryEvents}
        rows={rows}
        cursorX={cursorX}
        onZoom={onZoom}
        onSyncDateWithZoom={onSyncDateWithZoom}
        onZoomReferenceAreaChange={onZoomReferenceAreaChange}
        zoomReferenceAreaOverride={zoomReferenceAreaOverride}
        onMouseMove={onMouseMove}
        tooltipProps={chartTooltipProps(
          grouping,
          X_AXIS_LABEL,
          signalEventDescriptions
        )}
        onDotHover={(event: any) => setHoveredSignal(event.signal)}
        onDotLeave={() => setHoveredSignal("")}
        onReferenceLineClick={onReferenceLineClick}
        // we have to always show this so that left margins are applied correctly
        showYAxis={true}
        enableZoom={true}
        zoomOverride={currentZoom}
        onZoomOut={onZoomOut}
        legendConfig={sensorChartsPresent ? undefined : LEGEND_CONFIG}
        signalEventDescriptions={signalEventDescriptions}
      />
      {signalEventPoints.length === MAX_LIMIT_EVENTS && (
        <Alert severity="info">
          Some of the events might not be shown due to large volume.
        </Alert>
      )}
    </div>
  );
};

export default EventsTimelineGraph;
