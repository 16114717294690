export const getCriteriaUpdatedText = ({
  hasChangedVehicleFilters,
  hasChangedClaimFilters,
  hasChangedSignalEventFilters,
}: {
  hasChangedVehicleFilters: boolean;
  hasChangedClaimFilters: boolean;
  hasChangedSignalEventFilters: boolean;
}): string => {
  const criteriaUpdatedText = [];
  if (hasChangedClaimFilters) {
    criteriaUpdatedText.push("Claim criteria");
  }

  if (hasChangedVehicleFilters) {
    criteriaUpdatedText.push("At-Risk Vehicle criteria");
  }

  if (hasChangedSignalEventFilters) {
    criteriaUpdatedText.push("Signal Event criteria");
  }

  return criteriaUpdatedText.join(", ");
};
