import { EntityAttribute, ListAttributesRequest } from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI } from "shared/api/hooks";
import { listRepairPartsAttributesRequestURI } from "shared/api/repairParts/api";

export const useListRepairPartsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listRepairPartsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
