import { EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useSEByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  cumulativeEvents,
  totalVehicles,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option } from "features/ui/Select";

export const useGetByVehicleAgeChartActions = (
  eventType: EventTypeLabel
): ChartAction<Option>[] =>
  getByVehicleAgeChartOptions(
    [
      cumulativeDPTV,
      cumulativeDPTVDV,
      totalVehicles,
      cumulativeEvents(eventType),
    ],
    useByVehicleAgeBirthdayOptions(),
    useSEByVehicleAgeExposureOptions()
  );
