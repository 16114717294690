import { GENERIC_FILTER_WITHOUT_SELECT } from "shared/filterDefinitions";

import { ASSOCIATED_CLAIMS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  DEFAULT_EMPTY_OCCURS_FILTER,
  DEFAULT_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import { OccursFilterState } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import {
  PageChartSettingsState,
  UseFilterSortState,
} from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import * as config from "config/config";

import {
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "./constants";

export const getDefaultClaimFilters = (): FilterGroupState<FilterRowState> => {
  const {
    pages: { claimAnalytics },
  } = config.get();

  return (
    filterBuilderQueryToFilterBuilderState(claimAnalytics?.defaultFilters) ||
    DEFAULT_FILTER_BUILDER_STATE
  );
};

const getInitialAppliedWindowSize = (
  chartSettings: PageChartSettingsState | undefined
): number => {
  if (!chartSettings) return DEFAULT_WINDOW_SIZE;

  const tabSettings = chartSettings[ASSOCIATED_CLAIMS_TAB_KEY];
  if (!tabSettings) return DEFAULT_WINDOW_SIZE;

  const windowSizeOptionsSettings =
    tabSettings[SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY];
  if (!windowSizeOptionsSettings) return DEFAULT_WINDOW_SIZE;

  const windowSizeOption = windowSizeOptionsSettings?.find(
    (element) => element.id === WINDOW_SIZE_KEY
  );
  if (!windowSizeOption) return DEFAULT_WINDOW_SIZE;

  return parseInt(String(windowSizeOption.optionId));
};

export const createOccursFilter = (
  claimsFilterSortState: UseFilterSortState,
  windowSize?: number
): OccursFilterState => ({
  ...DEFAULT_EMPTY_OCCURS_FILTER,
  filters:
    getFiltersQuery(claimsFilterSortState.filters) ??
    DEFAULT_EMPTY_OCCURS_FILTER.filters,
  windowSize:
    windowSize ??
    getInitialAppliedWindowSize(claimsFilterSortState.chartSettings),
});

export const getDefaultSchema = (selectedPeriod: string): SchemaEntry[] => [
  {
    label: "Association strength",
    accessor: "associationStrength",
    dataType: DataType.NUMBER,
    sortable: true,
    description:
      "A measure of the association between the signal events and the set of claims. In particular, the association strength shows how many times more likely a vehicle which experiences the signal event is to go on to eventually have a claim than the average vehicle in the population (for example, association strength higher than 1 indicates that vehicles are more likely to experience the failure if they’ve experienced the signal event). A higher association strength indicates a higher likelihood of a non-random relationship between the signal event and  the set of claims. Empty cell indicates a failure without sufficient evidence of correlation.",
  },
  {
    label: "Vehicles w/ Co-occurrence",
    accessor: "vehiclesWithCooccurrence",
    dataType: DataType.NUMBER,
    description:
      "Number of unique vehicles that experienced both the base signal event and an associated claim within the time window.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Vehicles w/ Co-occurrence",
      fieldName: "vehiclesWithCooccurrence",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Vehicles",
    accessor: "numAssociatedVehicles",
    dataType: DataType.NUMBER,
    description: `Number of unique vehicles that experienced the associated claim.`,
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Associated Vehicles",
      fieldName: "numAssociatedVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Base Vehicles",
    accessor: "baseVehicles",
    dataType: DataType.NUMBER,
    description:
      "Number of unique vehicles that experienced a base signal event.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Base Vehicles",
      fieldName: "baseVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Total Vehicles",
    accessor: "totalVehicles",
    dataType: DataType.NUMBER,
    description: "Number of eligible vehicles.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Total Vehicles",
      fieldName: "totalVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
];
