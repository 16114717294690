import { useState } from "react";

import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";

import { DEFAULT_SUGGESTED_ISSUES_FILTER } from "pages/Issues/constants";
import { SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY } from "pages/Issues/SuggestedIssuesPage";

import DropdownSelect from "features/ui/DropdownSelect";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

interface Props {
  filterSortState: UseFilterSortState;
}

const SUGGESTED_ISSUES_FILTER_LABEL = "Suggested Issue Filters";

const SuggestedIssuesFilters = ({ filterSortState }: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(true);

  const { schema } = useSuggestedIssuesSchema();

  const { filters, isAdvancedFilterEditor } = filterSortState;

  const suggestedIssueFilterLabel = getFilterLabel(
    SUGGESTED_ISSUES_FILTER_LABEL,
    filters
  );

  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  return (
    <div className="mb-3">
      <div className="flex">
        <DropdownSelect
          label={suggestedIssueFilterLabel}
          testId="suggested-issues-filters-dropdown"
          open={filterDropdownOpen}
          onOpen={(open) => {
            setFilterDropdownOpen(open);
          }}
          hasAdvancedFilters={isAdvancedFilterEditor}
          content={
            <FilterSelector
              schema={schema}
              filterSortState={filterSortState}
              title={SUGGESTED_ISSUES_FILTER_LABEL}
              testId="suggested-issues-filters"
              pendingFiltersKey={SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY}
              onCloseFilters={() => {
                setFilterDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedFilterEditor}
              defaultFilters={DEFAULT_SUGGESTED_ISSUES_FILTER}
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: SUGGESTED_ISSUES_FILTER_LABEL,
            filters,
            schema,
          },
        ]}
      />
    </div>
  );
};

export default SuggestedIssuesFilters;
