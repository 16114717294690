import { NonEmptyStringArray, StringSetter } from "duck/graph/types";
import { DynamicTool, tool } from "@langchain/core/tools";

const getRetrieveTopContributorsGroupByOptionsTool = (
  groupByOptions: NonEmptyStringArray,
  setEphemeralMessage: StringSetter
): DynamicTool => {
  const retrieveTopContributorsGroupByOptions = async () => {
    setEphemeralMessage("retrieving groupBy options");
    console.debug(`retrieving ${groupByOptions.length} groupBy attributes`);

    return `Retrieved ${groupByOptions.length} groupBy options.\n\n* ${groupByOptions.join("\n* ")}`;
  };

  return tool(retrieveTopContributorsGroupByOptions, {
    name: "retrieveTopContributorsGroupByOptions",
    description: `Use this tool to identify the groupBy options that are available for use on the Top Contributors tab.
  This tool should be called before calling the 'goToTopContributorsTab' tool.`,
  });
};

export default getRetrieveTopContributorsGroupByOptionsTool;
