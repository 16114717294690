import { FormControlLabel, Switch } from "@mui/material";

import { API_DATE_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

import { PageConfigProps, VehiclesConfig } from "pages/Admin/PagesConfig/types";

import DatePickerRange from "features/ui/DatePickerRange";

import DefaultSignalEventFilterSelector from "./DefaultSignalEventFilterSelector";
import SingleVehicleDateAttributeSelect from "./SingleVehicleDateAttributeSelect";

const EVENT_TIMELINE_KEY = "eventTimeline";
const EVENT_TIMELINE_FROM_DATE_KEY = "eventTimelineFromDate";
const EVENT_TIMELINE_TO_DATE_KEY = "eventTimelineToDate";
const EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY =
  "eventTimelineFromDateVehicleAttribute";
const EVENT_TIMELINE_SIG_EV_FILTERS_KEY =
  "eventTimelineDefaultSignalEventFilters";

const VehiclesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<VehiclesConfig>) => (
  <div className="border p-2">
    <FormControlLabel
      control={
        <Switch
          checked={config[EVENT_TIMELINE_KEY] ?? false}
          onChange={(_, checked) =>
            onConfigChange(pageId, EVENT_TIMELINE_KEY, checked)
          }
          size="small"
        />
      }
      label={<div className="text-xs">Show event timeline on VINView</div>}
    />
    {config.eventTimeline && (
      <div className="flex flex-col space-y-3 text-xs mt-2">
        <SingleVehicleDateAttributeSelect
          selectedAttribute={
            config[EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY]
          }
          onAttributeChange={(attr) =>
            onConfigChange(
              pageId,
              EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY,
              attr
            )
          }
        />
        <DatePickerRange
          startDateLabel="From date"
          endDateLabel="To date"
          initialDateStart={
            config[EVENT_TIMELINE_FROM_DATE_KEY]
              ? new Date(config[EVENT_TIMELINE_FROM_DATE_KEY])
              : null
          }
          initialDateEnd={
            config[EVENT_TIMELINE_TO_DATE_KEY]
              ? new Date(config[EVENT_TIMELINE_TO_DATE_KEY])
              : null
          }
          onChange={(dateFrom, dateTo) => {
            onConfigChange(
              pageId,
              EVENT_TIMELINE_FROM_DATE_KEY,
              dateFrom
                ? formatDate(dateFrom.toISOString(), API_DATE_FORMAT)
                : null
            );
            onConfigChange(
              pageId,
              EVENT_TIMELINE_TO_DATE_KEY,
              dateTo ? formatDate(dateTo.toISOString(), API_DATE_FORMAT) : null
            );
          }}
        />
        <DefaultSignalEventFilterSelector
          initialFilterQuery={config[EVENT_TIMELINE_SIG_EV_FILTERS_KEY]}
          onChange={(filterQuery) =>
            onConfigChange(
              pageId,
              EVENT_TIMELINE_SIG_EV_FILTERS_KEY,
              filterQuery
            )
          }
        />
      </div>
    )}
  </div>
);

export default VehiclesConfigPage;
