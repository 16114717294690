import { Article } from "./types";

export const articles: Article[] = [
  {
    title: "Applying Filters",
    url: "https://viaduct-ai.atlassian.net/wiki/external/YTM5N2Y0YzY2M2U2NDUxZTljZTczYmQzMDE2Y2NiZDg",
  },
  {
    title: "Creating Pareto Charts on Claims",
    url: "https://viaduct-ai.atlassian.net/wiki/external/NTNjNTM3YmM1OGUyNDI2YWJhMjJlNmY5NzQwNzYxYzE",
  },
  {
    title: "Creating Pareto Charts on Signal Events",
    url: "https://viaduct-ai.atlassian.net/wiki/external/MGYxOTNkNmRmNDAyNGZhYTkzYmEyOTlhNGYyNTE2YzM",
  },
  {
    title: "Creating Issues to Track Quality Investigations",
    url: "https://viaduct-ai.atlassian.net/wiki/external/NDBlMTU0YjY0ZGJmNDU4Y2I2MjA3MDE2ODJhOWRjYmY",
  },
  {
    title: "Detecting Emerging Issues Using Suggested Issues",
    url: "https://viaduct-ai.atlassian.net/wiki/external/MzY5MDdkZTQ0MDliNDRhNzlmZDUzYWIyMzc5ZGI1OGI",
  },
  {
    title: "Glossary of Terms",
    url: "https://viaduct-ai.atlassian.net/wiki/external/ODM5NTZjZDYxYjkyNDg3Y2JkNTA2ZmExZjYxMjIwNzI",
  },
  {
    title: "Identify Text Patterns in Repair Notes",
    url: "https://viaduct-ai.atlassian.net/wiki/external/MTRjZjFhMDM1NzZhNGE4NzkwZjNlMjNlN2NmZTkwZjA",
  },
  {
    title: "Monitoring Countermeasure Impact",
    url: "https://viaduct-ai.atlassian.net/wiki/external/MDRiNmQxYzE4NGU1NGQ5ZmExOTNjOTk4ZTNiZjM5OGU",
  },
  {
    title: "Viewing Recent Vehicle Faults and Claims",
    url: "https://viaduct-ai.atlassian.net/wiki/external/OTFiMGJjZGUyNmIwNGYwMWJmODMzZmEwNDI1ODAwYzk",
  },
];
