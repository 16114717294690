import {
  format,
  startOfDay,
  startOfHour,
  startOfISOWeek,
  startOfMinute,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { XAxisProps } from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import {
  DATE_WITH_TIME_FORMAT,
  LONG_DATE_FORMAT,
  MONTH_YEAR,
  SHORT_DATE_FORMAT,
} from "shared/constants";
import { ChartActionID } from "shared/types";

import { YAxisValTopContributors } from "pages/constants";

import { Option } from "features/ui/Select";

import { ChartAction, SelectedChartOptions } from "./Actions/types";
import {
  BAR_COLOR_SELECTED,
  COLOR_PALETTE,
  TIMESTAMP_X_AXIS_KEY,
} from "./constants";
import { DataElement, YAxisLine } from "./types";

export interface LineChartXAxisProps extends XAxisProps {
  granularity?: Granularity;
}

export const enum Granularity {
  MONTH_YEAR = "month_year",
}

// 1 year in milliseconds
const YEAR_MS = 31536000000;
// 1 decade in milliseconds
const DECADE_MS = YEAR_MS * 10;
// 1 month in milliseconds
const MONTH_MS = 2629632000;
// 1 week in milliseconds
const WEEK_MS = 604800000;
// 1 day in milliseconds
export const DAY_MS = 86400000;
// 1 hour in milliseconds
const HOUR_MS = 3600000;
// 1 minute in milliseconds
const MINUTE_MS = 60000;

const MIS_TO_DIS_FACTOR = 30;

export const getDefaultActions = (
  actions: ChartAction[]
): SelectedChartOptions<Option>[] =>
  actions.map(({ id, options, defaultOptionId }) => {
    const firstOptionId =
      options && options.length > 0
        ? (options[0]?.id as string)
        : // options are sometimes loaded async, so we need to handle the case where options are not yet available
          defaultOptionId || "";
    const optionId =
      (options?.find((x) => x.id === defaultOptionId) && defaultOptionId) ||
      firstOptionId;

    return { id, optionId };
  });

export const getSelectedChartActionOptionName = (
  selectedOptions: SelectedChartOptions[],
  { id, options }: ChartAction
) => {
  const firstOptionId = options && options.length > 0 ? options[0].id : "";
  const selectedActionId =
    selectedOptions?.find((x) => x.id === id)?.optionId || firstOptionId;

  return String(options?.find((x) => x.id === selectedActionId)?.value);
};

export const getColor = (index: number) =>
  COLOR_PALETTE[index % COLOR_PALETTE.length];

export const trimAxisLabel = (label?: string, maxYAxisLength?: number) => {
  const numOfDots = 2;
  if (!label || !maxYAxisLength) return label;

  return label.length > maxYAxisLength
    ? label.substring(0, maxYAxisLength - numOfDots) +
        [...Array(numOfDots)].map(() => ".").join("")
    : label;
};

const Y_DOMAIN_PERCENTAGE_OF_MAX_TO_INCREASE = 5;
export const getYAxisDomainDefault = (
  yAxisLine?: YAxisLine,
  data?: DataElement[]
): AxisDomain => {
  if (!yAxisLine || !data?.length) return ["auto", "auto"];

  const key = yAxisLine.key;

  const values = data.filter((x) => x.hasOwnProperty(key)).map((x) => x[key]);

  const { bottom, top } = getYDomainWithAddedPercentageOfMax(
    Math.min(...values),
    Math.max(...values)
  );

  return [bottom, top];
};

export const getYDomainWithAddedPercentageOfMax = (
  min: number | string,
  max: number | string
): Record<"bottom" | "top", string | number> => {
  if (typeof min === "string" || typeof max === "string")
    return {
      bottom: min,
      top: max,
    };

  const valueToAdd = max * (Y_DOMAIN_PERCENTAGE_OF_MAX_TO_INCREASE / 100);
  const minToUse = valueToAdd * -1;
  const maxToUse = `dataMax + ${valueToAdd}`;

  return {
    bottom: minToUse,
    top: maxToUse,
  };
};

const sortTicks = (ticks: number[]): number[] =>
  Array.from(new Set(ticks)).sort((a, b) => b - a);

const generateTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number,
  tsFunc: (_: Date) => Date
): number[] => {
  const ticks = [];
  let currentTs = maxTimestamp;
  while (currentTs >= minTimestamp) {
    ticks.push(tsFunc(new Date(currentTs)).getTime());
    currentTs = currentTs - interval;
  }

  return sortTicks(ticks);
};

export const getMinutelyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(MINUTE_MS / 2)
) => {
  const ticks = generateTicks(
    minTimestamp,
    maxTimestamp,
    interval,
    startOfMinute
  );

  return sortTicks(ticks);
};

export const getHourlyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(HOUR_MS / 2)
) => {
  const ticks = generateTicks(
    minTimestamp,
    maxTimestamp,
    interval,
    startOfHour
  );

  return sortTicks(ticks);
};

export const getDailyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(DAY_MS / 2)
) => {
  const ticks = generateTicks(minTimestamp, maxTimestamp, interval, startOfDay);

  return sortTicks(ticks);
};

export const getWeeklyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(WEEK_MS / 2)
) => {
  const ticks = generateTicks(
    minTimestamp,
    maxTimestamp,
    interval,
    startOfISOWeek
  );

  return sortTicks(ticks);
};

export const getMonthlyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(MONTH_MS / 2)
) => {
  const ticks = generateTicks(
    minTimestamp,
    maxTimestamp,
    interval,
    startOfMonth
  );

  return sortTicks(ticks);
};

export const getYearlyTicks = (
  minTimestamp: number,
  maxTimestamp: number,
  interval: number = Math.floor(YEAR_MS / 2)
) => {
  const ticks = generateTicks(
    minTimestamp,
    maxTimestamp,
    interval,
    startOfYear
  );

  return sortTicks(ticks);
};

export const formatXAxisProps = (
  xAxisKey: string,
  data: DataElement[] | undefined,
  xAxisProps?: LineChartXAxisProps | undefined,
  minX?: number,
  maxX?: number
) => {
  if (xAxisKey !== TIMESTAMP_X_AXIS_KEY) {
    return xAxisProps;
  }

  return {
    ...xAxisProps,
    ...generateXAxisTickProps(data, xAxisProps?.granularity, minX, maxX),
  };
};

export const getMISTicksFromDIS = (data: DataElement[]) => {
  const maxDIS = Math.max(...data.map((x) => x.exposure as number));
  const maxMIS = Math.ceil(maxDIS / MIS_TO_DIS_FACTOR);

  return Array.from(
    { length: maxMIS + 1 },
    (_, index) => index * MIS_TO_DIS_FACTOR
  );
};

export const generateXAxisTickProps = (
  data?: DataElement[],
  granularity?: Granularity,
  minX?: number,
  maxX?: number
): Partial<XAxisProps> => {
  if (!data || data.length === 0) {
    return {};
  }

  if (data.length === 1) {
    return {
      tickFormatter: (unixTime: number) => format(unixTime, MONTH_YEAR),
    };
  }

  const minTimestamp = minX || (data[0][TIMESTAMP_X_AXIS_KEY] as number);
  const maxTimestamp =
    maxX || (data[data.length - 1][TIMESTAMP_X_AXIS_KEY] as number);
  const timestampDiff = maxTimestamp - minTimestamp;

  if (granularity === Granularity.MONTH_YEAR) {
    return {
      ticks: getMonthlyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) => format(unixTime, MONTH_YEAR),
    };
  }

  if (timestampDiff > DECADE_MS) {
    return {
      ticks: getYearlyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) => format(unixTime, LONG_DATE_FORMAT),
    };
  }

  if (timestampDiff > YEAR_MS) {
    return {
      ticks: getMonthlyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) => format(unixTime, MONTH_YEAR),
    };
  }

  if (timestampDiff > MONTH_MS) {
    return {
      ticks: getWeeklyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) => format(unixTime, SHORT_DATE_FORMAT),
    };
  }

  if (timestampDiff > WEEK_MS) {
    return {
      ticks: getDailyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) => format(unixTime, SHORT_DATE_FORMAT),
    };
  }

  if (timestampDiff > DAY_MS) {
    return {
      ticks: getHourlyTicks(minTimestamp, maxTimestamp),
      tickFormatter: (unixTime: number) =>
        format(unixTime, DATE_WITH_TIME_FORMAT),
    };
  }

  return {
    ticks: getMinutelyTicks(minTimestamp, maxTimestamp),
    tickFormatter: (unixTime: number) =>
      format(unixTime, DATE_WITH_TIME_FORMAT),
  };
};

export const getSelectedOptionId = <T>(
  options: SelectedChartOptions[],
  actionId: ChartActionID
): Extract<YAxisValTopContributors, keyof T> =>
  options.find(({ id }) => id === actionId)?.optionId as Extract<
    YAxisValTopContributors,
    keyof T
  >;

export const getAxisValue = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find(({ id }) => id === actionId)
    ?.options?.find(({ id }) => id === optionId)?.value as string;

export const getAxisLabel = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find(({ id }) => id === actionId)
    ?.options?.find(({ id }) => id === optionId)?.label as string;

export const addAlpha = (color: string, opacity: number): string => {
  // opacity should be between 0 and 1
  // scales opacity between 0 and 1 and adds it to the provided hex color
  const opacityHex = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + opacityHex.toString(16);
};

export const getPaletteColor = (
  palette: string[],
  index: number,
  opacity?: number
) => {
  if (palette.length === 0 || index < 0) {
    return BAR_COLOR_SELECTED;
  }

  let color = palette[index % palette.length];
  if (opacity) {
    color = addAlpha(color, opacity);
  }

  return color;
};

export const wrapText = (
  text: string,
  maxLineLength: number,
  maxLines: number = 3
): string[] => {
  // we want to show all labels in a chart
  // if they are too long, we display them in multiple lines
  const words = text.split(" ");
  const lines: string[] = [];
  let currentLine = "";

  words.forEach((word) => {
    if (currentLine.length + word.length + 1 <= maxLineLength) {
      currentLine += (currentLine ? " " : "") + word;
    } else {
      if (currentLine) {
        lines.push(currentLine);
        currentLine = "";
      }

      if (word.length > maxLineLength) {
        // when word is longer than maxLineLength we shorten it and return lines
        // we do not want to return more lines after the word was shortened
        const shortenedWord = word.slice(0, maxLineLength - 1) + "…";
        lines.push(shortenedWord);
      } else {
        currentLine = word;
      }
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  // we only display a limited number of lines
  if (lines.length > maxLines) {
    const truncatedLines = lines.slice(0, maxLines);
    if (!truncatedLines[maxLines - 1].endsWith("…")) {
      truncatedLines[maxLines - 1] = truncatedLines[maxLines - 1] + "…";
    }

    return truncatedLines;
  }

  return lines;
};
