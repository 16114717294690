import React, { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { IDToken } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

import AppLayout from "features/layout/AppLayout";

import { useQuery } from "services/hooks";
import * as config from "config/config";

import login from "./utils";

const VIADUCT_EMAIL_SUFFIX = "@viaduct.ai";

interface Props {
  children: React.ReactElement;
  wrapperClasses?: string;
}

const getTrackingEmailFromIDToken = (idToken: IDToken): string | undefined => {
  const { email } = idToken?.claims;
  const {
    featureFlags: { anonymizeEmail },
  } = config.get();

  // if client is configured to anonymize emails for privacy, we send a configured email
  // instead unless it is a viaduct employee.
  if (anonymizeEmail && !email?.endsWith(VIADUCT_EMAIL_SUFFIX)) {
    return anonymizeEmail;
  }

  return email;
};

const SecureComponent = ({ children, wrapperClasses }: Props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const client = useLDClient();
  const {
    redirect = window.location.pathname + window.location.search,
    autoLogin,
    iss,
  }: { redirect?: string; autoLogin?: string; iss?: string } = useQuery();

  const {
    environment,
    pages: { singleIDPLogin },
  } = config.get();

  const shouldRedirectUnauthenticated =
    environment === "prod" &&
    singleIDPLogin?.optionalAutoLogin &&
    singleIDPLogin.unauthenticatedRedirect &&
    !iss &&
    !autoLogin;

  const idToken = authState?.idToken;

  // LaunchDarkly - identify user: this would be better in okta.ts where other auth (ga, heap ..) takes place,
  // but hooks cannot be used in helper functions
  useEffect(() => {
    if (!client || !idToken) return;

    const email = getTrackingEmailFromIDToken(idToken);

    client.identify({
      key: email,
      email,
    });
  }, [idToken, client]);

  // Without !authState we get redirected to default page after /login/callback
  // but we want to stay on a page we were at if refreshing.
  // Also, when logged out, we also get error state and shouldn't start logging back in immediately
  // as otherwise postLogoutRedirectUri does not work deterministically
  if (!authState || authState?.error) {
    return null;
  } else if (!authState?.isAuthenticated) {
    // For some tenants we want to turn on auto login optionally by adding query parameter autoLogin. If user does
    // not have valid session with Okta, we redirect them to tenant specific page where they can perform login.
    if (shouldRedirectUnauthenticated) {
      window.location.href = singleIDPLogin.unauthenticatedRedirect as string;

      return null;
    }

    login({ oktaAuth, redirect });

    return null;
  }

  return <AppLayout wrapperClasses={wrapperClasses}>{children}</AppLayout>;
};

export default SecureComponent;
