import Button from "features/ui/Button";

interface Props {
  onApply?: () => void;
  onCancel?: () => void;
  occursState: string;
  appliedOccursFilter?: string;
}

const OccursFilterActions = ({
  onApply,
  onCancel,
  occursState,
  appliedOccursFilter,
}: Props) => {
  const buttonsDisabled = appliedOccursFilter === occursState;

  return (
    <div className="space-x-2">
      <Button
        label="Cancel"
        size="small"
        testId="relates-filter-cancel-cta"
        onClick={onCancel}
        disabled={buttonsDisabled}
        color="secondary"
      />
      <Button
        label="Apply"
        size="small"
        testId="relates-filter-apply-cta"
        onClick={onApply}
        color="primary"
        disabled={buttonsDisabled}
        variant="contained"
      />
    </div>
  );
};

export default OccursFilterActions;
