import { useCallback, useMemo, useState } from "react";
import { generatePath, Link } from "react-router-dom";

import { useTransportCategory, useVehicleCategory } from "shared/api/hooks";
import { APIFilter, getSortFilter } from "shared/api/utils";
import {
  getVehiclesExport,
  ListVehiclesRequest,
  Vehicle,
} from "shared/api/vehicles/api";
import {
  useListVehicles,
  useListVehiclesCount,
} from "shared/api/vehicles/hooks";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { RISK_MODEL_PREDICTION_PREFIX } from "shared/schemas/failureModesRiskModelPredictionsSchema";
import { formatNumber, getTenantMileageUnit } from "shared/utils";

import NewCollectionModal from "pages/Collections/NewCollectionModal/NewStaticFilterCollectionModal";
import SingleFleetLink from "pages/Fleets/SingleFleetLink";
import { getTablePageKey } from "pages/utils";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
  removeAttributesWithPrefixFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import StringList from "features/ui/StringList";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableAsyncCellValue from "features/ui/Table/TableAsyncCellValue";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";
import TutorialCTA from "features/ui/TutorialCTA";

import { routes } from "services/routes";

import {
  DEFAULT_SORT,
  MAX_VISIBLE_RPOS,
  STICKY_FIRST_COLUMN,
  TITLE,
  VEHICLES_PER_PAGE,
} from "./constants";
import {
  useVehiclesFailureModeColumns,
  useVehiclesSchemaWithFailureModes,
} from "./hooks";
import NewCollectionButton from "./NewCollectionButton";
import DOCS_STEPS from "./tutorial";
import { getColumnsToShow, getPredictionsFormatted } from "./utils";
import VehicleFilters, {
  VEHICLE_PENDING_FILTERS_LS_KEY,
} from "./VehicleFilters";

export interface VehiclePageProps {
  staticFilters?: APIFilter[];
  title?: string;
  columns?: string[];
  pageKey: string;
  initialVisibleFailureModeColumns?: string[];
  skipBreacrumbsAndActions?: boolean;
}

const ACCESSOR_TO_HIDE = "predictions";

const Vehicles = ({
  staticFilters,
  columns,
  title = TITLE,
  pageKey,
  initialVisibleFailureModeColumns = [],
  skipBreacrumbsAndActions,
}: VehiclePageProps) => {
  const PAGE_KEY_TABLE = getTablePageKey(pageKey);

  const { schema: vehicleAttributesSchema, getDisplayLabel } =
    useVehiclesSchemaWithFailureModes();

  const [newCollectionModalOpen, setNewCollectionModalOpen] = useState(false);

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    pendingFiltersLocalStorageKey: VEHICLE_PENDING_FILTERS_LS_KEY,
    defaultFailureModeColumns: initialVisibleFailureModeColumns,
  });

  const {
    manageFilterChange,
    manageOnSortChange,
    resetFilters: resetTableFilters,
    resetFilterSortState: resetTableFilterSortState,
    filters: tableFilters,
    initialized: initializedTableFilters,
    sort,
  } = useFilterSortState({
    pageKey: PAGE_KEY_TABLE,
    defaultSort: DEFAULT_SORT,
  });

  const { filters, resetFilterSortState } = vehiclesFiltersFilterSortState;

  const resetFilterSort = () => {
    resetTableFilterSortState();
    resetFilterSortState();
  };

  const {
    columnSettingsEnabled,
    visibleFailureModesColumns,
    TableColumnSettingsComponent,
    ExtraTableHeaderForFailureModes,
  } = useVehiclesFailureModeColumns({
    pageKey,
    vehiclesFiltersFilterSortState,
    initialVisibleFailureModeColumns,
  });

  const columnsToShow = getColumnsToShow(vehicleAttributesSchema, columns);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({
      [accessor]: sort,
    });
  };

  const schema = vehicleAttributesSchema
    .filter(({ accessor }: SchemaEntry) => columnsToShow.includes(accessor))
    .filter(({ accessor }: SchemaEntry) => {
      // if it's a FM column, only show it if it's in the visibleFailureModesColumns
      if (accessor.startsWith(`${RISK_MODEL_PREDICTION_PREFIX}.`)) {
        return visibleFailureModesColumns.includes(accessor);
      }

      return true;
    });

  // TODO Remove when we remove V0 failure modes
  const filteredFilters = removeAttributesWithPrefixFromFilterGroupState(
    filters,
    `${ACCESSOR_TO_HIDE}.`
  );
  const filteredTableFilters = removeAttributesWithPrefixFromFilterGroupState(
    tableFilters,
    `${ACCESSOR_TO_HIDE}.`
  );

  const allFilters = mergeFilterGroupStates(
    filteredFilters,
    filteredTableFilters
  );

  const filterQuery = getFiltersQuery(allFilters, staticFilters);

  const requestParams: ListVehiclesRequest = {
    filter: filterQuery,
    sort: getSortFilter(sort),
    limit: VEHICLES_PER_PAGE,
    mileageUnit: getTenantMileageUnit(),
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListVehicles(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListVehiclesCount({ filter: requestParams.filter });

  const formatRow = useCallback(
    (vehicle: Vehicle) => {
      const {
        VIN,
        mileage,
        fleet,
        transportCategoryID,
        vehicleCategoryID,
        tags,
        riskModelPredictions,
      } = vehicle;
      const pathname = generatePath(routes.vinView, {
        vin: encodeURIComponent(VIN),
      });

      return {
        ...vehicle,
        VIN: (
          <Link to={pathname} className="text-metabase-blue hover:underline">
            {VIN}
          </Link>
        ),
        mileage: formatNumber(mileage, 0),
        fleet: <SingleFleetLink fleetName={fleet} />,
        transportCategoryID: transportCategoryID && (
          <TableAsyncCellValue
            id={transportCategoryID}
            hook={useTransportCategory}
          />
        ),
        vehicleCategoryID: vehicleCategoryID && (
          <TableAsyncCellValue
            id={vehicleCategoryID}
            hook={useVehicleCategory}
          />
        ),
        riskModelPredictions: getPredictionsFormatted(riskModelPredictions),
        tags: tags && (
          <StringList
            title={getDisplayLabel("tags", "Tags")}
            values={tags}
            maxVisible={MAX_VISIBLE_RPOS}
          />
        ),
      };
    },
    [getDisplayLabel]
  );

  // re-format the data - but only when relevant data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <div>
      <PageHeaderWrapper skipBreacrumbsAndActions={skipBreacrumbsAndActions}>
        {title && <Title text={title} />}
        <PageHeaderActionsWrapper>
          {columnSettingsEnabled && <TutorialCTA steps={DOCS_STEPS} />}
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getVehiclesExport}
            fileName="vehicles"
            requestParams={{
              ...requestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="vehicle"
            filters={allFilters}
          />
          <NewCollectionButton
            filters={allFilters}
            count={countData?.count as number}
            onClick={() => setNewCollectionModalOpen(true)}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <VehicleFilters
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
      />
      <div className="flex my-2 items-center">
        <TableColumnSettingsComponent />
        <FiltersOverview
          filters={filteredTableFilters}
          tableSchema={schema}
          onFiltersReset={resetTableFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="vehicle"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={VEHICLES_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={initializedTableFilters}
          onFiltersReset={resetTableFilters}
          onFilterChange={manageFilterChange}
          filters={filteredTableFilters}
          staticFilters={staticFilters}
          hideStaticFiltersColumns
          pageKey={PAGE_KEY_TABLE}
          dense
          stickyFirstColumn={STICKY_FIRST_COLUMN}
          extraHeaderRowContent={<ExtraTableHeaderForFailureModes />}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSort} />}
      <NewCollectionModal
        isOpen={newCollectionModalOpen}
        onClose={() => setNewCollectionModalOpen(false)}
        filters={allFilters}
        staticFilters={staticFilters}
      />
    </div>
  );
};

export default Vehicles;
