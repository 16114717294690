import { useContext } from "react";

import { ECU, EntityAttribute } from "shared/api/api";
import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VEHICLES_ECUS_GENERIC_FILTER } from "shared/filterDefinitions";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
} from "./utils";

const NON_SCHEMA_ACCESSOR = ["VIN", "ECUID"];

const useVehicleECUsCombinedSchema = (): UseSchema => {
  const { attributes, ECUs } = useContext(VehicleECUsAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const combinedAttributes: EntityAttribute[] = generateCombinedAttributes(
    ECUs,
    attributes
  );

  const schema = generateCombinedSchema(ECUs, attributes);

  const getFilter = getFilterFunction(
    VEHICLES_ECUS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes: combinedAttributes,
    getDisplayLabel,
    getSchemaEntry,
    getSchemaForAccessor,
  };
};

const generateCombinedAttributes = (
  ECUs?: ECU[],
  attributes?: EntityAttribute[]
): EntityAttribute[] => {
  if (!ECUs || !attributes) {
    return [];
  }

  const combinedAttributes: EntityAttribute[] = [];

  for (const ECU of ECUs) {
    combinedAttributes.push({
      ID: `ECUs.${ECU.ID}.present`,
      displayName: `ECU: ${ECU.ID} present`,
      columnName: null,
      dataExplorerColumnName: null,
      type: "string",
      description: null,
      filtering: false,
      sorting: false,
      nullable: false,
      grouping: true,
      attributeGrouping: false,
      values: false,
      filteringConfig: {
        negativeNumbers: false,
        empty: false,
        startsWith: false,
        contains: false,
        lowCardinality: false,
        decimalNumbers: false,
        minMax: false,
      },
      inJSONBody: true,
      displayWideColumn: false,
      hideInTable: false,
      hideFilter: false,
      byVehicleAgeBirthday: false,
      byVehicleAgeExposure: false,
      byVehicleAgeExposureBuckets: [],
    });
    for (const { ID, displayName, ...otherAttributeFields } of attributes) {
      if (!NON_SCHEMA_ACCESSOR.includes(ID)) {
        combinedAttributes.push({
          ID: `ECUs.${ECU.ID}.${ID}`,
          displayName: `ECU: ${ECU.ID} ${displayName}`,
          ...otherAttributeFields,
        });
      }
    }
  }

  return combinedAttributes;
};

const generateCombinedSchema = (
  ECUs?: ECU[],
  attributes?: EntityAttribute[]
): SchemaEntry[] => {
  if (!ECUs || !attributes) {
    return [];
  }

  const schema: SchemaEntry[] = [];

  for (const ECU of ECUs) {
    schema.push({
      accessor: `ECUs.${ECU.ID}.present`,
      label: `ECU: ${ECU.ID} present`,
      dataType: DataType.STRING,
      filter: VEHICLES_ECUS_GENERIC_FILTER({
        label: `ECU: ${ECU.ID} present`,
        fieldName: `ECUs.${ECU.ID}.present`,
        filterType: "boolean",
        disableIsEmptyFilters: true,
      }),
    });
    for (const attribute of attributes) {
      if (!NON_SCHEMA_ACCESSOR.includes(attribute.ID)) {
        schema.push({
          accessor: `ECUs.${ECU.ID}.${attribute.ID}`,
          label: `ECU: ${ECU.ID} ${attribute.displayName}`,
          dataType: DataType.STRING,
          hideFilter: attribute.hideFilter,
          filter: VEHICLES_ECUS_GENERIC_FILTER({
            label: `ECU: ${ECU.ID} ${attribute.displayName}`,
            fieldName: `ECUs.${ECU.ID}.${attribute.ID}`,
            fieldNameForAPI: attribute.ID,
            filterType: "string",
            customFilter: filterStateToFilterGroupState({
              ECUID: {
                values: [ECU.ID],
                operator: FilterOperator.IN,
              },
            }),
          }),
        });
      }
    }
  }

  return schema;
};

export default useVehicleECUsCombinedSchema;
