import Skeleton from "react-loading-skeleton";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getSuggestedIssue } from "shared/api/suggestedIssues/api";

import { routes } from "services/routes";

type Params = {
  id: string;
};

const SuggestedIssueLatestRun = () => {
  const navigate = useNavigate();
  const { id } = useParams<Params>();

  if (!id) return null;

  getSuggestedIssue({ id })
    .then((response) => {
      const { data } = response;
      navigate(
        generatePath(routes.suggestedIssue, {
          id: encodeURIComponent(id),
          date: encodeURIComponent(data.updated),
        }),
        { replace: true }
      );
    })
    .catch((error) => {
      console.error("Failed to fetch suggested issue:", error);
      toast.error("Failed to fetch suggested issue.");
    });

  return <Skeleton height={400} />;
};

export default SuggestedIssueLatestRun;
