import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createAgent,
  getEphemeralMessageForNode,
  getToolArgs,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { searchCodeDescriptionsVectorstore } from "duck/graph/Vectorstore/codeDescriptionsVectorstore";
import { RunnableConfig } from "@langchain/core/runnables";

export const DEFAULT_CODE_SEARCH_RESULT_LIMIT = 12;
let prevShownResults = 0;

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(
    promptNames.SEARCH_CODES_BY_DESCRIPTION_AGENT
  );
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);
  const agent = createAgent(llm, [], prompt, { strict: true });

  const name = NodeNames.SEARCH_CODES_BY_DESCRIPTION;
  const setEphemeralMessage = params.uiHandlers.setEphemeralMessage;

  return async (
    { messages, userInput }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));
    console.debug(name);

    const { source, query, numResults, showMore } = getToolArgs(messages);
    console.debug("Searching for codes by description", {
      source,
      query,
      numResults,
      showMore,
    });

    let offset = 0;
    if (showMore) {
      offset = prevShownResults;
      prevShownResults += numResults;
    } else {
      prevShownResults = numResults;
    }

    const results = await searchCodeDescriptionsVectorstore({
      query,
      numResults,
      source,
      offset,
    });

    const documents = JSON.stringify(
      results.map((result) => ({
        id: result.metadata.id,
        description: result.pageContent,
      }))
    );

    const stream = agent.streamEvents(
      {
        source,
        userInput,
        documents,
      },
      { version: "v2", ...config }
    );

    for await (const streamEvent of stream) {
      params.uiHandlers.handleStreamEvent(streamEvent);
    }

    return {};
  };
};

export default getNode;
