import { EventTypeLabel } from "shared/types";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { useByVehicleAgeOptions, useChartSettings } from "pages/hooks";

import { getDefaultActions } from "features/ui/charts/utils";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";
import { useByVehicleAgeChartActions } from "./hooks";

export const BY_VEHICLE_AGE_CHART_OPTIONS_KEY =
  "claimAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
  chartSettings,
  manageChartSettingsChange,
}: ClaimsFiltersProps) => {
  const actions = useByVehicleAgeChartActions(EventTypeLabel.CLAIM);

  const { selectedOptions, setSelectedOptions } = useChartSettings(
    chartSettings,
    manageChartSettingsChange,
    BY_VEHICLES_AGE_TAB_KEY,
    BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
    getDefaultActions(actions)
  );

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ByVehicleAgeTable
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
