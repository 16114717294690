import * as config from "config/config";

import {
  createEmbeddingURL,
  createLegacyEmbeddingURL,
  EmbeddingURLPayloadResource,
  EmbeddingURLRequest,
} from "./api";

const SECONDS_IN_MINUTE = 60;

const getEmbedExpirationTime = (minutes: number): number =>
  Math.round(Date.now() / 1000) + minutes * SECONDS_IN_MINUTE;

export const createEmbeddingRequest = (
  resource: EmbeddingURLPayloadResource,
  params: object = {},
  options: object = {}
) => ({
  payload: {
    resource,
    params,
    exp: getEmbedExpirationTime(60),
  },
  options: {
    // required for
    // https://stackoverflow.com/questions/37978528/typescript-type-string-is-not-assignable-to-type
    bordered: "false" as const,
    titled: "false" as const,
    ...options,
  },
});

export const getEmbeddingURL = async (
  data: EmbeddingURLRequest,
  usesLegacyUrlEmbeddingService: boolean
): Promise<string> => {
  try {
    if (usesLegacyUrlEmbeddingService) {
      return createLegacyEmbeddingURL(data).then(({ url }) => url);
    } else {
      return createEmbeddingURL(data).then(({ url }) => url);
    }
  } catch (error) {
    console.error("error getting embedding url", error);
  }

  return "";
};

export const getHideFilterParametersOption = (
  filterConfig?: config.MetabaseDashboardFilterConfig
): string | undefined => {
  if (!filterConfig) return;

  if (Array.isArray(filterConfig))
    return filterConfig
      .flat()
      .map(({ key }) => key)
      .join(",");

  return Object.values(filterConfig)
    .flat()
    .map(({ key }) => key)
    .join(",");
};
