import React from "react";

const useShownSignalEvents = (id: string) => {
  const [shownSignalEvents, setShownSignalEventsInternal] = React.useState(
    new Set<string>()
  );

  const setShownSignalEvents = (events: Set<string>, persist = true) => {
    // we only persist when user makes an action, but not when initial population happens
    if (persist) {
      localStorage.setItem(
        `shown_signal_events_${id}`,
        JSON.stringify(Array.from(events))
      );
    }

    setShownSignalEventsInternal(events);
  };

  /**
   * When data is loaded into the table we do cross section of shown events and data we get so we eliminate the shown events that do not exist/are not included in global filters.
   * In case the cross section between selectedSignalEvents and signalEventsFromAPI is empty, we reset the selectedSignalEvents
   */
  const resetInitialShownSignalEvents = (
    signalEventsFromAPI: string[],
    onRemovedAllCallback: () => void
  ): void => {
    if (shownSignalEvents.size === 0) return;

    const filteredSignalEvents = new Set(
      [...signalEventsFromAPI].filter((i) => shownSignalEvents.has(i))
    );
    if (shownSignalEvents.size > 0 && filteredSignalEvents.size === 0) {
      onRemovedAllCallback();
    }

    setShownSignalEvents(filteredSignalEvents, true);
  };

  React.useEffect(() => {
    try {
      // only read from localstorage if value is not yet set
      if (shownSignalEvents.size !== 0) return;

      if (localStorage.getItem(`shown_signal_events_${id}`) === null) return;

      const events = JSON.parse(
        localStorage.getItem(`shown_signal_events_${id}`) || "[]"
      );
      const eventsSet = new Set<string>(events);

      setShownSignalEventsInternal(eventsSet);
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    shownSignalEvents,
    setShownSignalEvents,
    resetInitialShownSignalEvents,
  };
};

export default useShownSignalEvents;
