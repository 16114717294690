import { EntityAttribute } from "shared/api/api";
import { useVehicleAttributes } from "shared/api/vehicles/hooks";

import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { SelectOption } from "features/ui/Select";

const DATE_ATTRIBUTE_TYPE_SHOULD_INCLUDE = "date";

interface VehicleAttributeSelectorProps {
  selectedAttribute?: string;
  onAttributeChange: (attributeId: string | null) => void;
}

const SingleVehicleDateAttributeSelect = ({
  selectedAttribute: selectedAttributeId,
  onAttributeChange,
}: VehicleAttributeSelectorProps) => {
  const { data: attributes } = useVehicleAttributes({});

  const selectedAttribute = attributes?.find(
    (attr) => attr.ID === selectedAttributeId
  );

  const attributeOptions: SelectOption<string>[] =
    attributes
      ?.filter((attr: EntityAttribute) =>
        attr.type.includes(DATE_ATTRIBUTE_TYPE_SHOULD_INCLUDE)
      )
      ?.map((attr: EntityAttribute) => ({
        id: attr.ID,
        value: attr.displayName,
      })) || [];

  const handleAttributeSelect = (option: SelectOption<string> | null) => {
    onAttributeChange(option?.id || null);
  };

  return (
    <DropdownWithSearch<string>
      label="Vehicle date attribute"
      options={attributeOptions}
      selectedOption={
        selectedAttribute
          ? { id: selectedAttribute.ID, value: selectedAttribute.displayName }
          : null
      }
      onSelectedOptionChange={handleAttributeSelect}
      placeholder="Search for an attribute"
    />
  );
};

export default SingleVehicleDateAttributeSelect;
