import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";

import { ChartAction } from "features/ui/charts/Actions/types";

export const DEFAULT_R_AXIS_WIDTH = 70;
export const MAX_SENSOR_Y_AXIS_LABEL_LENGTH = 18;
export const MAX_CHART_LABEL_LENGTH = 30;
export const UNAVAILABLE_TEXT_WIDTH = -1;

export const CHART_ACTIONS: ChartAction<SensorReadingsTimelineGrouping>[] = [
  {
    id: "legend",
    title: "Aggregation",
    type: "dropdownSelect",
    options: [
      {
        id: "day",
        value: "Daily",
      },
      {
        id: "hour",
        value: "Hourly",
      },
      {
        id: "none",
        value: "None",
      },
    ],
  },
];

export const CHART_OPTIONS_KEY = "sensorsChartOptions";

export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX =
  "vin_event_timeline_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY = (
  vin: string
) => `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX}${vin}`;

export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX =
  "vin_event_timeline_se_filter_data_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY = (vin: string) =>
  `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX}${vin}`;
