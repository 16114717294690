import { SelectOption } from "features/ui/Select";

import * as config from "config/config";

import { ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION } from "./constants";

const {
  pages: { signalEventsAnalytics },
} = config.get();

interface Props {
  groupBySelectOptions?: SelectOption[];
}

export const useSignalEventsAssociatedClaimsDefaultGroupBy = ({
  groupBySelectOptions,
}: Props): SelectOption => {
  if (signalEventsAnalytics?.defaultClaimGroupByAttribute) {
    const defaultGroupByAttribute = groupBySelectOptions?.find(
      ({ id }) => id === signalEventsAnalytics.defaultClaimGroupByAttribute
    );

    if (!defaultGroupByAttribute) {
      return ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION;
    }

    return defaultGroupByAttribute;
  }

  return ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_SELECT_OPTION;
};
