import {
  DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
  DOCUMENT_RETRIEVAL_K,
  DOCUMENTS_SOURCE,
  GPT4OMINI_STREAMING_MODEL_SPEC,
} from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createAgent,
  createToolMessageFromAIMessageToolCall,
  getEphemeralMessageForNode,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import {
  formatDocs,
  getLLM,
  NodeNames,
  retrieveRelevantDocuments,
} from "duck/graph/utils";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.RAG_AGENT);
  const llm = getLLM(GPT4OMINI_STREAMING_MODEL_SPEC);
  const agent = createAgent(llm, [], prompt);

  const name = NodeNames.RAG;
  const setEphemeralMessage = params.uiHandlers.setEphemeralMessage;

  return async (
    { messages, userInput }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));
    console.debug(`RAG: ${name}`);

    const documents = formatDocs(
      await retrieveRelevantDocuments(
        userInput,
        DOCUMENTS_SOURCE,
        DOCUMENT_RETRIEVAL_K,
        DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD
      )
    );

    // Check if the last message is an AIMessage with non-empty tool calls
    const toolMessage = createToolMessageFromAIMessageToolCall(messages);

    // We narrow the scope of what this agent needs to consider to the RAG content
    // and the message history.
    const stream = agent.streamEvents(
      {
        messages: [...messages, ...toolMessage],
        documents,
      },
      { version: "v2", ...config }
    );

    for await (const streamEvent of stream) {
      params.uiHandlers.handleStreamEvent(streamEvent);
    }

    return {};
  };
};

export default getNode;
