import { Card, CardHeader } from "@mui/material";

import { RepairPart } from "shared/api/repairs/api";
import useRepairPartSchema from "shared/schemas/repairPartSchema";

import Table from "features/ui/Table";

interface Props {
  items: RepairPart[];
}

const CARD_TITLE = "Parts";

const PartItems = ({ items }: Props) => {
  const { schema } = useRepairPartSchema(["repairID"], ["ID"]);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <Table data={items} schema={schema} disableSort={true} />
    </Card>
  );
};
export default PartItems;
