import qs from "qs";

import { Issue } from "shared/api/issues/api";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { IssueFormData } from "pages/Issues/CreateIssue/utils";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY as VEHICLE_PAGE_KEY_SIGNAL_EVENTS,
} from "pages/SignalEventsAnalytics/constants";

import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
  getTopLevelRowFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { getBasicOccursFilterFromSignalEventFilter } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { getFiltersKey } from "features/ui/Filters/utils";
import { ActionType } from "features/ui/StepperModal";

import { routes } from "services/routes";

/**
 * Transfer vehicle population params to the new page by just using the correct URL query key
 * - also, if filters are empty, keep the key in the URL so that filters propagate but are set to ""
 * - Claim or Signal Event filters ARE NOT REMOVED when switching between pages, they are kept based on previous local storage state
 *   or default value as per the page if not local storage values are set
 */
export const getSearchParamsFor = (
  analyticsPageRouteToRedirectTo: string,
  query: qs.ParsedQs
): string => {
  const toClaimAnalytics =
    analyticsPageRouteToRedirectTo === routes.claimAnalytics;

  const vehicleFiltersCurrentPageKey = toClaimAnalytics
    ? VEHICLE_PAGE_KEY_SIGNAL_EVENTS
    : VEHICLES_PAGE_KEY;

  const vehicleFiltersNewPageKey = toClaimAnalytics
    ? VEHICLES_PAGE_KEY
    : VEHICLE_PAGE_KEY_SIGNAL_EVENTS;

  const currentVehicleFilterKey = getFiltersKey(vehicleFiltersCurrentPageKey);
  const newVehicleFilterKey = getFiltersKey(vehicleFiltersNewPageKey);

  const currentVehicleQueryFilterParams = query[currentVehicleFilterKey];

  const newSearchParams: Record<string, any> = {
    [newVehicleFilterKey]: currentVehicleQueryFilterParams || "",
  };

  // if going to claim analytics, we want to transfer "signalEventID" filter to claim analytics "relatedSignalEvents" filters
  // (but only if signalEventID is present in the current filters)
  const signalEventAnalyticsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);
  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const currentSignalEventFilters = filterBuilderQueryToFilterBuilderState(
    query[signalEventAnalyticsFilterKey] as string
  );

  const signalEventIDFilterRow = getTopLevelRowFromFilterGroupState(
    "signalEventID",
    currentSignalEventFilters
  );

  if (toClaimAnalytics && signalEventIDFilterRow) {
    const relatedSignalEventOccurrencesFilter =
      getBasicOccursFilterFromSignalEventFilter(signalEventIDFilterRow);

    if (relatedSignalEventOccurrencesFilter) {
      newSearchParams[claimAnalyticsFilterKey] = getFiltersQuery(
        filterStateToFilterGroupState({
          relatedSignalEventOccurrences: relatedSignalEventOccurrencesFilter,
        })
      );
    }
  }

  return qs.stringify(newSearchParams);
};

export const combineIssueData = (
  issueData: IssueFormData,
  issueModalAction: ActionType,
  isClaimAnalytics: boolean,
  issueToUpdateData?: Issue
): IssueFormData => {
  if (!issueToUpdateData || issueModalAction === "create") return issueData;

  /**
   * If the issue is being updated, we handle the following cases:
   *
   * 1. vehicle filters (atRiskPopulationFilter) are always taken from currently applied filters and override the current issue filters - because they are present on both SE & Claim Analytics)
   * 2. claim filters are taken from the current filters if we are on claim analytics page, otherwise they are taken from the issue being updated
   *    (only Claim Analytics has it and on we dont want to reset these on Signal Events Analytics)
   * 3. comparison population filters are always taken from current issue as they are not part of current filters
   * 4. signal events are taken from the current filters if we are on on Signal Event Analytics page, otherwise they are taken from the issue being updated
   *    (only Signal Events Analytics has it and on we dont want to reset these on Claim Analytics)
   */
  const atRiskPopulationFilterState = issueData.atRiskPopulationFilter;

  const claimFilterState =
    isClaimAnalytics && issueData.claimFilter
      ? issueData.claimFilter
      : (issueToUpdateData.claimFilter &&
          filterBuilderQueryToFilterBuilderState(
            issueToUpdateData.claimFilter
          )) ||
        undefined;

  const comparisonPopulationFilterState =
    issueToUpdateData.comparisonPopulationFilter
      ? filterBuilderQueryToFilterBuilderState(
          issueToUpdateData.comparisonPopulationFilter
        )
      : undefined;

  const signalEventOccurrencesFilterState =
    issueToUpdateData.signalEventOccurrencesFilter
      ? filterBuilderQueryToFilterBuilderState(
          issueToUpdateData.signalEventOccurrencesFilter
        )
      : undefined;

  return {
    ...issueData,
    atRiskPopulationFilter: atRiskPopulationFilterState,
    claimFilter: claimFilterState,
    signalEventOccurrencesFilter: signalEventOccurrencesFilterState,
    comparisonPopulationFilter: comparisonPopulationFilterState,
    ID: issueToUpdateData.ID as string,
    name: issueToUpdateData.name,
    description: issueToUpdateData.description || "",
    statusID: issueToUpdateData.statusObj?.ID || "",
    severityID: issueToUpdateData.severityObj?.ID || "",
    assignee: issueToUpdateData.assignee || "",
  };
};
