import { MileageUnit } from "shared/types";

import {
  FailureModesConfig,
  HelpArticlesConfig,
  IssuesConfig,
} from "pages/Admin/PagesConfig/types";

import { RoutesValues } from "services/routes";

import * as conard from "./conard";
import * as demo from "./demo";
import * as dev from "./dev";
import * as ford from "./ford";
import * as hermesnvc from "./hermesnvc";
import * as knightswift from "./knightswift";
import * as local from "./local";
import * as nelbro from "./nelbro";
import * as nikola from "./nikola";
import * as paccar from "./paccar";
import * as sridemo from "./sridemo";

export const SUPPORT_EMAIL = "support@viaduct.ai";

interface MetabaseDashboardFilter {
  name: string;
  id: string;
  key: string;
  search?: boolean;
  hide?: boolean;
}

export type MetabaseDashboardFilterConfig =
  | {
      [group: string]: MetabaseDashboardFilter[];
    }
  | MetabaseDashboardFilter[];
interface BrandingConfig {
  feedbackStyles?: Object;
}

interface HeapAnalyticsConfig {
  id: string;
}

interface AnalyticsConfig {
  heap?: HeapAnalyticsConfig;
}

export type AuthScopes = "openid" | "email" | "offline_access";

interface AuthConfig {
  name: string;
  idp?: string;
  issuer: string;
  clientId: string;
  scopes: AuthScopes[];
  postLogoutRedirectUri?: string;
}

interface GlobalConfig {
  mileageUnit: MileageUnit;
}

interface VehiclesConfig {
  eventTimeline?: boolean;
  // Default from date in event timeline
  // Priority 1: eventTimelineFromDateVehicleAttribute
  // Priority 2: eventTimelineFromDate
  eventTimelineFromDateVehicleAttribute?: string;
  eventTimelineFromDate?: string;
  eventTimelineToDate?: string;
  defaultFilters?: string;
}
interface FleetsConfig {}

interface EventsConfig {
  hideMenuItem?: boolean;
  staticFilters?: string;
  serviceRecordText?: string;
}
interface MaintenanceSchedulesConfig {}
interface CollectionsConfig {}
interface ServicePlansConfig {}
interface AlertDefinitionsConfig {}
interface DataExplorerConfig {
  url: string;
}
// this is more Authentication related config, but we are already passing
// pagesConfig to the e2e docker image so leaving it as part of this for now
interface SingleIDPConfig {
  unauthenticatedRedirect?: string;
  optionalAutoLogin?: boolean;
}

interface IssuesConfigOld extends IssuesConfig {}

interface ClaimAnalyticsConfig {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
}

interface SignalEventsAnalytics {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
  defaultClaimGroupByAttribute?: string;
}

interface CustomSignalEventsConfig {
  eventPreviewFromDate?: string;
  eventPreviewToDate?: string;
}

interface ChatBotConfig {
  url?: string;
}

interface RootPageConfig {
  route: RoutesValues;
}

interface NotFoundConfig {
  email: string;
}

export interface PagesConfig {
  global?: GlobalConfig;
  vehicles?: VehiclesConfig;
  fleets?: FleetsConfig;
  events?: EventsConfig;
  failureModesV0?: FailureModesConfig;
  failureModes?: FailureModesConfig;
  maintenanceSchedules?: MaintenanceSchedulesConfig;
  collections?: CollectionsConfig;
  notFound?: NotFoundConfig;
  rootPage?: RootPageConfig;
  dataExplorer?: DataExplorerConfig;
  singleIDPLogin?: SingleIDPConfig;
  servicePlans?: ServicePlansConfig;
  alertDefinitions?: AlertDefinitionsConfig;
  claimAnalytics?: ClaimAnalyticsConfig;
  repairAnalytics?: {};
  inspectionAnalytics?: {};
  signalEventsAnalytics?: SignalEventsAnalytics;
  issues?: IssuesConfigOld;
  chatBot?: ChatBotConfig;
  dashboards?: DashboardsConfig;
  customSignalEvents?: CustomSignalEventsConfig;
  helpArticles?: HelpArticlesConfig;
  calculatedAttributes?: {};
}

interface DashboardConfig {
  name: string;
  id: string;
}

export interface DashboardsConfig {
  dashboards: DashboardConfig[];
}

interface APIConfig {
  url: string;
}

interface FeatureFlagsConfig {
  launchDarklyClientId: string;
  anonymizeEmail?: string;
}

export interface AppConfig {
  environment: string;
  pages: PagesConfig;
  branding?: BrandingConfig;
  analytics: AnalyticsConfig;
  featureFlags: FeatureFlagsConfig;
  auth: AuthConfig;
  api: APIConfig;
  isIframed?: boolean;
  tenant?: string;
}

const configs: { [key: string]: AppConfig } = {
  [conard.host]: conard.prodAppConfig,
  [demo.host]: demo.prodAppConfig,
  [dev.host]: dev.appConfig,
  [ford.host]: ford.prodAppConfig,
  [hermesnvc.host]: hermesnvc.prodAppConfig,
  [knightswift.host]: knightswift.prodAppConfig,
  [local.host]: local.appConfig,
  [nelbro.host]: nelbro.prodAppConfig,
  [nikola.host]: nikola.prodAppConfig,
  [paccar.host]: paccar.prodAppConfig,
  [sridemo.host]: sridemo.prodAppConfig,
};

export const get = (): AppConfig => {
  // Allow local override
  const hostname = process.env.REACT_APP_HOST || window.location.hostname;

  const config = configs[hostname];

  if (!config) {
    if (process.env.NODE_ENV === "development") {
      return getDefaultConfig();
    }

    throw new Error(`unknown config for ${hostname}`);
  }

  return config;
};

const getDefaultConfig = (): AppConfig => local.appConfig;
