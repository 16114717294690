import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useSuggestedIssue } from "shared/api/suggestedIssues/hooks";

import IssueTabs from "pages/Issues/IssueTabs";

import APIError from "features/ui/APIError";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import SuggestedIssueDetails from "./SuggestedIssueDetails";
import SuggestedIssuesActions from "./SuggestedIssuesActions";

type Params = {
  id: string;
};

const SuggestedIssue = () => {
  const { issuePublishing } = useFlags();

  const { id } = useParams<Params>();

  const {
    data: issueData,
    isLoading: issueDataIsLoading,
    error: issueDataIsError,
    requestKey: issueDataRequestKey,
  } = useSuggestedIssue({ id: id as string });

  if (issueDataIsError) {
    return <APIError error={issueDataIsError!} />;
  }

  if (issueDataIsLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (!issueData) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No data for Suggested Issue {id}.
      </div>
    );
  }

  const publishedText = issueData.published ? "Published" : "Unpublished";
  const title = issuePublishing
    ? `${issueData.name} (${publishedText})`
    : `${issueData.name}`;

  return (
    <>
      <PageHeaderWrapper>
        <Title text={title} />
        <PageHeaderActionsWrapper>
          <SuggestedIssuesActions
            issue={issueData}
            issueRequestKeys={[issueDataRequestKey]}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <SuggestedIssueDetails
        issue={issueData}
        issueRequestKeys={[issueDataRequestKey]}
      />
      <IssueTabs issue={issueData} />
    </>
  );
};

export default SuggestedIssue;
