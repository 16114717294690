import { PageHandler } from "duck/graph/PageHandler";
import { sendAgentResponse } from "duck/graph/sendAgentResponse/sendAgentResponse";
import { DuckGraphParams, StringSetter } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
  SIGNAL_EVENTS_TAB_TITLE,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENT_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsChart";

interface GoToSignalEventsTabToolParams {
  pageHandler: PageHandler;
  signalEventsChartOptions: DuckGraphParams["availableData"]["signalEventAnalytics"]["signalEventsChartOptions"];
  setEphemeralMessage: StringSetter;
  setAgentResponse: StringSetter;
  selectedTab: string;
  selectedChartOptions: Record<string, string>;
}

const getGoToSignalEventsTabTool = ({
  pageHandler,
  signalEventsChartOptions,
  setEphemeralMessage,
  setAgentResponse,
  selectedTab,
  selectedChartOptions,
}: GoToSignalEventsTabToolParams): DynamicStructuredTool => {
  const goToSignalEventsTabSchema = z.object({
    y: z
      .enum(signalEventsChartOptions.y)
      .optional()
      .describe(
        `The metric to be displayed on the y-axis of the chart. Default value: '${signalEventsChartOptions.y[0]}'.`
      ),
  });

  type GoToSignalEventsTab = z.infer<typeof goToSignalEventsTabSchema>;

  const goToSignalEventsTab = ({ y }: GoToSignalEventsTab): string => {
    setEphemeralMessage("to signal events tab");
    console.debug("Navigating to Claims tab with yAxis: " + y);

    const nonNullY = y ?? signalEventsChartOptions.y[0];
    if (!signalEventsChartOptions.y.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: ${nonNullY}. Valid options: ${signalEventsChartOptions.y.join(", ")}`
      );
    }

    pageHandler.updateTabChartSettings(
      SIGNAL_EVENTS_PAGE_KEY,
      SIGNAL_EVENTS_TAB_KEY,
      { [SIGNAL_EVENT_CHART_OPTIONS_KEY]: [{ id: "y", optionId: nonNullY }] }
    );

    sendAgentResponse({
      setAgentResponse,
      selectedTabKey: selectedTab,
      newTabKey: SIGNAL_EVENTS_TAB_KEY,
      newTabTitle: SIGNAL_EVENTS_TAB_TITLE,
      chartName: SIGNAL_EVENTS_TAB_TITLE,
      selectedChartOptions,
      newChartOptions: { y: nonNullY },
    });

    return "queued navigation to Signal Events tab on the Signal Events Analytics page";
  };

  return tool(goToSignalEventsTab, {
    name: "queueGoToSignalEventsTab",
    description: `Navigate to the Signal Events tab to view a trend chart and a table of signal event occurrences.
Use this tool if the user wants to:
- See signal event occurrences on the Signal Events tab.
- Select a different metric to view on the y-axis of the trend chart.`,
    schema: goToSignalEventsTabSchema,
  });
};

export default getGoToSignalEventsTabTool;
