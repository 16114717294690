import { useAPI } from "shared/api/hooks";

import * as api from "./api";

export const useAdminPagesConfig = (args: api.AdminPagesConfigRequest) =>
  useAPI<typeof args, api.PageConfig[]>(
    api.getAdminPagesConfigRequestURI,
    args
  );

export const useGeneralConfig = (args: api.GeneralConfigRequest) =>
  useAPI<typeof args, api.GeneralConfigModel>(
    api.getGeneralConfigRequestURI,
    args
  );
