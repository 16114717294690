import classNames from "classnames";

import { TestProps } from "shared/types";
import { toSnakeCase } from "shared/utils";

export interface LabelProps
  extends React.ComponentPropsWithoutRef<"label">,
    TestProps {
  text: React.ReactNode;
  forId?: string;
}

const Label = ({
  text,
  id,
  forId,
  className = "text-gray-500",
  testId,
  ...otherProps
}: LabelProps) => {
  if (text === undefined || text === "") return null;

  const forIdToUse = forId
    ? forId
    : typeof text === "string"
      ? toSnakeCase(text)
      : undefined;

  return (
    <label
      {...otherProps}
      id={id}
      className={classNames(
        "block pl-1 text-base font-medium sm:text-sm mb-1",
        className
      )}
      htmlFor={forIdToUse as string}
      data-testid={testId}
    >
      {text}
    </label>
  );
};

export default Label;
