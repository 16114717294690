import { EntityAttribute, ListAttributesRequest } from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI } from "shared/api/hooks";
import { listPartsAttributesRequestURI } from "shared/api/parts/api";

export const useListPartsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listPartsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
