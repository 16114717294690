import {
  createContext,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  CHAT_END_EVENT,
  CHAT_START_EVENT,
  CHAT_STREAM_EVENT,
} from "duck/context/constants";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import { StringSetter } from "duck/graph/types";
import { DUCK_MESSAGES_KEY, DUCK_WELCOME_MESSAGE } from "duck/ui/constants";
import { usePendingAction } from "duck/ui/hooks";
import { StreamEvent } from "@langchain/core/dist/tracers/event_stream";

export interface DuckMessage {
  message: string;
  author: DuckMessageAuthor;
  format?: DuckMessageFormat;
  options?: Record<string, any>;
}

interface DuckContextInterface {
  messages: DuckMessage[];
  addMessage: (message: DuckMessage) => void;
  clearMessages: () => void;
  ephemeralMessage: string;
  setEphemeralMessage: StringSetter;
  streamingMessage: string;
  streamedMessage: MutableRefObject<boolean>;
  handleStreamEvent: (streamEvent: StreamEvent) => void;
  pendingAction: boolean;
  setPendingAction: (pendingAction: boolean) => void;
  loading: boolean;
  setLoading: (pendingAction: boolean) => void;
}

const DEFAULT_CONTEXT: DuckContextInterface = {
  messages: [],
  addMessage: () => {},
  clearMessages: () => {},
  ephemeralMessage: "",
  setEphemeralMessage: () => {},
  streamingMessage: "",
  streamedMessage: { current: false },
  handleStreamEvent: () => {},
  pendingAction: false,
  setPendingAction: () => {},
  loading: false,
  setLoading: () => {},
};

export const DuckContext = createContext<DuckContextInterface>(DEFAULT_CONTEXT);

interface DuckContextWrapperProps {
  children: JSX.Element;
}

const getInitialMessages = (): DuckMessage[] => {
  try {
    const messagesString = sessionStorage.getItem(DUCK_MESSAGES_KEY);
    if (messagesString) {
      return JSON.parse(messagesString);
    }

    return [DUCK_WELCOME_MESSAGE];
  } catch (error) {
    return [DUCK_WELCOME_MESSAGE];
  }
};

/**
 * @summary This context manages the messages in a Duck session.
 * These messages are visualized in the UI and are also sent with each request to the agent.
 * @returns The context provides a list of messages, a function to add a message,
 * and a function to clear all messages.
 */
const DuckContextWrapper = ({ children }: DuckContextWrapperProps) => {
  const [messages, setMessages] = useState<DuckMessage[]>(getInitialMessages());
  const [ephemeralMessage, setEphemeralMessage] = useState("");

  const [streamingMessage, setStreamingMessage] = useState("");

  const [loading, internalSetLoading] = useState(false);
  const streamedMessage = useRef(false);

  const setLoading = (loading: boolean) => {
    internalSetLoading(loading);
    if (loading) {
      streamedMessage.current = false;
    }
  };

  const { pendingAction, setPendingAction } = usePendingAction();

  const addMessage = useCallback((message: DuckMessage) => {
    setMessages((previousMessages) => {
      const newMessages = [...previousMessages, message];
      sessionStorage.setItem(DUCK_MESSAGES_KEY, JSON.stringify(newMessages));

      return newMessages;
    });
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([DUCK_WELCOME_MESSAGE]);
    sessionStorage.removeItem(DUCK_MESSAGES_KEY);
  }, []);

  const handleStreamEvent = (streamEvent: StreamEvent) => {
    if (streamEvent.event === CHAT_START_EVENT) {
      setStreamingMessage("");
    } else if (streamEvent.event === CHAT_STREAM_EVENT) {
      setStreamingMessage(
        (previousMessage) => previousMessage + streamEvent.data.chunk.content
      );
    } else if (streamEvent.event === CHAT_END_EVENT) {
      addMessage({
        author: DuckMessageAuthor.AGENT,
        message: streamEvent.data.output.content,
      });
      setStreamingMessage("");
      streamedMessage.current = true;
      setLoading(false);
    }
  };

  const contextValue: DuckContextInterface = {
    messages,
    addMessage,
    clearMessages,
    ephemeralMessage,
    setEphemeralMessage,
    streamingMessage,
    streamedMessage,
    handleStreamEvent,
    pendingAction,
    setPendingAction,
    loading,
    setLoading,
  };

  return (
    <DuckContext.Provider value={contextValue}>{children}</DuckContext.Provider>
  );
};

export default DuckContextWrapper;
