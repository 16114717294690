import { useState } from "react";

import {
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";
import { useClaimsSchema } from "shared/schemas/claimsSchema";

import { PageConfigProps } from "pages/Admin/PagesConfig/types";
import type { SignalEventsAnalyticsConfig } from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import {
  DEFAULT_FILTERS_KEY,
  DEFAULT_VEHICLE_FILTERS_KEY,
} from "./ClaimAnalyticsConfigPage";

const SIGNAL_EVENTS_PAGE_KEY = "admin_default_signal_event_filters";
const VEHICLES_PAGE_KEY = "admin_default_vehicle_filters";
const DEFAULT_CLAIM_GROUP_BY_ATTRIBUTE_KEY = "defaultClaimGroupByAttribute";

const SignalEventAnalyticsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<SignalEventsAnalyticsConfig>) => {
  const signalEventsFiltersSchema = useSignalEventOccurrencesFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();
  const { attributes } = useClaimsSchema();

  const initialSignalEventFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const initialVehicleFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_VEHICLE_FILTERS_KEY]
  );

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    initialSignalEventFilters
  );

  const defaultVehicleFilters = filterStateToFilterGroupState(
    initialVehicleFilters
  );

  const [signalEventsDropdownOpen, setSignalEventsDropdownOpen] =
    useState(false);
  const [vehiclesDropdownOpen, setVehiclesDropdownOpen] = useState(false);

  const signalEventsFilterState = useFilterSortState({
    pageKey: SIGNAL_EVENTS_PAGE_KEY,
    defaultFilterValues:
      defaultSignalEventFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const vehiclesFilterState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleSignalEventsChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  const handleVehiclesChange = (filterState: FilterGroupState) => {
    onConfigChange(
      pageId,
      DEFAULT_VEHICLE_FILTERS_KEY,
      getFiltersQuery(filterState)
    );
  };

  const attributeOptions: SelectOption[] =
    attributes?.map((attr) => ({
      id: attr.ID,
      value: attr.displayName,
    })) || [];

  const selectedAttribute = config[DEFAULT_CLAIM_GROUP_BY_ATTRIBUTE_KEY];
  const selectedAttributeOption = attributeOptions.find(
    (option) => option.id === selectedAttribute
  );

  const handleAttributeSelect = (option: SelectOption | null) => {
    onConfigChange(
      pageId,
      DEFAULT_CLAIM_GROUP_BY_ATTRIBUTE_KEY,
      option ? (option.id as string) : null
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <DropdownSelect
          testId="default-signal-event-filters-dropdown"
          label={getFilterLabel(
            "Default Signal Event Filters",
            signalEventsFilterState.filters
          )}
          open={signalEventsDropdownOpen}
          onOpen={(open) => {
            setSignalEventsDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={signalEventsFiltersSchema}
              filterSortState={signalEventsFilterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(SIGNAL_EVENTS_PAGE_KEY)}
              onApplyFilters={handleSignalEventsChange}
              testId="signal-event-filters-selector"
            />
          }
        />
        <DropdownSelect
          testId="default-vehicle-filters-dropdown"
          label={getFilterLabel(
            "Default Vehicle Filters",
            vehiclesFilterState.filters
          )}
          open={vehiclesDropdownOpen}
          onOpen={(open) => {
            setVehiclesDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={vehiclesFiltersSchema}
              filterSortState={vehiclesFilterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(VEHICLES_PAGE_KEY)}
              onApplyFilters={handleVehiclesChange}
              testId="vehicle-filters-selector"
            />
          }
        />
      </div>
      <div className="max-w-[300px]">
        <DropdownWithSearch
          label="Default Claim Group By Attribute"
          options={attributeOptions}
          selectedOption={
            selectedAttributeOption ? selectedAttributeOption : null
          }
          onSelectedOptionChange={handleAttributeSelect}
          placeholder="Search for an attribute"
        />
      </div>
    </div>
  );
};

export default SignalEventAnalyticsConfigPage;
