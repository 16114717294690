export enum DuckMessageFormat {
  TEXT = "text",
  FILTER = "filter",
  SORT = "sort",
}

export enum DuckMessageAuthor {
  HUMAN = "human",
  AGENT = "agent",
}
