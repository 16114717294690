import { useEffect, useState } from "react";
import classNames from "classnames";
import { Stepper as S, Step as SStep } from "react-form-stepper";
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes";

import Button from "features/ui/Button";
import { ActionType } from "features/ui/StepperModal";

import styles from "./Stepper.module.css";

export type onDataUpdatedCallback = (data: Record<string, any>) => void;

export interface StepProps {
  onDataUpdated?: onDataUpdatedCallback;
  data?: Record<string, any>;
  action?: ActionType;
  disableUsingLocalStorage?: boolean;
}

export interface Step extends StepDTO {
  label: string;
  content: React.ComponentType<StepProps>;
  validation?: (data: Record<string, any>) => boolean;
}

export interface Props {
  steps: Step[];
  activeStep: number;
  onStepChange: (step: number) => void;
  onClose?: () => void;
  nonLinear?: boolean;
  data?: Record<string, any>;
  onDataUpdated?: onDataUpdatedCallback;
  lastStepActionLabel?: string;
  lastStepCallback?: () => void; // if undefined default will be onClose
  lastStepCallbackIsLoading?: boolean;
  action?: ActionType;
  onlyAllowCurrentStep?: boolean; // if true, considers the given step as the last step (no navigation through steps allowed)
  disableUsingLocalStorage?: boolean;
}

const PREVIOUS_STEP = "Back";
const NEXT_STEP = "Next";
const DEFAULT_LAST_STEP_LABEL = "Close";

const Stepper = ({
  steps,
  activeStep,
  onStepChange,
  onClose,
  nonLinear,
  data,
  onDataUpdated,
  lastStepActionLabel = DEFAULT_LAST_STEP_LABEL,
  lastStepCallback,
  lastStepCallbackIsLoading,
  action,
  disableUsingLocalStorage,
  onlyAllowCurrentStep = action === "update",
}: Props) => {
  const isLastStep = activeStep === steps.length - 1 || onlyAllowCurrentStep;
  const hideBackBtn = activeStep === 0 || onlyAllowCurrentStep;
  const currentStep = steps[activeStep];

  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  useEffect(() => {
    let newCompleted = { ...completed };
    if (nonLinear) {
      newCompleted = completed;
      newCompleted[activeStep] = true;
    } else {
      // all steps up to activeStep are considered complete
      steps.map((m, indx) => {
        newCompleted[indx] = indx <= activeStep;

        return null;
      });
    }

    setCompleted(newCompleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, steps, nonLinear]);

  const goToNextStep = () => {
    if (isLastStep) return;

    onStepChange(activeStep + 1);
  };

  const goToPrevStep = () => {
    if (activeStep === 0) return;

    onStepChange(activeStep - 1);
  };

  const Component = currentStep.content;

  const dataForValidation = {
    ...(data || {}),
    onlyAllowCurrentStep,
  };

  const isStepValid = currentStep.validation
    ? currentStep.validation(dataForValidation) || false
    : true;

  return (
    <>
      <S
        className={styles.stepper}
        activeStep={activeStep}
        nonLinear={nonLinear}
        connectorStyleConfig={{
          disabledColor: "#D8D8D8",
          activeColor: "#60A5FA",
          completedColor: "#D8D8D8",
          size: 1,
          stepSize: "21px",
          style: "solid",
        }}
      >
        {steps.map(({ label }, index) => (
          <SStep
            active={activeStep === index}
            completed={completed[index]}
            key={label}
            onClick={nonLinear ? () => onStepChange(index) : undefined}
            className={classNames(styles.step, {
              [styles.active]: activeStep === index,
              [styles.completed]: completed[index],
              [styles.nonLinear]: nonLinear,
            })}
            title={label}
            styleConfig={{
              activeBgColor: "#60A5FA",
              activeTextColor: "#FFFFFF",
              completedBgColor: "#D8D8D8",
              completedTextColor: "#E0E0E0",
              inactiveBgColor: "#D8D8D8",
              inactiveTextColor: "#FFFFFF",
              size: 21,
              circleFontSize: 0,
              labelFontSize: 10,
              borderRadius: 50,
              fontWeight: "medium",
            }}
          ></SStep>
        ))}
      </S>
      {currentStep && (
        <>
          <div className="text-center">
            <span
              className="text-gray-600 text-xs mr-1"
              data-testid="stepper-step-number"
            >
              Step {activeStep + 1} of {steps.length}:
            </span>
            <span
              className="text-sm text-viaduct-black"
              data-testid="stepper-step-label"
            >
              {currentStep.label}
            </span>
          </div>
          <div className={styles.contentWrap}>
            <Component
              onDataUpdated={onDataUpdated}
              data={data}
              action={action}
              disableUsingLocalStorage={disableUsingLocalStorage}
            />
          </div>
        </>
      )}
      <div className={styles.navigation}>
        {!hideBackBtn && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={goToPrevStep}
            testId="stepper-btn-back"
            className="min-w-[128px]"
          >
            {PREVIOUS_STEP}
          </Button>
        )}
        {!isLastStep && (
          <Button
            color="secondary"
            variant="contained"
            onClick={goToNextStep}
            testId="stepper-btn-next"
            className="min-w-[128px]"
            disabled={!isStepValid}
          >
            {NEXT_STEP}
          </Button>
        )}
        {isLastStep && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              lastStepCallback ? lastStepCallback() : onClose && onClose()
            }
            disabled={!isStepValid}
            testId="stepper-btn-close"
            className="min-w-[128px]"
            isLoading={lastStepCallbackIsLoading}
          >
            {lastStepActionLabel}
          </Button>
        )}
      </div>
    </>
  );
};

export default Stepper;
