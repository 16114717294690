import { TestProps } from "shared/types";

export const DEFAULT_TAB = 0;

export interface Tab extends TestProps {
  key: string;
  title: string;
  content: string | JSX.Element;
  disabled?: boolean;
  disabledText?: string;
}

export const getFirstEnabledTab = (tabs: Tab[], defaultIndex: number) =>
  tabs.findIndex((x) => !x.disabled) || defaultIndex;

export const getIndexFromKey = (
  tabs: Tab[],
  tab: string,
  keys: string[],
  defaultIndex: number
) => {
  const i = keys.findIndex((key) => key === tab);

  if (tabs[i]?.disabled) {
    return getFirstEnabledTab(tabs, DEFAULT_TAB);
  }

  return i >= 0 ? i : defaultIndex;
};

export const getQueryContentToPersist = (
  otherQueryParams: qs.ParsedQs,
  queryParamsToPersist?: string[]
) => {
  if (!queryParamsToPersist) return {};

  return Object.keys(otherQueryParams).reduce((acc, key) => {
    if (queryParamsToPersist.includes(key)) {
      Object.assign(acc, { ...acc, [key]: otherQueryParams[key] });
    }

    return acc;
  }, {});
};
