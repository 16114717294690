import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createAgent,
  getEphemeralMessageForNode,
  getToolArgs,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { captureScreenshot } from "duck/ui/utils";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.ANALYZE_SCREENSHOT_AGENT);
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);

  const agent = createAgent(llm, [], prompt, { strict: true });

  const name = NodeNames.ANALYZE_SCREENSHOT;
  const setEphemeralMessage = params.uiHandlers.setEphemeralMessage;

  return async (
    { messages }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));
    console.debug(`AnalyzeScreenshotAgent: ${name}`);

    const { query } = getToolArgs(messages);

    // We narrow the scope of what this agent needs to consider.
    // No context (RAG documents). Only the screenshot message and the query.
    const screenshot = await captureScreenshot();
    const stream = agent.streamEvents(
      {
        screenshot,
        query,
      },
      { version: "v2", ...config }
    );

    for await (const streamEvent of stream) {
      params.uiHandlers.handleStreamEvent(streamEvent);
    }

    return {};
  };
};

export default getNode;
