import Label from "features/ui/Label";
import NestedAutocomplete from "features/ui/NestedAutocomplete";
import { SelectOption } from "features/ui/Select";

import { AttributeNameValueAction } from "./Definitions/AttributeNameValueAction";
import BooleanAction from "./Definitions/BooleanAction";
import DropdownAction from "./Definitions/DropdownAction";
import IssueMetricsAction from "./Definitions/IssueMetricsAction";
import LabelAction from "./Definitions/LabelAction";
import { ChartAction, SelectedChartOptions } from "./types";

interface Props {
  title?: string;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

export const TITLE = "Chart Settings";

/**
 * Chart Actions needs to be able to display NestedAutocomplete as well and accept it's options etc.
 * See comments in ReoccurrenceBarChartWrapper.tsx and ReocurrenceByAttributeChartWrapper.tsx.
 */
const ChartActions = ({
  title = TITLE,
  actions,
  selectedOptions,
  onOptionChange,
}: Props) => (
  <>
    {title && (
      <div className="mb-4 leading-none" data-testid="chart-actions-title">
        {title}
      </div>
    )}
    <div className="space-y-2">
      {actions.map((action) => {
        const { type, title: actionTitle, id } = action;

        if (type === "") {
          return null;
        }

        let actionValue = <LabelAction action={action} />;
        if (type === "dropdownSelect") {
          actionValue = (
            <DropdownAction
              action={action}
              selectedOptions={selectedOptions}
              onOptionChange={onOptionChange}
            />
          );
        } else if (type === "dropdownSelectNested") {
          const selectedNestedDropdownOption = selectedOptions.find(
            ({ id }) => id === action.id
          )?.optionId
            ? action.options?.find(
                ({ id }) =>
                  id ===
                  selectedOptions.find(({ id }) => id === action.id)?.optionId
              )
            : undefined;

          const handleOnSelect = (selectedOption: SelectOption) => {
            onOptionChange([
              ...selectedOptions.filter(({ id }) => id !== action.id),
              {
                id: action.id,
                optionId: selectedOption.id,
              },
            ]);
          };

          actionValue = (
            <NestedAutocomplete
              wrapperClasses="w-[300px]"
              options={action.options || []}
              onSelectionChange={handleOnSelect}
              selected={selectedNestedDropdownOption}
              testId={`chart-action-dropdown-${action.id}`}
            />
          );
        } else if (type === "label") {
          actionValue = <LabelAction action={action} />;
        } else if (type === "attrNameValue") {
          actionValue = <AttributeNameValueAction action={action} />;
        } else if (type === "boolean") {
          actionValue = (
            <BooleanAction
              action={action}
              selectedOptions={selectedOptions}
              onOptionChange={onOptionChange}
            />
          );
        } else if (type === "issueMetric") {
          return (
            <IssueMetricsAction
              key={id}
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={onOptionChange}
            />
          );
        }

        return (
          <div key={id}>
            <div className="flex space-x-3 items-center">
              <div className="w-20 text-right">
                <Label text={actionTitle} />
              </div>
              {actionValue}
            </div>
          </div>
        );
      })}
    </div>
  </>
);

export default ChartActions;
