import { useFlags } from "launchdarkly-react-client-sdk";

import { ClaimsMetrics } from "shared/api/claims/api";
import { useClaimsMetrics } from "shared/api/claims/hooks";
import { formatNumber } from "shared/utils";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import MetricCard from "features/ui/MetricCard";

interface Metric {
  title: string;
  prop: keyof ClaimsMetrics;
  valueSuffix?: string;
}

const MetricsCards = ({
  vehiclesFilters,
  claimsFilters,
  onBadRequest,
}: ClaimsFiltersProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const { data, isLoading, error } = useClaimsMetrics({
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    claimsFilter: getFiltersQuery(claimsFilters),
  });

  const metrics: Metric[] = [
    {
      title: "Total Claims",
      prop: "totalClaims",
    },
    {
      title: "IPTV",
      prop: "cumulativeIPTV",
    },
    warrantyClaimsCostFF && {
      title: "Total Cost",
      prop: "totalCost",
      valueSuffix: data?.currencyCode,
    },
    warrantyClaimsCostFF && {
      title: "Avg Cost / VIN",
      prop: "cumulativeCPV",
      valueSuffix: data?.currencyCode,
    },
  ].filter(Boolean) as Metric[];

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  return (
    <div className="flex flex-col space-y-5">
      {metrics.map(({ title, prop, valueSuffix }) => {
        const value = data && (formatNumber(data[prop] as number) || undefined);
        const metricValue = [value, valueSuffix].join(" ");

        return (
          <MetricCard
            key={prop}
            value={metricValue}
            isLoading={isLoading}
            title={title}
            testId={`claim-analytics-metric-${prop}`}
          />
        );
      })}
    </div>
  );
};

export default MetricsCards;
