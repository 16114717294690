import {
  AiOutlineFileProtect as ClaimsIcon,
  AiOutlineExclamationCircle as IssuesIcon,
  AiOutlineCar as VehiclesIcon,
} from "react-icons/ai";
import { GiAutoRepair as RepairIcon } from "react-icons/gi";

import { APIPaginatedRequest } from "shared/api/api";
import { listClaims } from "shared/api/claims/api";
import { listInspections } from "shared/api/inspections/api";
import { listIssues } from "shared/api/issues/api";
import { listRepairs } from "shared/api/repairs/api";
import { listSuggestedIssues } from "shared/api/suggestedIssues/api";
import { listVehicles } from "shared/api/vehicles/api";

import { FilterOperator } from "features/ui/Filters/types";

import { RouteKey } from "services/routes";

export interface SearchFieldConfig {
  fieldName: string;
  filterOperator: FilterOperator;
}

export interface SearchResource {
  type: string;
  searchFields: SearchFieldConfig[];
  loader: (args: APIPaginatedRequest) => Promise<any>;
  idField: string;
  nameField: string;
  orderBy?: string;
  icon: JSX.Element;
  routeKey: RouteKey;
}

export const searchConfig: SearchResource[] = [
  {
    idField: "VIN",
    nameField: "VIN",
    type: "Vehicle",
    searchFields: [
      { fieldName: "VIN", filterOperator: FilterOperator.CONTAINS },
    ],
    loader: listVehicles,
    icon: <VehiclesIcon />,
    routeKey: RouteKey.VinView,
  },
  {
    idField: "ID",
    nameField: "name",
    type: "Issue",
    searchFields: [
      {
        fieldName: "name",
        filterOperator: FilterOperator.CONTAINS,
      },
      {
        fieldName: "externalID",
        filterOperator: FilterOperator.CONTAINS,
      },
    ],
    loader: listIssues,
    icon: <IssuesIcon />,
    routeKey: RouteKey.Issue,
  },
  {
    idField: "ID",
    nameField: "name",
    type: "Suggested Issue",
    searchFields: [
      {
        fieldName: "name",
        filterOperator: FilterOperator.CONTAINS,
      },
      {
        fieldName: "description",
        filterOperator: FilterOperator.CONTAINS,
      },
    ],
    orderBy: "updatedAt:desc",
    loader: listSuggestedIssues,
    icon: <IssuesIcon />,
    routeKey: RouteKey.SuggestedIssue,
  },
  {
    idField: "ID",
    nameField: "externalID",
    type: "Claim",
    searchFields: [
      {
        fieldName: "externalID",
        filterOperator: FilterOperator.CONTAINS,
      },
    ],
    loader: listClaims,
    icon: <ClaimsIcon />,
    routeKey: RouteKey.ClaimAnalyticsDetails,
  },
  {
    idField: "ID",
    nameField: "externalID",
    type: "Repair",
    searchFields: [
      {
        fieldName: "externalID",
        filterOperator: FilterOperator.CONTAINS,
      },
    ],
    loader: listRepairs,
    icon: <RepairIcon />,
    routeKey: RouteKey.RepairAnalyticsDetails,
  },
  {
    idField: "ID",
    nameField: "externalID",
    type: "Inspection",
    searchFields: [
      {
        fieldName: "externalID",
        filterOperator: FilterOperator.CONTAINS,
      },
    ],
    loader: listInspections,
    icon: <ClaimsIcon />,
    routeKey: RouteKey.RepairAnalyticsDetails,
  },
];
