import { DEFAULT_CODE_SEARCH_RESULT_LIMIT } from "duck/graph/nodes/CodeSearchByDescriptionAgent/getNode";
import { greetingRejectClarifyValidActions } from "duck/graph/nodes/GreetingRejectClarifyAgent/getNode";
import {
  getClaimAnalyticsTabTitleList,
  getIssueDetailsTabTitleList,
  getIssuesTabTitleList,
  getSignalEventAnalyticsTabTitleList,
  getVinViewTabTitleList,
} from "duck/graph/tools/utils";
import { ToolCallRoutableNodeNames } from "duck/graph/utils";
import { DuckAccess } from "duck/ui/types";

/**
 * Routing tools definitions
 * These are the definitions for the routing tools that are used to route the
 * conversation to the appropriate node.
 * These tools do not get executed in a tool node but rather used in the conditional
 * edge logic.
 * The description is important because the agent uses it to determine which node to
 * route to. The maximum length of the description is 1024 characters.
 */

export const routeToRespondToUserToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.RESPOND_TO_USER,
    description: `Send a response to the user. This tool ensures that the agent communicates actions performed and gathers any necessary input from the user.
Use this tool when:  
1. You need to provide a summary of actions performed by the agent.  
2. Additional information or clarification is required from the user.  
3. You need to let the user know that you were unable to fulfill the user's request.`,
    parameters: {
      type: "object",
      properties: {
        response: {
          type: "string",
          description: "Description of the response to send to the user.",
        },
      },
      required: ["response"],
      additionalProperties: false,
    },
  },
} as const;

export const routeToGreetingRejectClarifyToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.GREETING_REJECT_CLARIFY,
    description: `Communicate with the user about greetings, queries that require clarification, and queries that must be rejected.
Use this tool for:
## **Greetings**  
- The query is a general greeting.
- The action should be "greeting" for these queries.
### Examples:
- "Hi"
- "How are you?"
- "Hey there"
- "Good morning"
- "Yo"
## **Queries that require clarification**  
- The query cannot be fulfilled due to missing, ambiguous, or invalid information.
- The action should be "clarify" for these queries.
### Examples:
- "Show me the metric"
- "Why?"
## **Queries that must be rejected**  
- The query is nonsensical and doesn't make any sense.
- The query is not in the scope of the agent's knowledge
- The action should be "reject" for these queries.
### Examples:
- "What is the meaning of life?"
- "akjsdhf shkdjfajks"
- "What is the weather in Alabama?"`,
    parameters: {
      type: "object",
      properties: {
        action: {
          type: "string",
          enum: greetingRejectClarifyValidActions,
          description: "Action to perform: reject or clarify or greeting.",
        },
        reason: {
          type: "string",
          description: "Reason for the action.",
        },
      },
      required: ["action", "reason"],
      additionalProperties: false,
    },
  },
} as const;

export const routeToClaimAnalyticsToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.CLAIM_ANALYTICS,
    description: `Navigate to and analyze data on the Claim Analytics page to facilitate the exploration of claims-related data.
Use this tool when:  
1. Users want to view claims or have questions about claims.
2. Users need to filter claims using claims or vehicle data.  
3. Users want to count claims or filtered sets of claims.
4. Navigation is required to one of the tabs on the **Claim Analytics** page:  
${getClaimAnalyticsTabTitleList()}`,
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const getRouteToSignalEventAnalyticsToolDef = (duckAccess: DuckAccess) =>
  ({
    type: "function",
    function: {
      name: ToolCallRoutableNodeNames.SIGNAL_EVENT_ANALYTICS,
      description: `Navigate to and analyze data on the Signal Event Analytics page, exploring Signal Events, impacted vehicle populations, and their relationships with claims. A signal event can be a fault event generated by vehicles identified by a DTC (Diagnostic Trouble Code) or a custom signal event generated by the system.
Use this tool when:  
1. Users need to filter Signal Events using Signal Event or vehicle attributes.  
2. Users want to count Signal Events or filtered sets of Signal Events.
3. Insights are required for:  
   - high-risk events
   - impacted vehicle populations
   - relationships between Signal Events and Claims
4. Navigation is required to one of the tabs on the **Signal Event Analytics** page:
${getSignalEventAnalyticsTabTitleList(duckAccess)}`,
      parameters: {
        type: "object",
        properties: {},
        additionalProperties: false,
      },
    },
  }) as const;

export const getRouteToVinViewToolDef = (duckAccess: DuckAccess) =>
  ({
    type: "function",
    function: {
      name: ToolCallRoutableNodeNames.VIN_VIEW,
      description: `Navigate to and analyze data on the VIN View page for a single vehicle identified by its Vehicle Identification Number (VIN).
Use this tool when:  
1. The user requests information about one vehicle.  
2. Actions or insights are needed for:  
   - Filtering Signal Events associated with the vehicle  
   - Viewing sensor readings for the vehicle
3. Navigating to one of the VIN View tabs:
${getVinViewTabTitleList(duckAccess)}
4. Updating filters or chart settings of one of the VIN View tabs.`,
      parameters: {
        type: "object",
        properties: {},
        additionalProperties: false,
      },
    },
  }) as const;

export const routeToKnightSwiftVinViewToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.KNIGHT_SWIFT_VIN_VIEW,
    description: `Analyze data on the VIN View page for a single vehicle.
  Use this tool for queries related to:  
  1. Diagnoses  
  2. Comparisons with vehicles that had similar diagnoses
  3. Lists of vehicles that have has similar diagnoses or had similar issues
  3. Explanations of the diagnoses of vehicles and comparisons between vehicles
  
  ## Examples
  - "I’m diagnosing VIN VDTOUUBQ4ML329875.  that came in because it won’t start. Can you help me diagnose it?"
  - "Can you give me a list of vehicles that fit the brake switch issue?"
  - "Why is this vehicle similar?"
  - "Why does this vehicle have a similar diagnosis?"`,
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToVehiclesToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.VEHICLES,
    description: `Navigate to and interact with the Vehicles page.
Use this tool when users want to:  
1. Navigate to the Vehicles page.  
2. Filter or sort vehicles based on vehicle attributes.  
3. Interact with groups of vehicles rather than a single vehicle.
4. Count vehicles or filtered sets of vehicles.
5. Have questions about the numbers, types, or attributes of vehicles.`,
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const getRouteToIssuesToolDef = (duckAccess: DuckAccess) =>
  ({
    type: "function",
    function: {
      name: ToolCallRoutableNodeNames.ISSUES,
      description: `Navigate to and interact with the Issues page.
Use this tool when users want to:
1. Navigate to one of the Issues tabs:
${getIssuesTabTitleList(duckAccess)}
2. Filter or sort Issues or Suggested Issues on the Issue page
3. Count Issues or Suggested Issues or filtered sets of Issues or Suggested Issues
4. Update settings for the Issues chart or the Suggested Issues chart
5. Have questions about the numbers, types, statuses, or classification of Issues or Suggested Issues`,
      parameters: {
        type: "object",
        properties: {},
        additionalProperties: false,
      },
    },
  }) as const;

export const routeToIssueDetailsToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.ISSUE_DETAILS,
    description: `Navigate to the Issue Details page. Use this tool when:
- The user wants to view the details of a specific issue.
- The user wants to view analysis of a specific issue from one of the following tabs:
${getIssueDetailsTabTitleList()}
- The user has provided the issue ID, name, or description to identify the issue.

Example: "Show me the Evaporative Emission on XTO-V6 issue."`,
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToRagToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.RAG,
    description: `Use this tool to respond to questions about:
- How to use the Viaduct application
- Industry help and documentation
- Terminology, definitions, and acronyms
    
## **Examples**:
- "how do i detect emerging issues?"
- "What is DIS?"
- "What is IPTV?"
- "What does the Viaduct app do?`,
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

export const routeToAnalyzeScreenshotToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.ANALYZE_SCREENSHOT,
    description: `Analyze a screenshot of the current page. Use this tool when the query requests information about or analysis of the content of the current page.
- Requests to analyze, explain, or tell the user about the screen, the page, or elements of the page, such as charts, graphs, or tables.
- Requests to read specific data elements from the page, including elements like a VIN or metrics
## **Examples**:
- "What does this screen show?"
- "Tell me about this page"
- "Can you explain this chart?"
- "What is the total mileage of this vehicle?"`,
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description:
            "The query related to the screenshot. What does the user want to know about the screenshot? What information, if any, should be extracted from the screenshot?",
        },
      },
      required: ["query"],
      additionalProperties: false,
    },
  },
} as const;

export const routeToSearchCodesByDescriptionToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.SEARCH_CODES_BY_DESCRIPTION,
    description: `Search for descriptions of codes to find relevant options. These codes can be labor codes (Customer Concern Code), part codes (Causal Part Numbers).`,
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description:
            "The query to search the descriptions of codes to find relevant options.",
        },
        source: {
          type: "string",
          enum: ["parts", "laborCodes"],
          description: "The source of the codes to search.",
        },
        numResults: {
          type: "number",
          description: `The number of results to return. The default is ${DEFAULT_CODE_SEARCH_RESULT_LIMIT}. Only set to a different value if the user requests more or fewer results.`,
        },
        showMore: {
          type: "boolean",
          description: "Whether to show more results.",
        },
      },
      required: ["query", "source", "numResults", "showMore"],
      additionalProperties: false,
    },
  },
} as const;

export const routeToSubmitFeedbackToolDef = {
  type: "function",
  function: {
    name: ToolCallRoutableNodeNames.SUBMIT_FEEDBACK,
    description: `Route to Submit Feedback Agent. Use this tool to submit feedback when the user is:
- Reporting a bug, dissatisfaction, or satisfaction about the agent's response
- Providing general feedback about Viaduct, Duck, or the agent`,
    parameters: {
      type: "object",
      properties: {
        score: {
          type: "number",
          description: "Score indicating the user's satisfaction level (0-1).",
        },
        value: {
          type: "string",
          enum: ["satisfactory", "unsatisfactory", "unsure"],
          description: "User's feedback value.",
        },
        comment: {
          type: "string",
          description: "General comments from the user.",
        },
      },
      required: ["score", "value", "comment"],
      additionalProperties: false,
    },
  },
} as const;
