import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ByVehicleAgeAgeRequest,
  Dealer,
  ExportRequest,
  getFetcher,
  LaborCode,
  ListAttributesRequest,
  Part,
  TopContributorsAgeRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { SignalEvent } from "shared/api/signalEvents/api";
import { createURL } from "shared/api/utils";
import {
  CountRequestWithVehiclesFilter,
  ListVehiclesRequest,
  Vehicle,
} from "shared/api/vehicles/api";
import { MileageUnit } from "shared/types";

const CLAIMS_BASE_ROUTE = "claims";

export interface Claim {
  ID: string;
  VIN: string;
  campaignID: string | null;
  costLabor: number | null;
  costParts: number | null;
  costTotal: number | null;
  costOther: number | null;
  totalApprovedNetCost: number | null;
  createdAt: string;
  currencyCode: string | null;
  data: Record<string, string | undefined>;
  date: string;
  dealer: Dealer | null;
  dealerID: string | null;
  externalID: string | null;
  externalURL: string | null;
  failedPartNumber: string | null;
  laborCode: string | null;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  notes: string | null;
  parts: string[];
  repairOrderNumber: string | null;
  status: string | null;
  updatedAt: string;
  binComponent: string | null;
  binSymptom: string | null;
  binProblem: string | null;
  binLocation: string | null;
  binResponsibility: string | null;
  complaintDetail: string | null;
  componentDetail: string | null;
  causalDetail: string | null;
  repairDetail: string | null;
  causalCode: string | null;
  binIssue: string | null;
  notesTechnician: string | null;
  notesPart: string | null;
  notesCorrection: string | null;
  notesCustomer: string | null;
  group: string | null;
  subgroup: string | null;
  transactionCategory: string | null;
  monthsInService: number | null;
  daysInService: number | null;
  mentionedPhrases: string[] | null;
  mentionedSignalEvents: SignalEvent[] | null;
  failedPartInformation: Part | null;
  laborCodeInformation: LaborCode | null;
  // added so they can be included in the schema that does keys of Claim
  "failedPartInformation.description": string | null;
  "laborCodeInformation.description": string | null;
}

export interface ListClaimsRequest extends APIPaginatedRequest {
  mileageUnit: MileageUnit;
  vehiclesFilter?: string;
  analytics?: boolean;
}

export const listClaimsRequestURI = ({
  ...params
}: ListClaimsRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: CLAIMS_BASE_ROUTE,
    params,
  });

export const listClaims = (args: APIPaginatedRequest) =>
  getFetcher(
    listClaimsRequestURI({ ...args, mileageUnit: "km", analytics: true })
  );

export const listClaimsCountRequestURI = ({
  ...params
}: CountRequestWithVehiclesFilter): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([CLAIMS_BASE_ROUTE, "count"]),
    params,
  });

interface APIListClaimValuesRequest extends APIListValuesRequest {
  analytics?: boolean;
}

export const listClaimsValues = (args: APIListClaimValuesRequest) => {
  args = { ...args, analytics: true };

  return getFetcher<APIListValuesResponse>(listClaimsValuesRequestURI(args));
};

const listClaimsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListClaimValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "values", fieldName]),
    params,
  });

const getClaimsExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "export", type]),
    params,
  });

export const getClaimsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getClaimsExportRequestURI(args), {
    responseType: "blob",
  });

export interface ClaimFiltersRequest {
  claimsFilter?: string;
  vehiclesFilter?: string;
  signalEventOccurrencesFilter?: string;
}

export interface ClaimFiltersByVehicleAgeRequest
  extends ClaimFiltersRequest,
    ByVehicleAgeAgeRequest {
  hideCosts: boolean;
}

export interface ClaimsMetrics {
  totalClaims: number;
  totalCost: number;
  cumulativeIPTV: number;
  cumulativeCPV: number;
  currencyCode: string;
}

export const getClaimsMetricsRequestURI = (
  params: ClaimFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "metrics"]),
    params,
  });

export interface ClaimsMetricsHistory {
  date: string;
  rollingIPTV: number;
  cumulativeIPTV: number;
  rollingCPV: number;
  cumulativeCPV: number;
  currencyCode: string;
  vehicleCount: number;
  rollingClaimCount: number;
  cumulativeClaimCount: number;
  rollingCost: number;
  cumulativeCost: number;
}

export const getClaimsMetricsHistoryRequestURI = (
  params: ClaimFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "metricsHistory"]),
    params,
  });

export interface GetClaimRequest {
  ID: string;
  mileageUnit: MileageUnit;
}

export const getClaimRequestURI = ({
  ID,
  ...params
}: GetClaimRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, ID]),
    params,
  });

export const getClaim = (args: GetClaimRequest) =>
  getFetcher<Claim>(getClaimRequestURI(args));

export interface ClaimsTopContributorsRequest
  extends APIPaginatedRequest,
    ClaimFiltersRequest,
    TopContributorsAgeRequest {
  groupByAttribute: string;
  hideCosts: boolean;
}

export interface ClaimsTopContributor {
  groupByAttributeValue: string | number | boolean | null;
  IPTV: number;
  IPTV30DayChangeRatio: number | null;
  CPV: number;
  CPV30DayChangeRatio: number | null;
  totalCost: number;
  numClaims: number;
  numEvents: number;
  numVehicles: number;
  totalNumVehicles: number;
  exposureBucket: number | null;
}

export const getClaimsTopContributorsRequestURI = ({
  groupByAttribute,
  ...otherParams
}: ClaimsTopContributorsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "topContributors", groupByAttribute]),
    params: otherParams,
  });

export const listClaimAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "attributes"]),
    params,
  });

const getClaimsTopContributorsExportRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      CLAIMS_BASE_ROUTE,
      "topContributors",
      ...IDs,
      "export",
      type,
    ]),
    params,
  });

export const getClaimsTopContributorsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getClaimsTopContributorsExportRequestURI(args), {
    responseType: "blob",
  });

// By Vehicle Age
export const getClaimsByVehicleAgeTimelineRequestURI = (
  params: ClaimFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "claimsByVehicleAgeTimeline"]),
    params,
  });

export interface ClaimAssociatedSignalEvent {
  IPTV: number;
  numAssociatedClaims: number;
  numAssociatedVehicles: number;
  percentClaimsWithPreceding30DaysSignalEvent: number;
  signalEventDescription: string | null;
  signalEventID: string;
  totalEventOccurrences: number;
  associationStrength: number | null;
}

const getClaimsByVehicleAgeTimelineExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      CLAIMS_BASE_ROUTE,
      "claimsByVehicleAgeTimeline",
      "export",
      type,
    ]),
    params,
  });

export const getClaimsByVehicleAgeTimelineExport = (args: ExportRequest) =>
  getFetcher<Blob>(getClaimsByVehicleAgeTimelineExportRequestURI(args), {
    responseType: "blob",
  });

export interface ClaimAssociatedSignalEventsRequest
  extends APIPaginatedRequest,
    ClaimFiltersRequest {
  signalEventsTimeWindow?: number;
}

export const listAssociatedSignalEventsRequestURI = (
  params: ClaimAssociatedSignalEventsRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "associatedSignalEvents"]),
    params,
  });

export interface ClaimAssociatedVehicle extends Vehicle {
  numRelatedClaimOccurrences: number;
  lastRelatedClaimOccurrenceAt: string;
  firstRelatedClaimOccurrenceAt: string;
}

export interface ClaimAssociatedVehiclesRequest
  extends APIPaginatedRequest,
    ListVehiclesRequest {
  claimFilter?: string;
}

export const listAssociatedVehiclesRequestURI = (
  params: ClaimAssociatedVehiclesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "associatedVehicles"]),
    params,
  });

export const countAssociatedVehiclesRequestURI = (
  params: ClaimAssociatedVehiclesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "associatedVehicles", "count"]),
    params,
  });

const getAssociatedVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([CLAIMS_BASE_ROUTE, "associatedVehicles", "export", type]),
    params,
  });

export const getAssociatedVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getAssociatedVehiclesExportRequestURI(args), {
    responseType: "blob",
  });
