import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { DynamicTool, tool } from "@langchain/core/tools";

interface GoToTabToolParams {
  pageHandler: PageHandler;
  setEphemeralMessage: StringSetter;
  setAgentResponse: StringSetter;
  tabKey: string;
  tabTitle: string;
  selectedTabKey: string;
}

const getGoToTabTool = ({
  pageHandler,
  setEphemeralMessage,
  setAgentResponse,
  tabKey,
  tabTitle,
  selectedTabKey,
}: GoToTabToolParams): DynamicTool => {
  const goToTab = () => {
    setEphemeralMessage(`to ${tabTitle} tab`);
    console.debug(`Navigating to ${tabTitle} tab`);
    pageHandler.navigateToTab(tabKey);

    if (selectedTabKey !== tabKey) {
      setAgentResponse(`- Navigate to the ${tabTitle} tab`);
    }

    return `Queued navigation to the ${tabTitle} tab`;
  };

  return tool(goToTab, {
    name: `goTo${tabKey}Tab`,
    description: `Navigate to the ${tabTitle} tab.`,
  });
};

export default getGoToTabTool;
