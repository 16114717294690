import { CodeDescriptionSourceOptions } from "duck/graph/types";
import { searchCodeDescriptionsVectorstore } from "duck/graph/Vectorstore/codeDescriptionsVectorstore";
import { z } from "zod";
import { tool } from "@langchain/core/tools";

const CodeSearchByDescriptionsToolParams = z.object({
  query: z.string().describe("The query to search for."),
});

type CodeSearchByDescriptionsToolParamsType = z.infer<
  typeof CodeSearchByDescriptionsToolParams
>;

interface CodeSearchByDescriptionsToolArgs {
  source: CodeDescriptionSourceOptions;
  numResults: number;
}

const getCodeSearchByDescriptionsTool = ({
  source,
  numResults,
}: CodeSearchByDescriptionsToolArgs) => {
  const codeSearchByDescriptions = async ({
    query,
  }: CodeSearchByDescriptionsToolParamsType) => {
    const results = await searchCodeDescriptionsVectorstore({
      query,
      numResults,
      source,
    });

    return JSON.stringify(results);
  };

  return tool(codeSearchByDescriptions, {
    name: `search${source}Descriptions`,
    schema: CodeSearchByDescriptionsToolParams,
    description: `Use this tool to find the relevant ${source} IDs to use for filtering.`,
  });
};

export default getCodeSearchByDescriptionsTool;
