import { PageHandler } from "duck/graph/PageHandler";
import { sendAgentResponse } from "duck/graph/sendAgentResponse/sendAgentResponse";
import { NonEmptyStringArray, StringSetter } from "duck/graph/types";
import { z } from "zod";
import { StructuredTool, tool } from "@langchain/core/tools";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { Option } from "features/ui/Select";

type SchemaDefinition = Record<
  string,
  z.ZodOptional<z.ZodEnum<NonEmptyStringArray>>
>;

interface UpdateChartSettingsToolParams {
  pageHandler: PageHandler;
  setEphemeralMessage: StringSetter;
  setAgentResponse: StringSetter;
  chartOptions: Record<string, NonEmptyStringArray>;
  selectedChartOptions: Record<string, string>;
  chartName: string;
  pageKey: string;
  tabKey: string;
  chartKey: string;
  toolDescription?: string;
}

const getUpdateChartSettingsTool = ({
  pageHandler,
  setEphemeralMessage,
  setAgentResponse,
  chartOptions,
  selectedChartOptions,
  chartName,
  pageKey,
  tabKey,
  chartKey,
  toolDescription,
}: UpdateChartSettingsToolParams): StructuredTool => {
  const schemaDefinition = Object.keys(chartOptions).reduce(
    (acc, actionId) => ({
      ...acc,
      [actionId]: z
        .enum(chartOptions[actionId])
        .optional()
        .describe(`The ${actionId} of the issues chart`),
    }),
    {} as SchemaDefinition
  );

  const updateChartSettingsSchema = z.object(schemaDefinition);

  type UpdateChartSettings = z.infer<typeof updateChartSettingsSchema>;

  const updateChartSettings = (settingsFromAgent: UpdateChartSettings) => {
    setEphemeralMessage(`update ${chartName} chart settings`);

    const chartSettings: SelectedChartOptions<Option>[] = [];

    Object.keys(settingsFromAgent).forEach((actionId: string) => {
      const nonNullOptionId =
        settingsFromAgent[actionId] ??
        selectedChartOptions[actionId] ??
        chartOptions[actionId][0];

      if (!chartOptions[actionId].includes(nonNullOptionId)) {
        throw new Error(
          `Invalid ${actionId} option for ${chartName}: ${nonNullOptionId}. Valid options: ${chartOptions[
            actionId
          ].join(", ")}`
        );
      }

      chartSettings.push({ id: actionId, optionId: nonNullOptionId });
    });

    pageHandler.updateTabChartSettings(pageKey, tabKey, {
      [chartKey]: chartSettings,
    });

    sendAgentResponse({
      setAgentResponse,
      chartName,
      selectedChartOptions,
      newChartOptions: settingsFromAgent,
    });

    console.debug("Updated chart settings", {
      chartName,
      settingsFromAgent,
      chartSettings,
    });

    return `queued the updating of the ${chartName} chart settings`;
  };

  const toolName =
    chartKey.length > 64 - "updateChartSettings".length
      ? chartKey.slice(0, 64)
      : `update${chartKey}ChartSettings`;

  return tool(updateChartSettings, {
    name: toolName,
    description:
      toolDescription ??
      `Use this tool when the user wants to update the settings of the ${chartName} chart.`,
    schema: updateChartSettingsSchema,
  });
};

export default getUpdateChartSettingsTool;
