import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { StructuredTool, tool } from "@langchain/core/tools";

import { setRouteValue, WithVin, withVinSchema } from "./utils";

const getGoToVinViewPageTool = (
  pageHandler: PageHandler,
  setAgentResponse: StringSetter
): StructuredTool => {
  const goToVinViewPage = async ({ vin }: WithVin) => {
    console.debug(`[VIN]: Navigating to VIN View page with vin ${vin}`);
    await setRouteValue(vin, pageHandler);
    pageHandler.navigateToPage();

    setAgentResponse(`- Navigate to the VIN View page for VIN ${vin}`);

    return `queued navigation to the VIN View page for VIN ${vin}`;
  };

  return tool(goToVinViewPage, {
    name: "queueGoToVinViewPage",
    description: `Navigate to the VIN View page to view data for the specified vehicle.
Use this tool when the user wants to view information about a vehicle but has not provided enough information for you to navigate to a specific tab.`,
    schema: withVinSchema,
  });
};

export default getGoToVinViewPageTool;
