import { useFlags } from "launchdarkly-react-client-sdk";

import { IssueTypes } from "shared/types";

import SignalEventAnalyticsLink from "pages/SignalEventsAnalytics/SignalEventAnalyticsLink";

import * as config from "config/config";

import { issueInfoToQuery } from "./ClaimAnalyticsLink";

interface Props {
  issue: IssueTypes;
}

const IssueSignalEventAnalyticsLink = ({ issue }: Props) => {
  const { signalEventsAnalytics } = useFlags();
  const { pages } = config.get();

  if (!signalEventsAnalytics || !pages.signalEventsAnalytics) {
    return null;
  }

  const { signalEventOccurrencesFilter, atRiskPopulationFilter } = issue;

  return (
    <SignalEventAnalyticsLink
      signalEventOccurrencesFilter={signalEventOccurrencesFilter || undefined}
      populationFilter={atRiskPopulationFilter}
      customSearchParams={issueInfoToQuery(issue)}
    />
  );
};

export default IssueSignalEventAnalyticsLink;
