export enum RouteKey {
  Root = "root",
  Docs = "docs",
  LoginCallback = "loginCallback",
  FailureModes = "failureModes",
  FailureMode = "failureMode",
  FailureModesV1 = "failureModesV1",
  FailureModeV1 = "failureModeV1",
  ClaimAnalytics = "claimAnalytics",
  ClaimAnalyticsDetails = "claimAnalyticsDetails",
  RepairAnalytics = "repairAnalytics",
  RepairAnalyticsDetails = "repairAnalyticsDetails",
  InspectionAnalytics = "inspectionAnalytics",
  InspectionAnalyticsDetails = "inspectionAnalyticsDetails",
  CustomRecordDetails = "customRecordDetails",
  SignalEventAnalytics = "signalEventAnalytics",
  SignalEventsAnalyticsDetails = "signalEventsAnalyticsDetails",
  Dashboard = "dashboard",
  Predictions = "predictions",
  VehiclesV1 = "vehiclesV1",
  Vehicles = "vehicles",
  VinView = "vinView",
  Fleets = "fleets",
  FleetView = "fleetView",
  ServicePlans = "servicePlans",
  NewServicePlan = "newServicePlan",
  ServiceRecords = "serviceRecords",
  ServicePlan = "servicePlan",
  ServiceRecommendations = "serviceRecommendations",
  CalculatedAttributes = "calculatedAttributes",
  NewCalculatedAttribute = "newCalculatedAttribute",
  CalculatedAttribute = "calculatedAttribute",
  AlertDefinitions = "alertDefinitions",
  AlertDefinition = "alertDefinition",
  NewAlertDefinition = "newAlertDefinition",
  Collections = "collections",
  Issues = "issues",
  Issue = "issue",
  SuggestedIssues = "suggestedIssues",
  SuggestedIssueLatestRun = "suggestedIssueLatestRun",
  SuggestedIssue = "suggestedIssue",
  CustomSignalEvents = "customSignalEvents",
  NewCustomSignalEvent = "newCustomSignalEvent",
  CustomSignalEvent = "customSignalEvent",
  Version = "version",
  Settings = "settings",
  Groups = "groups",
  Group = "group",
  ChatBot = "chatBot",
  AttributesConfig = "attributesConfig",
  PagesConfig = "pagesConfig",
  GeneralConfig = "generalConfig",
  NotFound = "notFound",
}

export type RoutesValues = (typeof routes)[keyof typeof routes];

export const routes: Record<RouteKey, string> = {
  [RouteKey.Root]: "/",
  [RouteKey.Docs]: "/docs",
  [RouteKey.LoginCallback]: "/login/callback",
  [RouteKey.FailureModes]: "/failure-modes",
  [RouteKey.FailureMode]: "/failure-modes/:id",
  [RouteKey.FailureModesV1]: "/failure-modes-v1",
  [RouteKey.FailureModeV1]: "/failure-modes-v1/:id",
  [RouteKey.ClaimAnalytics]: "/claim-analytics",
  [RouteKey.ClaimAnalyticsDetails]: "/claim-analytics/:id",
  [RouteKey.RepairAnalytics]: "/repair-analytics",
  [RouteKey.RepairAnalyticsDetails]: "/repair-analytics/:id",
  [RouteKey.InspectionAnalytics]: "/inspection-analytics",
  [RouteKey.InspectionAnalyticsDetails]: "/inspection-analytics/:id",
  [RouteKey.CustomRecordDetails]: "/custom-records/:id",
  [RouteKey.SignalEventAnalytics]: "/signal-event-analytics",
  [RouteKey.SignalEventsAnalyticsDetails]: "/signal-event-analytics/:id",
  [RouteKey.Dashboard]: "/dashboard/:id",
  [RouteKey.Predictions]: "/predictions",
  [RouteKey.Vehicles]: "/vehicles",
  [RouteKey.VehiclesV1]: "/vehiclesV1",
  [RouteKey.VinView]: "/vehicles/:vin",
  [RouteKey.Fleets]: "/fleets",
  [RouteKey.FleetView]: "/fleets/:fleetName",
  [RouteKey.ServicePlans]: "/service-plans",
  [RouteKey.NewServicePlan]: "/service-plans/new",
  [RouteKey.ServiceRecords]: "/service-records",
  [RouteKey.ServicePlan]: "/service-plans/:id",
  [RouteKey.ServiceRecommendations]: "/service-recommendations",
  [RouteKey.CalculatedAttributes]: "/calculated-attributes",
  [RouteKey.NewCalculatedAttribute]: "/calculated-attributes/new",
  [RouteKey.CalculatedAttribute]: "/calculated-attributes/:id",
  [RouteKey.AlertDefinitions]: "/alert-definitions",
  [RouteKey.AlertDefinition]: "/alert-definitions/:id",
  [RouteKey.NewAlertDefinition]: "/alert-definitions/new",
  [RouteKey.Collections]: "/collections",
  [RouteKey.Issues]: "/issues",
  [RouteKey.Issue]: "/issues/:id",
  [RouteKey.SuggestedIssues]: "/suggested-issues",
  [RouteKey.SuggestedIssueLatestRun]: "/suggested-issues/:id",
  [RouteKey.SuggestedIssue]: "/suggested-issues/:id/:date",
  [RouteKey.CustomSignalEvents]: "/custom-signal-events",
  [RouteKey.NewCustomSignalEvent]: "/custom-signal-events/new",
  [RouteKey.CustomSignalEvent]: "/custom-signal-events/:id",
  [RouteKey.Version]: "/version",
  [RouteKey.Settings]: "/settings",
  [RouteKey.Groups]: "/groups",
  [RouteKey.Group]: "/groups/:id",
  [RouteKey.ChatBot]: "/chat-bot",
  [RouteKey.AttributesConfig]: "/admin/attributes",
  [RouteKey.PagesConfig]: "/admin/pages",
  [RouteKey.GeneralConfig]: "/admin/general",
  [RouteKey.NotFound]: "*",
};

// we keep a list of all routes we have changed and want to keep the old links working
export const redirects = Object.freeze({
  "/campaigns/tracking": "/campaigns",
  "/campaigns/scoping": "/vehicles",
  "/campaign-scoping": "/vehicles",
  "/service-events": "/service-records",
  "/signal-event-studio": "/custom-signal-events",
});
