import { useState } from "react";
import { startOfHour, subWeeks } from "date-fns";
import qs from "qs";
import { HiChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router";

import { useListGroups } from "shared/api/rbac/hooks";
import { RFC3339_FORMAT } from "shared/constants";
import { useEmailFromJWT } from "shared/hooks";
import { IssueMeasuresEnum } from "shared/types";
import { formatDate } from "shared/utils";

import { ISSUES_PAGE_KEY } from "pages/Issues/constants";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import Checkbox from "features/ui/Checkbox";
import DropdownSelect from "features/ui/DropdownSelect";
import { getChartSettingsKey, getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

import {
  AFFECTED_BY_CLAIMS,
  AFFECTED_BY_CLAIMS_TITLE,
  AFFECTED_BY_HIGH_PRIORITY_SE,
  AFFECTED_BY_HIGH_PRIORITY_SIGNAL_EVENTS_TITLE,
  AFFECTED_BY_SE,
  AFFECTED_BY_SIGNAL_EVENTS_TITLE,
  ALL_ISSUES_TITLE,
  GROUP_ISSUES_TITLE,
  ISSUE_COUNT,
  IssuesViewType,
  MY_ISSUES_TITLE,
  OPEN_ISSUES_TITLE,
  STALE_ISSUES_TEXT,
  WARRANTY_COST,
  WARRANTY_COST_TITLE,
} from "./constants";
import DataDashboardChart from "./DataDashboardChart";
import DataMetricCard from "./DataMetricCard";
import {
  getChartOptionsAndOpenDashboard,
  getDataDashboardFilters,
  getIssuesCount,
  getMetricData,
  useIssuesOverviewData,
} from "./utils";

const DataDashboard = () => {
  const [restrictToStaleIssues, setRestrictToStaleIssues] = useState(false);
  const [showHighPrioritySignalEvents, setShowHighPrioritySignalEvents] =
    useState(false);
  const [issuesViewType, setIssuesViewType] =
    useState<IssuesViewType>("myIssues");

  const userEmail = useEmailFromJWT();

  const {
    data: dataGroups,
    error: errorGroups,
    isLoading: isLoadingGroups,
  } = useListGroups({
    filter: `users=exists:[email=eq:${userEmail}]`,
  });

  const groupIds = dataGroups ? dataGroups.map((group) => group.ID) : [];
  const waitForGroups = isLoadingGroups && issuesViewType === "groupIssues";

  const title =
    issuesViewType === "myIssues"
      ? MY_ISSUES_TITLE
      : issuesViewType === "groupIssues"
        ? GROUP_ISSUES_TITLE
        : ALL_ISSUES_TITLE;

  const issuesViewOptions: Array<{ id: IssuesViewType; value: string }> = [
    { id: "myIssues", value: MY_ISSUES_TITLE },
    { id: "groupIssues", value: GROUP_ISSUES_TITLE },
    { id: "allIssues", value: ALL_ISSUES_TITLE },
  ];

  const { filtersQuery, filters } = getDataDashboardFilters(
    restrictToStaleIssues,
    issuesViewType,
    userEmail,
    groupIds
  );

  // issue count
  const {
    data: dataIssues,
    isLoading: isLoadingIssues,
    error: errorIssues,
  } = useIssuesOverviewData(ISSUE_COUNT, undefined, filtersQuery);

  const {
    data: dataIssuesPrev,
    isLoading: isLoadingIssuesDiff,
    error: errorIssuesDiff,
  } = useIssuesOverviewData(
    ISSUE_COUNT,
    undefined,
    filtersQuery,
    formatDate(
      startOfHour(subWeeks(new Date(), 1)).toISOString(),
      RFC3339_FORMAT
    )
  );

  const { issuesCount, issuesCountDiff } = getIssuesCount(
    dataIssues,
    dataIssuesPrev
  );

  // warranty cost
  const {
    data: dataWarranty,
    isLoading: isLoadingWarranty,
    error: errorWarranty,
  } = useIssuesOverviewData(WARRANTY_COST, undefined, filtersQuery);

  const {
    data: dataWarrantyDiff,
    isLoading: isLoadingWarrantyDiff,
    error: errorWarrantyDiff,
  } = useIssuesOverviewData(WARRANTY_COST, "absolute", filtersQuery);

  const { total: warrantyCost, diff: warrantyCostDiff } = getMetricData(
    dataWarranty,
    dataWarrantyDiff
  );

  // vehicles affected by claims
  const {
    data: dataAffectedByClaims,
    isLoading: isLoadingAffectedByClaims,
    error: errorAffectedByClaims,
  } = useIssuesOverviewData(AFFECTED_BY_CLAIMS, undefined, filtersQuery);

  const {
    data: dataAffectedByClaimsDiff,
    isLoading: isLoadingAffectedByClaimsDiff,
    error: errorAffectedByClaimsDiff,
  } = useIssuesOverviewData(AFFECTED_BY_CLAIMS, "absolute", filtersQuery);

  const { total: claimsCount, diff: claimsCountDiff } = getMetricData(
    dataAffectedByClaims,
    dataAffectedByClaimsDiff
  );

  // vehicles affected by (high priority) signal events
  const signalEventsMeasure = showHighPrioritySignalEvents
    ? AFFECTED_BY_HIGH_PRIORITY_SE
    : AFFECTED_BY_SE;

  const {
    data: dataAffectedBySignalEvents,
    isLoading: isLoadingAffectedBySignalEvents,
    error: errorAffectedBySignalEvents,
  } = useIssuesOverviewData(signalEventsMeasure, undefined, filtersQuery);

  const {
    data: dataAffectedBySignalEventsDiff,
    isLoading: isLoadingAffectedBySignalEventsDiff,
    error: errorAffectedBySignalEventsDiff,
  } = useIssuesOverviewData(signalEventsMeasure, "absolute", filtersQuery);

  const { total: signalEventsCount, diff: signalEventsCountDiff } =
    getMetricData(dataAffectedBySignalEvents, dataAffectedBySignalEventsDiff);

  // navigation handling
  const navigate = useNavigate();
  const issuesFilterKey = getFiltersKey(ISSUES_PAGE_KEY);

  const handleNavigateToIssuesDashboard = (measureType: IssueMeasuresEnum) => {
    const chartOptions = getChartOptionsAndOpenDashboard(measureType);

    navigate({
      pathname: routes.issues,
      search: qs.stringify({
        [issuesFilterKey]: filtersQuery,
        [getChartSettingsKey(ISSUES_PAGE_KEY)]: JSON.stringify({
          issues: chartOptions,
        }),
      }),
    });
  };

  if (errorGroups) return <APIError error={errorGroups} />;

  if (errorIssues) return <APIError error={errorIssues} />;

  if (errorIssuesDiff) return <APIError error={errorIssuesDiff} />;

  if (errorWarranty) return <APIError error={errorWarranty} />;

  if (errorWarrantyDiff) return <APIError error={errorWarrantyDiff} />;

  if (errorAffectedByClaims) return <APIError error={errorAffectedByClaims} />;

  if (errorAffectedByClaimsDiff)
    return <APIError error={errorAffectedByClaimsDiff} />;

  if (errorAffectedBySignalEvents)
    return <APIError error={errorAffectedBySignalEvents} />;

  if (errorAffectedBySignalEventsDiff)
    return <APIError error={errorAffectedBySignalEventsDiff} />;

  return (
    <Card>
      <div className="flex flex-col justify-between w-full max-h-30">
        <div className="flex items-center space-x-3 mb-3">
          <DropdownSelect
            label={
              issuesViewOptions.find((option) => option.id === issuesViewType)
                ?.value
            }
            options={issuesViewOptions}
            onSelect={(option) =>
              setIssuesViewType(option.id as IssuesViewType)
            }
            color="secondary"
            variant="text"
            buttonComponent={
              <h3 className="font-semibold mr-6 cursor-pointer hover:opacity-80 transition-all duration-200 flex items-center">
                {
                  issuesViewOptions.find(
                    (option) => option.id === issuesViewType
                  )?.value
                }
                <HiChevronDown className="inline-block ml-1" />
              </h3>
            }
          />
          <Checkbox
            label={
              <span className="text-sm text-gray-600">{STALE_ISSUES_TEXT}</span>
            }
            onChange={(checked: boolean) => setRestrictToStaleIssues(checked)}
            checked={restrictToStaleIssues}
            dense={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataMetricCard
            title={OPEN_ISSUES_TITLE}
            value={issuesCount}
            diff={issuesCountDiff}
            isLoading={isLoadingIssues || isLoadingIssuesDiff || waitForGroups}
            onClick={() => handleNavigateToIssuesDashboard(ISSUE_COUNT)}
          />
          <DataMetricCard
            title={WARRANTY_COST_TITLE}
            value={warrantyCost}
            diff={warrantyCostDiff}
            prefix="$"
            isLoading={
              isLoadingWarranty || isLoadingWarrantyDiff || waitForGroups
            }
            onClick={() => handleNavigateToIssuesDashboard(WARRANTY_COST)}
          />
          <DataMetricCard
            title={AFFECTED_BY_CLAIMS_TITLE}
            value={claimsCount}
            diff={claimsCountDiff}
            isLoading={
              isLoadingAffectedByClaims ||
              isLoadingAffectedByClaimsDiff ||
              waitForGroups
            }
            onClick={() => handleNavigateToIssuesDashboard(AFFECTED_BY_CLAIMS)}
          />
          <DataMetricCard
            title={AFFECTED_BY_SIGNAL_EVENTS_TITLE}
            value={signalEventsCount}
            diff={signalEventsCountDiff}
            isLoading={
              isLoadingAffectedBySignalEvents ||
              isLoadingAffectedBySignalEventsDiff ||
              waitForGroups
            }
            onClick={() => handleNavigateToIssuesDashboard(signalEventsMeasure)}
            toggleOptions={{
              title: AFFECTED_BY_HIGH_PRIORITY_SIGNAL_EVENTS_TITLE,
              isOn: showHighPrioritySignalEvents,
              onToggle: () =>
                setShowHighPrioritySignalEvents(!showHighPrioritySignalEvents),
            }}
          />
        </div>
      </div>
      {dataIssues &&
        dataIssuesPrev &&
        !(dataIssues.length === 0 && dataIssuesPrev.length === 0) && (
          <DataDashboardChart
            dashboardTitle={title}
            issuesData={dataIssues}
            issuesDataPrev={dataIssuesPrev}
            filters={filters}
          />
        )}
    </Card>
  );
};

export default DataDashboard;
