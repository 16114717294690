import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { DuckContext } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import callGraph from "duck/graph";
import { PAGE_AGENT_RESPONSE_PRELUDE } from "duck/graph/nodes/constants";
import { UIHandlers } from "duck/graph/types";
import { jwtDecode } from "jwt-decode";
import { useOktaAuth } from "@okta/okta-react";

import { JWT } from "shared/types";
import { sleep } from "shared/utils";

import { DUCK_GENERIC_ERROR_MESSAGE } from "./constants";
import { useAgentData, useCtrlDKeyPress, useDuckAccess } from "./hooks";
import { LocationInfo, Reload } from "./types";

type DuckTextInputProps = {
  threadId: string;
  acquireLocationInformation: (reset: boolean) => LocationInfo;
  clearLocationInfo: () => void;
};

const DuckTextInput = ({
  threadId,
  acquireLocationInformation,
  clearLocationInfo,
}: DuckTextInputProps) => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";
  const { tenant, sub: user }: JWT = jwtDecode(accessToken);

  const [utterance, setUtterance] = useState("");

  const isCtrlDKeyPressed = useCtrlDKeyPress();

  // We use a ref to track this because we want updates to happen immediately.
  // If we use state, the delay in updating results in erroneous behavior.
  const agentResponseCountRef = useRef(0);

  const agentResponsePreludeSent = useRef(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const {
    messages,
    addMessage,
    setEphemeralMessage,
    handleStreamEvent,
    streamedMessage,
    setPendingAction,
    loading,
    setLoading,
  } = useContext(DuckContext);

  const { availableData, getPageState } = useAgentData();

  const duckAccess = useDuckAccess();

  /**
   * This function is passed to the agent so that it is able to respond to the user.
   *
   * @param message The message from the agent.
   * @param triggerAgentPreludeMessage This optional parameter causes the
   * "agent prelude message" to be sent before the indicated message is sent,
   * if it has not already been sent. This help us send the agent
   * prelude message to the user exactly one time.
   */
  const handleAgentResponse: UIHandlers["setAgentResponse"] = (
    message,
    options = {
      triggerAgentPreludeMessage: false,
      format: DuckMessageFormat.TEXT,
    }
  ) => {
    const { format, triggerAgentPreludeMessage, ...otherOptions } = options;

    if (triggerAgentPreludeMessage && !agentResponsePreludeSent.current) {
      addMessage({
        author: DuckMessageAuthor.AGENT,
        message: PAGE_AGENT_RESPONSE_PRELUDE,
      });

      agentResponsePreludeSent.current = true;
    }

    addMessage({
      author: DuckMessageAuthor.AGENT,
      format,
      message,
      options: otherOptions,
    });

    agentResponseCountRef.current++;
  };

  const uiHandlers: UIHandlers = {
    setAgentResponse: handleAgentResponse,
    setEphemeralMessage,
    duckAccess,
    handleStreamEvent,
  };

  const submit = async (): Promise<void> => {
    if (!availableData.vinView) {
      console.error("The available data needed by the agent has not loaded");

      return;
    }

    agentResponseCountRef.current = 0;
    agentResponsePreludeSent.current = false;

    const currentUtterance = isCtrlDKeyPressed
      ? "Please download images of the graph"
      : utterance.trim();

    const priorMessages = [...messages];

    addMessage({
      author: DuckMessageAuthor.HUMAN,
      message: currentUtterance,
    });

    setUtterance("");
    adjustHeight();
    setLoading(true);
    setPendingAction(false);
    clearLocationInfo();

    // Make sure that the page state is up to date
    const currentState = getPageState();

    try {
      await callGraph({
        text: currentUtterance,
        messageHistory: priorMessages,
        uiHandlers,
        threadId,
        tenant: String(tenant),
        user: String(user),
        currentState,
        availableData,
        captureImages: isCtrlDKeyPressed,
      });

      setEphemeralMessage("");

      const { reloadRequired } = acquireLocationInformation(false);
      if (reloadRequired === Reload.HARD || reloadRequired === Reload.SOFT) {
        if (agentResponseCountRef.current >= 1) {
          setPendingAction(true);
        } else {
          // All of the queued actions match the current state.
          // Because of that:
          // - No messages have been sent to the user
          // - There is no pending action
          addMessage({
            author: DuckMessageAuthor.AGENT,
            message:
              "The current page should already provide you the information you are looking for. Can I help you with anything else?",
          });
        }
      } else {
        if (!streamedMessage.current && agentResponseCountRef.current < 1) {
          // We didn't queue any actions and we didn't send any meaningful messages to the user
          addMessage({
            author: DuckMessageAuthor.AGENT,
            message:
              "I wasn't able to act on your request. Please try asking something more specific.",
          });
        }
      }
    } catch (error) {
      console.error(`${new Date().getTime()} error`, error);
      addMessage({
        author: DuckMessageAuthor.AGENT,
        message: DUCK_GENERIC_ERROR_MESSAGE,
      });
    } finally {
      setEphemeralMessage("");
      setLoading(false);
    }
  };

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(70, textarea.scrollHeight)}px`;
    // Scroll to the bottom. Without this call, the textarea does not scroll all
    // the way to the bottom when a new line is added.
    textarea.scrollTop = textarea.scrollHeight;
  }, []);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setUtterance(e.target.value);
    adjustHeight();
  };

  const assignFocus = async () => {
    // This brief delay is necessary for this to operate correctly
    await sleep(10);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      (!!utterance.trim() || isCtrlDKeyPressed)
    ) {
      event.preventDefault(); // Prevent default to avoid new line in multiline input
      await submit();
      await assignFocus();
    }
  };

  // The use of the MUI TextField component leads to runtime errors with the message of:
  // "ResizeObserver loop completed with undelivered notifications"
  // This happens when horizontally resizing the component over the CSS breakpoint boundary.
  // Using the native textarea component avoids this issue.
  return (
    <textarea
      data-testid="duck-text-input"
      ref={textareaRef}
      value={utterance}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Ask something"
      disabled={loading}
      rows={1}
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: "4px 8px",
        borderRadius: "0.375rem",
        resize: "none",
        overflow: "auto",
      }}
    />
  );
};

export default DuckTextInput;
