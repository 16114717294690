import classNames from "classnames";
import { generatePath, Link } from "react-router-dom";

import { FailureMode } from "shared/api/failureModes/api";

import { routes } from "services/routes";

import { INDEX_WHERE_FM_COLUMNS_START, STICKY_FIRST_COLUMN } from "./constants";

interface Props {
  visibleFailureModeColumns: string[];
  failureModeProps: string[];
  startColumnIndex?: number;
  failureModes: FailureMode[];
}

const ExtraFailureModesHeader = ({
  visibleFailureModeColumns,
  startColumnIndex = INDEX_WHERE_FM_COLUMNS_START,
  failureModes,
  failureModeProps,
}: Props) => {
  const failureModeIDs = failureModes.map((fm) => fm.ID);

  const visibleFMIds = failureModeIDs.filter((fmID) =>
    visibleFailureModeColumns.includes(fmID)
  );

  const numOfAttributesShown = visibleFailureModeColumns.filter((prop) =>
    failureModeProps.includes(prop)
  ).length;

  if (!visibleFMIds.length) return null;

  return (
    <>
      {new Array(startColumnIndex).fill(0).map((i, idx) => (
        <th
          key={idx}
          className={classNames({
            "sticky left-0 bg-white z-10": STICKY_FIRST_COLUMN && i === 0,
          })}
        ></th>
      ))}
      {visibleFMIds.map((fmID) => {
        const fmName = failureModes.find(({ ID }) => ID === fmID)?.name;

        const linkToFm = generatePath(routes.failureModeV1, { id: fmID });

        return (
          <th
            key={fmID}
            colSpan={numOfAttributesShown}
            className="p-2 font-semibold text-gray-500 tracking-wider uppercase whitespace-nowrap cursor-default "
            style={{ fontSize: "0.6rem" }}
          >
            <Link to={linkToFm} className="hover:underline">
              {fmName}
            </Link>
          </th>
        );
      })}
      {/* use a dummy number we ll likely never reach to span the remaining cols */}
      <th colSpan={42} />
    </>
  );
};

export default ExtraFailureModesHeader;
