import { useListVehiclesCount } from "shared/api/vehicles/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  filters: FilterGroupState;
  label?: string;
}

const VehicleCount = ({ filters, label }: Props) => {
  const { isLoading, data, error } = useListVehiclesCount({
    filter: getFiltersQuery(filters),
  });

  return (
    <TableCount
      prefix={label && `${label}: `}
      isLoading={isLoading}
      count={data?.count}
      entityName="vehicle"
      error={Boolean(error)}
    />
  );
};
export default VehicleCount;
