import classnames from "classnames";

import { RowData, SchemaEntry } from "features/ui/Table";
import { formatValue } from "features/ui/Table/TableBodyCell/TableBodyCell";
import { getValueByAccessor } from "features/ui/Table/utils";

interface Props<T> {
  schema: SchemaEntry<keyof T>[];
  data: T;
  fields: string[];
  hasNarratives?: boolean;
  alignment?: "left" | "center";
}

const DEFAULT_ALIGNMENT = "center";

const LabelValuePairs = <T,>({
  schema,
  data,
  fields = [],
  hasNarratives = false,
  alignment = DEFAULT_ALIGNMENT,
}: Props<T>) => (
  <div>
    {fields.map((accessor) => {
      const schemaEntry = schema.find((entry) => entry.accessor === accessor);
      if (!schemaEntry) return null;

      const value = formatValue({
        value: getValueByAccessor(
          accessor as string,
          data as RowData
        ) as string,
        dataType: schemaEntry.dataType,
      });

      if (!value) return null;

      return (
        <div
          key={accessor as string}
          className={classnames("text-sm", {
            "flex space-x-3 border-b py-1":
              !hasNarratives && alignment !== "left",
            "grid grid-cols-[180px_auto] gap-x-3 py-2 border-b":
              alignment === "left",
          })}
        >
          <div
            className={classnames(
              {
                "w-1/2 md:text-right": !hasNarratives && alignment !== "left",
              },
              "text-gray-400 text-sm"
            )}
          >
            {schemaEntry.label}:
          </div>
          <div
            className={classnames("break-words", {
              "w-1/2 text-left": !hasNarratives && alignment !== "left",
              "max-h-36 overflow-y-auto mt-1 mb-3 border border-gray-300":
                hasNarratives,
              "px-3 py-2": hasNarratives && typeof value === "string",
            })}
          >
            {value}
          </div>
        </div>
      );
    })}
  </div>
);

export default LabelValuePairs;
