import {
  FiTrash2 as DeleteRowIcon,
  FiPlusSquare as NewGroupIcon,
  FiPlus as NewRowIcon,
} from "react-icons/fi";
import IconButton from "@mui/material/IconButton";

interface Props {
  id: string;
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  disabled?: boolean;
  hideAddGroupCta?: boolean;
}

const RowActions = ({
  id,
  onNewRow,
  onNewGroup,
  onDelete,
  disabled,
  hideAddGroupCta,
}: Props) => (
  <div
    className="ml-auto flex flex-nowrap self-center space-x-1 order-3 invisible pointer-events-none group-hover/row:visible group-hover/row:pointer-events-auto group-hover/group:visible group-hover/group:pointer-events-auto"
    data-testid="filter-row-actions"
  >
    <IconButton
      onClick={() => onNewRow(id)}
      size="small"
      data-testid="filter-action-add-row"
      disabled={disabled}
    >
      <NewRowIcon />
    </IconButton>
    {!hideAddGroupCta && (
      <IconButton
        onClick={() => onNewGroup(id)}
        size="small"
        data-testid="filter-action-add-group"
        disabled={disabled}
      >
        <NewGroupIcon />
      </IconButton>
    )}
    <IconButton
      disabled={!onDelete || disabled}
      onClick={onDelete ? () => onDelete(id) : undefined}
      size="small"
      data-testid="filter-action-delete"
    >
      <DeleteRowIcon />
    </IconButton>
  </div>
);

export default RowActions;
