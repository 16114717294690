import { useFlags } from "launchdarkly-react-client-sdk";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import SecureComponent from "features/auth/Login/SecureComponent";

import { redirects, routes } from "services/routes";
import {
  RouteConfig,
  RouteDataProps,
  routesConfig,
} from "services/routesConfig";
import * as config from "config/config";

import RouteMiddleware from "./services/routes/RouteMiddleware";

const DEFAULT_ROUTE_VISIBILITY = true;

const RouteComponent = ({
  config,
  data,
}: {
  config: RouteConfig;
  data: RouteDataProps;
}) => {
  // RouteConfig has exactly one of component or componentFunction defined
  if (config.insecure) {
    return config.componentFunction
      ? config.componentFunction(data)
      : config.component;
  }

  return (
    <SecureComponent>
      <RouteMiddleware config={config}>
        {config.componentFunction
          ? config.componentFunction(data)
          : config.component}
      </RouteMiddleware>
    </SecureComponent>
  );
};

const AppRoutes = () => {
  const flags = useFlags();
  const { pages } = config.get();
  const dashboards = (pages.dashboards && pages.dashboards?.dashboards) || [];

  const rootPage = pages.rootPage?.route
    ? pages.rootPage.route
    : routes.vehicles;

  const routeProps: RouteDataProps = {
    flags,
    pages,
    props: { dashboards, rootPage },
  };

  const displayedRoutes = Object.entries(routesConfig).filter(([_, config]) =>
    config.shouldDisplay
      ? config.shouldDisplay(routeProps)
      : DEFAULT_ROUTE_VISIBILITY
  );

  const routerRedirects = Object.entries(redirects).map(([from, to]) => ({
    path: from,
    element: <Navigate to={to} />,
  }));

  const displayedRoutesRouter = displayedRoutes.map(([key, config]) => ({
    path: config.path,
    element: <RouteComponent config={config} data={routeProps} />,
  }));

  const wildcardRoutes = [
    {
      path: "*",
      element: (
        <RouteComponent config={routesConfig.notFound} data={routeProps} />
      ),
    },
  ];

  const routerConfig = [
    ...routerRedirects,
    ...displayedRoutesRouter,
    ...wildcardRoutes,
  ];

  const router = createBrowserRouter(routerConfig);

  // Lifted routes to data router
  return <RouterProvider router={router} />;
};

export default AppRoutes;
