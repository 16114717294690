import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { isFilterBuilderStateValid } from "features/ui/Filters/FilterBuilder/utils";

import {
  CALCULATED_ATTRIBUTE_FUNCTION_PARAMETERS,
  CalculatedAttributeFunction,
} from "./constants";
import { FunctionInputConfig } from "./FunctionInput/FunctionInput";
import { CalculatedAttributeParameters } from "./types";

export const validateForm = (
  name: string,
  description: string,
  parameters: CalculatedAttributeParameters
): { valid: boolean; message?: string } => {
  if (!name) {
    return { valid: false, message: "Please enter name." };
  }

  if (!description) {
    return { valid: false, message: "Please enter description." };
  }

  const paramErrors = validateParameters(parameters);
  if (paramErrors.length > 0) {
    return { valid: false, message: paramErrors.join(" ") };
  }

  return { valid: true, message: undefined };
};

export const validateParameters = (
  parameters: CalculatedAttributeParameters
): string[] => {
  if (!parameters.type) {
    return ["Please select function."];
  }

  const params: FunctionInputConfig[] =
    CALCULATED_ATTRIBUTE_FUNCTION_PARAMETERS[
      parameters.type as CalculatedAttributeFunction
    ];
  const errors: string[] = [];
  params.forEach((param) => {
    if (param.parameter === "eventFilter") {
      if (!isFilterValid(parameters[param.parameter])) {
        errors.push("Please enter valid event filter.");
      }
    } else if (
      parameters[param.parameter] === undefined ||
      parameters[param.parameter] === ""
    ) {
      errors.push(`Please enter ${param.labelBefore}.`);
    }
  });

  return errors;
};

export const isFilterValid = (
  filterBuilderState: FilterGroupState | undefined
): boolean =>
  filterBuilderState !== undefined &&
  isFilterBuilderStateValid(filterBuilderState);

export const getInputConfiguration = (
  selectedOptionID: string
): FunctionInputConfig[] => {
  if (!selectedOptionID) {
    return [];
  }

  return CALCULATED_ATTRIBUTE_FUNCTION_PARAMETERS[
    selectedOptionID as CalculatedAttributeFunction
  ];
};
