import React, { useEffect, useMemo, useState } from "react";
import { MdCheckBoxOutlineBlank as EmptyCheckboxItem } from "react-icons/md";

import { SignalEventOccurrencesVINAggregateBucket } from "shared/api/signalEvents/api";
import {
  useListSignalEventOccurrencesVINAggregate,
  useListSignalEventOccurrencesVINAggregateCount,
} from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER } from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { toTitleCase } from "shared/utils";

import APIError from "features/ui/APIError";
import Button from "features/ui/Button";
import { DataElement } from "features/ui/charts/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import Section from "features/ui/Section";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";
import ToggleShownCheckbox from "features/ui/ToggleShownCheckbox";

const ROWS_PER_PAGE = 1000;
const DEFAULT_SORT: SortBy = {
  signalEventType: "asc",
  signalEventID: "asc",
};
const MAX_SELECTED_ROWS = 15;

export type EventsTimelineTableProps = {
  vin: string;
  setShownSignalEvents: (events: Set<string>, persist?: boolean) => void;
  resetInitialShownSignalEvents: (
    eventsFromAPI: string[],
    onRemovedAllCallback: () => void
  ) => void;
  shownSignalEvents: Set<string>;
  hoveredSignal: string;
  setHoveredSignal: React.Dispatch<React.SetStateAction<string>>;
  filters: FilterGroupState;
  customEventTableRows?: SignalEventOccurrencesVINAggregateBucket[];
  dateFilterOccurrences: DataElement;
};

// we limit table height to (MAX_SELECTED_ROWS + 2) rows so user can see all selected rows and also that there are more down below
const tableHeight = 40 * (MAX_SELECTED_ROWS + 2);

const formatRow = (
  event: SignalEventOccurrencesVINAggregateBucket,
  setShownSignalEvents: (events: Set<string>, persist?: boolean) => void,
  shownSignalEvents: Set<string>
) => ({
  ...event,
  show: (
    <ToggleShownCheckbox
      rowKey={event.signalEventID}
      setFunction={setShownSignalEvents}
      values={shownSignalEvents}
      limit={MAX_SELECTED_ROWS}
    />
  ),
  signalEventType: toTitleCase(event.signalEventType ?? ""),
});

const SCHEMA: SchemaEntry[] = [
  {
    label: `Show (max ${MAX_SELECTED_ROWS})`,
    accessor: "show",
    dataType: DataType.JSX,
    description: (
      <div className="text-left">
        Click to show signal event in the vehicle timeline. A maximum of{" "}
        {MAX_SELECTED_ROWS} can be selected at any one time.
      </div>
    ),
    limitedWidthClass: "w-12",
  },
  {
    label: "Signal Event",
    accessor: "signalEventID",
    dataType: DataType.STRING,
    sortable: true,
    description: (
      <div className="text-left">
        A telematics-based vehicle event identifier.
      </div>
    ),
  },
  {
    label: "Type",
    accessor: "signalEventType",
    dataType: DataType.STRING,
    sortable: true,
    description: <div className="text-left">Type of signal event.</div>,
    filter: SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER({
      fieldName: "signalEventType",
      fieldNameForAPI: "signalEventType",
      label: "Type",
      description: "Signal Event Type",
      filterType: "string",
      filterDataType: "string" as DataType,
      search: true,
      disableFiltering: false,
      disableContainsFilters: false,
      disableIsEmptyFilters: false,
      loadDataOnOpen: true,
      disableSelectFilters: false,
      disableStartsWithFilters: false,
    }),
  },
  {
    label: "Description",
    accessor: "description",
    dataType: DataType.STRING,
    description: <div className="text-left">Description of signal event.</div>,
  },
  {
    label: "Occurrences",
    accessor: "totalOccurrences",
    dataType: DataType.NUMBER,
    sortable: true,
    description: (
      <div className="text-left">
        Number of occurrences of the signal event within the time period
        displayed.
      </div>
    ),
  },
];

const EventsTimelineTable = ({
  vin,
  setShownSignalEvents,
  resetInitialShownSignalEvents,
  shownSignalEvents,
  hoveredSignal,
  setHoveredSignal,
  filters,
  customEventTableRows = [],
  dateFilterOccurrences,
}: EventsTimelineTableProps) => {
  const pageKey = `vehicle_signal_event_occurrences_timeline_table_data_${vin}`;

  const [hasMarkedRowsOnInit, setHasMarkedRowsOnInit] = useState(false);

  const {
    manageFilterChange,
    resetFilters,
    sort,
    filters: tableFilters,
    initialized: filtersInitialized,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const mergedFilters = mergeFilterGroupStates(filters, tableFilters);
  const filtersQuery = getFiltersQuery(mergedFilters);

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventOccurrencesVINAggregate({
      sort: getSortFilter(sort),
      filter: filtersQuery,
      limit: ROWS_PER_PAGE,
    });

  const signalEventIDsFromAPI = (data?.map((x) => x.signalEventID) || []).join(
    ","
  );
  const customSignalEventIDs = (
    customEventTableRows?.map((x) => x.signalEventID) || []
  ).join(",");

  const allData = useMemo(
    () => [...customEventTableRows, ...(data || [])],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signalEventIDsFromAPI, customSignalEventIDs]
  );
  const allSignalEventIDs = allData?.map((item) => item.signalEventID) || [];
  const allSignalEventIDsString = allSignalEventIDs.join(",");

  const {
    isLoading: countIsLoading,
    data: count,
    error: countError,
  } = useListSignalEventOccurrencesVINAggregateCount({
    filter: filtersQuery,
  });

  useEffect(() => {
    if (!isLoading) {
      resetInitialShownSignalEvents(
        allSignalEventIDs,
        // Once we have changed the shown events, we can reevaluate if the initial rows should be marked
        () => setHasMarkedRowsOnInit(false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSignalEventIDsString, isLoading]);

  // as soon as the component mounts, we would like to mark first DEFAULT_SHOWN_ROWS rows as marked
  const shouldMarkInitialSelection =
    !hasMarkedRowsOnInit &&
    allData &&
    allData?.length > 0 &&
    shownSignalEvents.size === 0;

  useEffect(() => {
    if (!shouldMarkInitialSelection) {
      if (shownSignalEvents.size > 0) {
        setHasMarkedRowsOnInit(true);
      }

      return;
    }

    const markedRows = new Set<string>();
    allData?.forEach((value, index) => {
      if (index < MAX_SELECTED_ROWS) {
        markedRows.add(value.signalEventID);
      }
    });
    setShownSignalEvents(markedRows, false);
    setHasMarkedRowsOnInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allSignalEventIDsString,
    shouldMarkInitialSelection,
    shownSignalEvents.size,
  ]);

  // re-format the data - but only when data changes
  const formattedData = useMemo(
    () =>
      allData?.map((item) =>
        formatRow(item, setShownSignalEvents, shownSignalEvents)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSignalEventIDsString, shownSignalEvents]
  );

  formattedData?.forEach((row) => {
    row.totalOccurrences = dateFilterOccurrences[row.signalEventID] || 0;
  });

  const highlightedRowIndex = allData?.findIndex(
    (row) => row.signalEventID === hoveredSignal
  );

  const onRowHoverHandler = (rowIndex: number) => {
    const signalEvent = allData && allData[rowIndex];
    if (signalEvent) {
      setHoveredSignal(signalEvent.signalEventID);
    }
  };

  return (
    <Section testId="events-table">
      <div className="flex items-center my-3">
        {shownSignalEvents.size > 0 && (
          <Button
            label="Deselect all"
            size="small"
            onClick={() => setShownSignalEvents(new Set())}
            startIcon={<EmptyCheckboxItem />}
            className="mr-4 mt-1"
          />
        )}
        <TableCount
          extraClasses="ml-auto self-end"
          count={(count?.count || 0) + customEventTableRows.length}
          entityName="signal event"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={SCHEMA}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filters={mergedFilters}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filtersInitialized={filtersInitialized}
          highlightedRowIndex={highlightedRowIndex}
          onRowHover={onRowHoverHandler}
          onTableMouseLeave={() => setHoveredSignal("")}
          dense
          scrollHeight={tableHeight}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </Section>
  );
};

export default EventsTimelineTable;
