import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { ClaimAnalyticsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

// TODO: we shouldn't set defaults in code as these can be removed from the API.
// If changing this, make sure to also adjust in claims, repairs & signal event analytics
export const DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";

export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "claimAnalyticsTopContChartOptions";
// We manage the state of the group by options with a separate key from the rest of
// the chart options because they are not part of the chart options, and are set through
// a different mechanism.
export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "claimAnalyticsTopContGroupByOptions";

const TopContributors = ({
  claimsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: ClaimAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    claimsFiltersFilterSortState,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.CLAIM
  );

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.CLAIM,
    claimsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const claimsFilters = claimsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        groupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        filters={generalFilters}
        onClaimsFiltersChange={claimsFiltersFilterSortState?.manageFilterChange}
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
