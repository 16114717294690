import { useState } from "react";

import {
  MAX_CHART_LABEL_LENGTH,
  UNAVAILABLE_TEXT_WIDTH,
} from "pages/VINView/Events/constants";
import { getTextWidth } from "pages/VINView/Events/utils";

import {
  AXIS_TOOLTIP_FONT_SIZE,
  LABEL_COLOR,
} from "features/ui/charts/constants";

interface TruncatedYAxisTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
  signalEventDescriptions?: Record<string, string>;
}

const TruncatedYAxisTick = ({
  x,
  y,
  payload,
  signalEventDescriptions,
}: TruncatedYAxisTickProps) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!payload || !payload.value) return null;

  const isTruncated = payload.value.length > MAX_CHART_LABEL_LENGTH + 1;

  const truncatedValue = isTruncated
    ? `${payload.value.slice(0, MAX_CHART_LABEL_LENGTH)}...`
    : payload.value;

  const labelWidth = getTextWidth(
    truncatedValue,
    `${AXIS_TOOLTIP_FONT_SIZE}px lato`
  );

  const description = signalEventDescriptions?.[payload.value];
  const tooltipHeight = description ? 60 : 30;
  const showTooltip =
    isHovered &&
    labelWidth !== UNAVAILABLE_TEXT_WIDTH &&
    (isTruncated || description);

  return (
    <g
      transform={`translate(${x},${y})`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill={LABEL_COLOR}
        fontSize={AXIS_TOOLTIP_FONT_SIZE}
      >
        {truncatedValue}
      </text>
      {showTooltip && (
        <foreignObject
          x={-labelWidth - 5}
          y={-15}
          width={1000}
          height={tooltipHeight}
        >
          <div
            style={{
              background: "white",
              border: "1px solid #ccc",
              padding: "2px 4px",
              borderRadius: "2px",
              fontSize: AXIS_TOOLTIP_FONT_SIZE,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "fit-content",
            }}
          >
            {payload.value}
            {description && (
              <div style={{ fontSize: "0.9em", color: LABEL_COLOR }}>
                Description: {description}
              </div>
            )}
          </div>
        </foreignObject>
      )}
    </g>
  );
};

export default TruncatedYAxisTick;
