import {
  GPT4O_MODEL_SPEC,
  LC_API_KEY,
  LC_ENDPOINT,
  LC_PROJECT_NAME,
  OPENAI_API_KEY,
} from "duck/graph/constants";
import { GraphStateType } from "duck/graph/state";
import { ModelSpec } from "duck/graph/types";
import FileSaver from "file-saver";
import { Client } from "langsmith";
import { Document } from "@langchain/core/documents";
import { LangChainTracer } from "@langchain/core/tracers/tracer_langchain";
import { CompiledGraph, END } from "@langchain/langgraph/web";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import {
  vectorStoreSearch,
  VectorStoreSearchParameters,
  VectorStoreSearchResult,
} from "shared/api/vectorstore/api";

import { PageAgentSubgraphNodeNames } from "./pageAgentSubGraph/constants";

// Nodes that can be routed through a tool call with the same name
export const ToolCallRoutableNodeNames = {
  RAG: "rag",
  ANALYZE_SCREENSHOT: "analyzeScreenshot",
  GREETING_REJECT_CLARIFY: "greetingRejectClarify",
  CLAIM_ANALYTICS: "claimAnalytics",
  SIGNAL_EVENT_ANALYTICS: "signalEventAnalytics",
  VIN_VIEW: "vinView",
  VEHICLES: "vehicles",
  ROUTER: "router",
  RESPOND_TO_USER: "respondToUser",
  ISSUES: "issues",
  ISSUE_DETAILS: "issueDetails",
  KNIGHT_SWIFT_VIN_VIEW: "knightSwiftVinView",
  SUBMIT_FEEDBACK: "submitFeedback",
  SEARCH_CODES_BY_DESCRIPTION: "searchCodesByDescription",
} as const;
export type ToolCallRoutableNodeNamesType =
  (typeof ToolCallRoutableNodeNames)[keyof typeof ToolCallRoutableNodeNames];

// Combine the values for all NodeNames
export const NodeNames = {
  ...ToolCallRoutableNodeNames,
  ...PageAgentSubgraphNodeNames,
  CLAIM_ANALYTICS_TOOLS: "claimAnalyticsTools",
  SIGNAL_EVENT_ANALYTICS_TOOLS: "signalEventAnalyticsTools",
  VIN_VIEW_TOOLS: "vinViewTools",
  VEHICLES_TOOLS: "vehiclesTools",
  ISSUES_TOOLS: "issuesTools",
  ISSUE_DETAILS_TOOLS: "issueDetailsTools",
} as const;
export type NodeNamesType = (typeof NodeNames)[keyof typeof NodeNames];

export const GenericToolNodeName = "tools";

export type NextNodeType =
  | ToolCallRoutableNodeNamesType
  | typeof GenericToolNodeName
  | typeof END;

/**
 * Respond based on the last message's tool call
 * @summary Conditional routing function for the agent
 * @param state
 * @returns An indicator of which node to route to
 */
export const getNextNode = (state: GraphStateType): NextNodeType => {
  const { messages } = state;

  const lastMessage = messages[messages.length - 1];
  if (
    "tool_calls" in lastMessage &&
    Array.isArray(lastMessage.tool_calls) &&
    lastMessage.tool_calls?.length
  ) {
    const toolCallName = lastMessage.tool_calls[0].name;
    console.debug("getNextNode: Tool call name:", toolCallName);
    if (Object.values(ToolCallRoutableNodeNames).includes(toolCallName)) {
      console.debug("getNextNode: Routing to", toolCallName);

      return toolCallName;
    }

    console.debug("getNextNode: Routing to tools");

    return GenericToolNodeName;
  }

  // this should never happen but just in case
  console.error(
    "getNextNode: there is no tool call in the last message, ending the conversation"
  );

  return END;
};

export const getLLM = (
  modelSpec: ModelSpec = GPT4O_MODEL_SPEC
): ChatOpenAI<ChatOpenAICallOptions> =>
  new ChatOpenAI({
    openAIApiKey: OPENAI_API_KEY,
    model: modelSpec.modelName,
    temperature: modelSpec.temperature,
    modelKwargs: modelSpec.modelKwargs,
    streaming: modelSpec.streaming,
    maxTokens: modelSpec.maxTokens,
  });

export const retrieveVectorStoreResults = async (
  params: VectorStoreSearchParameters
): Promise<VectorStoreSearchResult[]> => {
  let data: VectorStoreSearchResult[] = [];

  try {
    const response = await vectorStoreSearch(params);
    data = response.data;
  } catch (vectorStoreError) {
    console.error("Failed to retrieve relevant documents", vectorStoreError);

    return [];
  }

  return data;
};

export const retrieveRelevantDocuments = async (
  query: string,
  source?: string,
  k?: number,
  distanceThreshold?: number
): Promise<Document[]> => {
  console.debug("Retrieving relevant documents", {
    query,
    source,
    k,
    distanceThreshold,
  });

  const params: VectorStoreSearchParameters = {
    query,
    k,
    distanceThreshold,
    source,
  };

  const data = await retrieveVectorStoreResults(params);

  if (!data) {
    console.error("No documents found for the given question.");

    return [];
  }

  const documents = data.map((result) => {
    const { documentID, document, title, url, metadata } = result;
    console.log("Document retrieved:", {
      documentID,
      document,
      title,
      url,
      source,
      metadata,
    });

    let parsedMetadata: Record<string, any>;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error("Failed to parse metadata:", error);
      parsedMetadata = { rawMetadata: metadata };
    }

    return new Document({
      pageContent: document,
      metadata: { title, url, source, ...parsedMetadata },
      id: documentID,
    });
  });

  return documents;
};

/**
 * @summary Format the documents for the LLM
 * @param docs The documents to format
 * @returns The formatted documents
 */

export const formatDocs = (docs: Document[]) =>
  JSON.stringify(
    docs.map((doc) => ({
      pageContent: doc.pageContent,
      url: doc.metadata.url,
      title: doc.metadata.title,
    }))
  );

let langSmithClient: Client | undefined;
export const getLangSmithClient = () => {
  if (!langSmithClient) {
    langSmithClient = new Client({ apiKey: LC_API_KEY, apiUrl: LC_ENDPOINT });
  }

  return langSmithClient;
};

export const getTracer = () =>
  new LangChainTracer({
    client: getLangSmithClient(),
    projectName: LC_PROJECT_NAME,
  });

export const drawGraph = async (
  compiledGraph: CompiledGraph<any>,
  fileName: string
) => {
  const drawableGraph = compiledGraph.getGraph();
  const image = await drawableGraph.drawMermaidPng();
  const arrayBuffer = await image.arrayBuffer();

  console.log(drawableGraph.drawMermaid());

  const blob = new Blob([arrayBuffer], { type: "image/png" });
  FileSaver.saveAs(blob, fileName);
};
