import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { updateAdminPagesConfig } from "shared/api/admin/api";
import { useAdminPagesConfig } from "shared/api/admin/hooks";

import { PagesConfig } from "pages/Admin/PagesConfig/types";

import Button from "features/ui/Button";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import SinglePageConfigItem, {
  PAGE_CONFIG_COMPONENTS,
} from "./SinglePageConfigItem";
import { updateConfig } from "./utils";

const TITLE = "Pages Configuration";

const PagesConfiguration = () => {
  const [localConfig, setLocalConfig] = useState<PagesConfig>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data,
    isLoading,
    error,
    requestKey: adminPagesConfigRequestKey,
  } = useAdminPagesConfig({});

  // When data is first loaded, we update the local state to match the API data
  useEffect(() => {
    if (data && !isLoading && !error && data.length) {
      setLocalConfig(data);
    }
  }, [data, isLoading, error]);

  const handleSave = () => {
    setIsSubmitting(true);

    updateAdminPagesConfig({ config: localConfig })
      .then(() => {
        toast.success("Pages configuration saved successfully");
        mutate(adminPagesConfigRequestKey);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleConfigChange = (
    pageID: string,
    configKey: string,
    configValue: any
  ) => {
    setLocalConfig((prev) =>
      updateConfig(prev, pageID, configKey, configValue)
    );
  };

  const [showJSON, setShowJSON] = useState(false);

  const handleShowJSON = () => {
    setShowJSON(!showJSON);
  };

  return (
    <>
      <PageHeaderWrapper>
        <Title text={TITLE} />
      </PageHeaderWrapper>
      {error && <p className="text-red-500">Failed to load configuration.</p>}
      {isLoading && <Skeleton height={300} />}
      {!isLoading && !error && (
        <div className="mt-4 max-w-4xl">
          {Object.keys(PAGE_CONFIG_COMPONENTS).map((pageKey) => (
            <SinglePageConfigItem
              key={pageKey}
              pageId={pageKey}
              config={localConfig.find((item) => item.ID === pageKey)?.config}
              onConfigChange={handleConfigChange}
            />
          ))}
          <div className="flex justify-between items-center mt-4">
            <div
              className="text-gray-400 cursor-pointer hover:underline"
              onClick={handleShowJSON}
            >
              {showJSON ? "Hide" : "Show"} JSON
            </div>
            <Button
              label="Save"
              color="primary"
              variant="contained"
              testId="save-pages-config"
              onClick={handleSave}
              isLoading={isSubmitting}
            />
          </div>
          {showJSON && (
            <div className="mt-4 flex justify-between text-xs text-gray-600">
              <div>
                Current local:
                <pre className="bg-gray-50">
                  {JSON.stringify(localConfig, null, 2)}
                </pre>
              </div>
              <div>
                Current API:
                <pre className="bg-gray-100">
                  {JSON.stringify(data, null, 2)}
                </pre>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PagesConfiguration;
