import { RefObject, useEffect, useState } from "react";
import DuckContextWrapper from "duck/context/DuckContextWrapper";
import { preloadPrompts } from "duck/graph/nodes/loadPrompt";
import { createCodeDescriptionsVectorstore } from "duck/graph/Vectorstore/codeDescriptionsVectorstore";
import DuckErrorBoundary from "duck/ui/error/DuckErrorBoundary";
import { createPortal } from "react-dom";

import { DUCK_FLOATING_KEY } from "./constants";
import DraggableDuck from "./DraggableDuck";
import Duck from "./Duck";

interface DuckNestProps {
  draggableDuckRef: RefObject<HTMLDivElement | null>;
}

const DuckNest = ({ draggableDuckRef }: DuckNestProps) => {
  const initialDraggable = sessionStorage.getItem(DUCK_FLOATING_KEY) === "true";
  const [isDuckDraggable, internalSetIsDuckDraggable] =
    useState(initialDraggable);

  const setIsDuckDraggable = (isDraggable: boolean): void => {
    internalSetIsDuckDraggable(isDraggable);
    sessionStorage.setItem(DUCK_FLOATING_KEY, String(isDraggable));
  };

  useEffect(() => {
    // Preloading prompts allows the agent to initialize faster.
    // This runs asynchronously and we do not wait for the result.
    // The preloading happens in memory. We don't need to actively do anything with
    // the result of the preload call.
    void preloadPrompts();
    void createCodeDescriptionsVectorstore();
  }, []);

  return (
    <DuckErrorBoundary>
      <DuckContextWrapper>
        <>
          {isDuckDraggable &&
            draggableDuckRef.current &&
            createPortal(
              <DraggableDuck setIsDraggable={setIsDuckDraggable} />,
              draggableDuckRef.current
            )}

          {!isDuckDraggable && (
            // This empty div is necessary for Duck to correctly set its height.
            <div>
              <Duck setIsDraggable={setIsDuckDraggable} />
            </div>
          )}
        </>
      </DuckContextWrapper>
    </DuckErrorBoundary>
  );
};

export default DuckNest;
