import { useFlags } from "launchdarkly-react-client-sdk";

import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import {
  SUGGESTED_ISSUE_GENERIC_FILTER,
  SUGGESTED_ISSUE_GROUP_NAME_FILTER,
  SUGGESTED_ISSUE_ISSUE_NAME_FILTER,
  SUGGESTED_ISSUE_STATUS_FILTER,
} from "shared/filterDefinitions";
import { addFormattedDateToOptionsAsLabel } from "shared/utils";

import { SchemaEntry } from "features/ui/Table";

import { SchemaEntryOverride, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useSuggestedIssuesSchema = (): UseSchema => {
  const { blacklistedSuggestedIssueColumns, issuePublishing } = useFlags();

  const { attributes } = useAttributesContext(
    ContextProviderName.SuggestedIssue
  );

  const attributeMap = getAttributeMap(attributes);
  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SUGGESTED_ISSUE_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: SchemaEntryOverride = {
    description: {
      schemaOverride: {
        limitedWidthClass: "w-96",
      },
    },
    assignedGroupID: {
      filterOverride: SUGGESTED_ISSUE_GROUP_NAME_FILTER({
        label: getDisplayLabel("assignedGroupID", "Responsible Group"),
        fieldName: "assignedGroupID",
        disableArbitraryText: true,
      }),
    },
    statusObj: {
      filterOverride: SUGGESTED_ISSUE_STATUS_FILTER({
        label: getDisplayLabel("statusObj", "Status"),
        fieldName: "statusObj.value",
        disableArbitraryText: true,
      }),
      sortOverride: {
        fieldNameForAPI: "statusObj.valueOrder",
      },
    },
    published: {
      ignore: !issuePublishing,
    },
    discovered: {
      filterOverride: {
        formatLabelFunc: addFormattedDateToOptionsAsLabel,
        loadOptionsArgs: { sortDirection: "desc" },
      },
    },
    updated: {
      filterOverride: {
        formatLabelFunc: addFormattedDateToOptionsAsLabel,
        loadOptionsArgs: { sortDirection: "desc" },
      },
    },
    promotedTo: {
      schemaOverride: {
        filter: SUGGESTED_ISSUE_ISSUE_NAME_FILTER({
          label: getDisplayLabel("promotedTo", "Promoted To"),
          fieldName: "promotedToIDs",
          loadDataOnOpen: true,
          disableArbitraryText: true,
          disableContainsFilters: true,
          disableStartsWithFilters: true,
        }),
      },
    },
    ...blacklistedSuggestedIssueColumns.reduce(
      (acc: SchemaEntryOverride, current: string) => {
        acc[current] = { ignore: true };

        return acc;
      },
      {}
    ),
  };

  const schema: SchemaEntry<string>[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};

export default useSuggestedIssuesSchema;
