import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { updateIssue } from "shared/api/issues/api";
import { useIssue } from "shared/api/issues/hooks";

import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
} from "pages/Issues/constants";

import ConfirmationModal from "features/ui/ConfirmationModal";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  areFiltersEqual,
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { useNavigationBlocker } from "features/ui/RouterPrompt/hooks";

import { useQuery } from "services/hooks";

import ModalText from "./ModalText";

const TITLE = "Save Changes?";
const CONFIRM_TEXT = "Yes, Save to Issue";
const CANCEL_TEXT = "No, Do Not Save";
const SUCCESS_MESSAGE = "Issue updated successfully";
const FAILURE_MESSAGE =
  "Updating issue failed. Please notify us if the problem persists.";

interface Props {
  page: "claimAnalytics" | "signalEventAnalytics";
  vehicleFilters?: FilterGroupState;
  claimFilters?: FilterGroupState;
  signalEventFilters?: FilterGroupState;
}

const ConfirmationModalIssueUpdate = ({
  page,
  vehicleFilters,
  claimFilters,
  signalEventFilters,
}: Props) => {
  const query = useQuery();

  const [initialVehicleFilters, setInitialVehicleFilters] =
    useState<FilterGroupState>();
  const [initialClaimFilters, setInitialClaimFilters] =
    useState<FilterGroupState>();
  const [
    initialSignalEventOccurrencesFilter,
    setInitialSignalEventOccurrencesFilter,
  ] = useState<FilterGroupState>();

  const issueId = query && (query[ISSUE_ID_QUERY_KEY] as string);
  const isSuggestedIssue = query[ISSUE_IS_SUGGESTED_QUERY_KEY] === "true";

  const skipRequest = !issueId || isSuggestedIssue;

  const { data: issue } = useIssue({
    id: issueId,
    skipRequest,
  });

  useEffect(() => {
    if (issue?.atRiskPopulationFilter) {
      setInitialVehicleFilters(
        filterBuilderQueryToFilterBuilderState(issue.atRiskPopulationFilter)
      );
    }

    if (issue?.claimFilter) {
      setInitialClaimFilters(
        filterBuilderQueryToFilterBuilderState(issue.claimFilter)
      );
    }

    if (issue?.signalEventOccurrencesFilter) {
      setInitialSignalEventOccurrencesFilter(
        filterBuilderQueryToFilterBuilderState(
          issue.signalEventOccurrencesFilter
        )
      );
    }
  }, [issue]);

  const hasChangedVehicleFilters = !areFiltersEqual(
    initialVehicleFilters,
    vehicleFilters
  );

  const hasChangedClaimFilters =
    page === "claimAnalytics" &&
    !areFiltersEqual(initialClaimFilters, claimFilters);

  const hasChangedSignalEventIDs =
    page === "signalEventAnalytics" &&
    !areFiltersEqual(initialSignalEventOccurrencesFilter, signalEventFilters);

  const issueName = query[ISSUE_NAME_QUERY_KEY] as string;

  const canEditThisIssue = issue?.canEdit;

  const promptUserWhenNavigatingAway = Boolean(
    canEditThisIssue &&
      (hasChangedVehicleFilters ||
        (page === "claimAnalytics" && hasChangedClaimFilters) ||
        (page === "signalEventAnalytics" && hasChangedSignalEventIDs))
  );

  const { confirmNavigation, blocked } = useNavigationBlocker(
    promptUserWhenNavigatingAway
  );

  if (!query || !issueId || !query[ISSUE_NAME_QUERY_KEY] || isSuggestedIssue) {
    return null;
  }

  const handleOnIssueUpdate = () => {
    let requestData: {
      ID: string;
      atRiskPopulationFilter: string;
      claimFilter?: string;
      signalEventOccurrencesFilter?: string;
    } = {
      ID: issueId,
      atRiskPopulationFilter: getFiltersQuery(vehicleFilters),
    };

    if (hasChangedClaimFilters) {
      requestData.claimFilter = getFiltersQuery(claimFilters);
    }

    if (hasChangedSignalEventIDs) {
      requestData.signalEventOccurrencesFilter =
        getFiltersQuery(signalEventFilters);
    }

    updateIssue(requestData)
      .then(() => {
        toast.success(SUCCESS_MESSAGE);
      })
      .catch((err: Error) => {
        toast.error(FAILURE_MESSAGE);
        console.log(err);
      });
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      handleOnIssueUpdate();
      confirmNavigation();
    } else {
      // special blocker case since we want to proceed with navigation even if the user cancels
      confirmNavigation();
    }
  };

  // We are not using RouterPrompt generic component, since there is non-standard logic on cancel
  return (
    <ConfirmationModal
      confirmText={CONFIRM_TEXT}
      closeText={CANCEL_TEXT}
      text={
        <ModalText
          hasChangedVehicleFilters={hasChangedVehicleFilters}
          hasChangedClaimFilters={hasChangedClaimFilters}
          hasChangedSignalEventFilters={hasChangedSignalEventIDs}
          issueName={issueName}
        />
      }
      title={TITLE}
      isOpen={blocked}
      onClose={handleClose}
    />
  );
};

export default ConfirmationModalIssueUpdate;
