import { useFlags } from "launchdarkly-react-client-sdk";

import { EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useClaimByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import {
  cumulativeCPV,
  cumulativeEvents,
  cumulativeIPTV,
  totalVehicles,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option, SelectOption } from "features/ui/Select";

/**
 * This hook is used by the ByVehicleAge chart to build the available chart actions.
 * It is also used by Duck to provide context to the agent.
 */
export const useByVehicleAgeChartActions = (
  eventType: EventTypeLabel
): ChartAction<Option>[] => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const yAxisOptions: SelectOption[] = [
    cumulativeIPTV,
    warrantyClaimsCostFF && cumulativeCPV,
    totalVehicles,
    cumulativeEvents(eventType),
  ].filter(Boolean);

  return getByVehicleAgeChartOptions(
    yAxisOptions,
    useByVehicleAgeBirthdayOptions(),
    useClaimByVehicleAgeExposureOptions()
  );
};
