import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { toTitleCase } from "shared/utils";

import { ACTIVE_STATUS_LIST, ERROR_STATUSES } from "./constants";

interface Props {
  customSignalEventDefinition: CustomSignalEventDefinition;
}

const DISABLED_COLOR = "text-gray-400";
const ERROR_COLOR = "text-red-600";
const BASE_COLOR = "text-viaduct-black";

const StatusSection = ({ customSignalEventDefinition }: Props) => {
  const { status: customSignalEventStatus } = customSignalEventDefinition;
  // show error status in a list of statuses only if current status is error status
  const filteredStatusList = ERROR_STATUSES.includes(customSignalEventStatus)
    ? ACTIVE_STATUS_LIST.filter(
        (status) =>
          !ERROR_STATUSES.includes(status) || status === customSignalEventStatus
      )
    : ACTIVE_STATUS_LIST.filter((status) => !ERROR_STATUSES.includes(status));

  return (
    <div className="h-full pt-2">
      <>
        {filteredStatusList.map((status, idx) => {
          const statusColor = ERROR_STATUSES.includes(status)
            ? ERROR_COLOR
            : BASE_COLOR;
          const textColor =
            status === customSignalEventStatus ? statusColor : DISABLED_COLOR;

          return (
            <span key={status}>
              <span className={textColor}>{toTitleCase(status)}</span>{" "}
              {idx !== filteredStatusList.length - 1 && (
                <span className={DISABLED_COLOR}>{" > "}</span>
              )}
            </span>
          );
        })}
      </>
    </div>
  );
};

export default StatusSection;
