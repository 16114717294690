import { useMemo } from "react";
import qs from "qs";
import { generatePath, Link } from "react-router-dom";

import {
  FailureModeEvent,
  FailureModeEventSource,
  getFailureModeEventsExport,
} from "shared/api/failureModes/api";
import {
  useFailureModeEvents,
  useFailureModeEventsCount,
} from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { FAILURE_MODE_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";
import { useFailureModeEventsSchema } from "shared/schemas/failureModeEventsSchema";
import { EventTypeEnumCapitalized, SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterSchemaItem } from "features/ui/Filters/types";
import Section from "features/ui/Section";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

const RESULTS_PER_PAGE = 500;
const SCROLL_HEIGHT_PX = 500;
const VIN_VIEW_HISTORY_TAB = 1;
const DEFAULT_SORT: SortBy = { date: "desc" };

const formatRow = (event: FailureModeEvent) => {
  const search = { tab: VIN_VIEW_HISTORY_TAB };
  const pathname = generatePath(routes.vinView, {
    vin: encodeURIComponent(event.VIN),
  });

  return {
    ...event,
    VIN: (
      <Link
        to={{ pathname, search: qs.stringify(search) }}
        className="text-metabase-blue hover:underline"
      >
        {event.VIN}
      </Link>
    ),
    sources: (
      <>
        {event.sources.map((source) => (
          <span className="after:content-[','] last:after:content-none mr-1 text-metabase-blue">
            <EventSourceLink eventSource={source} />
          </span>
        ))}
      </>
    ),
  };
};

const EventSourceLink = ({
  eventSource,
}: {
  eventSource: FailureModeEventSource;
}) => {
  switch (eventSource.type) {
    case EventTypeEnumCapitalized.CLAIM:
      return (
        <Link
          to={generatePath(routes.claimAnalyticsDetails, {
            id: eventSource.ID,
          })}
          className="text-metabase-blue hover:underline"
        >
          Claim
        </Link>
      );
    case EventTypeEnumCapitalized.SIGNAL_EVENT:
      return (
        <Link
          to={generatePath(routes.signalEventsAnalyticsDetails, {
            id: eventSource.ID,
            recordedAt: eventSource.timestamp,
          })}
          className="text-metabase-blue hover:underline"
        >
          Signal Event
        </Link>
      );
    case EventTypeEnumCapitalized.REPAIR:
      return (
        <Link
          to={generatePath(routes.repairAnalyticsDetails, {
            id: eventSource.ID,
          })}
          className="text-metabase-blue hover:underline"
        >
          Repair
        </Link>
      );
    case EventTypeEnumCapitalized.INSPECTION:
      return (
        <Link
          to={generatePath(routes.inspectionAnalyticsDetails, {
            id: eventSource.ID,
          })}
          className="text-metabase-blue hover:underline"
        >
          Inspection
        </Link>
      );
    case EventTypeEnumCapitalized.CUSTOM_RECORD:
      return (
        <Link
          to={generatePath(routes.customRecordDetails, {
            id: eventSource.ID,
          })}
          className="text-metabase-blue hover:underline"
        >
          Custom Record
        </Link>
      );

    default:
      return <>Unknown event</>;
  }
};

interface Props {
  failureModeID: string;
  vehicleFilters: FilterGroupState;
}

const FailureModeEventsTable = ({ failureModeID, vehicleFilters }: Props) => {
  const pageKey = `failure_mode_events_table_${failureModeID}`;

  const { schema } = useFailureModeEventsSchema((item: FilterSchemaItem) =>
    FAILURE_MODE_EVENTS_GENERIC_FILTER(failureModeID, item)
  );

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const { data, isLoading, headers, error, ...paginationData } =
    useFailureModeEvents({
      id: failureModeID,
      limit: RESULTS_PER_PAGE,
      filter: getFiltersQuery(filters),
      sort: getSortFilter(sort),
      vehiclesFilter: getFiltersQuery(vehicleFilters),
    });

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useFailureModeEventsCount({
    id: failureModeID,
    vehiclesFilter: getFiltersQuery(vehicleFilters),
    filter: getFiltersQuery(filters),
  });
  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <Section testId="failure-mode-events">
      <div className="flex space-x-3 items-center mb-3 mt-3 justify-between">
        <div>
          <FiltersOverview
            filters={filters}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
        </div>
        <div className="flex space-x-3 items-center self-end">
          <TableCount
            extraClasses="ml-auto"
            count={countData?.count as number}
            entityName="event"
            isLoading={countIsLoading}
            error={!!countError}
          />
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getFailureModeEventsExport}
            fileName="failure_mode_events"
            requestParams={{
              IDs: [failureModeID],
              vehiclesFilter: getFiltersQuery(vehicleFilters),
              filter: getFiltersQuery(filters),
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="event"
            filters={filters}
          />
        </div>
      </div>
      {!error && (
        <PaginatedTable
          isLoading={isLoading}
          loadingRows={RESULTS_PER_PAGE}
          data={formattedData}
          schema={schema}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={pageKey}
          scrollHeight={SCROLL_HEIGHT_PX}
          dense
          {...paginationData}
        />
      )}
      {/* We get empty [] back when using filters and there's no results */}
      {(!formattedData || !formattedData.length) && !isLoading && !error && (
        <div className="py-4 text-gray-400 text-sm">
          No Failure Mode Events found.
        </div>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </Section>
  );
};

export default FailureModeEventsTable;
