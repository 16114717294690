import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { Link } from "react-router-dom";

import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { IssueTypes } from "shared/types";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "pages/Issues/constants";
import { isSuggestedIssue } from "pages/Issues/utils";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";
import * as config from "config/config";

interface Props {
  issue: IssueTypes;
}

export const issueInfoToQuery = (issue: IssueTypes) => {
  const { ID } = issue;

  return {
    [ISSUE_ID_QUERY_KEY]: ID,
    [ISSUE_NAME_QUERY_KEY]: "name" in issue ? issue.name : "",
    [ISSUE_IS_SUGGESTED_QUERY_KEY]: isSuggestedIssue(issue),
    [ISSUE_RUN_DATE_QUERY_KEY]: isSuggestedIssue(issue)
      ? (issue as SuggestedIssue).updated
      : undefined,
  };
};

const ClaimAnalyticsLink = ({ issue }: Props) => {
  const { claimAnalytics } = useFlags();
  const { pages } = config.get();

  if (!claimAnalytics || !pages.claimAnalytics) {
    return null;
  }

  const { claimFilter, atRiskPopulationFilter } = issue;

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  return (
    <Link
      to={{
        pathname: routes.claimAnalytics,
        search: qs.stringify(
          {
            [claimAnalyticsFilterKey]: claimFilter,
            [vehicleFilterKey]: atRiskPopulationFilter,
            ...issueInfoToQuery(issue),
          },
          { arrayFormat: "indices" }
        ),
      }}
      className="ml-auto mt-2 text-metabase-blue hover:underline sm:text-sm"
      data-testid="explore-claim-analytics"
    >
      Explore in Claim Analytics
    </Link>
  );
};

export default ClaimAnalyticsLink;
