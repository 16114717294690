import { useClaimsSchema } from "shared/schemas/claimsSchema";

import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { SelectOption } from "features/ui/Select";

interface ClaimAttributesMultiSelectProps {
  selectedAttributes?: string[];
  onAttributesChange: (attributeIds: string[]) => void;
}

const ClaimAttributesMultiSelect = ({
  selectedAttributes = [],
  onAttributesChange,
}: ClaimAttributesMultiSelectProps) => {
  const { attributes } = useClaimsSchema();

  const attributeOptions: SelectOption<string>[] =
    attributes?.map((attr) => ({
      id: attr.ID,
      value: attr.displayName,
    })) || [];

  const selectedOptions: SelectOption<string>[] = selectedAttributes.map(
    (id) => {
      const attr = attributes?.find((a) => a.ID === id);

      return {
        id,
        value: attr?.displayName || id,
      };
    }
  );

  return (
    <DropdownWithSearch<string, true>
      multiple={true}
      options={attributeOptions}
      selectedOption={selectedOptions}
      onSelectedOptionChange={(options) =>
        onAttributesChange(options.map((option) => option.id))
      }
      label="Top X Charts Options"
      placeholder="Select attributes"
    />
  );
};

export default ClaimAttributesMultiSelect;
