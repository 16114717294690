import { Switch } from "@mui/material";

import {
  ALERT_DEFINITIONS_PAGE_KEY,
  CALCULATED_ATTRIBUTES_PAGE_KEY,
  CLAIM_ANALYTICS_PAGE_KEY,
  COLLECTIONS_PAGE_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_KEY,
  ENABLED_KEY,
  EVENTS_PAGE_KEY,
  FAILURE_MODES_PAGE_KEY,
  FAILURE_MODES_V0_PAGE_KEY,
  FLEETS_PAGE_KEY,
  HELP_ARTICLES_PAGE_KEY,
  INSPECTION_ANALYTICS_PAGE_KEY,
  ISSUES_PAGE_KEY,
  KnownPageKeys,
  MAINTENANCE_SCHEDULES_PAGE_KEY,
  PageConfigProps,
  REPAIR_ANALYTICS_PAGE_KEY,
  SERVICE_PLANS_PAGE_KEY,
  SIGNAL_EVENT_ANALYTICS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/Admin/PagesConfig/types";

import Card from "features/ui/Card";

import ClaimAnalyticsConfigPage from "./perPageConfigs/ClaimAnalyticsConfigPage";
import CustomSignalEventsConfigPage from "./perPageConfigs/CustomSignalEventsConfigPage";
import EventsConfigPage from "./perPageConfigs/EventsConfigPage";
import FailureModesConfigPage from "./perPageConfigs/FailureModesConfigPage";
import FailureModesV0ConfigPage from "./perPageConfigs/FailureModesV0ConfigPage";
import HelpArticlesConfigPage from "./perPageConfigs/HelpArticlesConfigPage";
import IssuesConfigPage from "./perPageConfigs/IssuesConfigPage";
import SignalEventAnalyticsConfigPage from "./perPageConfigs/SignalEventAnalyticsConfigPage";
import VehiclesConfigPage from "./perPageConfigs/VehiclesConfigPage";

// Define a mapping of page keys to their config components
export const PAGE_CONFIG_COMPONENTS: Record<
  KnownPageKeys,
  React.ComponentType<PageConfigProps<any>>
> = {
  [VEHICLES_PAGE_KEY]: VehiclesConfigPage,
  [EVENTS_PAGE_KEY]: EventsConfigPage,
  [FAILURE_MODES_PAGE_KEY]: FailureModesConfigPage,
  [FAILURE_MODES_V0_PAGE_KEY]: FailureModesV0ConfigPage,
  [ISSUES_PAGE_KEY]: IssuesConfigPage,
  [CLAIM_ANALYTICS_PAGE_KEY]: ClaimAnalyticsConfigPage,
  [SIGNAL_EVENT_ANALYTICS_PAGE_KEY]: SignalEventAnalyticsConfigPage,
  [CUSTOM_SIGNAL_EVENTS_PAGE_KEY]: CustomSignalEventsConfigPage,
  [HELP_ARTICLES_PAGE_KEY]: HelpArticlesConfigPage,
  [FLEETS_PAGE_KEY]: () => null,
  [SERVICE_PLANS_PAGE_KEY]: () => null,
  [ALERT_DEFINITIONS_PAGE_KEY]: () => null,
  [MAINTENANCE_SCHEDULES_PAGE_KEY]: () => null,
  [CALCULATED_ATTRIBUTES_PAGE_KEY]: () => null,
  [REPAIR_ANALYTICS_PAGE_KEY]: () => null,
  [INSPECTION_ANALYTICS_PAGE_KEY]: () => null,
  [COLLECTIONS_PAGE_KEY]: () => null,
} as const;

const SinglePageConfigItem = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps) => {
  const isEnabled = !!config;
  const ConfigComponent = PAGE_CONFIG_COMPONENTS[pageId as KnownPageKeys];

  return (
    <Card classNames="mb-3" key={pageId}>
      <div className="flex justify-between">
        <span className="capitalize font-semibold">{pageId}</span>
        <Switch
          checked={isEnabled}
          onChange={(_, checked) =>
            onConfigChange(pageId, ENABLED_KEY, checked)
          }
          color="primary"
          size="small"
        />
      </div>
      {isEnabled && (
        <div className="mt-2 text-xs">
          {ConfigComponent && (
            <ConfigComponent
              pageId={pageId}
              onConfigChange={onConfigChange}
              config={config}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default SinglePageConfigItem;
