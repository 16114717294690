import {
  getAnalyzeScreenshotAgentNode,
  getClaimAnalyticsAgentGraph,
  getCodeSearchByDescriptionAgentNode,
  getIssueAgentGraph,
  getIssueDetailsAgentGraph,
  getKnightSwiftVinViewAgentNode,
  getRagAgentNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getRouterAgentNode,
  getSignalEventAnalyticsAgentGraph,
  getSubmitFeedbackNode,
  getVehiclesAgentGraph,
  getVinViewAgentGraph,
} from "duck/graph/nodes";
import { graphState } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { Runnable } from "@langchain/core/runnables";
import { END, MemorySaver, START, StateGraph } from "@langchain/langgraph/web";

import { getNextNode, NodeNames } from "./utils";

// Typescript has been unusually finicky about the type of the routerEdgeTargets.
// It appears to want the type to align with the nodes in the initial base graph.
// It complains if extra node names are present. For example, it doesn't like this:
// type ExtendedNodeNames = NodeNamesType | "__end__" | "__start__";
// We should only add to this list if additional nodes are added to the initial graph
// that does not include nodes or edges for page agents.
type RouterEdgeTargets =
  | "rag"
  | "greetingRejectClarify"
  | "router"
  | "respondToUser"
  | "submitFeedback"
  | "__end__"
  | "__start__";

/**
 * Create the elements of the graph that are always present, regardless of the
 * configuration of the tenant and the settings in LaunchDarkly.
 */
const createGraph = async (params: DuckGraphParams) => {
  const duckAccess = params.uiHandlers.duckAccess;

  // We type this as Record<string, string> so that we can add anything we want
  // to it. We intentionally do not use the SupervisorEdgeTargets type here
  // because it would not allow us to add anything to the supervisorEdgeTargets,
  // which is the whole point of the exercise.
  const routerEdgeTargets: Record<string, string> = {
    [NodeNames.RAG]: NodeNames.RAG,
    [NodeNames.GREETING_REJECT_CLARIFY]: NodeNames.GREETING_REJECT_CLARIFY,
    [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
    [NodeNames.SUBMIT_FEEDBACK]: NodeNames.SUBMIT_FEEDBACK,
    [NodeNames.SEARCH_CODES_BY_DESCRIPTION]:
      NodeNames.SEARCH_CODES_BY_DESCRIPTION,
    [END]: END,
  };

  if (!duckAccess.knightSwiftVinView.enabled) {
    routerEdgeTargets[NodeNames.ANALYZE_SCREENSHOT] =
      NodeNames.ANALYZE_SCREENSHOT;
  }

  const stateGraph = new StateGraph(graphState)
    .addNode(NodeNames.ROUTER, await getRouterAgentNode(params))
    .addNode(
      NodeNames.GREETING_REJECT_CLARIFY,
      await getRejectClarifyNode(params)
    )
    .addNode(NodeNames.RAG, await getRagAgentNode(params))
    .addNode(NodeNames.RESPOND_TO_USER, getRespondToUserToolNode(params))
    .addNode(
      NodeNames.SUBMIT_FEEDBACK,
      getSubmitFeedbackNode(
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse
      )
    )
    .addNode(
      NodeNames.SEARCH_CODES_BY_DESCRIPTION,
      await getCodeSearchByDescriptionAgentNode(params)
    )
    .addEdge(START, NodeNames.ROUTER)
    .addEdge(NodeNames.RAG, END)
    .addEdge(NodeNames.GREETING_REJECT_CLARIFY, END)
    .addEdge(NodeNames.RESPOND_TO_USER, END)
    .addEdge(NodeNames.SUBMIT_FEEDBACK, END)
    .addEdge(NodeNames.SEARCH_CODES_BY_DESCRIPTION, END);

  // I would have preferred to break this apart into separate functions but
  // Typescript made it difficult to type the parameters.
  // The stateGraph and the conditional edges both had very particular and
  // unintuitive types that were difficult to reuse or define efficiently.
  // In the end I decided it was better to have a single mega-function.
  // It doesn't seem like Langgraph intends their graphs to be dynamically
  // defined like we are doing here.
  if (duckAccess.claimAnalytics.enabled) {
    stateGraph
      .addNode(
        NodeNames.CLAIM_ANALYTICS,
        await getClaimAnalyticsAgentGraph(params)
      )
      .addEdge(NodeNames.CLAIM_ANALYTICS, END);

    routerEdgeTargets[NodeNames.CLAIM_ANALYTICS] = NodeNames.CLAIM_ANALYTICS;
  }

  if (duckAccess.signalEventAnalytics.enabled) {
    stateGraph
      .addNode(
        NodeNames.SIGNAL_EVENT_ANALYTICS,
        await getSignalEventAnalyticsAgentGraph(params)
      )
      .addEdge(NodeNames.SIGNAL_EVENT_ANALYTICS, END);

    routerEdgeTargets[NodeNames.SIGNAL_EVENT_ANALYTICS] =
      NodeNames.SIGNAL_EVENT_ANALYTICS;
  }

  if (duckAccess.vinView.enabled) {
    stateGraph
      .addNode(NodeNames.VIN_VIEW, await getVinViewAgentGraph(params))
      .addEdge(NodeNames.VIN_VIEW, END);

    routerEdgeTargets[NodeNames.VIN_VIEW] = NodeNames.VIN_VIEW;
  }

  if (duckAccess.vehicles.enabled) {
    stateGraph
      .addNode(NodeNames.VEHICLES, await getVehiclesAgentGraph(params))
      .addEdge(NodeNames.VEHICLES, END);

    routerEdgeTargets[NodeNames.VEHICLES] = NodeNames.VEHICLES;
  }

  if (duckAccess.issues.enabled) {
    stateGraph
      .addNode(NodeNames.ISSUES, await getIssueAgentGraph(params))
      .addEdge(NodeNames.ISSUES, END);

    routerEdgeTargets[NodeNames.ISSUES] = NodeNames.ISSUES;
  }

  if (duckAccess.issueDetails.enabled) {
    stateGraph
      .addNode(NodeNames.ISSUE_DETAILS, await getIssueDetailsAgentGraph(params))
      .addEdge(NodeNames.ISSUE_DETAILS, END);

    routerEdgeTargets[NodeNames.ISSUE_DETAILS] = NodeNames.ISSUE_DETAILS;
  }

  if (duckAccess.knightSwiftVinView.enabled) {
    stateGraph.addNode(
      NodeNames.KNIGHT_SWIFT_VIN_VIEW,
      getKnightSwiftVinViewAgentNode(
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse
      )
    );
    routerEdgeTargets[NodeNames.KNIGHT_SWIFT_VIN_VIEW] =
      NodeNames.KNIGHT_SWIFT_VIN_VIEW;
  } else {
    stateGraph
      .addNode(
        NodeNames.ANALYZE_SCREENSHOT,
        await getAnalyzeScreenshotAgentNode(params)
      )
      .addEdge(NodeNames.ANALYZE_SCREENSHOT, END);
  }

  stateGraph.addConditionalEdges(
    NodeNames.ROUTER,
    getNextNode,
    // We "as" the routerEdgeTargets to the RouterEdgeTargets type,
    // even though we have added extra node names to the routerEdgeTargets
    // variable that do not comply with the indicated typing.
    // We need to do this for Typescript to accept the variable.
    // Yes, this is counterintuitive but it does allow us to dynamically add
    // nodes to the graph.
    routerEdgeTargets as Record<string, RouterEdgeTargets>
  );

  return stateGraph;
};

/**
 * @summary Get DUCK's compiled state graph.
 * @param params The parameters for the agent from the UI
 * @param withMemory True to use the memory checkpointer, false to not have a checkpointer
 * @returns The compiled state graph
 */
const getGraph = async (
  params: DuckGraphParams,
  withMemory: boolean = false
): Promise<Runnable> => {
  const stateGraph = await createGraph(params);

  // The MemorySaver checkpointer is not intended for production usage
  const checkpointer = withMemory ? new MemorySaver() : undefined;

  return stateGraph.compile({ checkpointer });
};

export default getGraph;
