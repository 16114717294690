import { APIErrorResponse, CountResponse } from "shared/api/api";
import { CustomSignalEventsRequestBody } from "shared/api/customSignalEvents/api";
import {
  useListCustomSignalEvents,
  useListCustomSignalEventsCount,
} from "shared/api/customSignalEvents/hooks";
import { SignalEventOccurrencesVINAggregateBucket } from "shared/api/signalEvents/api";
import { APIFilter } from "shared/api/utils";

import { CustomAttribute } from "pages/CustomSignalEvents/Definition/CustomAttributes/CustomAttributeSelect";
import {
  combineCustomEventsTableSchema,
  getMonthlyRange,
} from "pages/CustomSignalEvents/utils";
import { MAX_LIMIT_EVENTS } from "pages/VINView/constants";
import EventsTimeline from "pages/VINView/Events/EventsTimeline";

import APIError from "features/ui/APIError";
import FormSection from "features/ui/FormSection";
import Table, { RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  schema?: SchemaEntry[];
  customAttributes: CustomAttribute[];
  selectedRow: RowData;
  requestBody: CustomSignalEventsRequestBody;
  className?: string;
}

// We skip those because we only show events for 1 vin, and we replace timestamp with top-level date column.
const SKIPPED_ACCESSORS = ["VIN", "recordedAt", "readAt", "date"];

const createCustomSignalEventEntriesFromCount = (
  data: CountResponse | undefined,
  isLoading: boolean,
  error: APIErrorResponse | undefined,
  vin: string
): SignalEventOccurrencesVINAggregateBucket[] => {
  if (error || isLoading || data?.count === undefined) {
    return [];
  }

  return [
    {
      signalEventID: "Custom",
      VIN: vin,
      totalOccurrences: data?.count,
      description: "Events defined on this page",
    },
  ];
};

const VehiclePreview = ({
  schema,
  customAttributes,
  selectedRow,
  requestBody,
  className,
}: Props) => {
  const { VIN, date } = selectedRow;

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useListCustomSignalEventsCount({}, requestBody);

  const { data, isLoading, error } = useListCustomSignalEvents(
    { limit: MAX_LIMIT_EVENTS },
    requestBody
  );

  const timelineStaticFilters: APIFilter[] = [
    { name: "VIN", op: "eq", value: VIN },
  ];

  const customEvents: SignalEventOccurrencesVINAggregateBucket[] =
    createCustomSignalEventEntriesFromCount(
      countData,
      countIsLoading,
      countError,
      VIN
    );

  const customAttributesSchema: SchemaEntry[] = customAttributes.map(
    (attribute) => ({
      label: attribute.label,
      accessor: `customAttributes.${attribute.id}`,
      dataType: "number" as DataType,
    })
  );

  const combinedSchema = combineCustomEventsTableSchema(
    schema,
    customAttributesSchema,
    SKIPPED_ACCESSORS
  );

  const { previousDate, futureDate } = getMonthlyRange(date);

  return (
    <>
      <FormSection title="Events for Selected Vehicle" className={className}>
        {!error && (
          <Table
            testId="custom-signal-events-table"
            data={data}
            schema={combinedSchema}
            isLoading={isLoading || countIsLoading}
            loadingRows={40}
            filtersInitialized={true}
            stickyFirstColumn={true}
            scrollHeight={500}
            dense
          />
        )}
        {error && <APIError error={error} />}
        <div className="flex items-end mt-3">
          <TableCount
            extraClasses="ml-auto self-end"
            count={data?.length}
            prefix="Showing lastest "
            suffix={` out of ${countData?.count}`}
            entityName="custom signal event"
            isLoading={countIsLoading}
            error={!!countError}
          />
        </div>
      </FormSection>
      <FormSection
        data-testid="vehicle-event-timeline"
        title="Timeline for Selected Vehicle"
        text="See your custom events in the context of other events for this vehicle"
        className={className}
      >
        <EventsTimeline
          key={VIN}
          vin={VIN}
          staticFilters={timelineStaticFilters}
          customEventTableRows={customEvents}
          customEventsRequestBody={requestBody}
          customEventSchema={schema}
          defaultStartDate={previousDate}
          defaultEndDate={futureDate}
          disableDateStorage={true}
        />
      </FormSection>
    </>
  );
};

export default VehiclePreview;
