import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import {
  ClaimsTopContributor,
  ClaimsTopContributorsRequest,
} from "shared/api/claims/api";
import { useListClaimsTopContributors } from "shared/api/claims/hooks";
import { getSortFilter } from "shared/api/utils";
import { NO_DATA } from "shared/constants";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";
import { SortBy } from "shared/types";
import { formatNumber, isNumeric, roundToNDecimals } from "shared/utils";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT } from "pages/constants";
import {
  getTopContributorsChartTitle,
  prepareTopContributorsData,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import BarChart from "features/ui/charts/BarChart";
import { getAxisLabel, getSelectedOptionId } from "features/ui/charts/utils";
import DescriptionColumn from "features/ui/DescriptionColumn";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { FilterChangeCallback } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import { useBarSelection } from "./hooks";
import { canShowDescription } from "./utils";

const DEFAULT_SORT: SortBy = { IPTV: "desc" };
const CHART_DATA_LIMIT = 15;

interface TopContributorsChartProps extends ClaimsFiltersProps {
  selectedGroupByAttribute: SelectOption;
  filters?: FilterGroupState;
  onVehiclesFiltersChange?: FilterChangeCallback;
  onClaimsFiltersChange?: FilterChangeCallback;
  onBadRequest: () => void;
  actions: ChartAction[];
  selectedByVehicleAgeExposure?: string;
  selectedByVehicleAgeExposureBucket?: number;
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: (selectedOptions: SelectedChartOptions[]) => void;
}

const TopChart = ({
  selectedGroupByAttribute,
  filters,
  vehiclesFilters,
  claimsFilters,
  onVehiclesFiltersChange,
  onClaimsFiltersChange,
  actions,
  selectedByVehicleAgeExposure,
  selectedByVehicleAgeExposureBucket,
  selectedOptions,
  setSelectedOptions,
  onBadRequest,
}: TopContributorsChartProps) => {
  const { warrantyClaimsCost } = useFlags();

  const requestParams: ClaimsTopContributorsRequest = {
    sort: getSortFilter(DEFAULT_SORT),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    claimsFilter: getFiltersQuery(claimsFilters),
    filter: getFiltersQuery(filters),
    limit: CHART_DATA_LIMIT,
    groupByAttribute: selectedGroupByAttribute.id as string,
    byVehicleAgeExposure: selectedByVehicleAgeExposure,
    byVehicleAgeExposureBucket: selectedByVehicleAgeExposureBucket,
    hideCosts: !warrantyClaimsCost,
  };

  const {
    selectedBars,
    showContextMenu,
    handleOnBarClick,
    onBarRightClick,
    handleOnChartClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    onVehiclesFiltersChange,
    onClaimsFiltersChange,
  });

  const { data, isLoading, error } = useListClaimsTopContributors({
    ...requestParams,
    limit: CHART_DATA_LIMIT,
  });

  const selectedGroupByAttributeDisplayName =
    selectedGroupByAttribute.value.toString();

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={400} />
      </Card>
    );
  }

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (!data) return NO_DATA;

  const yAxisKey = getSelectedOptionId<ClaimsTopContributor>(
    selectedOptions,
    "y"
  );
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const graphData = prepareTopContributorsData<ClaimsTopContributor>(
    data,
    yAxisKey
  );

  const title = getTopContributorsChartTitle(
    actions,
    selectedOptions,
    selectedByVehicleAgeExposure,
    selectedByVehicleAgeExposureBucket
  );

  return (
    <Card>
      <ChartActionsWrap
        id="claims-top-contributors"
        chartTitle={title}
        contentClassName="w-[350px]"
      >
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {showContextMenu && contextMenu}
      {data.length === 0 && NO_DATA}
      {data.length > 0 && (
        <BarChart
          height={400}
          width="100%"
          selectedBars={selectedBars}
          onBarClick={handleOnBarClick}
          onBarRightClick={onBarRightClick}
          onChartClick={handleOnChartClick}
          data={graphData}
          xAxisKey={TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR}
          yAxisLabel={yAxisLabel}
          yAxisKey="dimension"
          xAxisProps={{
            tickFormatter: (value) => {
              if (!value) {
                return GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT;
              } else if (isNumeric(value)) {
                return roundToNDecimals(Number(value), 2);
              }

              return value;
            },
          }}
          tooltipProps={{
            labelFormatter: (value) => {
              if (isNumeric(value)) {
                value = roundToNDecimals(Number(value), 2);
              }

              return (
                <div>
                  {selectedGroupByAttributeDisplayName}: {value}
                  {canShowDescription(
                    selectedGroupByAttribute.id.toString()
                  ) && (
                    <div>
                      Description:{" "}
                      <DescriptionColumn
                        fieldName={selectedGroupByAttribute.id.toString()}
                        fieldValue={value}
                        textOnly
                      />
                    </div>
                  )}
                </div>
              );
            },
            formatter: (value) =>
              !isNaN(value) ? formatNumber(value, 2) : value,
          }}
        />
      )}
    </Card>
  );
};

export default TopChart;
