import { getEphemeralMessageForNode } from "duck/graph/nodes/utils";
import { UIHandlers } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";
import { z } from "zod";
import { StructuredTool, tool } from "@langchain/core/tools";

const responseSchema = z.object({
  response: z.string(),
});

type RespondToUserParams = z.infer<typeof responseSchema>;

const getRespondToUserTool = (
  setAgentResponseHandler: UIHandlers["setAgentResponse"],
  setEphemeralMessage: UIHandlers["setEphemeralMessage"]
): StructuredTool => {
  const respondToUser = ({ response }: RespondToUserParams) => {
    setEphemeralMessage(getEphemeralMessageForNode(NodeNames.RESPOND_TO_USER));
    console.debug("Final response: ", response);
    if (response) {
      setAgentResponseHandler(response);
    }

    return "success";
  };

  return tool(respondToUser, {
    name: "respondToUser",
    description: "Call this tool to respond to the user",
    schema: responseSchema,
  });
};

export default getRespondToUserTool;
