import {
  APIListValuesRequest,
  APIListValuesResponse,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";

export const listPartsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["parts", "attributes"]),
    params,
  });

const listPartsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["parts", "values", fieldName]),
    params,
  });

export const listPartsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listPartsValuesRequestURI(args));
