import { Link } from "react-router-dom";

import { Inspection } from "shared/api/inspections/api";
import { Repair } from "shared/api/repairs/api";
import { USE_RESOURCE_SCHEMA_MAP } from "shared/schemas/constants";
import { EventTypeEnum } from "shared/types";
import { formatNumber } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

export type ResourceData = Inspection | Repair;

interface Props {
  eventType: EventTypeEnum;
  data: ResourceData;
  accessorsToHide?: string[];
  alignment?: "left" | "center";
  removeCardWrapper?: boolean;
  columnsToSkip?: string[];
}

const ACCESSORS_TO_HIDE = [
  "ID",
  "data",
  "mileageUnit",
  "externalID",
  "createdAt",
  "updatedAt",
];
const DEFAULT_ALIGNMENT = "center";
const DEFAULT_REMOVE_CARD_WRAPPER = false;

/**
 * A generic card component that displays the details of a Resource
 * based on the /attributes endpoint, excluding ACCESSORS_TO_HIDE.
 */
const CardResourceDetails = ({
  eventType,
  data,
  accessorsToHide,
  alignment = DEFAULT_ALIGNMENT,
  removeCardWrapper = DEFAULT_REMOVE_CARD_WRAPPER,
  columnsToSkip = [],
}: Props) => {
  const joinedAccessorsToHide = [
    ...ACCESSORS_TO_HIDE,
    ...(accessorsToHide || []),
  ];

  const { schema, attributes } = USE_RESOURCE_SCHEMA_MAP[eventType](
    joinedAccessorsToHide
  );

  const filteredSchema = schema.filter(
    (schemaEntry) => !columnsToSkip.includes(schemaEntry.accessor)
  );

  const formatRow = (row: ResourceData) => {
    const { VIN, date, externalID, externalURL, mileage, mileageUnit } = row;

    return {
      ...row,
      externalID: externalURL ? (
        <Link
          target="_blank"
          to={externalURL}
          className="text-metabase-blue hover:underline"
        >
          {externalID}
        </Link>
      ) : (
        externalID
      ),
      VIN: <VINEventTimelineDateLink VIN={VIN} date={date} />,
      mileage: mileage && `${formatNumber(mileage)} ${mileageUnit}`,
      items: undefined,
    };
  };

  const formattedData = formatRow(data);
  const displayFields = attributes?.map((attribute) => attribute.ID) || [];
  const title = `${eventType} Details`;

  const content = (
    <>
      <CardHeader title={title} />
      <LabelValuePairs
        schema={filteredSchema as SchemaEntry<keyof ResourceData>[]}
        data={formattedData}
        fields={displayFields}
        alignment={alignment}
      />
    </>
  );

  return removeCardWrapper ? <>{content}</> : <Card>{content}</Card>;
};

export default CardResourceDetails;
