import { useEffect, useState } from "react";

import { CollectionType } from "shared/api/api";

import InfoIcon from "features/ui/Icons/Info";
import Label from "features/ui/Label";
import RadioButtonGroup, {
  RadioSelectOption,
} from "features/ui/RadioButtonGroup";

import { DEFAULT_COLLECTION_TYPE } from "./FilterCollectionForm";

interface Props {
  onChange: (value: CollectionType) => void;
  selectedType: CollectionType;
}

const COLLECTION_TYPE_OPTIONS: RadioSelectOption[] = [
  {
    id: "dynamic",
    value: "Dynamic",
  },
  {
    id: "static",
    value: "Static",
  },
];

const Info = () => (
  <ul className="px-4 py-2 list-disc">
    <li>
      Dynamic collection will automatically include new vehicles if they match
      the filtering criteria.
    </li>
    <li>Static collection does not change through time.</li>
  </ul>
);

const Step2 = ({ onChange, selectedType }: Props) => {
  const DEFAULT_SELECTED: string =
    DEFAULT_COLLECTION_TYPE || COLLECTION_TYPE_OPTIONS[0].id;

  const [selectedOption, setSelectedOption] =
    useState<string>(DEFAULT_SELECTED);

  const handleOnSelect = (val: string) => {
    onChange(val as CollectionType);
  };

  useEffect(() => {
    const o = COLLECTION_TYPE_OPTIONS.find((x) => x.id === selectedType);

    if (!o) return;

    setSelectedOption(selectedType);
  }, [selectedType]);

  return (
    <div>
      <div className="flex space-x-1 align-start">
        <Label text="Collection Type" />
        <div className="inline-block ml-1">
          <InfoIcon text={<Info />} size="sm" />
        </div>
      </div>
      <RadioButtonGroup
        options={COLLECTION_TYPE_OPTIONS}
        onSelect={handleOnSelect}
        selected={selectedOption}
      />
    </div>
  );
};

export default Step2;
