import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { YAxisProps } from "recharts";

import { GetIssuesOverviewRequest } from "shared/api/issues/api";
import { useIssuesOverview } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  BucketByIssuesEnum,
  GroupByIssuesEnum,
  IssueMeasuresEnum,
  ValueType,
} from "shared/types";

import Accordion from "features/ui/Accordion";
import APIError from "features/ui/APIError";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import BarChart from "features/ui/charts/BarChart";
import BarChartCustomTick from "features/ui/charts/BarChart/BarChartCustomTick";
import { DataElement } from "features/ui/charts/types";
import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import {
  DASHBOARD_GROUP_BY_KEY,
  ISSUE_HISTORIC_COMPARISONS,
  ISSUES_CHART_ACTIONS,
  ISSUES_DASHBOARD_KEY,
} from "./constants";
import { useBarSelection } from "./hooks";
import {
  getAxisKeyLabelFromActions,
  getFilterFromColumns,
  getLongestValue,
  getMaxValue,
  getMeasureLabel,
  getMetricsRequestParams,
  transformIssuesOverviewData,
} from "./utils";

const MAX_X_AXIS_CHARACTERS = 155;

interface Props {
  filterSortState: UseFilterSortState;
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: (selectedOptions: SelectedChartOptions[]) => void;
  setSelectedBarFilters: (filterState: FilterGroupState | undefined) => void;
}

const getAvailableActions = (
  selectedOptions: SelectedChartOptions[]
): ChartAction[] => {
  // we want to make sure that user cannot select the same option for both groupBy and bucketBy which returns an API error
  const groupByOption = selectedOptions.find(
    ({ id }) => id === "groupBy"
  )?.optionId;
  const bucketByOption = selectedOptions.find(
    ({ id }) => id === "bucketBy"
  )?.optionId;

  // update chart actions to remove the selected bucketBy option from groupBy options and vice versa
  return ISSUES_CHART_ACTIONS.map((action) => {
    if (action.id === "groupBy" && bucketByOption) {
      return {
        ...action,
        options: action.options?.filter(({ id }) => id !== bucketByOption),
      };
    }

    if (action.id === "bucketBy" && groupByOption) {
      return {
        ...action,
        options: action.options?.filter(({ id }) => id !== groupByOption),
      };
    }

    return action;
  }).filter(Boolean) as ChartAction[];
};

const IssuesDashboard = ({
  filterSortState,
  selectedOptions,
  setSelectedOptions,
  setSelectedBarFilters,
}: Props) => {
  const [issuesDashboardExtended, setIssuesDashboardExtended] =
    useCustomLocalStorageState(ISSUES_DASHBOARD_KEY, {
      defaultValue: false,
    });

  const [availableChartActions, setAvailableChartActions] = useState<
    ChartAction[]
  >(getAvailableActions(selectedOptions));

  const [showAllLabels, setShowAllLabels] = useState(true);

  const { filters, resetFilterSortState, updateFilters } = filterSortState;

  const filtersQuery = getFiltersQuery(filters);

  const { axisKey: axisKeyBucketBy, axisValue: axisValueBucketBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      ISSUES_CHART_ACTIONS,
      "bucketBy"
    );

  const { axisKey: axisKeyGroupBy, axisValue: axisValueGroupBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      ISSUES_CHART_ACTIONS,
      "groupBy"
    );

  const { axisKey: axisKeyOrigin } = getAxisKeyLabelFromActions(
    selectedOptions,
    ISSUES_CHART_ACTIONS,
    "splitByIssueSource"
  );

  const { axisKey: measureKey, axisValue: measure } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      ISSUES_CHART_ACTIONS,
      "measure"
    );

  const selectedGroupByAttribute: SelectOption = useMemo(
    () => ({ id: axisKeyGroupBy, value: axisValueGroupBy }) as SelectOption,
    [axisKeyGroupBy, axisValueGroupBy]
  );

  const splitByIssueSource = axisKeyOrigin === "true";

  const { lookbackWindow, valueType } = getMetricsRequestParams(
    selectedOptions,
    ISSUES_CHART_ACTIONS
  );
  const valueTypeKey = valueType as ValueType;

  const requestParams: GetIssuesOverviewRequest = {
    measure: measureKey as IssueMeasuresEnum,
    bucketBy: axisKeyBucketBy as BucketByIssuesEnum,
    lookbackWindow,
    valueType: valueTypeKey,
    filter: filtersQuery,
    groupBy: axisKeyGroupBy as GroupByIssuesEnum,
    splitByIssueSource,
  };

  const { data, isLoading, error } = useIssuesOverview(requestParams);

  const measureLabel = getMeasureLabel(
    ISSUES_CHART_ACTIONS,
    valueTypeKey,
    measureKey,
    lookbackWindow?.toString(),
    measure
  );

  const baseChartTitle = `${measureLabel} grouped by ${axisValueGroupBy}`;
  const historicBucketTitle = ISSUE_HISTORIC_COMPARISONS.includes(
    axisKeyBucketBy
  )
    ? (ISSUES_CHART_ACTIONS.find(({ id }) => id === "bucketBy")?.options?.find(
        ({ id }) => id === axisKeyBucketBy
      )?.label as string)
    : `and bucketed by ${axisValueBucketBy}`;
  const chartTitle =
    axisKeyBucketBy === "none"
      ? baseChartTitle
      : `${baseChartTitle} ${historicBucketTitle}`;

  const { chartData, yAxisBars, splitChartData, nameIDMapping } =
    transformIssuesOverviewData(
      data,
      axisKeyBucketBy as BucketByIssuesEnum,
      axisKeyGroupBy as GroupByIssuesEnum,
      measureKey,
      measureLabel,
      splitByIssueSource,
      valueTypeKey
    );

  const {
    selectedColumns,
    showContextMenu,
    handleOnChartClick,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    selectedBucketByKey: axisKeyBucketBy,
    splitByIssueSource,
    menuOffsetElement: document.querySelector(
      '[data-testid="accordion-issuesDashboard"]'
    ),
    nameIDMapping,
    updateFilters,
    existingFilters: filters,
  });

  // left click - selectedColumns filter propagated to the SuggestedIssuesTable
  useEffect(() => {
    const newColumnsFilter = getFilterFromColumns(
      selectedColumns,
      axisKeyGroupBy,
      axisKeyBucketBy,
      splitByIssueSource,
      nameIDMapping
    );
    setSelectedBarFilters(newColumnsFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  const yAxisProps: YAxisProps = {
    domain: [0, Math.max(getMaxValue(chartData), getMaxValue(splitChartData))],
  };

  const handleOnOptionChange = (selectedOptions: SelectedChartOptions[]) => {
    const availableActions = getAvailableActions(selectedOptions);

    setAvailableChartActions(availableActions);

    const defaultActions = getDefaultActions(availableActions);
    const selectedActionIds = selectedOptions.map(({ id }) => id);
    const missingSelectedOptions = availableActions.filter(
      ({ id }) => !selectedActionIds.includes(id)
    );

    const newSelectedOptions = [...selectedOptions];
    missingSelectedOptions.forEach((o) =>
      newSelectedOptions.push({
        id: o.id,
        optionId: defaultActions.find((x) => x.id === o.id)?.optionId!,
      })
    );

    setSelectedOptions(newSelectedOptions);
  };

  const longestChartValue = getLongestValue(chartData);
  const longestSplitChartValue = getLongestValue(splitChartData);

  return (
    <Accordion
      id="issuesDashboard"
      title={chartTitle}
      expanded={issuesDashboardExtended}
      onChange={() => setIssuesDashboardExtended(!issuesDashboardExtended)}
      arrowPosition="left"
      rightContent={
        <ChartActionsWrap>
          <ChartActions
            actions={availableChartActions}
            selectedOptions={selectedOptions}
            onOptionChange={handleOnOptionChange}
          />
        </ChartActionsWrap>
      }
    >
      <div>
        {showContextMenu && contextMenu}
        {!error && (
          <div>
            {isLoading && <Skeleton height={300} />}
            {splitByIssueSource && (
              <div>
                Promoted Issues
                {splitChartData && splitChartData.length > 0 ? (
                  <div>
                    <BarChart
                      data={splitChartData}
                      margin={{
                        left: longestSplitChartValue.toString().length * 4,
                        bottom: 20,
                      }}
                      xAxisKey={DASHBOARD_GROUP_BY_KEY}
                      xAxisProps={{
                        interval: showAllLabels ? 0 : undefined,
                      }}
                      customTick={
                        <BarChartCustomTick
                          maxCharacters={MAX_X_AXIS_CHARACTERS}
                          nBars={chartData.length}
                          setShowAllLabels={setShowAllLabels}
                        />
                      }
                      yAxisBars={yAxisBars}
                      yAxisProps={yAxisProps}
                      selectedColumns={selectedColumns}
                      onBarClick={(
                        row: DataElement,
                        event: React.MouseEvent<SVGPathElement, MouseEvent>
                      ) => {
                        handleOnBarClick(row, event, true);
                      }}
                      onBarRightClick={onBarRightClick}
                      onChartClick={handleOnChartClick}
                      enableBarGroupHover={true}
                      promotedIssue={true}
                    />
                  </div>
                ) : (
                  <div className="py-4 text-gray-400 text-sm">No data.</div>
                )}
                Created Issues
              </div>
            )}
            {chartData && chartData.length > 0 ? (
              <div>
                <BarChart
                  data={chartData}
                  margin={{
                    left: longestChartValue.toString().length * 4,
                    bottom: 20,
                  }}
                  xAxisKey={DASHBOARD_GROUP_BY_KEY}
                  xAxisProps={{
                    interval: showAllLabels ? 0 : undefined,
                  }}
                  customTick={
                    <BarChartCustomTick
                      maxCharacters={MAX_X_AXIS_CHARACTERS}
                      nBars={chartData.length}
                      setShowAllLabels={setShowAllLabels}
                    />
                  }
                  yAxisBars={yAxisBars}
                  yAxisProps={yAxisProps}
                  selectedColumns={selectedColumns}
                  onBarClick={(
                    row: DataElement,
                    event: React.MouseEvent<SVGPathElement, MouseEvent>
                  ) => {
                    handleOnBarClick(
                      row,
                      event,
                      splitByIssueSource ? false : undefined
                    );
                  }}
                  onBarRightClick={onBarRightClick}
                  onChartClick={handleOnChartClick}
                  enableBarGroupHover={true}
                  promotedIssue={splitByIssueSource ? false : undefined}
                />
              </div>
            ) : (
              <div className="py-4 text-gray-400 text-sm">No data.</div>
            )}
          </div>
        )}
        {error && (
          <APIError
            error={error}
            onBadRequest={() => {
              resetFilterSortState();
              setSelectedOptions(getDefaultActions(ISSUES_CHART_ACTIONS));
            }}
          />
        )}
      </div>
    </Accordion>
  );
};

export default IssuesDashboard;
