import React from "react";
import classNames from "classnames";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import InfoIcon from "features/ui/Icons/Info";

export type Option = string | number;

export interface RadioSelectOption {
  value: Option | JSX.Element;
  id: string;
  info?: string;
  additionalContent?: React.ReactElement;
  additionalContentInSameLine?: boolean;
}

export interface RadioSelectProps<T extends RadioSelectOption> {
  options: T[];
  selected?: string;
  onSelect: (value: string) => void;
  label?: React.ReactNode;
  disabled?: boolean;
}

const RadioButtonGroup = <T extends RadioSelectOption>({
  options,
  selected,
  onSelect,
  label,
  disabled = false,
}: RadioSelectProps<T>) => (
  <div>
    <FormControl disabled={disabled}>
      <FormLabel color="secondary">{label}</FormLabel>
      <RadioGroup value={selected || null} onChange={(_, v) => onSelect(v)}>
        <div className="space-y-2 flex flex-col px-1.5">
          {options.map((option) => {
            const checked = option.id === selected;

            return (
              <div
                key={option.id}
                className={classNames({
                  flex: option.additionalContentInSameLine,
                })}
              >
                <FormControlLabel
                  value={option.id}
                  control={
                    <Radio checked={checked} role="radio" size="small" />
                  }
                  style={{ marginTop: 0 }}
                  label={
                    <span
                      className={classNames("flex items-center gap-2", {
                        "text-gray-500": !checked,
                      })}
                    >
                      {option.value}
                      {option.info && <InfoIcon text={option.info} size="sm" />}
                    </span>
                  }
                />
                {option.additionalContent && (
                  <div
                    className={classNames({
                      "opacity-40": !checked,
                      "pb-4 ml-4": !option.additionalContentInSameLine,
                    })}
                  >
                    {option.additionalContent}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </RadioGroup>
    </FormControl>
  </div>
);

export default RadioButtonGroup;
