import NestedAutocomplete from "features/ui/NestedAutocomplete";
import { SelectOption } from "features/ui/Select";

interface GroupBySelectorProps {
  groupBySelectOptions: SelectOption[];
  selectedGroupByAttribute: SelectOption;
  setSelectedGroupByAttribute: (option: SelectOption) => void;
}

export const TopContributorsGroupBySelector = ({
  groupBySelectOptions,
  selectedGroupByAttribute,
  setSelectedGroupByAttribute,
}: GroupBySelectorProps) => (
  <div className="mt-4">
    {groupBySelectOptions.length > 0 && (
      <div className="mb-5">
        <div className="text-sm mb-1">
          Select a dimension to update the x-axis of the chart and first column
          of the table:
        </div>
        {selectedGroupByAttribute && (
          <NestedAutocomplete
            wrapperClasses="max-w-[300px]"
            options={groupBySelectOptions}
            onSelectionChange={setSelectedGroupByAttribute}
            selected={selectedGroupByAttribute}
            testId="top-contributors-group-by-dropdown"
          />
        )}
      </div>
    )}
  </div>
);

export default TopContributorsGroupBySelector;
