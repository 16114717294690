import { DuckMessage } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import DuckMarkdownMessage from "duck/ui/DuckMarkdownMessage";
import { DuckMessageProps } from "duck/ui/DuckMessage";
import { SchemaUsage, useSchema } from "duck/ui/hooks";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";

/**
 * @param props.message.message The message property of the parameter DuckMessage
 * is the filter string to apply to the source.
 * @param props.message.options.filterOptions.source The data type to apply the
 * filter to, i.e. claims, vehicles, etc.
 */
const DuckFilterMessage = ({
  message: { message: filterString, options },
}: DuckMessageProps) => {
  const source = options?.filterOptions?.source;

  const selectedSchema = useSchema(source, SchemaUsage.FILTER);

  if (selectedSchema.length === 0) {
    console.error(
      `Invalid source in message options.filterOptions: "${JSON.stringify(options)}"`
    );

    return <div className="pl-6">{filterString}</div>;
  }

  const introMessage: DuckMessage = {
    author: DuckMessageAuthor.AGENT,
    message:
      filterString === ""
        ? `- Clear the filters from ${source}`
        : `- Apply these filters to ${source}:`,
    format: DuckMessageFormat.TEXT,
  };

  return (
    <div>
      <DuckMarkdownMessage message={introMessage} />
      <div className="pl-6">
        <FilterQueryPresentation
          filter={filterString}
          tableSchema={selectedSchema}
        />
      </div>
    </div>
  );
};

export default DuckFilterMessage;
