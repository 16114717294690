import {
  AuthState,
  CoreStorageManagerInterface,
  OktaAuth,
  OktaAuthCoreInterface,
  OktaAuthCoreOptions,
  OktaAuthOptions,
  PKCETransactionMeta,
  TransactionManagerInterface,
} from "@okta/okta-auth-js";

import * as heap from "services/heap";
import * as config from "config/config";

const {
  auth: { issuer, clientId },
  analytics,
} = config.get();

interface Heap {
  load: (id: string) => void;
  identify: (id: string) => void;
  addUserProperties: (props: object) => void;
}
// rely on interface merging to extend the window object
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
    heap: Heap;
  }
}

const handleTransformAuthState = async (
  oktaAuth: OktaAuthCoreInterface<
    PKCETransactionMeta,
    CoreStorageManagerInterface<PKCETransactionMeta>,
    OktaAuthCoreOptions,
    TransactionManagerInterface
  >,
  authState: AuthState
): Promise<AuthState> => {
  if (!authState.isAuthenticated) {
    return authState;
  }

  const user = await oktaAuth?.token?.getUserInfo();
  authState.isAuthenticated = !!user; // convert to boolean

  // still not authenticated or doesn't have an email
  if (!authState.isAuthenticated || !user?.email) {
    return authState;
  }

  const email = user.email.toLowerCase();
  const emailDomain = email.split("@").pop();

  // only try to set-up Heap analytics if relevant config is present
  if (analytics.heap) {
    try {
      heap
        .init()
        .then(() => {
          window.heap.identify(email);
          window.heap.addUserProperties({ email, emailDomain });
        })
        .catch((err) => {
          console.error("failed to init heap", err);
        });
    } catch (error) {
      console.error("heap not registered", error);
    }
  }

  return authState;
};

const oktaConfig: OktaAuthOptions = {
  issuer,
  clientId,
  redirectUri: `${window.location.origin}/login/callback`,
  // extra requirement: user must have valid Okta SSO session
  transformAuthState: handleTransformAuthState,
};
export const oktaAuth = new OktaAuth(oktaConfig);
