import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { FrequencyOptions } from "pages/AlertDefinitions/constants";

import {
  ALERT_FREQUENCY_LABELS,
  ALERT_FREQUENCY_TEXT,
  ALERT_TYPE_TEXT,
  NOTIFICATION_TYPE_LABELS,
} from "./constants";

// todo: this is subject to certain change when the api
type NotificationType = "app" | "email" | "all";

interface Props {
  frequency: FrequencyOptions;
  inAppAlerts: boolean;
  emailAlerts: boolean;
  onChange: (
    frequency: FrequencyOptions,
    inAppAlerts: boolean,
    emailAlerts: boolean
  ) => void;
}

const WatchlistAlertRadioForm = ({
  frequency,
  inAppAlerts,
  emailAlerts,
  onChange,
}: Props) => {
  const type =
    inAppAlerts && emailAlerts ? "all" : inAppAlerts ? "app" : "email";

  const handleFrequencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange(event.target.value as FrequencyOptions, inAppAlerts, emailAlerts);
  };
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as NotificationType;
    const inApp = value === "all" || value === "app";
    const email = value === "all" || value === "email";
    onChange(frequency, inApp, email);
  };

  return (
    <div className="flex flex-row">
      <div className="flex flex-col w-1/2">
        <div className="text-xs font-bold">{ALERT_TYPE_TEXT}</div>
        <div className="flex flex-row gap-2 ml-2">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={type}
            name="radio-buttons-group"
            onChange={handleTypeChange}
          >
            {Object.entries(NOTIFICATION_TYPE_LABELS).map(([key, value]) => (
              <FormControlLabel
                key={key}
                className="text-xs"
                value={key}
                control={<Radio size="small" />}
                label={<span className="text-xs">{value}</span>}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="flex flex-col w-1/2">
        <div className="text-xs font-bold">{ALERT_FREQUENCY_TEXT}</div>
        <div className="flex flex-row gap-2 ml-2">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={frequency}
            name="radio-buttons-group"
            onChange={handleFrequencyChange}
          >
            {Object.entries(ALERT_FREQUENCY_LABELS).map(([key, value]) => (
              <FormControlLabel
                key={key}
                className="text-xs"
                value={key}
                control={<Radio size="small" />}
                label={<span className="text-xs">{value}</span>}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};
export default WatchlistAlertRadioForm;
