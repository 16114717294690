import { PermissionEntry } from "shared/api/api";
import { PermissionID } from "shared/types";

import Select from "features/ui/Select";

import { getHigherPermissionsList, PERMISSION_TYPES } from "./utils";

interface Props {
  permission: PermissionEntry;
  canEdit: boolean;
  generalAccess: PermissionID;
  onUpdatePermission: (id: string, access: PermissionID) => void;
}

const PermissionAccess = ({
  permission,
  canEdit,
  generalAccess,
  onUpdatePermission,
}: Props) => {
  const { ID, email, access } = permission;

  if (!canEdit) {
    const perm = PERMISSION_TYPES.find((x) => x.id === access)?.value;

    return <div className="ml-1">{perm}</div>;
  }

  const permissionTypes = getHigherPermissionsList(generalAccess);
  if (permissionTypes.length === 0) return <span />;

  const selectedPermissionType =
    permissionTypes.find((x) => x.id === access) || permissionTypes[0];

  return (
    <Select
      options={permissionTypes}
      selected={selectedPermissionType}
      onSelect={(selectedOption) =>
        onUpdatePermission(ID, selectedOption.id as PermissionID)
      }
      testId={email ? `permission-dropdown-${email}` : "permission-dropdown"}
    />
  );
};

export default PermissionAccess;
