import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { UseAPIState } from "shared/api/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
} from "shared/types";

import IssueLineChart from "pages/Issues/IssueLineChart";
import {
  getBaseAPIRoute,
  getIssueCombinedID,
  getKeysAndLabels,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";

import RateBarChart from "./RateBarChart";
import {
  DataLoadFuncParams,
  getChartData,
  getChartLines,
  isBarChart,
} from "./utils";

interface Props {
  issue: IssueTypes;
  xAxisLabel?: string;
  yAxisKey: string;
  yAxisWithPrecedingKey?: string;
  yAxisLabel: string;
  chartType: IssueChartType;
  chart: IssueChart;
  dataLoadHook: (props: DataLoadFuncParams) => UseAPIState<any>;
  dataLoadHookParams?: Record<string, any>;
  precedingStr?: string;
  syncId?: number | string;
  timePeriod?: number;
  valueFormatter?: (val: any) => {};
  tooltipValueFormatter?: (val: any) => {};
  tooltipLabelFormatter?: (val: any) => {};
  groupByField?: IssueChartGroupBy;
  exposure?: string;
}

const ChartWithComparisonPopulation = ({
  issue,
  xAxisLabel,
  yAxisKey,
  yAxisWithPrecedingKey,
  yAxisLabel,
  chartType,
  chart,
  dataLoadHook,
  dataLoadHookParams,
  precedingStr,
  syncId,
  timePeriod,
  valueFormatter,
  tooltipValueFormatter,
  tooltipLabelFormatter,
  groupByField,
  exposure,
}: Props) => {
  const { updatedAt } = issue;

  const { data, isLoading, error } = dataLoadHook({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: "atRisk",
    signalEventsTimeWindow: timePeriod,
    ...dataLoadHookParams,
    updatedAt,
  });

  const {
    data: comparisonData,
    isLoading: isComparisonLoading,
    error: comparisonError,
  } = dataLoadHook({
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    vehiclePopulation: "comparison",
    signalEventsTimeWindow: timePeriod,
    ...dataLoadHookParams,
    updatedAt,
  });

  const { xAxisKey } = getKeysAndLabels(chartType, chart);

  const chartData = useMemo(() => {
    if (
      (!data || data.length === 0) &&
      (!comparisonData || comparisonData.length === 0)
    ) {
      return [];
    }

    return getChartData(
      chart,
      data,
      xAxisKey,
      yAxisKey,
      comparisonData,
      yAxisWithPrecedingKey,
      valueFormatter,
      groupByField
    );
  }, [
    data,
    comparisonData,
    chart,
    xAxisKey,
    yAxisKey,
    yAxisWithPrecedingKey,
    valueFormatter,
    groupByField,
  ]);

  if (isLoading || isComparisonLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (comparisonError) {
    return <APIError error={comparisonError} />;
  }

  if (
    (!data || data.length === 0) &&
    (!comparisonData || comparisonData.length === 0)
  ) {
    return <div className="text-gray-400 text-sm">No data.</div>;
  }

  const tooltipProps: Record<string, any> = {};
  if (tooltipValueFormatter) {
    tooltipProps.formatter = tooltipValueFormatter;
  }

  if (tooltipValueFormatter) {
    tooltipProps.labelFormatter = tooltipLabelFormatter;
  }

  if (isBarChart(chart)) {
    return (
      <RateBarChart
        data={chartData}
        xAxisKey={xAxisKey}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisLabel}
        tooltipProps={tooltipProps}
      />
    );
  }

  const lines = getChartLines(true, precedingStr !== undefined, precedingStr);

  return (
    <IssueLineChart
      data={chartData}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      yAxisLines={lines}
      chart={chart}
      type={chartType}
      syncId={syncId}
      tooltipProps={tooltipProps}
      exposure={exposure}
    />
  );
};

export default ChartWithComparisonPopulation;
