import { useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import { IssuesOverview } from "shared/api/issues/api";
import { randomID } from "shared/utils";

import { EMPTY_CHART_LABEL, ISSUES_PAGE_KEY } from "pages/Issues/constants";

import BarChart from "features/ui/charts/BarChart";
import BarChartCustomTick from "features/ui/charts/BarChart/BarChartCustomTick";
import {
  BAR_COLOR_LAST_WEEK,
  BAR_COLOR_NOW,
} from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getChartSettingsKey, getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

import { ISSUE_COUNT } from "./constants";
import { getChartOptionsAndOpenDashboard } from "./utils";

const MAX_X_AXIS_CHARACTERS = 120;

interface Props {
  dashboardTitle: string;
  issuesData: IssuesOverview[];
  issuesDataPrev: IssuesOverview[];
  filters: FilterGroupState;
}

const DataDashboardChart = ({
  dashboardTitle,
  issuesData,
  issuesDataPrev,
  filters,
}: Props) => {
  const navigate = useNavigate();
  const issuesFilterKey = getFiltersKey(ISSUES_PAGE_KEY);

  const [showAllLabels, setShowAllLabels] = useState(true);

  const graphTitle = `${dashboardTitle}, Split by Status, Compared to 7 Days Ago`;

  const groupByKey = "groupByAttributeValue";
  const allGroupByValues = Array.from(
    new Set([
      ...issuesData.map((item) => item[groupByKey]),
      ...issuesDataPrev.map((item) => item[groupByKey]),
    ])
  );

  // process data to include all groupByAttributeValue
  const chartData = allGroupByValues.map((groupByValue) => {
    const currentItem = issuesData.find(
      (item) => item.groupByAttributeValue === groupByValue
    );
    const prevItem = issuesDataPrev.find(
      (item) => item.groupByAttributeValue === groupByValue
    );

    return {
      [groupByKey]: groupByValue === "" ? EMPTY_CHART_LABEL : groupByValue,
      currentCount: currentItem ? currentItem.count : 0,
      previousCount: prevItem ? prevItem.count : 0,
    };
  });

  const yAxisBars = {
    previousCount: "1 week ago",
    currentCount: "Now",
  };

  const handleOnBarClick = (data: DataElement) => {
    // we have to reverse the empty label mapping
    const clickedStatus =
      data[groupByKey] === EMPTY_CHART_LABEL ? "" : data[groupByKey];

    const groupByAttribute = "statusObj.value";

    const newStatusFilter: FilterRowState = {
      type: "row",
      id: `row-${randomID()}`,
      attribute: groupByAttribute,
      operator: FilterOperator.IN,
      values: [clickedStatus],
    };

    // remove all existing status filters and append the new one
    const updatedFilters: FilterGroupState = {
      ...filters,
      children: filters.children.filter(
        (child) =>
          !(child.type === "row" && child.attribute === groupByAttribute)
      ),
    };
    updatedFilters.children.push(newStatusFilter);

    const chartOptions = getChartOptionsAndOpenDashboard(
      ISSUE_COUNT,
      groupByAttribute
    );

    navigate({
      pathname: routes.issues,
      search: qs.stringify({
        [issuesFilterKey]: getFiltersQuery(updatedFilters),
        [getChartSettingsKey(ISSUES_PAGE_KEY)]: JSON.stringify({
          issues: chartOptions,
        }),
      }),
    });
  };

  return (
    <div className="mt-6">
      <h3 className="font-semibold mb-6">{graphTitle}</h3>
      <BarChart
        data={chartData}
        xAxisKey={groupByKey}
        yAxisBars={yAxisBars}
        barGap={1}
        barCategoryGap={15}
        height={200}
        colorPalette={[BAR_COLOR_LAST_WEEK, BAR_COLOR_NOW]}
        radius={[5, 5, 0, 0]}
        customTick={
          <BarChartCustomTick
            maxCharacters={MAX_X_AXIS_CHARACTERS}
            nBars={chartData.length}
            fontSize={10}
            setShowAllLabels={setShowAllLabels}
          />
        }
        onBarClick={handleOnBarClick}
        enableBarGroupHover={true}
        xAxisProps={{
          interval: showAllLabels ? 0 : undefined,
        }}
        margin={{ bottom: 15 }}
      />
    </div>
  );
};

export default DataDashboardChart;
