import { PageHandler } from "duck/graph/PageHandler";
import { sendAgentResponse } from "duck/graph/sendAgentResponse/sendAgentResponse";
import { DuckGraphParams, PageState, StringSetter } from "duck/graph/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
} from "pages/ClaimAnalytics/constants";
import { CLAIMS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";
import { claimsActionIds, ClaimsActionIdType } from "pages/utils";

const actionDescriptions: Map<ClaimsActionIdType, string> = new Map([
  [
    "y",
    "The metric to be displayed on the y-axis of the chart. Default value: 'cumulativeIPTV'.",
  ],
]);

interface GoToClaimsTabToolParams {
  pageHandler: PageHandler;
  claimsChartOptions: DuckGraphParams["availableData"]["claimAnalytics"]["claimsChartOptions"];
  setEphemeralMessage: StringSetter;
  setAgentResponse: StringSetter;
  selectedTab: PageState["selectedTab"];
  selectedClaimsChartOptions: PageState["claimAnalytics"]["selectedClaimsChartOptions"];
}

const getGoToClaimsTabTool = ({
  pageHandler,
  claimsChartOptions,
  setEphemeralMessage,
  setAgentResponse,
  selectedTab,
  selectedClaimsChartOptions,
}: GoToClaimsTabToolParams): DynamicStructuredTool => {
  type SchemaDefinition = {
    [K in ClaimsActionIdType]: z.ZodOptional<z.ZodEnum<[string, ...string[]]>>;
  };

  const schemaDefinition = claimsActionIds.reduce(
    (acc, actionId) => ({
      ...acc,
      [actionId]: z
        .enum(claimsChartOptions[actionId])
        .optional()
        .describe(actionDescriptions.get(actionId) ?? ""),
    }),
    {} as SchemaDefinition
  );

  const goToClaimsTabSchema = z.object(schemaDefinition);

  type GoToClaimsTab = z.infer<typeof goToClaimsTabSchema>;

  const goToClaimsTab = ({ y }: GoToClaimsTab): string => {
    setEphemeralMessage("to claims tab");
    console.debug("Navigating to Claims tab with y: " + y);

    const nonNullY = y ?? claimsChartOptions.y[0];
    if (!claimsChartOptions.y.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: ${nonNullY}. Valid options: ${claimsChartOptions.y.join(", ")}`
      );
    }

    pageHandler.updateTabChartSettings(CLAIMS_PAGE_KEY, CLAIMS_TAB_KEY, {
      [CLAIMS_CHART_OPTIONS_KEY]: [{ id: "y", optionId: nonNullY }],
    });

    sendAgentResponse({
      setAgentResponse,
      selectedTabKey: selectedTab,
      newTabKey: CLAIMS_TAB_KEY,
      newTabTitle: "Claims",
      chartName: "Claims",
      selectedChartOptions: selectedClaimsChartOptions,
      newChartOptions: { y: nonNullY },
    });

    return "queued navigation to Claims tab";
  };

  return tool(goToClaimsTab, {
    name: "queueGoToClaimsTab",
    description: `Navigate to the Claims tab on the Claim Analytics page to view a trend chart and a tabular summary of claim event data.
Use this tool when the user wants to:
- Explore claims
- Analyze trends such as costs or incident rates over time
- Select a different metric to view on the y-axis of the trend chart`,
    schema: goToClaimsTabSchema,
  });
};

export default getGoToClaimsTabTool;
