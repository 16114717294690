import { SyntheticEvent, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";

import { APIListValuesRequest, APIListValuesResponse } from "shared/api/api";
import { listVehiclesValues } from "shared/api/vehicles/api";
import { useDebounce } from "shared/hooks";
import { excludeNulls } from "shared/utils";

import { routes } from "services/routes";

const DEFAULT_NUM_SEARCH_RESULTS = 10;
const MIN_CHARS_TO_START_SEARCH = 1;
const MIN_CHARS_TEXT = "Start typing...";
const SEARCH_INPUT_DELAY = 300;
const DEFAULT_LABEL = "Go to VIN";
interface Props {
  onChangeHandler?: (vin: string) => void;
  label?: string;
  value?: string;
}

const VINSearch = ({
  onChangeHandler,
  label = DEFAULT_LABEL,
  value,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [searchStatusText, setSearchStatusText] = useState(MIN_CHARS_TEXT);
  const [searchResultItems, setSearchResultItems] = useState<
    APIListValuesResponse["distinctValues"]
  >([]);
  const [selectedValue, setSelectedValue] = useState(value);

  const debouncedSearchInput = useDebounce(searchInput, SEARCH_INPUT_DELAY);

  const getData = (str: string) => {
    setSearchStatusText("Loading ...");
    setSearchResultItems([]);
    const args: APIListValuesRequest = {
      fieldName: "VIN",
      like: str,
      limit: DEFAULT_NUM_SEARCH_RESULTS,
    };

    listVehiclesValues(args)
      .then(({ data }) => {
        if (data.distinctValues && data.distinctValues.length > 0) {
          setSearchResultItems(data.distinctValues);

          return;
        }

        setSearchStatusText("No results.");
      })
      .catch(() => {
        setSearchStatusText("An issue occurred.");
      });
  };

  const resetSearchInput = () => {
    setSearchInput("");
    setSearchResultItems([]);
    setSearchStatusText(MIN_CHARS_TEXT);
    setSelectedValue("");
  };

  useEffect(() => {
    if (
      debouncedSearchInput &&
      debouncedSearchInput.length >= MIN_CHARS_TO_START_SEARCH
    ) {
      getData(debouncedSearchInput);

      return;
    }

    setSearchStatusText(MIN_CHARS_TEXT);
    setSearchResultItems([]);
  }, [debouncedSearchInput]);

  const onVINSelect = (
    _: SyntheticEvent<Element, Event>,
    vin: string | null
  ) => {
    resetSearchInput();
    if (!vin) {
      if (onChangeHandler) {
        onChangeHandler("");
      }

      return;
    }

    // If we specify a handler, we execute it; otherwise, we apply the default behavior - redirect to the VIN view
    if (onChangeHandler) {
      setSelectedValue(vin);
      onChangeHandler(vin);
    } else {
      const pathname = generatePath(routes.vinView, {
        vin: encodeURIComponent(vin),
      });

      navigate({ pathname, hash: location.hash });
    }
  };

  const searchResultItemsNotNull = excludeNulls(searchResultItems);

  return (
    <Autocomplete
      id="vin-search"
      data-testid="vin-search"
      options={searchResultItemsNotNull}
      getOptionLabel={(option) => option}
      multiple={false}
      onChange={onVINSelect}
      blurOnSelect={true}
      noOptionsText={searchStatusText}
      inputValue={searchInput}
      onInputChange={(_, newInputValue) => {
        setSearchInput(newInputValue);
      }}
      value={selectedValue}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          slotProps={{
            htmlInput: params.inputProps,
          }}
        />
      )}
    />
  );
};

export default VINSearch;
