import { FiCopy as CopyIcon } from "react-icons/fi";
import { useNavigate } from "react-router";
import { IconButton } from "@mui/material";

import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";

import { routes } from "services/routes";

interface Props {
  customSignalEventDefinition: CustomSignalEventDefinition;
}

const CopyCustomSignalEventAction = ({
  customSignalEventDefinition,
}: Props) => {
  const navigate = useNavigate();

  const { name, description, eventDefinition, preFlight, query, type } =
    customSignalEventDefinition;

  const copiedDefinition: CustomSignalEventDefinition = {
    ID: "",
    name: `Copy of ${name}`,
    description,
    eventDefinition,
    preFlight,
    query,
    type,
    status: "draft",
    backfillError: "",
    invalidationReason: "",
    createdAt: "",
    updatedAt: "",
  };

  const handleNavigate = () => {
    navigate(routes.newCustomSignalEvent, {
      state: copiedDefinition,
    });
  };

  return (
    <IconButton onClick={handleNavigate} data-testid="btn-copy" size="small">
      <CopyIcon size="15" />
    </IconButton>
  );
};

export default CopyCustomSignalEventAction;
