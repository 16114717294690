import { useState } from "react";

import { useClaimsFiltersSchema, useVehiclesFiltersSchema } from "shared/hooks";

import { PageConfigProps } from "pages/Admin/PagesConfig/types";
import type { ClaimAnalyticsConfig } from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

const CLAIMS_PAGE_KEY = "admin_default_claim_filters";
const VEHICLES_PAGE_KEY = "admin_default_vehicle_filters";

export const DEFAULT_FILTERS_KEY = "defaultFilters";
export const DEFAULT_VEHICLE_FILTERS_KEY = "defaultVehicleFilters";

const ClaimAnalyticsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<ClaimAnalyticsConfig>) => {
  const claimsFiltersSchema = useClaimsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const initialClaimFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const initialVehicleFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_VEHICLE_FILTERS_KEY]
  );

  const defaultClaimFilters =
    filterStateToFilterGroupState(initialClaimFilters);

  const defaultVehicleFilters = filterStateToFilterGroupState(
    initialVehicleFilters
  );

  const [claimsDropdownOpen, setClaimsDropdownOpen] = useState(false);
  const [vehiclesDropdownOpen, setVehiclesDropdownOpen] = useState(false);

  const claimsFilterState = useFilterSortState({
    pageKey: CLAIMS_PAGE_KEY,
    defaultFilterValues: defaultClaimFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const vehiclesFilterState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleClaimsChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  const handleVehiclesChange = (filterState: FilterGroupState) => {
    onConfigChange(
      pageId,
      DEFAULT_VEHICLE_FILTERS_KEY,
      getFiltersQuery(filterState)
    );
  };

  return (
    <div className="flex space-x-4">
      <DropdownSelect
        testId="default-claim-filters-dropdown"
        label={getFilterLabel(
          "Default Claim Filters",
          claimsFilterState.filters
        )}
        open={claimsDropdownOpen}
        onOpen={(open) => {
          setClaimsDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={claimsFiltersSchema}
            filterSortState={claimsFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(CLAIMS_PAGE_KEY)}
            onApplyFilters={handleClaimsChange}
            testId="claim-filters-selector"
          />
        }
      />
      <DropdownSelect
        testId="default-vehicle-filters-dropdown"
        label={getFilterLabel(
          "Default Vehicle Filters",
          vehiclesFilterState.filters
        )}
        open={vehiclesDropdownOpen}
        onOpen={(open) => {
          setVehiclesDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={vehiclesFiltersSchema}
            filterSortState={vehiclesFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(VEHICLES_PAGE_KEY)}
            onApplyFilters={handleVehiclesChange}
            testId="vehicle-filters-selector"
          />
        }
      />
    </div>
  );
};

export default ClaimAnalyticsConfigPage;
