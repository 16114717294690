import { useState } from "react";
import { AxiosResponse } from "axios";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import { DeleteRequest } from "shared/api/api";
import { camelCaseToTitle, mutateMultipleSWRRequestKeys } from "shared/utils";

import Button from "features/ui/Button";
import ConfirmationModal from "features/ui/ConfirmationModal";

const CONFIRM_TEXT = "Yes, delete forever";
const CANCEL_TEXT = "No, cancel";

const ERROR_MESSAGES_MAP: Record<number, string> = {
  409: "You cannot remove this object since it's still used in other places. Please remove it elsewhere too before deleting.",
};

export interface RequiredProps {
  ID: string;
  name: string;
}

interface Props<T> {
  data: T;
  entityName: string;
  deleteCallback: (args: DeleteRequest) => Promise<AxiosResponse<any, any>>;
  onSuccessNavigateToRoute?: string;
  onErrorDeleteText?: string;
  onSuccessDeleteText?: string;
  showButton?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  iconOnly?: boolean;
  entityRequestKeys?: string[];
  disabled?: boolean;
}

const DefaultText = ({
  name,
  entityName,
}: {
  name: string;
  entityName: string;
}) => (
  <span>
    Are you sure you want to <strong>permanently</strong> delete <i>{name}</i>{" "}
    {entityName}?
  </span>
);

const DeleteAction = <T extends RequiredProps>({
  data: { ID, name },
  entityName,
  deleteCallback,
  onSuccessNavigateToRoute,
  onErrorDeleteText = "Whoops, could not delete at this time, sorry! Contact us if the issue persists.",
  onSuccessDeleteText = "Successfully deleted",
  showButton = true,
  defaultOpen = false,
  iconOnly = false,
  onClose,
  entityRequestKeys,
  disabled,
}: Props<T>) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(defaultOpen);
  const [isDeleting, setIsDeleting] = useState(false);
  const [disableCTAs, setDisableCTAs] = useState(false);
  const [text, setText] = useState<JSX.Element | string>(
    <DefaultText
      name={name}
      entityName={camelCaseToTitle(entityName).toLowerCase()}
    />
  );

  const handleOnConfirm = (confirm: boolean) => {
    if (!confirm) {
      setModalVisible(false);
      onClose && onClose();

      return;
    }

    setIsDeleting(true);
    deleteCallback({ id: ID })
      .then(() => {
        toast.success(onSuccessDeleteText);
        if (entityRequestKeys) {
          mutateMultipleSWRRequestKeys(entityRequestKeys);
        }

        if (onSuccessNavigateToRoute) {
          navigate(onSuccessNavigateToRoute);
          onClose && onClose();
        }
      })
      .catch((err) => {
        if (ERROR_MESSAGES_MAP[err.response?.status]) {
          setText(ERROR_MESSAGES_MAP[err.response.status]);
        } else {
          setText(onErrorDeleteText);
        }

        setDisableCTAs(false);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const deleteButton = iconOnly ? (
    <IconButton
      onClick={() => setModalVisible(true)}
      data-testid="btn-delete"
      size="small"
      disabled={disabled}
    >
      <DeleteIcon size="15" />
    </IconButton>
  ) : (
    <Button
      label="Delete"
      variant="contained"
      color="error"
      onClick={() => setModalVisible(true)}
      testId="btn-delete"
      endIcon={<DeleteIcon />}
      disabled={disabled}
    />
  );

  return (
    <>
      {showButton && deleteButton}
      <ConfirmationModal
        isOpen={modalVisible}
        onClose={handleOnConfirm}
        loading={isDeleting}
        confirmText={CONFIRM_TEXT}
        closeDisabled={disableCTAs}
        hideCTA={disableCTAs}
        closeText={CANCEL_TEXT}
        title=""
        text={text}
        confirmColor="error"
      />
    </>
  );
};
export default DeleteAction;
