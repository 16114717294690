import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter, UIHandlers } from "duck/graph/types";
import { StructuredTool, tool } from "@langchain/core/tools";

import { setRouteValue, WithIssueId, withIssueIdSchema } from "./utils";

const getGoToIssueDetailsPageTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: UIHandlers["setEphemeralMessage"],
  setAgentResponse: StringSetter,
  selectedPathname: string
): StructuredTool => {
  const goToIssueDetailsPage = async ({ issueId }: WithIssueId) => {
    console.debug("Navigating to the Issue Details page", { issueId });
    setEphemeralMessage("Navigating to the Issue Details page");

    await setRouteValue(issueId, pageHandler);
    pageHandler.navigateToPage();

    const newRouteValue = pageHandler.getRouteValue();

    if (selectedPathname !== newRouteValue) {
      setAgentResponse(
        `- Navigate to the Issue Details page for issue ${issueId}`
      );
    }

    return "queued navigation to the Issue Details page";
  };

  return tool(goToIssueDetailsPage, {
    name: "queueGoToIssueDetailsPage",
    description: `Navigate to the Issue Details page with the given Issue ID.
      Use this tool when the user wants to view the details of a specific issue.`,
    schema: withIssueIdSchema,
  });
};

export default getGoToIssueDetailsPageTool;
