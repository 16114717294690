import { EntityAttribute } from "shared/api/api";
import { GENERIC_FILTER_WITHOUT_SELECT } from "shared/filterDefinitions";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useGeneralDescriptionSchema = (filtering = false): UseSchema => {
  const attributes: EntityAttribute[] = [
    {
      displayName: "Description",
      columnName: "Description",
      dataExplorerColumnName: "Description",
      ID: "description",
      description: "",
      type: "string",
      displayWideColumn: false,
      hideInTable: false,
      hideFilter: false,
      nullable: true,
      filtering,
      attributeGrouping: false,
      grouping: false,
      inJSONBody: true,
      sorting: false,
      values: false,
      filteringConfig: {
        negativeNumbers: false,
        empty: filtering,
        startsWith: filtering,
        contains: filtering,
        decimalNumbers: false,
        lowCardinality: false,
        minMax: false,
      },
      byVehicleAgeBirthday: false,
      byVehicleAgeExposure: false,
      byVehicleAgeExposureBuckets: [],
    },
  ];

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    GENERIC_FILTER_WITHOUT_SELECT,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(attributes, getSchemaEntry);

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};
export default useGeneralDescriptionSchema;
