import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getAnalyzeScreenshotAgentNode from "./AnalyzeScreenshotAgent/getNode";
import getClaimAnalyticsAgentGraph from "./ClaimAnalyticsAgent/getGraph";
import getCodeSearchByDescriptionAgentNode from "./CodeSearchByDescriptionAgent/getNode";
import getRejectClarifyNode from "./GreetingRejectClarifyAgent/getNode";
import getHandleRepeatedToolCallNode from "./HandleRepeatedToolCallNode/getNode";
import getIssueDetailsAgentGraph from "./IssueDetailsAgent/getGraph";
import getIssueAgentGraph from "./IssuesAgent/getGraph";
import getKnightSwiftVinViewAgentNode from "./KnightSwiftVinViewAgent/getNode";
import getRagAgentNode from "./RagAgent/getNode";
import getRouterAgentNode from "./RouterAgent/getNode";
import getSignalEventAnalyticsAgentGraph from "./SignalEventAnalyticsAgent/getGraph";
import getSubmitFeedbackNode from "./SubmitFeedbackAgent/getNode";
import getVehiclesAgentGraph from "./VehiclesAgent/getGraph";
import getVinViewAgentGraph from "./VinViewAgent/getGraph";

const getRespondToUserToolNode = (
  params: DuckGraphParams
): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>([
    getRespondToUserTool(
      params.uiHandlers.setAgentResponse,
      params.uiHandlers.setEphemeralMessage
    ),
  ]);

export {
  getClaimAnalyticsAgentGraph,
  getRagAgentNode,
  getRouterAgentNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getAnalyzeScreenshotAgentNode,
  getSignalEventAnalyticsAgentGraph,
  getVinViewAgentGraph,
  getVehiclesAgentGraph,
  getIssueAgentGraph,
  getIssueDetailsAgentGraph,
  getKnightSwiftVinViewAgentNode,
  getSubmitFeedbackNode,
  getHandleRepeatedToolCallNode,
  getCodeSearchByDescriptionAgentNode,
};
