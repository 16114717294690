import { promptNames } from "duck/graph/nodes/types";
import buildPageAgentSubgraph from "duck/graph/pageAgentSubGraph/subGraph";
import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";

import { routes } from "services/routes";

import { getActionTools, getRetrievalTools } from "./tools/getTools";

const getGraph = (params: DuckGraphParams) => {
  const pageHandler = new PageHandler(routes.vehicles);

  return buildPageAgentSubgraph({
    params,
    retrievalTools: getRetrievalTools(params),
    actionTools: getActionTools(params, pageHandler),
    promptName: promptNames.VEHICLES_AGENT,
    name: NodeNames.VEHICLES,
    route: routes.vehicles,
  });
};

export default getGraph;
