import { getMaxNumOfLinesForFilterPresenter } from "./utils";

export const DescriptionTableCell = ({
  description,
  filters,
}: {
  description?: string;
  filters: (string | undefined)[];
}) => {
  // The height of each table row in issue tables is determined by number of lines
  // that are present in the filter presenters, we clamp description cell
  // to the same number of rows, to make descriptions more readable at a glance.
  const maxLineHeight = getMaxNumOfLinesForFilterPresenter(filters);

  return (
    <div
      title={description}
      className="overflow-hidden whitespace-normal height-full"
      style={{
        display: "-webkit-box",
        WebkitLineClamp: maxLineHeight,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
    >
      {description}
    </div>
  );
};
