import { FormControlLabel, Switch } from "@mui/material";

import {
  FailureModesConfig,
  PageConfigProps,
} from "pages/Admin/PagesConfig/types";

const SIMILAR_VEHICLES_KEY = "similarVehicles";
const SURVIVAL_CURVES_KEY = "survivalCurves";

const FailureModesV0ConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<FailureModesConfig>) => (
  <div>
    <FormControlLabel
      control={
        <Switch
          checked={config[SIMILAR_VEHICLES_KEY] ?? false}
          onChange={(_, checked) =>
            onConfigChange(pageId, SIMILAR_VEHICLES_KEY, checked)
          }
          size="small"
        />
      }
      label={<div className="text-xs">Show Similar Vehicles</div>}
    />
    <FormControlLabel
      control={
        <Switch
          checked={config[SURVIVAL_CURVES_KEY] ?? false}
          onChange={(_, checked) =>
            onConfigChange(pageId, SURVIVAL_CURVES_KEY, checked)
          }
          size="small"
        />
      }
      label={<div className="text-xs">Show Survival Curves</div>}
    />
  </div>
);

export default FailureModesV0ConfigPage;
