export const ENABLED_KEY = "enabled";

export const VEHICLES_PAGE_KEY = "vehicles";
export type VehiclesConfig = {
  eventTimeline?: boolean;
  // Ideally we don't manually enter eventTimelineFromDateVehicleAttribute but have a dropdown when configuring.
  // Default from date in event timeline
  // Priority 1: eventTimelineFromDateVehicleAttribute
  // Priority 2: eventTimelineFromDate
  eventTimelineFromDateVehicleAttribute?: string;
  eventTimelineFromDate?: string;
  eventTimelineToDate?: string;
  eventTimelineDefaultSignalEventFilters?: string;
};

export const EVENTS_PAGE_KEY = "events";
export type EventsConfig = {
  hideMenuItem?: boolean;
  serviceRecordText?: string;
  staticFilters?: string;
};

export const FAILURE_MODES_PAGE_KEY = "failureModes";
export type FailureModesConfig = {
  similarVehicles?: boolean;
  survivalCurves?: boolean;
  nominalRiskModelPerformance?: boolean;
};

export const FAILURE_MODES_V0_PAGE_KEY = "failureModesV0";
export type FailureModesV0Config = FailureModesConfig;

export const ISSUES_PAGE_KEY = "issues";
export type IssuesConfig = {
  suggestedIssues?: boolean;
  topXChartsOptions?: string[];
  defaultBreakdownAttribute?: string;
  defaultFilters?: string;
};

export const CLAIM_ANALYTICS_PAGE_KEY = "claimAnalytics";
export type ClaimAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
};

export const SIGNAL_EVENT_ANALYTICS_PAGE_KEY = "signalEventsAnalytics";
export type SignalEventsAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
  defaultClaimGroupByAttribute?: string;
};

export const CUSTOM_SIGNAL_EVENTS_PAGE_KEY = "customSignalEvents";
export type CustomSignalEventsConfig = {
  eventPreviewFromDate?: string;
  eventPreviewToDate?: string;
};

export const HELP_ARTICLES_PAGE_KEY = "helpArticles";
type HelpArticle = {
  url: string;
  title: string;
};
export type HelpArticlesConfig = {
  articles: HelpArticle[];
};

export const FLEETS_PAGE_KEY = "fleets";
export const SERVICE_PLANS_PAGE_KEY = "servicePlans";
export const ALERT_DEFINITIONS_PAGE_KEY = "alertDefinitions";
export const REPAIR_ANALYTICS_PAGE_KEY = "repairAnalytics";
export const INSPECTION_ANALYTICS_PAGE_KEY = "inspectionAnalytics";
export const MAINTENANCE_SCHEDULES_PAGE_KEY = "maintenanceSchedules";
export const COLLECTIONS_PAGE_KEY = "collections";
export const CALCULATED_ATTRIBUTES_PAGE_KEY = "calculatedAttributes";

const PAGE_KEYS = [
  VEHICLES_PAGE_KEY,
  EVENTS_PAGE_KEY,
  FAILURE_MODES_PAGE_KEY,
  FAILURE_MODES_V0_PAGE_KEY,
  ISSUES_PAGE_KEY,
  CLAIM_ANALYTICS_PAGE_KEY,
  SIGNAL_EVENT_ANALYTICS_PAGE_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_KEY,
  FLEETS_PAGE_KEY,
  SERVICE_PLANS_PAGE_KEY,
  REPAIR_ANALYTICS_PAGE_KEY,
  INSPECTION_ANALYTICS_PAGE_KEY,
  MAINTENANCE_SCHEDULES_PAGE_KEY,
  COLLECTIONS_PAGE_KEY,
  CALCULATED_ATTRIBUTES_PAGE_KEY,
  ALERT_DEFINITIONS_PAGE_KEY,
  HELP_ARTICLES_PAGE_KEY,
] as const;

export type KnownPageKeys = (typeof PAGE_KEYS)[number];

export const DEFAULT_CONFIG = {};

// Here we can define page configs that have other settings besides just "enabled" flag
// Then modify PageConfigItem to ensure all known keys are handled
export type PageConfigItem =
  | { ID: typeof VEHICLES_PAGE_KEY; config: VehiclesConfig }
  | { ID: typeof EVENTS_PAGE_KEY; config: EventsConfig }
  | { ID: typeof FAILURE_MODES_PAGE_KEY; config: FailureModesConfig }
  | { ID: typeof ISSUES_PAGE_KEY; config: IssuesConfig }
  | { ID: typeof CLAIM_ANALYTICS_PAGE_KEY; config: ClaimAnalyticsConfig }
  | {
      ID: typeof SIGNAL_EVENT_ANALYTICS_PAGE_KEY;
      config: SignalEventsAnalyticsConfig;
    }
  | {
      ID: typeof CUSTOM_SIGNAL_EVENTS_PAGE_KEY;
      config: CustomSignalEventsConfig;
    }
  | {
      ID: typeof HELP_ARTICLES_PAGE_KEY;
      config: HelpArticlesConfig;
    }
  | { ID: Exclude<string, KnownPageKeys>; config: typeof DEFAULT_CONFIG };

export type PagesConfig = PageConfigItem[];

export type PageConfigValues = boolean | string | null | string[];

export interface PageConfigProps<T = typeof DEFAULT_CONFIG | undefined> {
  pageId: string;
  onConfigChange: (
    pageID: string,
    configKey: string,
    configValue: PageConfigValues
  ) => void;
  config: T;
}
