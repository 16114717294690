import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { VEHICLE_ACCESSOR } from "shared/schemas/constants";
import { IssueTypes, ReoccurrenceProps } from "shared/types";

import {
  numberOfClaimsWithReoccurringSignalEvent,
  rateOfClaimsWithReoccurringSignalEvent,
  yAxis,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { SelectOption } from "features/ui/Select";

import * as config from "config/config";

import ReoccurrenceBarChart from "./ReoccurrenceBarChart";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const {
  pages: { issues },
} = config.get();

const ReoccurrenceBarChartWrapper = ({
  issue,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  // Note: we need to use const { groupBySelectOptions } = useClaimsSchema(); to retrieve these (will also include Vehicle attributes)
  // but first we need to allow chart settings to support NestedAutocomplete for these ...
  // And lastly, when we ditch useGroupBySelectOptions alltogether we should remove it from our codebase entirely.
  const { groupBySelectOptions: groupBySelectOptionsFromSchema } =
    useClaimsSchema([VEHICLE_ACCESSOR]);

  const groupBySelectOptions: SelectOption[] = groupBySelectOptionsFromSchema
    ? groupBySelectOptionsFromSchema.map((x) => ({
        ...x,
        label: x.value,
      }))
    : [];

  const actions: ChartAction[] = [
    {
      id: "legend",
      title: "Legend",
      type: "dropdownSelectNested",
      options: groupBySelectOptions,
      defaultOptionId: issues?.defaultBreakdownAttribute || "laborCode",
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [
        rateOfClaimsWithReoccurringSignalEvent,
        numberOfClaimsWithReoccurringSignalEvent,
      ],
    },
  ];

  if (!groupBySelectOptions || groupBySelectOptions.length === 0) {
    return null;
  }

  return (
    <ReoccurrenceBarChart
      issue={issue}
      withComparisonPopulation={withComparisonPopulation}
      actions={actions}
      seOccurrenceWithin={seOccurrenceWithin}
      seReoccurrenceStartingFrom={seReoccurrenceStartingFrom}
      seReoccurrenceUpTo={seReoccurrenceUpTo}
    />
  );
};

export default ReoccurrenceBarChartWrapper;
