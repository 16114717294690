import React, { createContext, useContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { UseAPIState } from "shared/api/hooks";
import { useGroupByRelationIDValues } from "shared/schemas/hooks";
import { M2MRelationshipsGroupBy } from "shared/schemas/types";

import { ContextName, ContextWrapComponentProps } from "./ContextWrappers";

export type AttributesContextContent = {
  attributes: EntityAttribute[] | undefined;
  groupByValues: M2MRelationshipsGroupBy;
};

export type UseAttributesHook = (
  args?: any
) => UseAPIState<EntityAttribute[] | undefined>;

interface ContextRegistry {
  [key: string]: React.Context<AttributesContextContent>;
}

export const contextRegistry: ContextRegistry = {};

export const createGenericContextWrapper = (
  useAttributesHook: UseAttributesHook,
  contextName: ContextName
) => {
  const Context = createContext<AttributesContextContent>({
    attributes: undefined,
    groupByValues: {},
  });
  contextRegistry[contextName] = Context;

  const GenericContextWrapper: React.FC<ContextWrapComponentProps> = ({
    children,
  }) => {
    const { data: attributes } = useAttributesHook();
    const groupByValues = useGroupByRelationIDValues(attributes);

    return (
      <Context.Provider value={{ attributes, groupByValues }}>
        {children}
      </Context.Provider>
    );
  };

  return GenericContextWrapper;
};

export const useAttributesContext = (
  contextName: ContextName
): AttributesContextContent => {
  const Context = contextRegistry[contextName];
  if (!Context) {
    throw new Error(`Context for ${contextName} not found`);
  }

  return useContext(Context);
};
