import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BiPencil as EditIcon } from "react-icons/bi";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  AlertDefinition as AlertDefinitionModel,
  AlertDefinitionPostRequestData,
  AlertEventType,
  deleteAlertDefinition,
  getAlertDefinition,
  newAlertDefinition,
  updateAlertDefinition,
} from "shared/api/alertDefinitions/api";
import { DeleteRequest } from "shared/api/api";
import { loadGroupsListFunc } from "shared/api/filter.utils";
import {
  useClaimsFiltersSchema,
  useServiceRecommendationsFiltersSchema,
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";

import {
  ALERT_DEFINITION_DESCRIPTION_LABEL,
  ALERT_DEFINITION_NAME_LABEL,
  ALERT_EVENT_TYPE_OPTIONS,
  ALERT_PLAN_TITLE,
  EMPTY_ALERT_DEFINITION_STATE,
  FrequencyOptions,
  ICON_SIZE,
  ON_SUCCESS_CREATE_TEXT,
  ON_UPDATE_CREATE_TEXT,
} from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";
import { validateForm } from "pages/AlertDefinitions/utils";
import {
  CANCEL_CTA_TEXT,
  SUBMIT_CTA_EDIT,
  SUBMIT_CTA_NEW,
} from "pages/constants";
import ClaimCount from "pages/Issues/CreateIssue/ClaimCount";
import SignalEventOccurrencesCount from "pages/Issues/CreateIssue/SignalEventOccurrencesCount";
import FormLoader from "pages/ServicePlans/Form/FormLoader";
import RecommendationCount from "pages/ServiceRecommendations/RecommendationCount";
import EligibleVehicleCount from "pages/shared/EligibleVehicleCount";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import FilterBuilder from "features/ui/Filters/FilterBuilder";
import {
  DEFAULT_FILTER_BUILDER_STATE,
  DEFAULT_FILTER_BUILDER_STATE_ANY,
} from "features/ui/Filters/FilterBuilder/constants";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import EditDetails from "features/ui/Form/EditDetails";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import Select from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import TagsInput from "features/ui/TagsInput";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import AlertTypeAndFrequencyRadioForm from "./AlertTypeAndFrequencyRadioForm";

type AlertDefinitionParams = {
  id: string;
};

const ALERT_EVENT_TYPE_TITLE = "Alert Event Type";
const ALERT_EVENT_TYPE_TEXT = "Specify the Event Type of the alert";

const AlertDefinition = () => {
  const { id: alertDefinitionId } = useParams<AlertDefinitionParams>();
  const isCreatePage = !alertDefinitionId;
  const [editMode, setEditMode] = useState(isCreatePage);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [validationErrors, setValidationErrors] = useState("");
  const [showStaticValidationErrors, setShowStaticValidationErrors] =
    useState(!isCreatePage);

  const navigate = useNavigate();

  const [currentAlertDefinition, setCurrentAlertDefinition] =
    useState<AlertDefinitionState>(EMPTY_ALERT_DEFINITION_STATE);

  const { rbac } = useFlags();
  const canEditAlertDefinition: boolean =
    !rbac || (rbac && currentAlertDefinition.canEdit);

  const { getDisplayLabel } = useAlertDefinitionSchema();
  const [initialAlertDefinition, setInitialAlertDefinition] =
    useState<AlertDefinitionState>();

  const SUBMIT_CTA_TEXT = isCreatePage ? SUBMIT_CTA_NEW : SUBMIT_CTA_EDIT;

  const isFormDisabled = isSubmitting || !editMode;

  useEffect(() => {
    if (isCreatePage) return;

    setIsLoading(true);
    getAlertDefinition({ ID: alertDefinitionId as string })
      .then(({ data }) => setAndRememberInitialAlertDefinition(data))
      .catch((err) => {
        navigate(routes.alertDefinitions);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePage, alertDefinitionId]);

  const { valid, message: staticErrorMessage } = validateForm(
    currentAlertDefinition
  );

  const showError =
    validationErrors || (showStaticValidationErrors && staticErrorMessage);

  const onDataUpdated = (update: Partial<AlertDefinitionState>) => {
    setCurrentAlertDefinition({ ...currentAlertDefinition, ...update });
  };

  const resetToInitialAlertDefinition = () => {
    if (!initialAlertDefinition) return;

    setCurrentAlertDefinition(initialAlertDefinition);
  };

  const setAndRememberInitialAlertDefinition = (data: AlertDefinitionModel) => {
    const alertDefinitionState: AlertDefinitionState = {
      ID: data.ID,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      createdBy: data.createdBy,
      updatedBy: data.createdBy,
      name: data.name,
      description: data.description,
      vehiclesFilter:
        filterBuilderQueryToFilterBuilderState(data.vehiclesFilter) ||
        DEFAULT_FILTER_BUILDER_STATE,
      eventType: data.eventType,
      eventFilter:
        filterBuilderQueryToFilterBuilderState(data.eventFilter) ||
        DEFAULT_FILTER_BUILDER_STATE_ANY,
      frequency: data.frequency,
      emails: data.emails,
      groups: data.groups.map((item) => item.ID),
      access: data.access,
      initialGroups: data.groups.map(({ name, ID }) => ({
        id: ID,
        value: name,
      })),
      canEdit: data.canEdit,
      inAppAlerts: data.inAppAlerts,
      emailAlerts: data.emailAlerts,
    };

    setInitialAlertDefinition(alertDefinitionState);
    setCurrentAlertDefinition(alertDefinitionState);
  };

  const vehiclesFiltersSchema = useVehiclesFiltersSchema({
    disableIsNotFilteredFilters: true,
  });
  const serviceRecommendationsSchema = useServiceRecommendationsFiltersSchema({
    disableIsNotFilteredFilters: true,
  });

  const getAlertDefinitionRequestData = () => {
    const vehiclesFiltersString = getFiltersQuery(
      currentAlertDefinition.vehiclesFilter
    );
    const eventFilterString = getFiltersQuery(
      currentAlertDefinition.eventFilter
    );

    const alertDefinitionPostRequestData: AlertDefinitionPostRequestData = {
      name: currentAlertDefinition.name,
      description: currentAlertDefinition.description,
      vehiclesFilter:
        vehiclesFiltersString === "" ? null : vehiclesFiltersString,
      eventType: currentAlertDefinition.eventType,
      eventFilter: eventFilterString === "" ? null : eventFilterString,
      frequency: currentAlertDefinition.frequency,
      emails: currentAlertDefinition.emails,
      groupIds: currentAlertDefinition.groups,
      inAppAlerts: currentAlertDefinition.inAppAlerts,
      emailAlerts: currentAlertDefinition.emailAlerts,
    };

    return alertDefinitionPostRequestData;
  };

  const handleAlertDefinitionCreate = () => {
    const data = getAlertDefinitionRequestData();

    newAlertDefinition(data)
      .then(({ data: { ID } }) => {
        toast.success(ON_SUCCESS_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.alertDefinition, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleAlertDefinitionUpdate = () => {
    updateAlertDefinition({
      ...getAlertDefinitionRequestData(),
      ID: currentAlertDefinition.ID,
    })
      .then(({ data: { ID } }) => {
        toast.success(ON_UPDATE_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.alertDefinition, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitCallback = isCreatePage
    ? handleAlertDefinitionCreate
    : handleAlertDefinitionUpdate;

  const handleOnCancel = () => {
    if (isCreatePage) {
      navigate(routes.alertDefinitions);
    } else {
      resetToInitialAlertDefinition();
      setEditMode(false);
    }
  };

  const handleOnSubmit = () => {
    if (!valid) {
      setShowStaticValidationErrors(true);

      return;
    }

    setValidationErrors("");
    setIsSubmitting(true);

    submitCallback();
  };

  const handleOnDelete = (args: DeleteRequest) =>
    deleteAlertDefinition(args).then((response) => {
      navigate(routes.alertDefinitions);

      return response;
    });

  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;

  const handleFrequencyOrTypeChange = (
    frequency: string,
    inAppAlerts: boolean,
    emailAlerts: boolean
  ) => {
    currentAlertDefinition.frequency = frequency;
    currentAlertDefinition.emailAlerts = emailAlerts;
    currentAlertDefinition.inAppAlerts = inAppAlerts;
    setCurrentAlertDefinition({ ...currentAlertDefinition });
    onDataUpdated({ ...currentAlertDefinition });
  };

  const signalEventOccurrencesSchema = useSignalEventOccurrencesFiltersSchema();
  const claimsSchema = useClaimsFiltersSchema();

  const getSchemaForEventType = (eventType: string): SchemaEntry[] => {
    switch (eventType) {
      case "signalEventOccurrence":
        return signalEventOccurrencesSchema;
      case "claim":
        return claimsSchema;
      case "serviceRecommendation":
        return serviceRecommendationsSchema;
      default:
        return [];
    }
  };

  const getLabelForEventFilter = (eventType: string) => {
    const base = "Eligible ";
    const option = ALERT_EVENT_TYPE_OPTIONS.find(
      (option) => option.id === currentAlertDefinition.eventType
    )?.value.toString();

    return `${base}${option}`;
  };
  const getLabelForEventFilterDescription = (eventType: string) => {
    const base = (type: string) =>
      `Specify the ${type} for which alerts should be sent`;
    const option = ALERT_EVENT_TYPE_OPTIONS.find(
      (option) => option.id === currentAlertDefinition.eventType
    )?.value.toString();
    if (!option) return "";

    return base(option);
  };

  return (
    <div>
      {isLoading && <FormLoader />}
      {!isLoading && (
        <div className="max-w-5xl">
          {showError && (
            <Alert
              severity="error"
              data-testid="alert-definition-form-validation-errors"
              className="mb-5"
            >
              <div className="font-bold mb-1">Validation errors</div>
              {validationErrors || staticErrorMessage}
            </Alert>
          )}
          <PageHeaderWrapper>
            <Title text={ALERT_PLAN_TITLE} />
          </PageHeaderWrapper>
          <div className="mb-2 flex">
            <div className="flex w-full">
              <div className="mr-4 leading-9 text-right w-[82px]">
                {getDisplayLabel("name", ALERT_DEFINITION_NAME_LABEL)}
              </div>
              <Input
                value={currentAlertDefinition.name}
                onChange={({ target: { value } }) =>
                  onDataUpdated({ name: value })
                }
                testId="alert-definition-name"
                disabled={isFormDisabled}
                fullWidth={false}
                className="w-80"
                tabIndex={0}
              />
              <div className="flex items-center ml-auto">
                {!isCreatePage && (
                  <EditDetails props={currentAlertDefinition} />
                )}
                {!editMode && !isCreatePage && canEditAlertDefinition && (
                  <div className="ml-auto">
                    <PermissionsSettingsAction
                      entity="alertDefinition"
                      entityId={alertDefinitionId!}
                      entityName={currentAlertDefinition.name}
                      permissions={currentAlertDefinition.access}
                      canEdit={currentAlertDefinition.canEdit}
                      iconSize={ICON_SIZE}
                      entityRequestKeys={[]}
                    />
                    <DeleteAction
                      data={{
                        ID: currentAlertDefinition.ID!,
                        name: currentAlertDefinition?.name,
                      }}
                      entityName="alert definition"
                      deleteCallback={handleOnDelete}
                      entityRequestKeys={[]}
                      iconOnly={true}
                    />
                    <IconButton
                      onClick={() => setEditMode(true)}
                      size="small"
                      data-testid="alert-definition-form-edit-mode-cta"
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
                {editMode && (
                  <>
                    <Button
                      color="secondary"
                      variant="outlined"
                      label={CANCEL_CTA_TEXT}
                      onClick={handleOnCancel}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="alert-form-cancel-cta"
                      size="medium"
                      className="!mr-4"
                      tabIndex={-1}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      label={SUBMIT_CTA_TEXT}
                      onClick={handleOnSubmit}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="alert-definition-form-submit-cta"
                      size="medium"
                      tabIndex={-1}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mb-8 flex">
            <div className="mr-4  leading-9 text-right shrink-0 w-[82px]">
              {getDisplayLabel(
                "description",
                ALERT_DEFINITION_DESCRIPTION_LABEL
              )}
            </div>
            <div className="w-full">
              <Input
                value={currentAlertDefinition.description || ""}
                onChange={({ target: { value } }) =>
                  onDataUpdated({ description: value })
                }
                testId="alert-definition-description"
                disabled={isFormDisabled}
                tabIndex={1}
              />
            </div>
          </div>
          <FormSection
            title={ALERT_EVENT_TYPE_TITLE}
            text={ALERT_EVENT_TYPE_TEXT}
          >
            <div className="max-w-96">
              <Select
                testId="alert-event-type"
                disabled={isFormDisabled}
                options={ALERT_EVENT_TYPE_OPTIONS}
                selected={ALERT_EVENT_TYPE_OPTIONS.find(
                  (option) => option.id === currentAlertDefinition.eventType
                )}
                onSelect={(selectedOption) =>
                  onDataUpdated({
                    eventType: selectedOption.id as AlertEventType,
                    eventFilter: DEFAULT_FILTER_BUILDER_STATE_ANY,
                  })
                }
              />
            </div>
          </FormSection>
          <FormSection
            title={getLabelForEventFilter(currentAlertDefinition.eventType)}
            extraTitleComponent={
              !editMode && (
                <>
                  {currentAlertDefinition.eventType ===
                    "serviceRecommendation" && (
                    <RecommendationCount
                      filterState={currentAlertDefinition.eventFilter}
                      vehiclesFilterState={
                        currentAlertDefinition.vehiclesFilter
                      }
                    />
                  )}
                  {currentAlertDefinition.eventType === "claim" && (
                    <ClaimCount
                      filters={currentAlertDefinition.eventFilter}
                      vehicleFilters={currentAlertDefinition.vehiclesFilter}
                      clickable={true}
                    />
                  )}
                  {currentAlertDefinition.eventType ===
                    "signalEventOccurrence" && (
                    <SignalEventOccurrencesCount
                      filters={currentAlertDefinition.eventFilter}
                      vehicleFilters={currentAlertDefinition.vehiclesFilter}
                      clickable={true}
                    />
                  )}
                </>
              )
            }
            text={getLabelForEventFilterDescription(
              currentAlertDefinition.eventType
            )}
            middleMargin={false}
          >
            {isFormDisabled ? (
              <FilterQueryPresentation
                filterState={currentAlertDefinition.eventFilter}
                tableSchema={getSchemaForEventType(
                  currentAlertDefinition.eventType
                )}
                dense={false}
              />
            ) : (
              <FilterBuilder
                filterBuilderState={currentAlertDefinition.eventFilter}
                filtersSchema={getSchemaForEventType(
                  currentAlertDefinition.eventType
                )}
                onChange={(filterState) =>
                  onDataUpdated({ eventFilter: filterState })
                }
                inline
                testId="alert-event-filter-builder"
              />
            )}
          </FormSection>
          <FormSection
            title={getDisplayLabel("vehicleFilter", "Eligible Vehicles")}
            extraTitleComponent={
              !editMode && (
                <EligibleVehicleCount
                  vehicleFilter={currentAlertDefinition.vehiclesFilter}
                />
              )
            }
            text={
              isFormDisabled
                ? undefined
                : "Optional filters to refine the set of affected vehicles covered by this alert"
            }
            middleMargin={false}
          >
            {isFormDisabled ? (
              <FilterQueryPresentation
                filterState={currentAlertDefinition.vehiclesFilter}
                tableSchema={vehiclesFiltersSchema}
                dense={false}
              />
            ) : (
              <FilterBuilder
                filterBuilderState={currentAlertDefinition.vehiclesFilter}
                filtersSchema={vehiclesFiltersSchema}
                onChange={(filterState) =>
                  onDataUpdated({ vehiclesFilter: filterState })
                }
                inline
                testId="eligible-vehicles-filter-builder"
              />
            )}
          </FormSection>
          <FormSection title="Notification Recipients">
            <div className="flex">
              <div className="flex flex-row w-full">
                <div className="flex flex-col">
                  <div className="h-full">
                    <div className="mr-4 leading-9 text-right shrink-0">
                      Individuals
                    </div>
                  </div>
                  <div className="h-full pt-2">
                    <div className="mr-4 leading-9 text-right shrink-0">
                      Groups
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div>
                    <TagsInput
                      label="Recipients"
                      key="recipients"
                      fieldName="Individuals"
                      testId="alert-definition-recipients"
                      onChange={(options) => {
                        onDataUpdated({ emails: options });
                      }}
                      disabled={isFormDisabled}
                      validationFunction={(value: string) =>
                        isValidEmail(value)
                      }
                      validationErrorMessage="Not a valid email."
                      values={currentAlertDefinition.emails}
                      caseInsensitive
                    />
                  </div>
                  <div className="pt-2">
                    <SelectFilter
                      label="Groups"
                      fieldName="name"
                      loadOptionsFunc={loadGroupsListFunc}
                      onChange={(options) => {
                        onDataUpdated({ groups: options });
                      }}
                      disabled={isFormDisabled}
                      initialSelected={currentAlertDefinition.initialGroups}
                      search={true}
                      loadDataOnOpen={true}
                      disableArbitraryText={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormSection>
          <FormSection title="Alert Type And Frequency" className="mt-6">
            <AlertTypeAndFrequencyRadioForm
              frequency={currentAlertDefinition.frequency as FrequencyOptions}
              inAppAlerts={currentAlertDefinition.inAppAlerts}
              emailAlerts={currentAlertDefinition.emailAlerts}
              onChange={handleFrequencyOrTypeChange}
              disabled={isFormDisabled}
            ></AlertTypeAndFrequencyRadioForm>
          </FormSection>
        </div>
      )}
    </div>
  );
};

export default AlertDefinition;
