import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { APIFilter, getSortFilter } from "shared/api/utils";
import { FailureModePrediction } from "shared/api/v0_failureModes/api";
import {
  useFailureModePredictions,
  useFailureModes,
  useListSimilarVehicles,
} from "shared/api/v0_failureModes/hooks";
import { toTitleCase } from "shared/utils";

import { DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER } from "pages/FailureModes/constants";
import StatusBadge from "pages/V0_FailureModes/StatusBadge";
import {
  repairStatusDescription,
  riskGroupDescription,
  riskMultipleDescription,
  riskStatusDescription,
  warrantyActiveDescription,
} from "pages/V0_Vehicles/content";
import RiskBadge from "pages/Vehicles/RiskBadge";
import SurvivalCurveV0 from "pages/VINView/V0_SurvivalCurve";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import Table, { RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import * as config from "config/config";

import SimilarityModal from "./V0_SimilarityModal";

interface Props {
  vin: string;
}

const isChildNull = (children: JSX.Element) =>
  !Boolean(ReactDOMServer.renderToStaticMarkup(children));

const V0_HealthTab = ({ vin }: Props) => {
  const {
    pages: { failureModesV0 },
  } = config.get();

  const [selectedProfile, setSelectedProfile] = useState<RowData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [failureModeID, setFailureModeId] = useState("");

  const staticFilters: APIFilter[] = [
    {
      name: "VIN",
      op: "eq",
      value: vin,
    },
  ] as APIFilter[];

  const staticFiltersSimilarVINS: APIFilter[] = [
    {
      name: "currentVIN",
      op: "eq",
      value: vin,
    },
  ] as APIFilter[];

  const onSimilarVehiclesClick = ({ row }: { row: RowData }) => {
    // Don't open the modal if the 'View' button isn't present
    if (isChildNull(row.similar)) {
      return;
    }

    let { failureModeID } = row;

    setFailureModeId(failureModeID);
    setIsModalOpen(true);
  };

  const schema = [
    {
      label: "Failure Mode",
      accessor: "name",
      dataType: DataType.STRING,
    },
    {
      label: "Risk Group",
      accessor: "riskGroup",
      dataType: DataType.JSX,
      description: riskGroupDescription,
    },
    {
      label: "Risk Status",
      accessor: "riskStatus",
      dataType: DataType.STRING,
      description: riskStatusDescription,
    },
    {
      label: "Repair Status",
      accessor: "repairStatus",
      dataType: DataType.STRING,
      description: repairStatusDescription,
    },
    {
      label: "Repair Details",
      accessor: "repairDetails",
      dataType: DataType.STRING,
    },
    {
      label: "Warranty Active",
      accessor: "warrantyActive",
      dataType: DataType.STRING,
      description: warrantyActiveDescription,
    },
    {
      label: "Risk Multiple",
      accessor: "riskMultiple",
      dataType: DataType.NUMBER,
      description: riskMultipleDescription,
    },
    failureModesV0?.similarVehicles && {
      label: "Similar Failed Vehicles",
      accessor: "similar",
      dataType: DataType.JSX,
      onCellClick: onSimilarVehiclesClick,
    },
  ].filter(Boolean) as SchemaEntry[];

  const {
    data: failureModes,
    isLoading: failureModesLoading,
    error: failureModesError,
  } = useFailureModes({
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER)
    ),
  });

  const {
    data,
    isLoading: predictionsIsLoading,
    error: predictionsError,
  } = useFailureModePredictions({
    filter: getFiltersQuery(undefined, staticFilters),
  });

  const { data: similarVINS } = useListSimilarVehicles({
    filter: getFiltersQuery(undefined, staticFiltersSimilarVINS),
  });

  const error = predictionsError || failureModesError;
  const isLoading = predictionsIsLoading || failureModesLoading;

  const formatRow = useCallback(
    (vehicleFailureMode: FailureModePrediction) => {
      const failureMode = failureModes?.find(
        (fm) => fm.ID === vehicleFailureMode.failureModeID
      );

      const similarVINSView =
        similarVINS &&
        similarVINS.find(
          ({ failureModeID }) =>
            failureModeID === vehicleFailureMode.failureModeID
        ) &&
        "View";

      return {
        ...vehicleFailureMode,
        name: failureMode?.name ?? "",
        riskGroup: <RiskBadge risk={vehicleFailureMode.riskGroup} />,
        status: <StatusBadge status={failureMode?.status ?? ""} />,
        warrantyActive:
          vehicleFailureMode.warrantyActive !== null
            ? toTitleCase(String(vehicleFailureMode.warrantyActive))
            : "",
        similar: similarVINSView,
      };
    },
    [failureModes, similarVINS]
  );

  const removeInactiveFailureModePredictions = useCallback(
    ({ failureModeID }: FailureModePrediction) =>
      failureModes?.find(({ ID }) => failureModeID === ID),
    [failureModes]
  );

  // when data changes, update selectedProfile
  useEffect(() => {
    if (!data || error) {
      setSelectedProfile(undefined);
    } else {
      setSelectedProfile(data[0]);
    }
  }, [data, error]);

  const formattedData = useMemo(
    () => data?.filter(removeInactiveFailureModePredictions).map(formatRow),
    [data, formatRow, removeInactiveFailureModePredictions]
  );

  return (
    <div className="py-4">
      <div className="mb-10">
        {(!isLoading && error && (
          <div className="py-8 text-lg">
            {/* TODO: Add APIError component here, but keep this in mind: we intentionally dont display APIError here right now since: if error, not eligible immediately 
          This makes it annoying to distinguish between actually API down state and expected 404 response*/}
            <strong>{vin}</strong> is not eligible for existing failure modes
          </div>
        )) || <h3 className="text-xl mb-4">Failure Mode Profiles</h3>}
        {!error && formattedData && formattedData.length > 0 && (
          <Table
            data={formattedData}
            schema={schema}
            isLoading={isLoading}
            onRowSelect={
              failureModesV0?.survivalCurves ? setSelectedProfile : undefined
            }
          />
        )}
        {!selectedProfile && (
          <div className="text-xs text-gray-400">
            No active failure modes available for this vehicle.
          </div>
        )}
      </div>
      {failureModesV0?.survivalCurves && selectedProfile?.failureModeID && (
        <SurvivalCurveV0 failureModeID={selectedProfile.failureModeID} />
      )}
      {failureModeID && (
        <SimilarityModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          vin={vin}
          failureModeID={failureModeID}
        />
      )}
    </div>
  );
};

export default V0_HealthTab;
