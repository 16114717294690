export const FILTER_PROMPT_CLAUSE = `## Filter String Parameter Logic
Viaduct's API allows filtering using the \`filter\` query parameter, formatted as:  
\`$field=$operation:$value\`.

### **Supported Operations:**
- **Comparisons:** \`gt\`, \`gte\`, \`lt\`, \`lte\`  
- **Set Membership:** \`in\`, \`nin\` (values separated by \`|\`)  
- **Null Checks:** \`is\`, \`isn\`  
- **Substring Match:** \`like\`, \`nlike\`
- **Prefix Match:** \`startswith\`, \`nstartswith\` (values separated by \`|\`)  
- **Recent Date Range:** \`inLast\` (e.g., \`inLast:3d\`)

> **Important:** Use only **one operation** per \`$field\` when constructing the filter string.

### **Combining Filters:**
- **AND:** Use a comma \`,\`  
- **OR:** Use \`||\`  
- **Grouping:** Use parentheses for nested expressions  

### **Special Considerations:**
- For values containing \`,\` or \`|\`, enclose the value in double quotes:  
  Example: \`$field=$operation:"$value"\`
- If a filter attribute supports \`distinctValues\` and the user requests a value that does not exist, inform the user that there are no matches for the given value.

### **Examples:**
- **Field is not null:** \`vehicleStartedDrivingAt=isn:null\`  
- **Exact match:** \`VIN=in:ABC\`  
- **Contains:** \`vehicleMake=like:Toyota\`
- **Starts with multiple values:** \`signalEventID=startswith:"u"|"p"\`  
- **AND combination:** \`VIN=in:ABC,mileage=gt:100\`  
- **OR combination:** \`VIN=in:ABC||mileage=gt:100\`  
- **Nested expressions:** \`VIN=in:ABC||(mileage=gt:100,monthsInService=gt:50)\``;

export const SORT_PROMPT_CLAUSE = `## Sort String Parameter Logic
Viaduct's API supports sorting via the \`sort\` query parameter, formatted as:  
\`[$field]=$order\`

### **Parameters:**
- **\`$field\`:** The attribute to sort by (e.g., mileage, vehicleModelYear).  
- **\`$order\`:** The sort order, which can be:
  - \`asc\`: Ascending
  - \`desc\`: Descending

### **Guidelines:**
- The sort parameter is **optional** and should only be specified if there is a valid reason to apply sorting.
- The attributes indicate whether they support sorting.

### **Examples:**
- **Sort by mileage in descending order:**  
  \`[mileage]=desc\`
- **Sort by vehicle model year in ascending order:**  
  \`[vehicleModelYear]=asc\``;

export const PAGE_AGENT_WORKFLOW_CLAUSE = `## Workflow Details:
1. **Plan and Identify Steps**:
   - Carefully analyze the user’s request and determine the **required steps** to fulfill it.  
   - If the task requires additional context or information, identify which tools are necessary to retrieve that information. 
   - Always **prioritize retrieving context** (via tool calls) before taking actions. 
2. **Gather Context in Parallel**:
    - Execute **all necessary tool calls** to retrieve relevant information **at the same time**.  
3. **Execute Actions in Parallel**:
   - Once all required information is available, proceed to **execute all actions simultaneously** to fulfill the user’s request.`;

export const PAGE_AGENT_RESPONSE_PRELUDE = "To get you what you want, I would:";
