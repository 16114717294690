import { VIN_VIEW_EVENTS_TIMELINE_TAB_KEY } from "pages/VINView/constants";

const responses = [
  [
    `Most likely diagnosis: **Faulty brake switch**
- This repair is 11 times more likely to occur after DTCs C1026 AND U0420 than other repairs
- 303 vehicles experienced this DTC pattern
- 261 of these have this repair within 15 days`,

    "Here are the top 3 most likely diagnoses based on the information you provided, and the evidence that exists to support them:",

    `| Potential diagnosis | Top labor code | Supporting symptoms present in this VIN | Common symptoms in vehicles with this diagnosis | Resolution |
| -------------------- | -------------- | --------------------- | --------------- | ---------- |
| Faulty Brake Switch | AHD9G1EI: BR - STOP LAMP SW | <ul><li>C1026: HVAC circuit voltage below threshold</li><li>U0420: Stop lamp signal plausibility error</li></ul> | <ul><li>Engine not starting</li><li>Cruise control disruption</li><li>C1026</li><li>U0420</li></ul> | <ol><li>Replace brake switch</li><li>Reset codes</li></ol> |
| Bad battery | E9B3AD4B: Battery | <ul><li>C1026: HVAC circuit voltage below threshold</li><li>Voltage drop</li></ul> | <ul><li>Engine cranks but does not start.</li><li>C1026</li><li>Voltage drop</li></ul> | <ol><li>Replace battery</li><li>Reset codes</li></ol> |
| Clogged fuel filter | E10FGRDB: Fuel Filter | <ul><li>Fuel efficiency drop >10%</li></ul> | <ul><li>Engine cranks but does not start</li><li>Stalling or sputtering</li><li>P0087</li><li>Fuel efficiency drop</li></ul> | <ol><li>Replace fuel filter</li><li>Reset codes</li></ol> |`,

    `This suggestion is based off of most common repairs for vehicles that share similarities, such as make, model, model year, mileage, engine hours, vocation, and general usage that also experienced similar DTCs as VIN VDTOUUBQ4ML329875 within 15 days of the repair.`,
  ],

  [
    "Here is a list of similar vehicles that experienced similar DTC patterns as VIN VDTOUUBQ4ML329875, and went on to have a AHD9G1EI: BR - STOP LAMP SW repair within 15 days of the DTCs",

    `| VIN | Number of related repairs | Latest Occurrence |
| --- | ------------------------- | ----------------- |
| [VDT13ATJ8OP243586](https://demo.cloud.viaduct.ai/vehicles/VDT13ATJ8OP243586?tab=${VIN_VIEW_EVENTS_TIMELINE_TAB_KEY}) | 1 | 09/17/2021 |
| [VDTYDZVU9GN509814](https://demo.cloud.viaduct.ai/vehicles/VDTYDZVU9GN509814?tab=${VIN_VIEW_EVENTS_TIMELINE_TAB_KEY}) | 1 | 08/17/2021 |
| [VDTYRZFF0GX796535](https://demo.cloud.viaduct.ai/vehicles/VDTYRZFF0GX796535?tab=${VIN_VIEW_EVENTS_TIMELINE_TAB_KEY}) | 1 | 04/27/2021 |`,

    "I have queued a navigation to the first vehicle in the list, VIN VDT13ATJ8OP243586.",
  ],

  [
    `VIN VDT13ATJ8OP243586 is similar to VIN [VDTOUUBQ4ML329875](https://demo.cloud.viaduct.ai/vehicles/VDTOUUBQ4ML329875?tab=${VIN_VIEW_EVENTS_TIMELINE_TAB_KEY}) because they share the same make and model, same vocation, had similar occurrences of DTCs C1026 and U0420, and also included a repair of the brake switch in which the driver complained about the cruise control.`,
  ],
];

export default responses;
