import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import {
  Bookmark,
  GeneralConfigModel,
  MetabaseDashboard,
  updateGeneralConfig,
} from "shared/api/admin/api";
import { useGeneralConfig } from "shared/api/admin/hooks";
import { formatAPIDate } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import Button from "features/ui/Button";
import Checkbox from "features/ui/Checkbox";
import DatePicker from "features/ui/DatePicker";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Select from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell";
import TextArea from "features/ui/TextArea";
import Title from "features/ui/Title";

const TITLE = "General Config";

const defaultConfig: GeneralConfigModel = {
  mileageUnit: "km",
  maxDate: "",
  globalSearch: true,
  flagUserAnon: true,
  helpContactEmail: "",
  rootPage: "",
  bookmarks: [],
  metabaseDashboards: [],
};

const mileageUnitOptions = [
  { id: "km" as MileageUnit, value: "Kilometers" },
  { id: "mi" as MileageUnit, value: "Miles" },
];

const GeneralConfig = () => {
  const bookmarkExample: Bookmark[] = [
    {
      title: "bookmark",
      path: 'vehicles?filters_v1.vehicles=mileage%3Dgt%3A"2"&sort_v1.vehicles%5Bmileage%5D=desc&chartSettings_v1.vehicles=&relatedSignalEventsFilter_v1.vehicles=&filters_v1.vehicles_table=&sort_v1.vehicles_table%5Bmileage%5D=desc&chartSettings_v1.vehicles_table=&relatedSignalEventsFilter_v1.vehicles_table=',
      description: "bookmark description",
    },
  ];
  const metabaseDashboardExample: MetabaseDashboard[] = [
    { name: "dashboard", id: "1" },
  ];

  const [generalConfigState, setGeneralConfigState] =
    useState<GeneralConfigModel>(defaultConfig);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, isLoading, error } = useGeneralConfig({});

  const onDataUpdated = (update: Partial<GeneralConfigModel>) => {
    setGeneralConfigState({ ...generalConfigState, ...update });
  };

  const [metabaseDashboardString, setMetabaseDashboardString] =
    useState<string>("");
  const [dashboardsValid, setDashboardsValid] = useState(true);
  const [bookmarksString, setBookmarksString] = useState<string>("");
  const [bookmarksValid, setBookmarksValid] = useState(true);

  const setBookmarks = (value: string) => {
    try {
      const bookmarks = JSON.parse(value);
      onDataUpdated({ bookmarks });
      setBookmarksValid(true);
    } catch (e) {
      setBookmarksValid(false);
    }

    setBookmarksString(value);
  };

  const setMetabaseDashboards = (value: string) => {
    try {
      const metabaseDashboards = JSON.parse(value);
      onDataUpdated({ metabaseDashboards });
      setDashboardsValid(true);
    } catch (e) {
      setDashboardsValid(false);
    }

    setMetabaseDashboardString(value);
  };

  useEffect(() => {
    if (data && !isLoading && !error) {
      setGeneralConfigState(data);
      setMetabaseDashboardString(JSON.stringify(data.metabaseDashboards || []));
      setBookmarksString(JSON.stringify(data.bookmarks || []));
    }
  }, [data, isLoading, error]);

  const handleSave = async () => {
    setIsSubmitting(true);

    updateGeneralConfig({ config: generalConfigState })
      .then(() => {
        toast.success("General configuration saved successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const ctaEnabled = dashboardsValid && bookmarksValid && !isSubmitting;

  return (
    <>
      <PageHeaderWrapper>
        <Title text={TITLE} />
      </PageHeaderWrapper>
      {error && <p className="text-red-500">Failed to load configuration.</p>}
      {isLoading && <Skeleton height={100} />}
      {!isLoading && !error && (
        <div className="mt-4 max-w-4xl">
          <div className="pb-3 max-w-48">
            <Select
              label="Mileage Unit"
              options={mileageUnitOptions}
              selected={
                mileageUnitOptions.find(
                  ({ id }) => id === generalConfigState.mileageUnit
                ) || undefined
              }
              onSelect={(selectedOption) =>
                onDataUpdated({ mileageUnit: selectedOption.id as MileageUnit })
              }
            />
          </div>
          <DatePicker
            label="Max Date"
            initialDate={
              generalConfigState.maxDate
                ? new Date(generalConfigState.maxDate)
                : null
            }
            key={(generalConfigState.maxDate !== "").toString()}
            onChange={(date: Date | null) => {
              if (date) {
                onDataUpdated({
                  maxDate: formatAPIDate(date.toString(), DataType.DATE),
                });
              }
            }}
          />
          <div className="mt-3">
            <div className="text-gray-400 text-xs mb-1">
              If global search is enabled, search will be done on all resources,
              not just VIN
            </div>
            <Checkbox
              label="Enable Global Search"
              checked={generalConfigState.globalSearch}
              onChange={(checked) => onDataUpdated({ globalSearch: checked })}
              testId="global-search-checkbox"
              dense
            />
          </div>
          <div className="mt-3">
            <div className="text-gray-400 text-xs mb-1">
              If flag user anonymization is enabled, user email will be
              anonymized when sent to LaunchDarkly
            </div>
            <Checkbox
              label="Enable Flag User Anonymization"
              checked={generalConfigState.flagUserAnon}
              onChange={(checked) => onDataUpdated({ flagUserAnon: checked })}
              testId="flag-user-anon-checkbox"
              dense
            />
          </div>
          <div className="mt-6">
            <Input
              label="Help Contact Email"
              value={generalConfigState.helpContactEmail || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ helpContactEmail: value })
              }
            />
          </div>
          <div className="mt-3">
            <Input
              label="Root Page"
              value={generalConfigState.rootPage || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ rootPage: value })
              }
            />
          </div>
          <div className="mt-4 md:flex space-x-2 justify-between">
            <TextArea
              label="Bookmarks"
              value={bookmarksString}
              onChange={(e) => setBookmarks(e.target.value)}
              rows={12}
              error={!bookmarksValid}
            />
            <div className="md:w-1/2 text-xs text-gray-400 mb-2">
              JSON bookmark example:
              <pre className="text-xs bg-gray-100 mt-2 p-2 text-viaduct-black whitespace-pre-wrap overflow-y-auto break-all">
                {JSON.stringify(bookmarkExample, null, 2)}
              </pre>
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-between">
            <TextArea
              label="Metabase Dashboards"
              value={metabaseDashboardString}
              onChange={(e) => setMetabaseDashboards(e.target.value)}
              rows={12}
              error={!dashboardsValid}
            />
            <div className="w-1/2 text-xs text-gray-400 mb-2">
              JSON metabase dashboard example:
              <pre className=" text-xs bg-gray-100 mt-2 p-2 text-viaduct-black whitespace-pre-wrap overflow-y-auto break-all">
                {JSON.stringify(metabaseDashboardExample, null, 2)}
              </pre>
            </div>
          </div>
          <div className="mt-4 max-w-4xl">
            <div className="flex justify-end mt-4">
              <Button
                label="Save"
                color="primary"
                variant="contained"
                testId="save-pages-config"
                onClick={handleSave}
                disabled={!ctaEnabled}
                isLoading={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralConfig;
