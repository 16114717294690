import qs from "qs";

import { useListClaimsCount } from "shared/api/claims/hooks";
import { EventTypeEnum } from "shared/types";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { getFiltersKey } from "features/ui/Filters/utils";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

interface Props {
  filters: FilterGroupState;
  vehicleFilters: FilterGroupState;
  label?: string;
  clickable?: boolean;
}

const ClaimCount = ({ filters, vehicleFilters, label, clickable }: Props) => {
  const filterQuery = getFiltersQuery(filters);
  const vehiclesFilterQuery = getFiltersQuery(vehicleFilters);
  const { isLoading, data, error } = useListClaimsCount({
    filter: filterQuery,
    vehiclesFilter: vehiclesFilterQuery,
    analytics: true,
  });

  return (
    <TableCount
      prefix={label ? `${label}: ` : undefined}
      isLoading={isLoading}
      count={data?.count}
      entityName={EventTypeEnum.CLAIM}
      error={Boolean(error)}
      {...(clickable && {
        extraClasses: "font-normal mb-0.5",
        link: {
          pathname: routes.claimAnalytics,
          search: qs.stringify({
            [getFiltersKey(CLAIMS_PAGE_KEY)]: filterQuery,
            [getFiltersKey(VEHICLES_PAGE_KEY)]: vehiclesFilterQuery,
          }),
        },
      })}
    />
  );
};
export default ClaimCount;
