import React from "react";

import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import AssociatedSignalEventsOccursFilter from "features/ui/Filters/FilterTypes/OccursFilter/AssociatedSignalEventsOccursFilter";
import { useAssociatedSignalEventsOccursFilter } from "features/ui/Filters/FilterTypes/OccursFilter/hooks";
import OccursFilterActions from "features/ui/Filters/FilterTypes/OccursFilter/OccursFilterActions";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";

import AssociatedSignalEventsTable from "./AssociatedSignalEventsTable";
import { SE_ASSOCIATED_SE_PAGE_KEY } from "./constants";

const PENDING_FILTERS_ASSOCIATED_SE_LS_KEY = getPendingFiltersKey(
  SE_ASSOCIATED_SE_PAGE_KEY
);
const OCCURS_FILTER_KEY =
  "signal-event-analytics-associated-se-relates-filter-v2";

const AssociatedSignalEvents = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsAnalyticsTabsProps) => {
  const {
    occursFilter,
    setOccursFilter,
    appliedOccursFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  } = useAssociatedSignalEventsOccursFilter(
    SE_ASSOCIATED_SE_PAGE_KEY,
    PENDING_FILTERS_ASSOCIATED_SE_LS_KEY,
    OCCURS_FILTER_KEY
  );

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="flex flex-wrap leading-10 space-x-2 items-end mb-6">
          <AssociatedSignalEventsOccursFilter
            occursFilter={occursFilter}
            setOccursFilter={setOccursFilter}
            filterSortState={associatedSignalEventsFilterSortState}
            pendingFiltersKey={PENDING_FILTERS_ASSOCIATED_SE_LS_KEY}
            baseEntityText="Base Signal Event"
          />
          <OccursFilterActions
            occursState={occursFilter}
            appliedOccursFilter={appliedOccursFilter}
            onApply={onApply}
            onCancel={onCancel}
          />
        </div>
        <AssociatedSignalEventsTable
          onBadRequest={onBadRequest}
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          occursFilter={appliedOccursFilter}
        />
      </div>
    </>
  );
};

export default AssociatedSignalEvents;
