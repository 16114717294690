import classNames from "classnames";

interface TypeBadgeProps {
  type: string;
}

const colorMap: Record<string, string> = {
  Decisiv: "text-blue-400",
  Claim: "text-green-500",
  Inspection: "text-red-500",
  TCS: "text-orange-500",
  "Customer Pay": "text-orange-500",
  Support: "text-orange-500",
  Internal: "text-blue-400",
  "Software Update": "text-orange-500",
  OTA: "text-blue-400",
  "Part Return": "text-red-500",
};

const TypeBadge = ({ type }: TypeBadgeProps) => {
  const colorClass = (type && colorMap[type]) || "default";

  return (
    <span className={classNames(colorClass, "font-semibold")}>{type}</span>
  );
};

export default TypeBadge;
