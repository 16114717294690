import { EventTypeEnum } from "shared/types";

import { SelectOption } from "features/ui/Select";

import { useClaimsSchema } from "./claimsSchema";
import { useCustomRecordsSchema } from "./customRecordsSchema";
import { useInspectionsSchema } from "./inspectionsSchema";
import { useRepairsSchema } from "./repairsSchema";
import useSensorReadingsSchema from "./sensorReadingsSchema";
import useSignalEventOccurrencesSchema from "./signalEventOccurrencesSchema";

export const TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR = "groupByAttributeValue";
export const CLAIM_DEALER_ACCESSOR = "dealer";
export const VEHICLE_ACCESSOR = "vehicle";
export const VEHICLE_LAST_KNOWN_DEALER_ACCESSOR = "lastKnownDealer";

export const USE_RESOURCE_SCHEMA_MAP: Record<
  EventTypeEnum,
  (accessorsToHide?: string[]) => ReturnType<typeof useInspectionsSchema>
> = {
  [EventTypeEnum.CLAIM]: useClaimsSchema,
  [EventTypeEnum.REPAIR]: useRepairsSchema,
  [EventTypeEnum.INSPECTION]: useInspectionsSchema,
  [EventTypeEnum.SIGNAL_EVENT]: useSignalEventOccurrencesSchema,
  [EventTypeEnum.SENSOR_READING]: useSensorReadingsSchema,
  [EventTypeEnum.CUSTOM_RECORD]: useCustomRecordsSchema,
};

// m2m relations always have this (ECUs, Options)
export const MANY_TO_MANY_PRESENT_SELECT_OPTION: SelectOption = {
  id: "present",
  value: "Present",
  label: "Present",
};

export const VALUES_ENDPOINT_LIMIT_DEFAULT = 1000;
