import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";

import { useIssuesFiltersSchema } from "shared/hooks";

import { IssuesConfig, PageConfigProps } from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

import { DEFAULT_FILTERS_KEY } from "./ClaimAnalyticsConfigPage";
import ClaimAttributesMultiSelect from "./ClaimAttributesMultiSelect";

const SUGGESTED_ISSUES_KEY = "suggestedIssues";
const ISSUES_PAGE_KEY = "admin_default_issues_filters";
const ISSUES_FILTER_LABEL = "Default Issue Filters";
const TOP_X_CHARTS_OPTIONS_KEY = "topXChartsOptions";

const IssuesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<IssuesConfig>) => {
  const issuesFiltersSchema = useIssuesFiltersSchema();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const initialFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const defaultFilters = filterStateToFilterGroupState(initialFilters);

  const filterState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: defaultFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleFiltersChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  return (
    <>
      <div className="flex space-x-3 mb-4">
        <FormControlLabel
          control={
            <Switch
              checked={config[SUGGESTED_ISSUES_KEY] ?? false}
              onChange={(_, checked) =>
                onConfigChange(pageId, SUGGESTED_ISSUES_KEY, checked)
              }
              size="small"
            />
          }
          label={<div className="text-xs">Show Suggested Issues</div>}
        />
        <DropdownSelect
          testId="default-issues-filters-dropdown"
          label={getFilterLabel(ISSUES_FILTER_LABEL, filterState.filters)}
          open={dropdownOpen}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={issuesFiltersSchema}
              filterSortState={filterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(ISSUES_PAGE_KEY)}
              onApplyFilters={handleFiltersChange}
              testId="issues-filters-selector"
            />
          }
        />
      </div>
      <ClaimAttributesMultiSelect
        selectedAttributes={config[TOP_X_CHARTS_OPTIONS_KEY]}
        onAttributesChange={(attributes) =>
          onConfigChange(pageId, TOP_X_CHARTS_OPTIONS_KEY, attributes)
        }
      />
    </>
  );
};

export default IssuesConfigPage;
