import { DuckMessage } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import DuckMarkdownMessage from "duck/ui/DuckMarkdownMessage";
import { DuckMessageProps } from "duck/ui/DuckMessage";
import { SchemaUsage, useSchema } from "duck/ui/hooks";

import { SchemaEntry } from "features/ui/Table";

const getSortText = (
  sortString: string,
  source: string,
  schema: SchemaEntry[]
): string => {
  if (sortString === "") {
    return `- Reset the sort order of ${source}`;
  }

  const [sortKeySuffix, sortOrder] = sortString.split("=");

  const nakedSortKeySuffix = sortKeySuffix
    .replaceAll("[", "")
    .replaceAll("]", "");

  const schemaEntry = schema.find(
    (schemaEntry) => schemaEntry.accessor === nakedSortKeySuffix
  );

  const fieldName = schemaEntry?.label ?? nakedSortKeySuffix;

  return `- Sort the ${source} by ${fieldName} in ${sortOrder}ending order`;
};

/**
 * @param props.message.message The message property of the parameter DuckMessage
 * is the filter string to apply to the source.
 * @param props.message.options.sortOptions.source The data type to apply the
 * filter to, i.e. claims, vehicles, etc.
 */
const DuckSortMessage = ({
  message: { message: sortString, options },
}: DuckMessageProps) => {
  const source = options?.sortOptions?.source;

  const selectedSchema = useSchema(source, SchemaUsage.SORT);

  if (selectedSchema.length === 0) {
    console.error(
      `Invalid source in message options.filterOptions: "${JSON.stringify(options)}"`
    );
  }

  const sortMessage: DuckMessage = {
    author: DuckMessageAuthor.AGENT,
    message: getSortText(sortString, source, selectedSchema),
    format: DuckMessageFormat.TEXT,
  };

  return <DuckMarkdownMessage message={sortMessage} />;
};

export default DuckSortMessage;
