import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineLine,
} from "react-icons/ai";

import { formatPercent } from "shared/utils";

const PercentageTrend = ({ trend }: { trend: number }) => {
  let Arrow = AiOutlineLine;
  let color = "black";
  if (trend > 0) {
    Arrow = AiOutlineArrowUp;
    color = "red";
  } else if (trend < 0) {
    Arrow = AiOutlineArrowDown;
    color = "green";
  }

  return (
    <span className="flex items-center gap-x-2 inline-flex">
      <Arrow style={{ color, display: "inline" }} />{" "}
      {formatPercent(Math.abs(trend))}
    </span>
  );
};

export default PercentageTrend;
