import { useEffect } from "react";

import {
  AXIS_TEXT_COLOR,
  AXIS_TOOLTIP_FONT_SIZE,
} from "features/ui/charts/constants";
import { wrapText } from "features/ui/charts/utils";

interface CustomXAxisTickProps {
  x?: number;
  y?: number;
  payload?: { value: string };
  maxCharacters: number;
  nBars: number;
  fontSize?: number;
  setShowAllLabels: (showAllLabels: boolean) => void;
}

const minCharsPerLabel = 6;

// maxCharacters parameter should contain the maximum length of the label if there was only 1 line
// if you want the xAxis labels to always be displayed you have to set "interval" to 0 in xAxisProps
const BarChartCustomTick = ({
  x,
  y,
  payload,
  maxCharacters,
  nBars,
  fontSize,
  setShowAllLabels,
}: CustomXAxisTickProps) => {
  const charsPerLabel = Math.floor(maxCharacters / nBars);
  const shouldDisplayAllLabels = charsPerLabel >= minCharsPerLabel;

  useEffect(() => {
    setShowAllLabels(shouldDisplayAllLabels);
  }, [setShowAllLabels, shouldDisplayAllLabels]);

  if (!payload || !x || !y) return null;

  // if there is not enough space for the labels, we don't show all of them
  if (!shouldDisplayAllLabels) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          textAnchor="middle"
          fill={AXIS_TEXT_COLOR}
          fontSize={fontSize ?? AXIS_TOOLTIP_FONT_SIZE}
        >
          {payload.value}
        </text>
      </g>
    );
  }

  const maxLineLength = Math.max(charsPerLabel, minCharsPerLabel);
  const lines = wrapText(payload.value, maxLineLength);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={10}
        textAnchor="middle"
        fill={AXIS_TEXT_COLOR}
        fontSize={fontSize ?? AXIS_TOOLTIP_FONT_SIZE}
      >
        <title>{payload.value}</title>
        {lines.map((line, index) => (
          <tspan key={index} x="0" dy={index === 0 ? 0 : "1.2em"}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
};

export default BarChartCustomTick;
