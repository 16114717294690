import { DuckMessage } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor } from "duck/context/types";

export const DUCK_VISIBILITY_KEY = "duck_visibility";
export const LANGCHAIN_THREAD_ID_KEY = "langchain_thread_id";

export const DUCK_DRAG_HANDLE_CLASS_NAME = "floating-duck-drag-handle";
export const DUCK_POPPED_INITIAL_HEIGHT = 400;
export const DUCK_POPPED_INITIAL_WIDTH = 400;
export const DUCK_POPPED_INITIAL_X = 270;
export const DUCK_MESSAGES_KEY = "duck_messages";
export const DUCK_WELCOME_MESSAGE: DuckMessage = {
  author: DuckMessageAuthor.AGENT,
  message:
    "Quack! I can help you navigate the application. What would you like to do?",
};
export const DUCK_ACTION_PERFORMED_MESSAGE: DuckMessage = {
  author: DuckMessageAuthor.AGENT,
  message: "Done",
};
export const DUCK_ACTION_DISCARDED_MESSAGE: DuckMessage = {
  author: DuckMessageAuthor.AGENT,
  message: "Action skipped",
};
export const DUCK_DO_IT_FOR_ME = "Do it for me";
export const DUCK_GENERIC_ERROR_MESSAGE =
  "Sorry, I'm having trouble understanding your request. Please try again.";

export const DUCK_PENDING_ACTION_KEY = "duck_pending_action";
export const DUCK_RELOAD_REQUIRED_KEY = "duck_reload_required";
export const DUCK_UPDATED_QUERY_PARAMS_KEY = "duck_updated_query_params";
export const DUCK_ROUTE_VALUE_KEY = "duck_route_value";
export const DUCK_OMIT_EXISTING_QUERY_PARAM_KEY =
  "duck_omit_existing_query_param";
export const DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY =
  "duck_omit_existing_query_params_starting_with";
export const DUCK_PREVIOUS_RUN_ID_KEY = "duck_previous_run_id";

export const DUCK_FLOATING_KEY = "duck_floating";
export const DUCK_FLOATING_POSITION_KEY = "duck_floating_position";
export const DUCK_FLOATING_SIZE_KEY = "duck_floating_size";
