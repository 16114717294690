import { TestProps } from "shared/types";

import Checkbox from "features/ui/Checkbox";

interface Props extends TestProps {
  rowKey: string;
  setFunction: (events: Set<string>) => void;
  values: Set<string>;
  limit?: number;
}

const ToggleShownCheckbox = ({
  rowKey,
  setFunction,
  values,
  limit,
  testId,
}: Props) => {
  const handleOnChange = (checked: boolean) => {
    const newSet = new Set(values);
    if (checked) {
      newSet.add(rowKey);
    } else {
      newSet.delete(rowKey);
    }

    setFunction(newSet);
  };

  const disabled =
    limit !== undefined && values.size >= limit && !values.has(rowKey);

  return (
    <Checkbox
      disabled={disabled}
      checked={values.has(rowKey)}
      onChange={handleOnChange}
      testId={testId}
      dense
    />
  );
};

export default ToggleShownCheckbox;
