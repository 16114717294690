import { TestProps } from "shared/types";

import Button from "features/ui/Button";

interface Props extends TestProps {
  onResetToDefault?: (accessors: string[]) => void;
  fieldName?: string;
  disabled?: boolean;
}

const ResetToDefaultButton = ({
  testId,
  onResetToDefault,
  fieldName,
  disabled,
}: Props) => {
  const handleOnClick = () => {
    onResetToDefault && onResetToDefault([fieldName || ""].filter(Boolean));
  };

  const text = `Reset ${fieldName ? "" : "all"} to default`;

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleOnClick}
      testId={testId}
      size="small"
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ResetToDefaultButton;
