import React from "react";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import {
  OccursFilterWindowDirectionType,
  UseFilterSortState,
} from "features/ui/Filters/types";

import OccursFilter from "./OccursFilter";

interface OccursFilterFormProps {
  occursFilter: string;
  setOccursFilter: (value: string) => void;
  filterSortState: UseFilterSortState;
  pendingFiltersKey: string;
  baseEntityText: string;
  windowDirectionOptions?: DropdownSelectOption<OccursFilterWindowDirectionType>[];
}

const AssociatedSignalEventsOccursFilter = ({
  occursFilter,
  setOccursFilter,
  baseEntityText,
  windowDirectionOptions,
}: OccursFilterFormProps) => {
  const filterTitle = "Associated Signal Event defined by";

  return (
    <OccursFilter
      filterTitle={filterTitle}
      baseEntityText={baseEntityText}
      currentFilterValues={[occursFilter]}
      selectorTestId="associated-signal-event-filters"
      inFilterSelector={false}
      onChange={(values) => {
        values && values.length && setOccursFilter(values[0]);
      }}
      windowDirectionOptions={windowDirectionOptions}
      label=""
      fieldName="relatedSignalEventOccurrences"
    />
  );
};

export default AssociatedSignalEventsOccursFilter;
