export const replaceUrlParams = (urlTemplate: string, key: string) =>
  urlTemplate.replace(/:([a-zA-Z]+)/, key);

// markMatchingText takes a string and a search term and returns the string with the search term bolded.
export const markMatchingText = (t: string, searchTerm: string): string => {
  if (searchTerm.length === 0 || !t) {
    return t;
  }

  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const reg = new RegExp(escapedSearchTerm, "gi"); // Case-insensitive search

  const matches = t.matchAll(reg);

  if (Array.from(matches).length > 0) {
    return t.replace(
      reg,
      (matched) => `<span style="font-weight:bold">${matched}</span>`
    ); // Preserve original case
  }

  const newTerm = searchTerm.slice(0, -1);

  return markMatchingText(t, newTerm);
};
