import { DuckMessageProps } from "duck/ui/DuckMessage";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

/**
 * This component is used to render text messages from the agent.
 *
 * The Markdown component by itself recognizes lists but does not properly render them.
 * It omits the discs and numbers from the lists. The `components` parameter sent to
 * the Markdown component allows us to override the default rendering of lists to make
 * them look correct. It is likely that the CSS of the project prevents the standard
 * rendering of lists from working as expected in markdown.
 */
const DuckMarkdownMessage = ({ message: { message } }: DuckMessageProps) => (
  <div className="w-full flex justify-start">
    <div className="p-1 text-left">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{
          ul: ({ node, ...props }) => (
            <ul
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
              {...props}
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              style={{ listStyleType: "decimal", paddingLeft: "20px" }}
              {...props}
            />
          ),
          a: ({ node, ...props }) => (
            // The ReactMarkdown library injects content into the anchor tag.
            // It is not a problem that there is no content at this point in the process.
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              // It strikes me as odd that we are using a color named "metabase-blue" here
              // but it is widely used throughout the codebase. It appears to be our defacto
              // standard blue color for links.
              className="text-metabase-blue hover:underline"
              {...props}
              target="_blank"
            />
          ),
          th: ({ node, ...props }) => (
            <th className="border border-gray-500 px-2 py-1" {...props} />
          ),
          td: ({ node, ...props }) => (
            <td className="border border-gray-500 px-2 py-1" {...props} />
          ),
        }}
      >
        {message}
      </ReactMarkdown>
    </div>
  </div>
);

export default DuckMarkdownMessage;
