import { increaseOneMonth } from "shared/utils";

import {
  FailureModeCampaignEventsRollingGraphProps,
  GraphDataBucket,
} from "./FailureModeEventGraph";

// We convert from group label, which is in "YYYY-mm" format to timestamp, so graph can ingest it.
// As we want to show also the months without any failures/repairs, we need to manually fill the gaps in the data.
export const formatDataForRollingGraph = ({
  data,
}: FailureModeCampaignEventsRollingGraphProps): GraphDataBucket[] => {
  if (data.length === 0) {
    return [];
  }

  const preparedData = [];
  const firstGroup = data[0].group;
  const lastGroup = data[data.length - 1].group;
  for (let i = firstGroup; i <= lastGroup; i = increaseOneMonth(i)) {
    const entry = data.find((entry) => entry.group === i);
    // we do middle of the month to avoid timezone conversion problems
    const timestamp = new Date(`${i}-15`).getTime();
    preparedData.push({
      ts: timestamp,
      countFailures: entry?.countFailures || 0,
      countRepairs: entry?.countRepairs || 0,
    });
  }

  return preparedData;
};

/* We convert from group label, which is in "YYYY-mm" format to timestamp, so graph can ingest it.
This function processes the same input as formatDataForRollingGraph, but it accumulates over time
example: if January 2022 has count 3, and February 2022 has count 6, January would have accumulated count
3, and February would have count 3 + 6 = 9. As zero values do not affect this graph, we do not need to fill the gaps
like on the rolling graph (helper function above). */
export const formatDataForCumulativeGraph = ({
  data,
}: FailureModeCampaignEventsRollingGraphProps): GraphDataBucket[] => {
  let sumCountFailures = 0;
  let sumCountRepairs = 0;

  return data!.map((entry) => {
    // we do middle of the month to avoid timezone conversion problems
    const timestamp = new Date(`${entry.group}-15`).getTime();
    // we accumulate repairs and failures
    sumCountFailures += entry.countFailures;
    sumCountRepairs += entry.countRepairs;

    return {
      ts: timestamp,
      countFailures: sumCountFailures,
      countRepairs: sumCountRepairs,
    };
  });
};

export const tooltipFormatter = (value: any, name: string) => {
  // we do not want to see connecting dotted line in the tooltip.
  if (name === "connection") {
    return [null, null];
  }

  return [formatValue(value), name];
};

const formatValue = (value: number | number[]) => {
  // confidence interval is in form of [MIN, MAX] and we want to print MIN - MAX
  if (Array.isArray(value)) {
    return value.map(roundToOneDecimal).join(" - ");
  }

  return roundToOneDecimal(value);
};

const roundToOneDecimal = (value: number) => Math.round(value * 10) / 10;
