import { QueryStringNavigation } from "duck/ui/types";

const queryStringNavigationWrapper: {
  current: QueryStringNavigation | undefined;
} = { current: undefined };

/**
 * A component that can use a hook can make the QueryStringNavigation functions easily
 * accessible to functions that could not otherwise easily access them.
 * This allows us to avoid passing them all over the place as parameters.
 */
export const provideQueryStringNavigation = (
  qsNavigation: QueryStringNavigation
) => {
  queryStringNavigationWrapper.current = qsNavigation;
};

/**
 * If a component has made the QueryStringNavigation functions available (see above),
 * then functions that don't have access to hooks can use this function to access them.
 */
export const accessQueryStringNavigationOrThrow = (): QueryStringNavigation => {
  if (!queryStringNavigationWrapper.current) {
    throw new Error("QueryStringNavigation is not available");
  }

  return queryStringNavigationWrapper.current;
};
