import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getFilterGroupStateFromExistsFilterValue } from "features/ui/Filters/FilterTypes/ExistsFilter/utils";
import { decodeOccursFilterAndOptions } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { FilterOperator, FilterState } from "features/ui/Filters/types";

export const getFilterPresenterNumOfLines = (
  filterState?: FilterState | FilterGroupState | FilterRowState,
  count: number = 0
): number => {
  if (!filterState) return count;

  if (
    "type" in filterState &&
    filterState.type === "group" &&
    filterState.children.length === 0
  ) {
    return 0;
  }

  if ("type" in filterState && filterState.type === "group") {
    return (filterState as FilterGroupState).children.reduce(
      (acc, child) => getFilterPresenterNumOfLines(child, acc),
      count + 1
    );
  }

  const filterRowState = filterState as FilterRowState;
  if (
    filterRowState?.values?.length === 1 &&
    [FilterOperator.OCCURS, FilterOperator.NOT_OCCURS].includes(
      filterRowState.operator as FilterOperator
    )
  ) {
    const { filters } = decodeOccursFilterAndOptions(filterRowState.values[0]);

    return getFilterPresenterNumOfLines(
      filterBuilderQueryToFilterBuilderState(filters),
      count + 2
    );
  }

  if (
    filterRowState?.values?.length === 1 &&
    [FilterOperator.EXISTS, FilterOperator.NOT_EXISTS].includes(
      filterRowState.operator as FilterOperator
    )
  ) {
    const filters = getFilterGroupStateFromExistsFilterValue(
      filterRowState.values[0] || ""
    );

    return getFilterPresenterNumOfLines(filters, count + 2);
  }

  return count + 1;
};

export const getMaxNumOfLinesForFilterPresenter = (
  filters: (string | undefined)[]
) => {
  const filterObjects = filters.map((filter) =>
    filterBuilderQueryToFilterBuilderState(filter)
  );
  const numOfLines = filterObjects.map((filter) =>
    getFilterPresenterNumOfLines(filter)
  );

  return Math.max(...numOfLines);
};
