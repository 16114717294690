import { QueryStringNavigation, Reload } from "duck/ui/types";

import { OccursFilterState } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { TabChartSettingsState } from "features/ui/Filters/types";
import {
  getPageKeyWithVersion,
  getQueryKeys,
  getStateFromLocalStorage,
  injectPageChartSettings,
} from "features/ui/Filters/utils";

import { PageHandlerRoute } from "./types";
import { accessQueryStringNavigationOrThrow } from "./utils";

/**
 * PageHandler is a page-agnostic handler for updating page and tab filters and managing tab navigation and chart settings.
 */
export class PageHandler {
  private routeValue?: PageHandlerRoute;
  private queryStringNavigation: QueryStringNavigation;

  /**
   * Constructs a PageHandler.
   * @param routeValue - The route value (urlPathName) of the page for navigation.
   * This value is optional because of routes like `/vehicles/:vin` where we might
   * not know the VIN value until the LLM has provided a VIN to a tool, that is,
   * after the agent has already done some processing.
   * In other words, the PageHandler constructor is called before the agent is
   * created, but the route value may not be known until a bit later.
   */
  constructor(routeValue?: PageHandlerRoute) {
    this.routeValue = routeValue;
    this.queryStringNavigation = accessQueryStringNavigationOrThrow();
  }

  /**
   * We call this method if the routeValue is not known at the time of construction.
   * In this case, we can set the route value later, after the agente has provided
   * the information that was missing at the time of construction, such as a VIN
   * in a route like /vehicles/:vin.
   * @param routeValue The route value (urlPathName) of the page for navigation.
   */
  setRouteValue(routeValue: PageHandlerRoute): void {
    this.routeValue = routeValue;
  }

  /**
   * Assigns the route value to the query string navigation.
   * The routeValue of the PageHandler must be set before this method can be called.
   */
  assignRouteValue(): void {
    if (!this.routeValue) {
      throw new Error("The route value is not set");
    }

    this.queryStringNavigation.setRouteValue(this.routeValue);
  }

  getRouteValue(): PageHandlerRoute | undefined {
    return this.routeValue;
  }

  /**
   * Navigates to the page indicated by the routeValue.
   * This triggers a soft reload if it wasn't already queued.
   */
  navigateToPage() {
    this.assignRouteValue();
    this.queryStringNavigation.setMinimumReload(Reload.SOFT);
  }

  /**
   * Navigates to a specific tab.
   * @param tabKey - The key of the tab to navigate to.
   */
  navigateToTab(tabKey: string) {
    this.assignRouteValue();
    this.queryStringNavigation.navigateToTab(tabKey);
  }

  /**
   * Updates the chart settings for a specific tab.
   * @param pageKey - The "page key" that corresponds to the chart we are controlling.
   * Counterintuitively, there can be many page keys associated with a single page on the site.
   * @param tabKey - The key of the tab.
   * @param tabChartSettingsState - The state of the tab chart settings.
   */
  updateTabChartSettings(
    pageKey: string,
    tabKey: string,
    tabChartSettingsState: TabChartSettingsState
  ) {
    this.assignRouteValue();
    this.navigateToTab(tabKey);

    const pageKeyWithVersion = getPageKeyWithVersion(pageKey);
    const { chartSettings } = getStateFromLocalStorage(pageKeyWithVersion);
    const nextChartSettings = {
      [tabKey]: tabChartSettingsState,
    };
    const updatedChartSettings = injectPageChartSettings(
      chartSettings ?? {},
      nextChartSettings
    );

    const { chartSettingsKey } = getQueryKeys(pageKeyWithVersion);

    this.queryStringNavigation.updateQueryStringParameter(
      chartSettingsKey,
      JSON.stringify(updatedChartSettings),
      Reload.HARD
    );
  }

  /**
   * Updates the page filter.
   * @param filterQueryString - The filter query string.
   * @param filterKey - The key of the filter.
   */
  updateFilter(filterQueryString: string, filterKey: string) {
    this.assignRouteValue();
    this.queryStringNavigation.updateFilter(filterQueryString, filterKey);
  }

  /**
   * Updates the sort order.
   * @param sortKey
   * @param sortQueryString
   */
  updateSort(
    sortKeyRoot: string,
    sortKeySuffix: string,
    sortQueryString: string
  ) {
    this.assignRouteValue();
    // It is important to include the opening bracket because some page keys are
    // substrings of others. We don't want to delete too many query parameters.
    this.queryStringNavigation.setOmitExistingQueryParamsStartingWith(
      `${sortKeyRoot}[`
    );
    this.queryStringNavigation.setOmitExistingQueryParam(sortKeyRoot);
    this.queryStringNavigation.updateQueryStringParameter(
      `${sortKeyRoot}${sortKeySuffix}`,
      sortQueryString,
      Reload.HARD
    );
  }

  /**
   * Updates the relates filter for a specific tab.
   * @param tabKey - The key of the tab.
   * @param relatesFilter - The state of the relates filter.
   * @param tabFilterKey - The key of the tab filter.
   */
  updateTabRelatesFilter(
    tabKey: string,
    occursFilter: OccursFilterState,
    tabFilterKey: string
  ) {
    this.assignRouteValue();
    this.navigateToTab(tabKey);
    this.queryStringNavigation.updateQueryStringParameter(
      tabFilterKey,
      JSON.stringify(occursFilter),
      Reload.HARD
    );
  }
}
