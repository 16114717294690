import { useState } from "react";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
  CustomSignalEventDefinition,
  publishCustomSignalEventDefinition,
} from "shared/api/customSignalEvents/api";

import { PUBLISH_CSE } from "pages/CustomSignalEvents/constants";

import Button from "features/ui/Button";
import ConfirmationModal from "features/ui/ConfirmationModal";

const TEXT =
  "Publishing a custom signal event is a resource intensive operation and may take up to several hours. It also permanently freezes the definition. Are you sure you want to publish this custom signal event?";
const CONFIRM_TEXT = "Yes, publish custom signal event";
const CANCEL_TEXT = "No, cancel";

interface Props {
  data?: CustomSignalEventDefinition;
  onClose?: () => void;
  entityRequestKey?: string;
  disablePublish?: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

const PublishSignalEventAction = ({
  data,
  entityRequestKey,
  disablePublish,
  setIsSubmitting,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const ctaIcon =
    (isPublishing && <LoadingIcon className="animate-spin" />) || undefined;

  const publishDisabled = disablePublish || !data;

  const handlePublishing = (publishing: boolean) => {
    setIsPublishing(publishing);
    setIsSubmitting(publishing);
  };

  const handleOnConfirm = (confirm: boolean) => {
    if (!confirm) {
      setModalVisible(false);

      return;
    }

    handlePublishing(true);

    publishCustomSignalEventDefinition(data!.ID)
      .then(() => {
        toast.success(`Custom Signal Event published`);
        entityRequestKey && mutate(entityRequestKey);
      })
      .catch(() => {
        toast.error(`Publishing Custom Signal Event failed`);
      })
      .finally(() => {
        setModalVisible(false);
        handlePublishing(false);
      });
  };

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        label={PUBLISH_CSE}
        onClick={() => setModalVisible(true)}
        isLoading={isPublishing}
        endIcon={ctaIcon}
        disabled={publishDisabled}
        size="medium"
        tabIndex={-1}
      />
      <ConfirmationModal
        isOpen={modalVisible}
        onClose={handleOnConfirm}
        loading={isPublishing}
        confirmText={CONFIRM_TEXT}
        closeDisabled={isPublishing}
        closeText={CANCEL_TEXT}
        title=""
        text={TEXT}
        confirmColor="error"
      />
    </>
  );
};

export default PublishSignalEventAction;
