import * as api from "shared/api/api";
import parseLinkHeader from "shared/api/parse-link-header";

const laborCodesGetFetcher = (args: api.ListLaborCodesRequest) =>
  api.getFetcher<api.LaborCode[]>(api.listLaborCodesURI(args));

const partsGetFetcher = (args: api.ListPartsRequest) =>
  api.getFetcher<api.Part[]>(api.listPartsURI(args));

const getPages = async <T>(
  fetchFunction: (
    args: api.APIPaginatedRequest
  ) => Promise<{ data: T[]; headers: api.APIHeaders }>,
  args: api.APIPaginatedRequest,
  limit: number = 4000
): Promise<T[]> => {
  let allItems: T[] = [];
  let before: string | undefined = undefined;
  let after: string | undefined = undefined;
  let hasMorePages = true;

  while (hasMorePages && (!limit || allItems.length < limit)) {
    const response = await fetchFunction({
      ...args,
      before,
      after,
      limit: Math.min(limit ?? 2000, 2000),
    });
    allItems = allItems.concat(response.data);

    const links = parseLinkHeader(response.headers.link);
    hasMorePages = Boolean(links?.next);
    before = links?.next?.before;
    after = links?.next?.after;
  }

  return allItems;
};

// Specific functions to fetch labor codes and parts
export const getLaborCodes = (args: api.ListLaborCodesRequest) =>
  getPages<api.LaborCode>(laborCodesGetFetcher, args);

export const getParts = (args: api.ListPartsRequest) =>
  getPages<api.Part>(partsGetFetcher, args);
