import { AiOutlineArrowRight } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { Props } from "react-rnd";
import { generatePath, Link } from "react-router-dom";

import {
  FieldUpdateResponse,
  IssueUpdateResponse,
} from "shared/api/issues/api";
import { useGetIssueActivity } from "shared/api/issues/hooks";
import { useOrderedValue } from "shared/api/orderedValues/hooks";
import { useGroup } from "shared/api/rbac/hooks";
import useIssuesSchema from "shared/schemas/issuesSchema";

import APIError from "features/ui/APIError";

import { routes } from "services/routes";

import {
  ClaimFilterPresenter,
  PopulationFilterPresenter,
  SignalEventFilterPresenter,
} from "./IssuePresenters";
import { getFieldLabel } from "./utils";

const AssignedGroupComponent = ({ assignedGroupId }: any) => {
  const { data, isLoading, error } = useGroup({
    id: assignedGroupId as string,
  });
  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return "n/a";
  }

  if (data) {
    return (
      <Link
        to={generatePath(routes.group, {
          id: assignedGroupId,
        })}
        className="text-metabase-blue hover:underline"
      >
        {data.name}
      </Link>
    );
  }
};

const OrderedValueComponent = ({ id }: any) => {
  const { data, isLoading, error } = useOrderedValue({
    id: id as string,
  });
  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return "n/a";
  }

  if (data) {
    return data.value;
  }
};

const getFieldValueComponent = (field: string, fieldValue: any) => {
  if (fieldValue == null || fieldValue === "") {
    return "n/a";
  }

  switch (field) {
    case "atRiskPopulationFilter":
      return <PopulationFilterPresenter populationFilter={fieldValue} />;
    case "comparisonPopulationFilter":
      return <PopulationFilterPresenter populationFilter={fieldValue} />;
    case "claimFilter":
      return <ClaimFilterPresenter claimFilter={fieldValue} />;
    case "signalEventOccurrencesFilter":
      return (
        <SignalEventFilterPresenter signalEventOccurrencesFilter={fieldValue} />
      );
    case "promotedFromID":
      return (
        <Link
          to={generatePath(routes.suggestedIssueLatestRun, {
            id: fieldValue,
          })}
          className="text-metabase-blue hover:underline"
        >
          {fieldValue}
        </Link>
      );
    case "statusUpdatedAt":
      return new Date(fieldValue).toLocaleString();
    case "severityID":
    case "statusID":
      return <OrderedValueComponent id={fieldValue}></OrderedValueComponent>;
    case "assignedGroupID":
      return (
        <AssignedGroupComponent
          assignedGroupId={fieldValue}
        ></AssignedGroupComponent>
      );
    default:
      return fieldValue;
  }
};

const FieldUpdate = ({ field, oldValue, newValue }: FieldUpdateResponse) => {
  const { schema } = useIssuesSchema();
  let overwrittenField = null;
  if (field === "statusID") {
    overwrittenField = "statusObj";
  }

  if (field === "severityID") {
    overwrittenField = "severityObj";
  }

  return (
    <div className="flex flex-row gap-2 text-base font-medium sm:text-sm">
      <label className="text-gray-500">
        {getFieldLabel(schema, overwrittenField ?? field)}:
      </label>

      <div className="flex flex-row gap-2 items-center">
        <div className="text-viaduct-black">
          {getFieldValueComponent(field, oldValue)}
        </div>
        <AiOutlineArrowRight></AiOutlineArrowRight>
        <div className="text-viaduct-black">
          {getFieldValueComponent(field, newValue)}
        </div>
      </div>
    </div>
  );
};

const IssueSingleUpdate = (issueUpdate: IssueUpdateResponse) => {
  const updateDate = new Date(issueUpdate.updatedAt).toLocaleString();

  return (
    <>
      <div>
        <b>{issueUpdate.updatedBy}</b> at {updateDate}:
      </div>
      <div className="space-y-1.5 ml-3">
        {issueUpdate.updates.map((fieldUpdate) => FieldUpdate(fieldUpdate))}
      </div>
    </>
  );
};

const IssueActivityTab = ({ issue }: Props) => {
  const { data, isLoading, error } = useGetIssueActivity({
    id: issue.ID as string,
  });

  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No activity data yet for Issue.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 ml-4">
      {[...data].reverse().map((i) => IssueSingleUpdate(i))}
    </div>
  );
};

export default IssueActivityTab;
