import React, { useCallback, useMemo } from "react";

import { Group } from "shared/api/rbac/api";
import { useEmailFromJWT } from "shared/hooks";

import { NEW_GROUP_MEMBER_ENTRY } from "pages/Groups/utils";

import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import UserActions from "./UserActions";
import UserEmail from "./UserEmail";
import { GroupMemberEntry } from "./utils";

type Props = {
  group: Group;
  groupMembers: GroupMemberEntry[];
  onUpdate: (members: GroupMemberEntry[]) => void;
};
const MembersTable = ({ group, groupMembers, onUpdate }: Props) => {
  const myEmail = useEmailFromJWT();

  const { canEdit, admin } = group;

  const schemaItems: (SchemaEntry | boolean | undefined)[] = [
    {
      label: "User",
      accessor: "userField",
      dataType: DataType.JSX,
    },
    canEdit && {
      label: "Action",
      accessor: "userActions",
      dataType: DataType.JSX,
      align: "center",
    },
  ];

  const schema = schemaItems.filter(Boolean) as SchemaEntry[];

  const formatRow = useCallback(
    (groupUser: GroupMemberEntry) => {
      const onUpdateEmail = (email: string) => {
        const newGroupMembers = groupMembers.map((member) => {
          if (!member.ID) {
            return {
              ...member,
              email,
            };
          }

          return member;
        });
        onUpdate(newGroupMembers);
      };

      const onAddMember = () => {
        const newMember = groupMembers.find((member) => !member.ID);
        if (!newMember) {
          return;
        }

        const newGroupMembers = [
          ...groupMembers,
          { ...newMember, ID: newMember.email },
        ];
        newGroupMembers[0] = NEW_GROUP_MEMBER_ENTRY;
        onUpdate(newGroupMembers);
      };

      const onRemoveMember = (id: string) => {
        const memberToRemove = groupMembers.find((member) => member.ID === id);
        if (!memberToRemove || !id) {
          return;
        }

        const newGroupMembers = groupMembers.filter(
          (member) => member.ID !== memberToRemove.ID
        );
        onUpdate(newGroupMembers);
      };

      return {
        userField: (
          <UserEmail
            {...groupUser}
            myEmail={myEmail}
            canEdit={canEdit}
            onUpdateEmail={onUpdateEmail}
          />
        ),
        userActions: (
          <UserActions
            {...groupUser}
            isAdminGroup={admin}
            currentMembers={groupMembers}
            onAddMember={onAddMember}
            onRemoveMember={onRemoveMember}
          />
        ),
      };
    },
    [admin, canEdit, groupMembers, myEmail, onUpdate]
  );

  const formattedData = useMemo(
    () => groupMembers.map(formatRow),
    [formatRow, groupMembers]
  );

  return <Table schema={schema} data={formattedData} dense />;
};

export default MembersTable;
