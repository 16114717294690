import { useState } from "react";
import { ResizeDirection } from "re-resizable";
import {
  DraggableData,
  Position,
  ResizableDelta,
  Rnd,
  RndDragEvent,
} from "react-rnd";

import {
  DUCK_DRAG_HANDLE_CLASS_NAME,
  DUCK_FLOATING_POSITION_KEY,
  DUCK_FLOATING_SIZE_KEY,
  DUCK_POPPED_INITIAL_HEIGHT,
  DUCK_POPPED_INITIAL_WIDTH,
  DUCK_POPPED_INITIAL_X,
} from "./constants";
import Duck from "./Duck";

interface DraggableDuckProps {
  setIsDraggable: (draggable: boolean) => void;
}

interface DraggableEventHandler {
  (e: RndDragEvent, data: DraggableData): false | void;
}

interface ResizeStartEventHandler {
  (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    direction: ResizeDirection
  ): void;
}

interface ResizeStopEventHandler {
  (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    elementRef: HTMLElement,
    delta: {
      height: number;
      width: number;
    }
  ): void;
}

const BOTTOM_MARGIN = 20;
const DEFAULT_POSITION: Position = {
  x: DUCK_POPPED_INITIAL_X,
  y: window.innerHeight - DUCK_POPPED_INITIAL_HEIGHT - BOTTOM_MARGIN,
};

const DEFAULT_SIZE = {
  width: DUCK_POPPED_INITIAL_WIDTH,
  height: DUCK_POPPED_INITIAL_HEIGHT,
};

const savePosition = (position: Position) =>
  sessionStorage.setItem(DUCK_FLOATING_POSITION_KEY, JSON.stringify(position));

const handleDragStop: DraggableEventHandler = (_event, data): void =>
  savePosition({ x: data.x, y: data.y });

const getPosition = (): Position => {
  const initialPositionFromSS = sessionStorage.getItem(
    DUCK_FLOATING_POSITION_KEY
  );

  return initialPositionFromSS
    ? JSON.parse(initialPositionFromSS)
    : DEFAULT_POSITION;
};

// Funny name for the return type but Rnd gives us the exact structure we want.
const getInitialSize = (): ResizableDelta => {
  const initialSizeFromSS = sessionStorage.getItem(DUCK_FLOATING_SIZE_KEY);

  return initialSizeFromSS ? JSON.parse(initialSizeFromSS) : DEFAULT_SIZE;
};

const isRepositionDirection = (direction: ResizeDirection) =>
  direction === "top" ||
  direction === "left" ||
  direction === "topLeft" ||
  direction === "bottomLeft" ||
  direction === "topRight";

const hasCoordinates = (
  event: any
): event is {
  pageX: number;
  pageY: number;
} =>
  "pageX" in event &&
  "pageY" in event &&
  typeof event.pageX === "number" &&
  typeof event.pageY === "number";

/**
 * This component wraps Duck in a draggable container.
 * It allows the user to drag Duck around on the screen.
 * @param props.initialPosition The initial position of the draggable Duck.
 * @param props.setIsDraggable Call to let the SidebarNav container
 * know that Duck should no longer be draggable.
 */
const DraggableDuck = ({ setIsDraggable }: DraggableDuckProps) => {
  const [size, setSize] = useState(getInitialSize);

  const [positionBeforeResize, setPositionBeforeResize] = useState<
    Position | undefined
  >(undefined);

  const initialPosition = getPosition();

  const handleResizeStart: ResizeStartEventHandler = (event, direction) => {
    if (isRepositionDirection(direction) && hasCoordinates(event)) {
      setPositionBeforeResize({ x: event.pageX, y: event.pageY });
    }
  };

  const handleResizeStop: ResizeStopEventHandler = (
    event,
    direction,
    _element,
    delta
  ) => {
    const newSize = {
      width: size.width + delta.width,
      height: size.height + delta.height,
    };
    setSize(newSize);
    sessionStorage.setItem(DUCK_FLOATING_SIZE_KEY, JSON.stringify(newSize));

    if (
      isRepositionDirection(direction) &&
      hasCoordinates(event) &&
      positionBeforeResize
    ) {
      const priorPosition = getPosition();
      const x =
        priorPosition.x +
        (direction.toLowerCase().includes("left")
          ? event.pageX - positionBeforeResize.x
          : 0);
      const y =
        priorPosition.y +
        (direction.toLowerCase().includes("top")
          ? event.pageY - positionBeforeResize.y
          : 0);
      savePosition({ x, y });
    }
  };

  return (
    <Rnd
      default={{
        x: initialPosition.x,
        y: initialPosition.y,
        width: size.width,
        height: size.height,
      }}
      minWidth={200}
      minHeight={200}
      bounds="window"
      style={{
        position: "fixed",
      }}
      dragHandleClassName={DUCK_DRAG_HANDLE_CLASS_NAME}
      onDragStop={handleDragStop}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
    >
      <Duck
        isDraggable={true}
        setIsDraggable={setIsDraggable}
        forceOpen={true}
      />
    </Rnd>
  );
};

export default DraggableDuck;
