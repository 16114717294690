import { joinWithCommasAndAnd, pluralize } from "shared/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { isFilterBuilderStateValid } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator, FilterState } from "features/ui/Filters/types";

import { ServicePlanRuleState } from "./types";

interface ValidateFormResponse {
  valid: boolean;
  message?: string;
}

export const validateForm = (
  name: string,
  vehicleFilter: FilterGroupState,
  rules: ServicePlanRuleState[]
): ValidateFormResponse => {
  if (!name) {
    return { valid: false, message: "Please enter name." };
  }

  if (!isFilterBuilderStateValid(vehicleFilter)) {
    return {
      valid: false,
      message: "Please enter valid Eligible Vehicles filter",
    };
  }

  if (!rules || rules.length === 0 || !rules.some((r) => isRuleComplete(r))) {
    return {
      valid: false,
      message: "Please complete at least one service recommendation.",
    };
  }

  if (rules.some((r) => !isRuleComplete(r))) {
    const incompleteRules = rules
      .map((r, i) => (!isRuleComplete(r) ? `${i + 1}` : undefined))
      .filter(Boolean);

    return {
      valid: false,
      message: `Please complete ${pluralize("recommendation", incompleteRules.length)} ${joinWithCommasAndAnd(incompleteRules as string[])}.`,
    };
  }

  return { valid: true, message: undefined };
};

const isRuleComplete = (rule: ServicePlanRuleState): boolean =>
  isFilterBuilderStateValid(rule.filter) &&
  rule.recommendation.trim().length > 0;

export const getFilterStateFromRecommendation = (
  recommendation: string | null,
  servicePlanId: string
) => {
  const filterState: FilterState = {
    "servicePlan.ID": {
      operator: FilterOperator.IN,
      values: [servicePlanId],
    },
  };
  if (recommendation) {
    filterState["recommendation"] = {
      operator: FilterOperator.IN,
      values: [recommendation],
    };
  }

  return filterState;
};
