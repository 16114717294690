import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { Link, Path } from "react-router-dom";

import { formatNumber, pluralize } from "shared/utils";

interface Props {
  isLoading: boolean;
  error: boolean;
  count?: number;
  entityName: string;
  entityNamePlural?: string;
  extraClasses?: string;
  prefix?: string;
  suffix?: string;
  link?: string | Partial<Path>;
}

const TableCount = ({
  isLoading,
  count,
  error,
  entityName,
  entityNamePlural,
  extraClasses,
  prefix = "",
  suffix = "",
  link,
}: Props) => (
  <div
    className={classNames(
      "inline-block text-gray-400 text-xs text-nowrap",
      extraClasses
    )}
  >
    {isLoading && <Skeleton width="200px" />}
    {!isLoading && error && (
      <div>{`Unable to fetch the number of ${pluralize(
        entityName,
        0,
        entityNamePlural
      )}.`}</div>
    )}
    {!isLoading && !error && count !== undefined && (
      <CountDisplayWrapper link={link}>
        <span data-testid="table-count">{`${prefix}${formatNumber(
          count,
          0
        )}`}</span>{" "}
        {pluralize(entityName, count, entityNamePlural)}
        {suffix}
      </CountDisplayWrapper>
    )}
  </div>
);

interface CountDisplayWrapperProps {
  link?: string | Partial<Path>;
  children: React.ReactNode;
}

const CountDisplayWrapper = ({ link, children }: CountDisplayWrapperProps) => {
  if (link) {
    return (
      <Link className="text-metabase-blue hover:underline" to={link}>
        {children}
      </Link>
    );
  }

  return <div>{children}</div>;
};

export default TableCount;
