import { useListGroups } from "shared/api/rbac/hooks";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";

import { DUMMY_GROUP_SELECT_OPTION } from "pages/Groups/utils";

import Select, { SelectOption } from "features/ui/Select";
import { NONE_SELECTED_OPTION } from "features/ui/Select/Select";

interface Props {
  assignedGroupID: string | null;
  onUpdateSuggestedIssue: (
    field: string,
    value: string | null,
    fieldNiceName?: string
  ) => void;
}

const GroupDropdown = ({ assignedGroupID, onUpdateSuggestedIssue }: Props) => {
  const { data: groups } = useListGroups({});
  const groupSelectOptions: SelectOption[] =
    groups?.map(({ ID, name }) => ({
      id: ID,
      value: name,
    })) || [];

  const selectedGroup: SelectOption | undefined =
    groupSelectOptions.find((group) => group.id === assignedGroupID) ||
    (assignedGroupID ? DUMMY_GROUP_SELECT_OPTION : undefined);

  const { getDisplayLabel } = useSuggestedIssuesSchema();
  const groupDisplayLabel = getDisplayLabel(
    "assignedGroupID",
    "Responsible Group"
  );

  return (
    <Select
      label={groupDisplayLabel}
      options={groupSelectOptions}
      allowNoneSelected={true}
      fullWidth
      selected={selectedGroup}
      onSelect={(option) => {
        if (selectedGroup && option.id === selectedGroup?.id) return;

        const valueForAPI =
          option.id === NONE_SELECTED_OPTION.id ? null : (option.id as string);

        onUpdateSuggestedIssue(
          "assignedGroupID",
          valueForAPI,
          groupDisplayLabel
        );
      }}
      testId="suggested-issue-assignedGroupID"
    />
  );
};
export default GroupDropdown;
