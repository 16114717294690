export const promptNames = {
  CLAIM_ANALYTICS_AGENT: "duck_claim_analytics_agent",
  SIGNAL_EVENT_ANALYTICS_AGENT: "duck_se_analytics_agent",
  VIN_VIEW_AGENT: "duck_vin_view_agent",
  VEHICLES_AGENT: "duck_vehicles_agent",
  GREETING_REJECT_CLARIFY_AGENT: "duck_reject_clarify",
  ROUTER_AGENT: "duck_router_agent",
  RAG_AGENT: "duck_rag_agent",
  ANALYZE_SCREENSHOT_AGENT: "duck_analyze_screenshot_agent",
  ISSUES_AGENT: "duck_issues_agent",
  ISSUE_DETAILS_AGENT: "duck_issue_details_agent",
  PAGE_AGENT_RESPONDER_AGENT: "duck_page_agent_responder_agent",
  PAGE_AGENT_ANALYZE_SCREENSHOT_AGENT:
    "duck_page_agent_analyze_screenshot_agent",
  SEARCH_CODES_BY_DESCRIPTION_AGENT: "duck_search_codes_by_description_agent",
} as const;

export type PromptName = (typeof promptNames)[keyof typeof promptNames];

export const promptTags = {
  DEV: "development",
  PROD: "production",
  LATEST: "latest",
} as const;

export type PromptTag = (typeof promptTags)[keyof typeof promptTags];

export const tagMapping: Record<typeof process.env.NODE_ENV, PromptTag> = {
  test: promptTags.DEV,
  development: promptTags.DEV,
  production: promptTags.PROD,
};
