import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import {
  AttributeResource,
  getAdminAttributesConfig,
  RESOURCE_OPTIONS,
  updateAdminAttributesConfig,
} from "shared/api/admin/api";

import Button from "features/ui/Button";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { SelectOption } from "features/ui/Select";
import TextArea from "features/ui/TextArea";
import Title from "features/ui/Title";

const TITLE = "Attributes Configuration";
const CONFIG_ROWS = 25;

const AttributesConfiguration = () => {
  const [selectedResource, setSelectedResource] = useState<SelectOption | null>(
    null
  );

  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [configuration, setConfiguration] = useState("");

  const handleResourceChange = (option: SelectOption | null) => {
    if (option) {
      setSelectedResource(option);
      setIsConfigLoading(true);
      setIsError(false);

      getAdminAttributesConfig({ resource: option.id as AttributeResource })
        .then(({ data }) => {
          setConfiguration(JSON.stringify(data, null, 2));
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => setIsConfigLoading(false));
    } else {
      setSelectedResource(null);
      setConfiguration("");
      setIsError(false);
    }
  };

  const textAreaLabel = `${selectedResource?.value} attributes configuration`;

  const handleSave = () => {
    if (!showConfig && !isValidJSON) return;

    setIsSubmitting(true);

    const parsedConfig = JSON.parse(configuration);
    const configArray = Object.values(parsedConfig);

    updateAdminAttributesConfig({
      resource: selectedResource!.id as AttributeResource,
      config: configArray,
    })
      .then(() => {
        toast.success(
          `${selectedResource!.value} configuration saved successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isValidJSON = Boolean(
    configuration &&
      configuration.trim().length > 0 &&
      (() => {
        try {
          JSON.parse(configuration);

          return true;
        } catch (error) {
          return false;
        }
      })()
  );

  const showConfig = Boolean(
    !isConfigLoading && configuration && selectedResource
  );

  return (
    <div>
      <PageHeaderWrapper>
        <Title text={TITLE} />
      </PageHeaderWrapper>
      <div className="mb-4">
        <DropdownWithSearch
          label="Select a resource"
          options={RESOURCE_OPTIONS}
          selectedOption={selectedResource}
          onSelectedOptionChange={handleResourceChange}
          testId="resource-select"
        />
      </div>
      {isError && (
        <div className="mb-4">
          <p className="text-red-500">Failed to load configuration.</p>
        </div>
      )}
      {isConfigLoading && <Skeleton height={CONFIG_ROWS * 20} />}
      {showConfig && (
        <div className="max-w-4xl">
          <div className="text-xs text-gray-600 mb-4">
            Note: "enabled" needs to be set to "true" for attribute changes to
            be applied.
          </div>
          <TextArea
            label={textAreaLabel}
            value={configuration}
            onChange={(e) => setConfiguration(e.target.value)}
            testId="attributes-config-text-area"
            rows={CONFIG_ROWS}
            error={!isValidJSON}
          />
          <div className="flex justify-end mt-4">
            <Button
              label="Save"
              color="primary"
              variant="contained"
              testId="save-attributes-config"
              onClick={handleSave}
              disabled={!isValidJSON}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributesConfiguration;
