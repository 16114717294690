import { SelectOption } from "features/ui/Select";
import { DATE_TYPE_VALUES } from "features/ui/Table/TableBodyCell/TableBodyCell";

import { FunctionInputConfig } from "./Definition/CustomAttributes/FunctionInput/FunctionInput";
import { CustomSignalEventStatus } from "./types";

export const DEFAULT_NAME = "";
export const DEFAULT_DESCRIPTION = "";
export const DEFAULT_ID = "";
export const DEFAULT_DOWNSAMPLING_OPTION = "none";
export const DEFAULT_DOWNSAMPLING_SECONDS_AFTER = 0;
export const DEFAULT_DOWNSAMPLING_SECONDS_BEFORE = 0;
export const DEFAULT_DOWNSAMPLING_OCCURRENCES = 0;
export const DEFAULT_SELECTED_VIN = undefined;
export const DEFAULT_CUSTOM_ATTRIBUTES = [];

export const PAGE_TITLE = "Custom Signal Events";

export const CTA_TEXT = "New Custom Signal Event";

// ACTIVE_STATUS_LIST represents an ordered list of status progressions during the lifespan of a custom signal event definition
export const ACTIVE_STATUS_LIST: CustomSignalEventStatus[] = [
  "draft",
  "ready_for_validation",
  "invalid",
  "ready_for_backfill",
  "backfill_in_progress",
  "error_during_backfill",
  "ready_for_incremental_computation",
];

export const ERROR_STATUSES: CustomSignalEventStatus[] = [
  "invalid",
  "error_during_backfill",
];

export const EDITABLE_STATUSES: CustomSignalEventStatus[] = [
  "draft",
  ...ERROR_STATUSES,
];
export const PUBLISHABLE_STATUSES: CustomSignalEventStatus[] = ["draft"];

export enum CustomAttributeFunction {
  GET_NEAREST_EVENT_ATTRIBUTE = "getNearestEventAttribute",
  TIME_UNTIL = "timeUntil",
  AGGREGATE_WINDOW = "aggregateWindow",
  AGGREGATE_BETWEEN = "aggregateBetween",
  AGGREGATE_ROLLING = "aggregateRolling",
  COUNT_WINDOW = "countWindow",
  COUNT_BETWEEN = "countBetween",
  SUBTRACT = "subtract",
  ADD = "add",
  DIVIDE = "div",
}

export enum DownsamplingOptions {
  NONE = "none",
  OCCURS_AT_LEAST = "occursAtLeast",
  REST_PERIOD = "restPeriod",
}

export const CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS: Record<
  CustomAttributeFunction,
  FunctionInputConfig[]
> = {
  [CustomAttributeFunction.GET_NEAREST_EVENT_ATTRIBUTE]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "field",
      labelBefore: "field",
      parameter: "field",
      useSelectedEvent: true,
    },
    {
      type: "direction",
      labelBefore: "direction",
      parameter: "direction",
    },
  ],
  [CustomAttributeFunction.TIME_UNTIL]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "unit",
      labelBefore: "unit",
      parameter: "unit",
    },
  ],
  [CustomAttributeFunction.AGGREGATE_WINDOW]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "field",
      labelBefore: "field",
      parameter: "field",
      useSelectedEvent: true,
    },
    {
      type: "text",
      labelBefore: "before",
      labelAfter: "seconds",
      parameter: "before",
    },
    {
      type: "text",
      labelBefore: "after",
      labelAfter: "seconds",
      parameter: "after",
    },
    {
      type: "aggregation_function",
      labelBefore: "function",
      parameter: "aggregation",
    },
  ],
  [CustomAttributeFunction.AGGREGATE_ROLLING]: [
    {
      type: "field",
      labelBefore: "field",
      parameter: "field",
    },
    {
      type: "text",
      labelBefore: "before",
      labelAfter: "seconds",
      parameter: "before",
    },
    {
      type: "text",
      labelBefore: "after",
      labelAfter: "seconds",
      parameter: "after",
    },
    {
      type: "aggregation_function",
      labelBefore: "function",
      parameter: "aggregation",
    },
  ],
  [CustomAttributeFunction.AGGREGATE_BETWEEN]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "field",
      labelBefore: "field",
      parameter: "field",
      useSelectedEvent: true,
    },
    {
      type: "combined_field",
      labelBefore: "start",
      parameter: "start",
      dataTypes: DATE_TYPE_VALUES.map((x) => x),
    },
    {
      type: "combined_field",
      labelBefore: "end",
      parameter: "end",
      dataTypes: DATE_TYPE_VALUES.map((x) => x),
    },
    {
      type: "aggregation_function",
      labelBefore: "function",
      parameter: "aggregation",
    },
  ],
  [CustomAttributeFunction.COUNT_WINDOW]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "text",
      labelBefore: "before",
      labelAfter: "seconds",
      parameter: "before",
    },
    {
      type: "text",
      labelBefore: "after",
      labelAfter: "seconds",
      parameter: "after",
    },
  ],
  [CustomAttributeFunction.COUNT_BETWEEN]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "combined_field",
      labelBefore: "start",
      parameter: "start",
      dataTypes: DATE_TYPE_VALUES.map((x) => x),
    },
    {
      type: "combined_field",
      labelBefore: "end",
      parameter: "end",
      dataTypes: DATE_TYPE_VALUES.map((x) => x),
    },
  ],
  [CustomAttributeFunction.SUBTRACT]: [
    {
      type: "field",
      labelBefore: "field A",
      parameter: "fieldA",
    },
    {
      type: "field",
      labelBefore: "field B",
      parameter: "fieldB",
    },
  ],
  [CustomAttributeFunction.ADD]: [
    {
      type: "field",
      labelBefore: "field A",
      parameter: "fieldA",
    },
    {
      type: "field",
      labelBefore: "field B",
      parameter: "fieldB",
    },
  ],
  [CustomAttributeFunction.DIVIDE]: [
    {
      type: "field",
      labelBefore: "field A",
      parameter: "fieldA",
    },
    {
      type: "field",
      labelBefore: "field B",
      parameter: "fieldB",
    },
  ],
};

export const CUSTOM_ATTRIBUTE_FUNCTION_OPTIONS: SelectOption[] = [
  {
    id: CustomAttributeFunction.GET_NEAREST_EVENT_ATTRIBUTE,
    value:
      "get nearest event attribute (event type, event filter, field, direction)",
  },
  {
    id: CustomAttributeFunction.TIME_UNTIL,
    value: "time until (event type, unit)",
  },
  {
    id: CustomAttributeFunction.AGGREGATE_WINDOW,
    value:
      "aggregate window (event type, event filter, field, seconds before, seconds after, aggregation function)",
  },
  {
    id: CustomAttributeFunction.AGGREGATE_BETWEEN,
    value:
      "aggregate between (event type, event filter, field, start timestamp, end timestamp, aggregation function)",
  },
  {
    id: CustomAttributeFunction.AGGREGATE_ROLLING,
    value:
      "aggregate rolling (field, seconds before, seconds after, aggregation function)",
  },
  {
    id: CustomAttributeFunction.COUNT_WINDOW,
    value: "count window (event type, seconds before, seconds after)",
  },
  {
    id: CustomAttributeFunction.COUNT_BETWEEN,
    value:
      "count between (event type, event filter, start timestamp, end timestamp)",
  },
  {
    id: CustomAttributeFunction.SUBTRACT,
    value: "subtract (fieldA, fieldB)",
  },
  {
    id: CustomAttributeFunction.ADD,
    value: "add (fieldA, fieldB)",
  },
  {
    id: CustomAttributeFunction.DIVIDE,
    value: "divide (fieldA, fieldB)",
  },
];

export const DIRECTION_OPTIONS: SelectOption[] = [
  { id: "before", value: "before" },
  { id: "after", value: "after" },
];

export const UNIT_OPTIONS: SelectOption[] = [
  { id: "second", value: "seconds" },
  { id: "minute", value: "minutes" },
  { id: "hour", value: "hours" },
  { id: "day", value: "days" },
  { id: "week", value: "weeks" },
  { id: "month", value: "months" },
  { id: "year", value: "years" },
];

export const AGGREGATION_OPTIONS: SelectOption[] = [
  { id: "avg", value: "avg" },
  { id: "count", value: "count" },
  { id: "max", value: "max" },
  { id: "min", value: "min" },
  { id: "sum", value: "sum" },
];

export const DEFAULT_EDIT_MODE = true;

export const SAVE_CSE = "Save";
export const UPDATE_CSE = "Update";
export const CANCEL_CSE = "Cancel";

export const PUBLISH_CSE = "Publish";

export const STOP_WORDS = new Set([
  "is",
  "a",
  "an",
  "be",
  "was",
  "were",
  "has",
  "have",
  "had",
  "are",
  "and",
  "or",
  "but",
  "nor",
  "the",
  "so",
  "for",
  "yet",
  "after",
  "although",
  "as",
  "because",
  "before",
  "if",
  "once",
  "since",
  "though",
  "till",
  "unless",
  "until",
  "when",
  "where",
  "while",
  "at",
  "by",
  "for",
  "from",
  "in",
  "of",
  "on",
  "to",
  "with",
  "about",
  "into",
  "then",
  "do",
  "did",
  "does",
  "can",
]);

export const MAX_VEHICLES_PREVIEW = 30;
export const DEFAULT_DATE_PARAM = "date";
