import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";

export const extractTextBetweenSquareBrackets = (
  input: string
): string | undefined => {
  const match = input.match(/^\[(.*?)\]$/);

  return match ? match[1] : undefined;
};

export const getFilterGroupStateFromExistsFilterValue = (
  input: string
): FilterGroupState | undefined => {
  const filterQuery = extractTextBetweenSquareBrackets(input);

  return filterBuilderQueryToFilterBuilderState(filterQuery);
};

export const encodeExistsFilterValue = (
  groupFilterState: FilterGroupState | undefined
): string => `[${getFiltersQuery(groupFilterState)}]`;
