export enum DurationUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
}

interface DurationField {
  name: DurationUnit;
  label: string;
}

export type DurationValues = Record<DurationUnit, string>;

export const DURATION_FIELDS_LABELS: DurationField[] = [
  { name: DurationUnit.DAYS, label: "days" },
  { name: DurationUnit.HOURS, label: "hrs" },
  { name: DurationUnit.MINUTES, label: "min" },
  { name: DurationUnit.SECONDS, label: "sec" },
];

const DURATION_UNIT_MAP: Record<DurationUnit, string> = {
  [DurationUnit.DAYS]: "d",
  [DurationUnit.HOURS]: "h",
  [DurationUnit.MINUTES]: "m",
  [DurationUnit.SECONDS]: "s",
};

export const getValueFromDuration = (
  duration: string,
  unit: DurationUnit
): string => {
  const unitChar = unit.charAt(0);
  const match = duration.match(new RegExp(`(\\d+)${unitChar}`));

  return match ? match[1] : "0";
};

export const setValueToDuration = (
  duration: string,
  unit: DurationUnit,
  value: string
): string => {
  const newValue = parseInt(value, 10);

  // Remove all existing units
  let newDuration = duration.replace(/\d+[dhms]/g, "");

  // Add non-zero units back
  DURATION_FIELDS_LABELS.forEach(({ name }) => {
    const char = DURATION_UNIT_MAP[name];
    if (name === unit) {
      if (newValue > 0) {
        newDuration += `${newValue}${char}`;
      }
    } else {
      const existingValue = getValueFromDuration(duration, name);
      if (parseInt(existingValue, 10) > 0) {
        newDuration += `${existingValue}${char}`;
      }
    }
  });

  // If the resulting duration is empty, return "0s"
  return newDuration || "0s";
};
