import { useContext, useEffect, useRef } from "react";
import {
  DuckContext,
  DuckMessage as DuckMessageType,
} from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor } from "duck/context/types";
import { Stack } from "@mui/material";

import DuckLoadingAnimation from "./DuckLoadingAnimation";
import DuckMessage from "./DuckMessage";
import DuckPendingActionAvailable from "./DuckPendingActionAvailable";

interface DuckMessagesProps {
  updateLocation: () => void;
}

const DuckMessages = ({ updateLocation }: DuckMessagesProps) => {
  const { messages, streamingMessage, pendingAction, loading } =
    useContext(DuckContext);

  const isStreaming = Boolean(streamingMessage);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const mostRecentHumanMessageRef = useRef<HTMLDivElement>(null);

  const skipStandardScrollRef = useRef(false);

  const scrollToMostRecentHumanMessage = () => {
    if (skipStandardScrollRef.current) {
      skipStandardScrollRef.current = false;

      return;
    }

    if (!messagesContainerRef.current || !mostRecentHumanMessageRef.current) {
      return;
    }

    const containerRect = messagesContainerRef.current.getBoundingClientRect();
    const mostRecentHumanMessageRect =
      mostRecentHumanMessageRef.current.getBoundingClientRect();

    const scrollTop =
      mostRecentHumanMessageRect.top -
      containerRect.top +
      messagesContainerRef.current.scrollTop;

    messagesContainerRef.current.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isStreaming) {
      // If the user has scrolled the streaming message, the standard message
      // scrolling would scroll the user back to the prior human
      // message, taking them away from where the were. Skipping that standard
      // scroll leaves them properly positioned where they already were.
      // There is a funky shuffling of the text as the streaming message is
      // replaced by a standard message.
      // https://viaduct-ai.atlassian.net/browse/DUCK-364
      skipStandardScrollRef.current = true;
    }
  }, [isStreaming]);

  useEffect(() => {
    scrollToMostRecentHumanMessage();
  }, [messages, loading, pendingAction]);

  const mostRecentHumanMessageIx = Math.max(
    // Use 0 instead of -1 if there are no human messages.
    // This happens at the beginning of a session.
    0,
    messages.findLastIndex(
      (message) => message.author === DuckMessageAuthor.HUMAN
    )
  );

  return (
    <Stack
      ref={messagesContainerRef}
      spacing={1}
      sx={{
        bgcolor: "white",
        width: "100%",
        flexGrow: 1,
        border: 1,
        borderColor: "grey.300",
        overflowY: "auto",
        padding: 1,
        height: "100%",
      }}
    >
      {messages.map((message: DuckMessageType, ix: number) => (
        <div
          key={ix}
          ref={
            ix === mostRecentHumanMessageIx ? mostRecentHumanMessageRef : null
          }
        >
          <DuckMessage message={message} />
        </div>
      ))}

      {streamingMessage && (
        <DuckMessage
          message={{
            author: DuckMessageAuthor.AGENT,
            message: streamingMessage,
          }}
        />
      )}

      {loading && !streamingMessage && (
        <Stack
          spacing={1}
          sx={{
            bgcolor: "white",
            width: "100%",
            border: 0,
            borderColor: "white",
            padding: 0,
          }}
        >
          <DuckLoadingAnimation />
        </Stack>
      )}

      {pendingAction && (
        <DuckPendingActionAvailable updateLocation={updateLocation} />
      )}

      <div
        style={{
          height: "100%",
          flexShrink: 0,
          width: "100%",
          pointerEvents: "none",
        }}
      />
    </Stack>
  );
};

export default DuckMessages;
