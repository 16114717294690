import { useListServiceRecommendationsCount } from "shared/api/serviceRecommendations/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterState } from "features/ui/Filters/types";
import TableCount from "features/ui/Table/TableCount";

import { constructLinkToRecommendationsPage } from "./utils";

interface Props {
  filterState?: FilterState | FilterGroupState;
  vehiclesFilterState?: FilterState | FilterGroupState;
  label?: string;
}

const RecommendationCount = ({
  filterState,
  vehiclesFilterState,
  label = "Recommendation",
}: Props) => {
  const filterQuery = getFiltersQuery(
    filterStateToFilterGroupState(filterState)
  );
  const vehiclesFilterQuery = getFiltersQuery(
    filterStateToFilterGroupState(vehiclesFilterState)
  );
  const { isLoading, data, error } = useListServiceRecommendationsCount({
    filter: filterQuery,
    vehiclesFilter: vehiclesFilterQuery,
  });

  return (
    <TableCount
      count={data?.count}
      isLoading={isLoading}
      error={!!error}
      entityName={label}
      extraClasses="font-normal mb-0.5"
      link={constructLinkToRecommendationsPage(
        filterQuery,
        vehiclesFilterQuery
      )}
    />
  );
};

export default RecommendationCount;
