import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { IoSearchSharp as SearchIcon } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { routes } from "services/routes";

import {
  NO_RESULTS_TEXT,
  SEARCH_PLACEHOLDER,
  START_TYPING_TEXT,
} from "./constants";
import GlobalSearchControlsInfo, {
  GlobalSearchEnterIcon,
} from "./GlobalSearchControlsInfo";
import { useGlobalSearch } from "./hooks";
import { searchConfig, SearchResource } from "./searchConfig";
import { markMatchingText, replaceUrlParams } from "./utils";

interface SingleResourceListProps {
  items: any[];
  type: string;
  searchInput: string;
  firstResultIndex: number;
  selectedIndex: number;
  onClick: () => void;
}

interface ListItemProps {
  item: any;
  config: SearchResource;
  index: number;
  innerSelectedIndex?: number;
  searchInput: string;
  onClick: () => void;
}

const SearchResultItem = ({
  config,
  item,
  onClick,
  searchInput,
  index,
  innerSelectedIndex,
}: ListItemProps) => {
  const elementRef = useRef<HTMLAnchorElement>(null);
  const secondaryText = config.searchFields
    .filter((field) => field.fieldName !== config.nameField)
    .map((field) => item[field.fieldName])
    .join(", ");

  if (index === innerSelectedIndex) {
    elementRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }

  return (
    <Link
      ref={elementRef}
      key={item[config.idField]}
      className={classNames(
        "p-2 border border-gray-200 flex flex-row items-center rounded gap-4 hover:bg-gray-200",
        { "bg-gray-200": index === innerSelectedIndex }
      )}
      to={replaceUrlParams(routes[config.routeKey], item[config.idField])}
      onClick={onClick}
    >
      <div className="text-gray-400">{config.icon}</div>
      <div
        className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap min-w-36"
        dangerouslySetInnerHTML={{
          __html: markMatchingText(item[config?.nameField], searchInput),
        }}
      ></div>
      <div
        className="text-gray-400 overflow-hidden text-ellipsis whitespace-nowrap"
        dangerouslySetInnerHTML={{
          __html: markMatchingText(secondaryText, searchInput),
        }}
      ></div>
      {index === innerSelectedIndex && (
        <div className="ml-auto mr-2">
          <GlobalSearchEnterIcon />
        </div>
      )}
    </Link>
  );
};

const SearchResultList = ({
  items,
  type,
  searchInput,
  firstResultIndex,
  selectedIndex,
  onClick,
}: SingleResourceListProps) => {
  const config = searchConfig.find((config) => config.type === type);
  const innerSelectedIndex =
    selectedIndex - firstResultIndex >= 0
      ? selectedIndex - firstResultIndex
      : undefined;

  if (items.length === 0 || config == null) {
    return;
  }

  return (
    <ul key={type} className="flex flex-col gap-2">
      <div className="font-bold text-sm mt-2">{type}</div>
      {items.map((item: any, index: number) => (
        <SearchResultItem
          key={item[config.idField]}
          config={config}
          item={item}
          index={index}
          innerSelectedIndex={innerSelectedIndex}
          searchInput={searchInput}
          onClick={onClick}
        ></SearchResultItem>
      ))}
    </ul>
  );
};

interface GlobalSearchProps {
  onClick: () => void;
}

const GlobalSearch = ({ onClick }: GlobalSearchProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { data, loading, debouncedSearchInput } = useGlobalSearch({
    searchInput,
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const navigate = useNavigate();

  // getCountAboveType handles calculates the number of items above the current type
  // and is used to calculate the index of the selected item inside individual resource lists
  const getCountAboveType = (type: string) => {
    let c = 0;
    for (const [key, value] of Object.entries(data)) {
      if (key === type) return c;

      c += value.length;
    }

    return c > 0 ? c - 1 : 0;
  };

  const handleInputChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedIndex(0);
    setSearchInput(ev.target.value);
  };

  const handleSelectedEnter = useCallback(() => {
    const item = Object.values(data).flat()[selectedIndex];
    let config: SearchResource | undefined = undefined;
    let c = 0;

    // handling finding the type for the flattened array of result lists index
    for (const [key, value] of Object.entries(data)) {
      if (selectedIndex <= value.length + c - 1) {
        config = searchConfig.find((config) => config.type === key)!;
        break;
      }

      c += value.length;
    }

    if (!config) {
      return;
    }

    navigate(replaceUrlParams(routes[config.routeKey], item[config.idField]));
    onClick();
  }, [data, navigate, onClick, selectedIndex]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const dataLength = Object.values(data).flat().length;
      if (event.key === "ArrowUp" && selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      } else if (event.key === "ArrowDown" && dataLength - 1 > selectedIndex) {
        setSelectedIndex(selectedIndex + 1);
      } else if (event.key === "Enter") {
        handleSelectedEnter();
      }
    },
    [data, handleSelectedEnter, selectedIndex]
  );

  // handling keyboard navigation
  useEffect(() => {
    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup: Remove event listener when the hook is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="min-h-24 flex flex-col">
      <TextField
        fullWidth
        autoFocus
        placeholder={SEARCH_PLACEHOLDER}
        id="input-with-icon-textfield"
        onChange={handleInputChange}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size={24} />
              </InputAdornment>
            ),
          },
        }}
        variant="standard"
      />
      <div className="max-h-[32rem] overflow-auto mt-4">
        {debouncedSearchInput !== "" &&
          Object.entries(data).map(([type, items]) => (
            <SearchResultList
              key={type}
              selectedIndex={selectedIndex}
              firstResultIndex={getCountAboveType(type)}
              items={items}
              type={type}
              searchInput={debouncedSearchInput}
              onClick={onClick}
            />
          ))}
        {debouncedSearchInput === "" && (
          <div className="flex flex-col justify-center text-sm h-5">
            {START_TYPING_TEXT}
          </div>
        )}
        {debouncedSearchInput !== "" &&
          !loading &&
          Object.values(data).every((items) => items.length === 0) && (
            <div className="flex flex-col justify-center text-sm h-5">
              {NO_RESULTS_TEXT}
            </div>
          )}

        {loading && (
          <div className="mt-auto h-5 flex flex-col justify-center">
            <LinearProgress />
          </div>
        )}
      </div>
      <GlobalSearchControlsInfo />
    </div>
  );
};

export default GlobalSearch;
