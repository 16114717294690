import classNames from "classnames";

import {
  FilterOperator,
  FilterOverviewFormat,
  FilterValue,
} from "features/ui/Filters/types";
import { getFormattedFilter } from "features/ui/Filters/utils";
import Label from "features/ui/Label";
import { DataType } from "features/ui/Table/TableBodyCell";

interface Props {
  fieldName: string;
  operator: FilterOperator;
  fieldValue: FilterValue;
  format?: FilterOverviewFormat;
  dense?: boolean;
  dataType?: DataType;
}

const DENSE_CLASSES = "!text-xs";

const FLabel = ({
  fieldName,
  operator,
  fieldValue,
  format = "badge",
  dense,
  dataType = DataType.STRING,
}: Props) => {
  const { formattedFieldName, formattedOperator, formattedFieldValue } =
    getFormattedFilter({ fieldName, operator, fieldValue }, format, dataType);

  const className = dense ? DENSE_CLASSES : "";

  const labelText = (
    <span>
      <span className="font-medium text-viaduct-black">
        {formattedFieldName}
      </span>{" "}
      <span className="italic font-normal text-gray-500">
        {formattedOperator}
      </span>
      {formattedFieldValue !== "" && ":"}
    </span>
  );

  if (format === "label") {
    return (
      <div className="flex-row">
        <Label text={labelText} className={className} />
        <Label
          text={formattedFieldValue}
          className={classNames("text-viaduct-black break-all", className)}
        />
      </div>
    );
  }

  if (format === "label-inline") {
    return (
      <div className="flex w-full">
        <Label
          text={labelText}
          className={classNames("!pl-0 !mb-0", className)}
        />
        <Label
          text={formattedFieldValue}
          className={classNames("text-viaduct-black !mb-0", className)}
        />
      </div>
    );
  }

  if (format === "label-block") {
    return (
      <div className="mb-2">
        <Label
          text={labelText}
          className={classNames("!pl-0 inline text-viaduct-black", className)}
        />
        <Label
          text={formattedFieldValue}
          className={classNames("inline text-viaduct-black", className)}
        />
      </div>
    );
  }

  return (
    <span className={className}>
      <span className="capitalize">{formattedFieldName}</span>{" "}
      {formattedOperator} {formattedFieldValue}
    </span>
  );
};

export default FLabel;
