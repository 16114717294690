interface CheckboxSelectedProps {
  allChecked: boolean;
  indeterminateChecked: boolean;
}
export const getCheckboxCheckedProps = (
  selectedValues: Set<string>,
  allSelectableValues: string[]
): CheckboxSelectedProps => {
  if (allSelectableValues.length === 0) {
    return {
      allChecked: false,
      indeterminateChecked: false,
    };
  }

  const allSelected =
    selectedValues.size === new Set<string>(allSelectableValues).size;

  return {
    allChecked: allSelected,
    indeterminateChecked: !allSelected && selectedValues.size > 0,
  };
};
